import React from "react"

// 3rd-party libraries
import { Dropdown } from "react-bootstrap"
import { FaEllipsisV } from "react-icons/fa"

//Redux
import { useSelector } from "react-redux"

import Icon from "../../../../commons/Icon"
import CopyFeed from "../../feeds-list/components/CopyFeed"
import { selectFeed } from "../../../../../redux/feed/feed.selectors"
import DeleteFeed from "./DeleteFeed"

export default function FeedActions() {
  const feed = useSelector(selectFeed)
  // The forwardRef is important!!
  // Dropdown needs access to the DOM node in order to position the Menu
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
      ref={ref}
      onClick={e => {
        e.preventDefault()
        onClick(e)
      }}
    >
      {children}
    </div>
  ))

  return (
    <Dropdown>
      <Dropdown.Toggle as={CustomToggle} id="form-actions">
        <Icon tooltip="More functions">
          <FaEllipsisV />
        </Icon>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item>
          <CopyFeed
            feed={feed}
            btn={<Dropdown.ItemText>Duplicate feed</Dropdown.ItemText>}
          />
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item>
          <DeleteFeed />
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}
