import React, { useState } from "react"

// 3rd-party libraries
import InfiniteScroll from "react-infinite-scroll-component"

// Reeler components
import FeedItem from "./FeedItem"
import Loader from "../loader/Loader"

import * as S from "./Feed.styles"

const Feed = ({ assets, feed }) => {
  const [noOfAssets, setNoOfAssets] = useState(feed?.feedSettings?.initial)
  const settings = feed?.feedSettings

  const loadMoreAssets = () => {
    setNoOfAssets(noOfAssets + settings?.initial)
  }

  return (
    <>
      <S.FeedContainer settings={settings}>
        <InfiniteScroll
          style={{ overflow: "none" }}
          dataLength={noOfAssets} //This is important field to render the next data
          next={() => loadMoreAssets()}
          hasMore={noOfAssets < assets.length}
          loader={<Loader />}
        >
          {assets &&
            assets
              .filter((item, index) => index < noOfAssets)
              .map(asset => {
                return <FeedItem key={asset.id} asset={asset} feed={feed} />
              })}
        </InfiniteScroll>
      </S.FeedContainer>
    </>
  )
}

export default Feed
