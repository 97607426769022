import CreatorsActionTypes from "./creators.types"

export const setLoading = payload => ({
  type: CreatorsActionTypes.SET_LOADING,
  payload,
})
export const setIsSearching = payload => ({
  type: CreatorsActionTypes.SET_IS_SEARCHING,
  payload,
})

export const setCreators = payload => ({
  type: CreatorsActionTypes.SET_CREATORS,
  payload,
})

export const setCreator = payload => ({
  type: CreatorsActionTypes.SET_CREATOR,
  payload,
})
export const setCreatorFilterParameters = payload => ({
  type: CreatorsActionTypes.SET_FILTER_PARAMETERS,
  payload,
})
export const setCreatorTextSearch = payload => ({
  type: CreatorsActionTypes.SET_CREATOR_TEXT_SEARCH,
  payload,
})
export const clearCreator = () => ({
  type: CreatorsActionTypes.CLEAR_CREATOR,
})
