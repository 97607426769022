import React, { useState } from "react"
import { FaCloudDownloadAlt } from "react-icons/fa"
import SpinnerComponent from "../../../commons/SpinnerComponent"
import ReelerTooltip from "../../../commons/ReelerTooltip/ReelerTooltip"

export default function AssetDownload({ asset }) {
  const [loading, setLoading] = useState(false)
  let { id, url } = asset

  const downloadAssetContent = async url => {
    setLoading(true)
    fetch(url)
      .then(res => res.blob())
      .then(blob => {
        const previewUrl = window.URL.createObjectURL(blob)
        const anchor = document.createElement("a")
        anchor.download =
          id + "." + url.split(/[#?]/)[0].split(".").pop().trim()
        anchor.crossOrigin = "Anonymous"
        anchor.href = URL.createObjectURL(blob)
        anchor.click()

        window.URL.revokeObjectURL(previewUrl)
        setLoading(false)
      })
  }

  return loading ? (
    <SpinnerComponent />
  ) : (
    <ReelerTooltip text="Download file to computer">
      <FaCloudDownloadAlt
        className="icon-btn icon-color"
        size={16}
        onClick={() => downloadAssetContent(url)}
      />
    </ReelerTooltip>
  )
}
