const FeedActionTypes = {
  SET_LOADING: "SET_LOADING",
  SET_SORTING_ASSETS: "SET_SORTING_ASSETS",
  INITIATE_FEED: "INITIATE_FEED",
  SET_FEED: "SET_FEED",
  UPDATE_FEED: "UPDATE_FEED",
  SET_IS_VALID: "SET_IS_VALID",
  SET_IS_FEED_DIRTY: "SET_IS_FEED_DIRTY",
  SET_IS_ASSETS_DIRTY: "SET_IS_ASSETS_DIRTY",
  SET_VALIDATION_MSG: "SET_VALIDATION_MSG",
  SET_ASSETS: "SET_ASSETS",
  UPDATE_ITEM_IN_ASSETS: "UPDATE_ITEM_IN_ASSETS",
  SET_DEFAULT_ASSETS: "SET_DEFAULT_ASSETS",
  RESET_ASSETS: "RESET_ASSETS",
  RESET: "RESET",
  GRID_SETTINGS_CHANGE: "GRID_SETTINGS_CHANGE",
  SOCIAL_WALL_SETTINGS_CHANGE: "SOCIAL_WALL_SETTINGS_CHANGE",
  SLIDESHOW_SETTINGS_CHANGE: "SLIDESHOW_SETTINGS_CHANGE",
  CAROUSEL_SETTINGS_CHANGE: "CAROUSEL_SETTINGS_CHANGE",
  FEED_LAYOUT_SETTINGS_CHANGE: "FEED_LAYOUT_SETTINGS_CHANGE",
  FEED_TYPE_CHANGE: "FEED_TYPE_CHANGE",
  FEED_EMBED_TYPE_CHANGE: "FEED_EMBED_TYPE_CHANGE",
  FEED_SORT_TYPE_CHANGE: "FEED_SORT_TYPE_CHANGE",
  SET_FEED_TAGS: "SET_FEED_TAGS",
  RESET_TO_INITIAL_STATE: "RESET_TO_INITIAL_STATE",
  SET_FEED_TABS_KEY: "SET_FEED_TABS_KEY",
}

export default FeedActionTypes
