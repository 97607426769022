/**
 * Generate a user based on user information
 *
 * @param user.
 
 * @return Formatted user name as string.
 */

export function generateUsername(user) {
  var username = ""

  if (user?.firstName || user?.lastName) {
    if (user?.firstName) {
      username += user?.firstName
    }

    if (user?.lastName) {
      if (username.length > 0) {
        username += " " + user?.lastName
      } else {
        username += user?.lastName
      }
    }
    return username
  }

  return user.email
}
