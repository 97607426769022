import React from "react"

// 3rd-party
import moment from "moment"
import {
  FaEdit,
  FaRegClock,
  FaPhone,
  FaEnvelope,
  FaMap,
  FaBirthdayCake,
  FaUserAlt,
  FaFacebook,
  FaYoutube,
  FaInstagram,
  FaRegImage,
  FaVideo,
  FaMicrophone,
  FaLocationArrow,
  FaMapMarkerAlt,
  FaLandmark,
} from "react-icons/fa"

// Redux
import { useSelector } from "react-redux"
import { selectActiveAsset } from "../../../../../../redux/asset/asset.selectors"

// Reeler components
import AssetTextField from "./AssetTextField"
import FieldValue from "./FieldValue"
import AssetViewIGPost from "./AssetViewIGPost"
import * as S from "../../styles"
import { CAMPAIGN_TYPE, MEDIA_TYPE } from "../../../../../../constants"
import AssetSourceName from "./AssetSourceName"
import AssetShare from "./AssetShare"
import AssetUsername from "./AssetUsername"
import EditedCaption from "./EditedCaption"
import { capitalize } from "lodash"
import AssetCreatorHashtags from "./AssetCreatorHashtags"
import { BsCardText, BsStars } from "react-icons/bs"
import ReelerTooltip from "../../../../../commons/ReelerTooltip/ReelerTooltip"
import SingleRowIcon from "./SingleRowIcon"
import AssetMap from "./AssetMap/AssetMap"
import AssetCreator from "./AssetCreator"
import AuthBasedComponent from "../../../../../authentication/AuthBasedComponent"
import { MAIN_ROUTES } from "../../../../../../constants/routes"

const aiParameters = [
  "resolution",
  "sharpness",
  "colors",
  "composition",
  "creativity",
]
const ScoreParameter = ({ parameter, score }) => (
  <div className="d-flex flex-row align-items-center me-2">
    <span className="me-1 capitalize">{parameter}:</span>
    <span>{score}</span>
  </div>
)

export default function AssetDetails() {
  const asset = useSelector(selectActiveAsset)

  return (
    <S.ModalTabContainer>
      <AuthBasedComponent plans={MAIN_ROUTES.creator.plans}>
        <AssetCreator />
      </AuthBasedComponent>
      {asset && <AssetShare assetId={asset.id} />}
      <AssetSourceName />

      {asset.createdAt && (
        <AssetTextField
          icon={
            <SingleRowIcon tooltip="Date of collection">
              <FaRegClock className="icon-color" />
            </SingleRowIcon>
          }
          text={moment(asset.createdAt.toDate()).format("YYYY-MM-DD")}
        />
      )}
      {asset?.media_type !== MEDIA_TYPE.TEXT && (
        <AssetTextField
          icon={
            <SingleRowIcon tooltip="Metadata">
              {asset.media_type === MEDIA_TYPE.IMAGE ? (
                <FaRegImage className="icon-color" />
              ) : asset.media_type === MEDIA_TYPE.VIDEO ? (
                <FaVideo className="icon-color" />
              ) : asset.media_type === MEDIA_TYPE.AUDIO ? (
                <FaMicrophone className="icon-color" />
              ) : null}
            </SingleRowIcon>
          }
          text={
            asset.media_type !== MEDIA_TYPE.AUDIO
              ? asset?.metadata
                ? `${asset?.metadata?.type} (${capitalize(
                    asset?.metadata?.orientation
                  )}, ${asset?.metadata?.dimensions?.width}x${
                    asset?.metadata?.dimensions?.height
                  }, ${asset?.metadata?.aspect_ratio?.aspectRatio})`
                : asset?.meta_data
                ? `${asset?.meta_data?.type} (${asset?.meta_data?.dimensions?.width}x${asset?.meta_data?.dimensions?.height}, ${asset?.meta_data?.aspect_ratio?.aspectRatio})`
                : asset.type
              : asset.type
          }
        />
      )}
      {asset?.ai_score && (
        <AssetTextField
          icon={
            <SingleRowIcon tooltip="AI content score">
              <BsStars />
            </SingleRowIcon>
          }
          text={
            <div className="d-flex flex-row align-items-center">
              <strong className="me-3">{asset?.ai_score?.total_score}</strong>
              <div className="d-flex flex-row align-items-center">
                {aiParameters.map((parameter, index) => (
                  <ScoreParameter
                    key={index}
                    parameter={parameter}
                    score={asset?.ai_score?.parameters[parameter]}
                  />
                ))}
              </div>
            </div>
          }
        />
      )}

      {asset?.landmark ? (
        <AssetTextField
          icon={
            <SingleRowIcon tooltip="Landmark">
              <FaLandmark />
            </SingleRowIcon>
          }
          text={
            <div className="d-flex flex-row align-items-center">
              <span>
                {asset?.landmark?.name}
                {asset?.landmark?.address_components
                  ? ` (${
                      asset?.landmark?.address_components?.locality
                        ? asset?.landmark?.address_components?.locality
                        : ""
                    }${
                      asset?.landmark?.address_components?.country
                        ? asset?.landmark?.address_components?.locality
                          ? ` ,${asset?.landmark?.address_components?.country}`
                          : `${asset?.landmark?.address_components?.country}`
                        : ""
                    })`
                  : null}
              </span>
              {asset.landmark?.latlng !== null &&
              asset.landmark?.latlng?.latitude !== null &&
              asset.landmark?.latlng?.longitude !== null ? (
                <div className="mx-2">
                  <AssetMap
                    location={{
                      description: asset?.landmark?.name,
                      lat: asset.landmark?.latlng?.latitude,
                      lng: asset.landmark?.latlng?.longitude,
                    }}
                  />
                </div>
              ) : null}
            </div>
          }
        />
      ) : asset?.location ? (
        <AssetTextField
          icon={
            <SingleRowIcon tooltip="Location">
              <FaMapMarkerAlt />
            </SingleRowIcon>
          }
          text={
            <div className="d-flex flex-row align-items-center">
              <span>{asset.location?.formatted_address}</span>
              {asset.location?.geometry && (
                <div className="mx-2">
                  <AssetMap
                    location={{
                      description: asset.location?.formatted_address,
                      lat: asset?.location?.geometry.lat,
                      lng: asset?.location?.geometry.lng,
                    }}
                  />
                </div>
              )}
            </div>
          }
        />
      ) : null}

      {asset?.media && asset.media.permalink && (
        <AssetViewIGPost permalink={asset.media.permalink} />
      )}

      <AssetUsername />

      {asset?.caption && (
        <AssetTextField
          icon={
            <ReelerTooltip text="Creator caption">
              <BsCardText className="icon-color" />
            </ReelerTooltip>
          }
          text={<FieldValue value={asset?.caption ? asset?.caption : ""} />}
        />
      )}
      <EditedCaption />
      {asset?.creator_tags ? <AssetCreatorHashtags asset={asset} /> : null}

      {asset.campaign_type === CAMPAIGN_TYPE.upload.type && asset?.order ? (
        asset.order.map(fieldId => {
          let assetValue = asset.values[fieldId]
          return assetValue ? (
            <S.FieldContainer key={fieldId}>
              <S.FieldTitle>
                {assetValue.database_name
                  ? assetValue.database_name
                  : assetValue.label
                  ? assetValue.label
                  : "n/a"}
              </S.FieldTitle>

              {assetValue?.values && assetValue.values.length > 0 ? (
                assetValue.values.map((value, key) => (
                  <S.FieldValue key={key}>{value}</S.FieldValue>
                ))
              ) : (
                <FieldValue value={assetValue.value} />
              )}
            </S.FieldContainer>
          ) : null

          // asset.values[fieldId]?.label ? (
          //   <S.FieldContainer>
          //     <S.FieldTitle>
          //       {asset.values[fieldId]?.label
          //         ? asset.values[fieldId]?.label
          //         : asset.values[fieldId]?.database_name
          //         ? asset.values[fieldId]?.database_name
          //         : "n/a"}
          //     </S.FieldTitle>

          //     <S.FieldValue>
          //       {asset.values[fieldId]?.value
          //         ? asset.values[fieldId]?.value
          //         : "n/a"}
          //     </S.FieldValue>
          //   </S.FieldContainer>
          // ) : null
        })
      ) : (
        <>
          {(asset.firstName || asset.lastName) && (
            <AssetTextField
              icon={<FaUserAlt className="icon-color" />}
              text={
                asset.firstName
                  ? asset.firstName
                  : null + " " + asset.lastName
                  ? asset.lastName
                  : null
              }
            />
          )}
          {asset.name && (
            <AssetTextField
              icon={<FaUserAlt className="icon-color" />}
              text={asset.name ? asset.name : null}
            />
          )}
          {asset.email && (
            <AssetTextField
              icon={<FaEnvelope className="icon-color" />}
              text={asset.email}
            />
          )}

          {asset.titleOfTheWork && (
            <AssetTextField
              icon={<FaEdit className="icon-color" />}
              text={asset.titleOfTheWork}
            />
          )}

          {asset.contentLocation && (
            <AssetTextField
              icon={<FaMap className="icon-color" />}
              text={asset.contentLocation}
            />
          )}

          {asset.phone && (
            <AssetTextField
              icon={<FaPhone className="icon-color" />}
              text={asset.phone}
            />
          )}

          {asset.street && (
            <AssetTextField
              icon={<FaMap className="icon-color" />}
              text={asset.street}
            />
          )}
          {(asset.postalCode || asset.city) && (
            <AssetTextField
              icon={<FaMap className="icon-color" />}
              text={
                (asset.postalCode ? `${asset.postalCode} ` : "") +
                (asset.city ? `${asset.city} ` : "")
              }
            />
          )}

          {asset.country && (
            <AssetTextField
              icon={<FaMap className="icon-color" />}
              text={asset.country}
            />
          )}
          {asset.birthDate && (
            <AssetTextField
              icon={<FaBirthdayCake className="icon-color" />}
              text={
                typeof asset.birthDate === "string" ||
                asset.birthDate instanceof String
                  ? moment(asset.birthDate).format("YYYY-MM-DD")
                  : moment(asset.birthDate.toDate()).format("YYYY-MM-DD")
              }
            />
          )}
          {asset.facebook && (
            <AssetTextField
              icon={<FaFacebook className="icon-color" />}
              text={asset.facebook}
            />
          )}
          {asset.instagram && (
            <AssetTextField
              icon={<FaInstagram className="icon-color" />}
              text={asset.instagram}
            />
          )}
          {asset.youtube && (
            <AssetTextField
              icon={<FaYoutube className="icon-color" />}
              text={asset.youtube}
            />
          )}
        </>
      )}
      {asset?.thirdPartyApprovals?.third_party_email ? (
        <S.FieldContainer>
          <S.FieldTitle>Approval email</S.FieldTitle>
          <FieldValue value={asset?.thirdPartyApprovals?.third_party_email} />
        </S.FieldContainer>
      ) : null}
    </S.ModalTabContainer>
  )
}
