import React, { useState, useRef, useEffect } from "react"
import { Form, Modal, InputGroup } from "react-bootstrap"
import { FaTrash, FaUsers, FaUsersCog } from "react-icons/fa"
import { database } from "../../../../../firebase"
import firebase from "firebase/app"
import Select from "react-select"

import { useDispatch, useSelector } from "react-redux"
import { selectCurrentUser } from "../../../../../redux/user/user.selectors"
import { selectAccountId } from "../../../../../redux/account/account.selectors"
import {
  selectActiveFilter,
  selectShowViewers,
} from "../../../../../redux/filter/filter.selectors"
import {
  clearFilter,
  setActiveFilter,
  setShowViewers,
} from "../../../../../redux/filter/filter.actions"
import ReelerButton from "../../../../commons/ReelerButton"
import ReelerTooltip from "../../../../commons/ReelerTooltip/ReelerTooltip"
import useUserFeedbackMessage from "../../../../../hooks/useUserFeedbackMessage"
import Icon from "../../../../commons/Icon"
import { generateUsername } from "../../../../../utils/GenerateUsername"
import withoutProperty from "../../../../../utils/withoutProperty"
import SpinnerComponent from "../../../../commons/SpinnerComponent"

import { USER_ROLES } from "../../../../../constants/routes"
import { USER_STATUS } from "../../../../../constants"

const customStyles = {
  container: () => ({
    // none of react-select's styles are passed to <Control />
    width: `100%`,
  }),
}

const AddViewers = () => {
  const [viewer, setViewer] = useState("")
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(false)
  const [isRemoving, setIsRemoving] = useState(false)
  const dispatch = useDispatch()
  const filter = useSelector(selectActiveFilter)
  const currentUser = useSelector(selectCurrentUser)
  const accountId = useSelector(selectAccountId)
  const showViewers = useSelector(selectShowViewers)
  const { setUserFeedbackMessage } = useUserFeedbackMessage()
  // eslint-disable-next-line no-unused-vars
  const modalRef = useRef()

  useEffect(() => {
    setLoading(true)
    if (accountId) {
      const usersRef = database.users
        .where("accounts", "array-contains", accountId)
        .where("role", "==", USER_ROLES.viewer)

      var unsubscribe = usersRef.onSnapshot(querySnapshot => {
        let userArray = []
        querySnapshot.forEach(doc => {
          userArray.push(database.formatDoc(doc))
        })
        setUsers(userArray)
        setLoading(false)
      })
      return () => unsubscribe()
    }
  }, [accountId])

  const handleChange = user => {
    setLoading(true)
    let userIds
    if (filter?.userIds) {
      userIds = [...filter?.userIds, user.id]
    } else {
      userIds = [user.id]
    }

    let selectedUsers
    if (filter?.users) {
      selectedUsers = [...filter?.users, user]
    } else {
      selectedUsers = [user]
    }

    updateFilterinDB(
      selectedUsers,
      userIds,
      `${generateUsername(user)} added successfully!`
    )
  }

  const removeViewer = user => {
    let userIds = filter?.userIds?.filter(uId => uId !== user.id)

    let selectedUsers = filter?.users?.filter(u => u.id !== user.id)

    updateFilterinDB(
      selectedUsers,
      userIds,
      `${generateUsername(user)} removed successfully!`
    )
  }

  const addAll = () => {
    let userIds = users.map(u => u.id)
    let selectedUsers = users.map(u => u)
    updateFilterinDB(selectedUsers, userIds, "All viewers added successfully!")
  }
  const removeAll = () => {
    updateFilterinDB([], [], "All viewers removed successfully!")
  }

  const updateFilterinDB = async (selectedUsers, userIds, userMessage) => {
    let updateFilter = withoutProperty(filter, "id")
    updateFilter["userIds"] = userIds
    updateFilter["users"] = selectedUsers
    const filterRef = database.accounts
      .doc(`${accountId}`)
      .collection("filters")
      .doc(filter.id)
    await filterRef.update(updateFilter)

    dispatch(setActiveFilter({ id: filter.id, ...updateFilter }))
    setUserFeedbackMessage(userMessage)
    setViewer("")
    setLoading(false)
  }

  const closeModal = () => {
    dispatch(setShowViewers(false))
  }

  return (
    <>
      <Modal ref={modalRef} show={showViewers} onHide={closeModal}>
        <Form>
          <Modal.Header closeButton>
            <Modal.Title>Viewers</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Form.Group>
              <InputGroup>
                <Select
                  loadingMessage="Loading..."
                  styles={customStyles}
                  theme={theme => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: "var(--reeler-o-25)",
                      primary: "var(--reeler-light)",
                    },
                  })}
                  value={viewer}
                  placeholder="Search viewers"
                  onChange={(selectedOption, action) => {
                    handleChange(selectedOption.value)
                  }}
                  options={users
                    ?.filter(u => u?.status !== USER_STATUS.DEACTIVATED)
                    ?.filter(
                      u => !filter?.users?.some(value => value.id === u.id)
                    )
                    .map(u => ({
                      value: u,
                      label: generateUsername(u),
                    }))}
                />
              </InputGroup>
              <Form.Text muted>Search and add viewers</Form.Text>
            </Form.Group>
            <Form.Group>
              <ReelerButton
                dispatch={() => addAll()}
                text="Add all viewers"
                styleClass="btn-secondary"
              />
              <ReelerButton
                dispatch={() => removeAll()}
                text="Remove all viewers"
                styleClass="btn-secondary ms-3"
              />
            </Form.Group>
            <Form.Group>
              <Form.Text>
                <strong>Added viewers:</strong>
              </Form.Text>
              {loading ? <SpinnerComponent /> : null}
              <div className="d-flex flex-column">
                {filter?.users?.map(user => (
                  <div
                    key={user.id}
                    className="d-flex flex-row align-items-center"
                  >
                    <div>
                      <span style={{ fontSize: "0.85rem" }}>
                        {generateUsername(user)}
                      </span>
                    </div>
                    <div className="mx-3">
                      <Icon
                        onClick={() => removeViewer(user)}
                        tooltip="Remove viewer"
                        size="sm"
                      >
                        <FaTrash />
                      </Icon>
                    </div>
                  </div>
                ))}
              </div>
            </Form.Group>
          </Modal.Body>

          <Modal.Footer className="d-flex justify-content-between">
            <div>
              <ReelerButton
                text="Close"
                styleClass="btn-secondary"
                dispatch={closeModal}
              />
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}

export default AddViewers
