import React from "react"
import { GridContainer, GridItem, GridVideoItem, SingleImage } from "../styles"
import MediaType from "../../../../../../commons/MediaType"
import { selectFeed } from "../../../../../../../redux/feed/feed.selectors"
import { useSelector } from "react-redux"
import { IMG_SIZES } from "../../../../../../../constants"

export default function EmailSingelWidget({ assets }) {
  const feed = useSelector(selectFeed)

  const asset = assets[0]

  return (
    <div style={{ maxHeight: "500px" }}>
      <MediaType
        asset={asset}
        imgSize={IMG_SIZES.preView}
        objectFit="contain"
        br={feed?.feedSettings?.borderRadius}
      />
    </div>
  )
}
