import React, { useRef, useState } from "react"
import * as S from "../../styles"
import { Form, FloatingLabel, Row, Col } from "react-bootstrap"
import ReelerButton from "../../../../../commons/ReelerButton"
import { useDispatch, useSelector } from "react-redux"
import { selectActiveAsset } from "../../../../../../redux/asset/asset.selectors"
import axios from "axios"
import Text from "../../../../../commons/Text"
import { updateAsset } from "../../../../../../redux/asset/asset.actions"

export default function AssetGenAi() {
  const asset = useSelector(selectActiveAsset)
  const promptRef = useRef()
  const temperatureRef = useRef()
  const topPRef = useRef()
  const topKRef = useRef()
  const [isLoading, setIsLoading] = useState(null)
  const [result, setResult] = useState(null)
  const dispatch = useDispatch()

  const handleRunAi = async () => {
    setIsLoading(true)
    setResult(null)
    try {
      const data = {
        assetId: asset.id,
      }

      if (promptRef.current.value) {
        data.prompt = promptRef.current.value
      }

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_FIREBASE_HTTPS_FUNCTIONS}/contentcurator`,
        headers: {},
        data: data,
      }

      const response = await axios.request(config)

      const updateData = response.data

      console.log(updateData)

      dispatch(updateAsset({ ...asset, ...updateData }))

      let x = response?.data?.contentCurator

      // check if response.data is an object
      let pretty
      if (typeof x === "object") {
        pretty = JSON.stringify(x, undefined, 4)
      } else {
        const obj = JSON.parse(x.trim())
        pretty = JSON.stringify(obj, undefined, 4)
      }

      setResult(pretty)
      setIsLoading(false)
    } catch (e) {
      console.log(e)
      setIsLoading(false)
    }
  }

  return (
    <S.ModalTabContainer>
      <Form.Group className="mb-3">
        <Form.Label>AI Prompt</Form.Label>
        <Form.Control
          as="textarea"
          rows={10}
          ref={promptRef}
          placeholder="Enter test prompt"
          size="sm"
        />
        <ReelerButton
          text="Run AI"
          dispatch={handleRunAi}
          loading={isLoading}
          disabled={isLoading}
          styleClass="btn-main me-2 mt-2"
        />
        {/* <ReelerButton
          text="Edit photo AI"
          dispatch={handleEditPhoto}
          loading={isLoading}
          disabled={isLoading}
          styleClass="btn-secondary"
        /> */}
      </Form.Group>
      {result && (
        <Form.Group className="mb-3">
          <Form.Label>Result</Form.Label>
          <Form.Control
            as="textarea"
            rows={10}
            style={{ height: "400px" }}
            size="sm"
            value={result}
            disabled
          />
        </Form.Group>
      )}
    </S.ModalTabContainer>
  )
}
