import styled, { keyframes } from "styled-components"

const fade = keyframes`

  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }

`

export const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Dots = styled.div`
  width: 3.5em;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;

  div {
    width: 0.8em;
    height: 0.8em;
    border-radius: 50%;
    background-color: var(--reeler-icon);
    animation: ${fade} 0.8s ease-in-out alternate infinite;
  }

  div:nth-of-type(1) {
    animation-delay: -0.4s;
  }

  div:nth-of-type(2) {
    animation-delay: -0.2s;
  }
`
