import React, { useState } from "react"
import "./grid.css"
import * as S from "./styles"
import GridItem from "./components/GridItem"

const Grid = ({ feed, assets }) => {
  const [noOfAssets, setNoOfAssets] = useState()

  return (
    <>
      <S.GridContainer settings={feed?.feedSettings}>
        {assets &&
          assets
            .filter(
              (item, i) =>
                i < feed?.feedSettings?.columns * feed?.feedSettings?.rows
            )
            .map((asset, index) => <GridItem feed={feed} asset={asset} />)}
      </S.GridContainer>
      {noOfAssets < assets.length ? (
        <S.LoadMoreContainer>
          <S.LoadMoreButton
            onClick={() =>
              setNoOfAssets(noOfAssets + feed?.feedSettings?.columns * 2)
            }
          >
            Load More
          </S.LoadMoreButton>
        </S.LoadMoreContainer>
      ) : null}
    </>
  )
}

export default Grid
