import { ASSET_STATE } from "../../constants"
import ImportActionsTypes from "./import.types"

export const INITIAL_STATE = {
  media: [],
  selectedMedia: [],
  settings: {
    tags: {},
    replace: false,
    main: true,
    library: false,
    state: ASSET_STATE.approved,
    title: "",
    notes: "",
  },
  errorMessage: undefined,
  mediaErrors: [],
}

const importReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ImportActionsTypes.ADD_MEDIA:
      return {
        ...state,
        media: [...state.media, ...action.payload],
        selectedMedia: [...state.selectedMedia, ...action.payload],
      }
    case ImportActionsTypes.RESET_IMPORT:
      return {
        media: [],
        selectedMedia: [],
        settings: {
          tags: {},
          replace: false,
          main: true,
          library: false,
          state: ASSET_STATE.approved,
          title: "",
          notes: "",
        },
        errorMessage: undefined,
        mediaErrors: [],
      }

    case ImportActionsTypes.ADD_SELECTED_MEDIA:
      return {
        ...state,
        selectedMedia: [...state.selectedMedia, action.payload],
      }
    case ImportActionsTypes.REMOVE_SELECTED_MEDIA:
      return {
        ...state,
        selectedMedia: state.selectedMedia.filter(
          media => media.id !== action.payload.id
        ),
      }

    case ImportActionsTypes.UPDATE_SETTINGS:
      return {
        ...state,
        settings: { ...state.settings, ...action.payload },
      }
    case ImportActionsTypes.ADD_ERROR_MEDIA:
      return {
        ...state,
        mediaErrors: [...state.mediaErrors, action.payload],
      }
    case ImportActionsTypes.UPDATE_SELECTED_MEDIA_FILE:
      return {
        ...state,
        selectedMedia: state.selectedMedia.map((item, index) => {
          if (item.id !== action.payload.id) {
            // This isn't the item we care about - keep it as-is
            return item
          }

          // Otherwise, this is the one we want - return an updated value
          return {
            ...item,
            ...action.payload,
          }
        }),
      }

    default:
      return state
  }
}

export default importReducer
