import React, { useState, useEffect } from "react"
import { database, functions } from "../../../firebase"
import moment from "moment"
import { useDispatch } from "react-redux"

// Third party
import { useParams } from "react-router-dom"
import parse from "html-react-parser"
import * as S from "./ConfirmationPage.styled"
import { Container, Row, Col, Button, Form } from "react-bootstrap"
import Media from "./components/Media"
import Loader from "../../commons/loader/Loader"
import PublicNotFound from "../publicNotFound/PublicNotFound"
import DownloadAssets from "./components/DownloadAssets"

moment.locale()

export default function ConfirmationPage() {
  const { confirmationId } = useParams()
  const [confirmation, setConfirmation] = useState(null)

  useEffect(() => {
    database.confirmations
      .doc(confirmationId)
      .get()
      .then(snapshot => {
        if (snapshot) {
          if (snapshot.data()) {
            setConfirmation(snapshot.data())
            console.log(snapshot.data())
          }
        } else {
          console.log("No data available")
        }
      })
  }, [confirmationId])

  if (!confirmation)
    return (
      <S.LoadingScreen>
        <Loader />
      </S.LoadingScreen>
    )

  return confirmation ? (
    <S.Styles>
      <Container className="pt-5 pb-5">
        <Row>
          <Col>
            <S.ImageContainer $align={confirmation.align}>
              <img
                alt=""
                style={{
                  width: `${confirmation.width}%`,
                  margin: `${confirmation.margin}px`,
                  padding: `${confirmation.padding}px`,
                }}
                src={confirmation.image_url}
              />
            </S.ImageContainer>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col>{parse(confirmation.intro)}</Col>
        </Row>
        {confirmation?.values ? (
          <>
            <Row>
              <Col>
                <hr class="solid" />
              </Col>
            </Row>
            <Row>
              <Col md={{ span: 10, offset: 1 }} className="d-flex flex-column">
                {confirmation?.order
                  ? confirmation?.order.map(fieldId => {
                      let field = confirmation?.values[fieldId]
                      if (field && field?.value) {
                        return (
                          <S.MotivationSection key={fieldId}>
                            <h2>
                              {field?.label
                                ? field.label
                                : field?.database_name
                                ? field?.database_name
                                : ""}
                            </h2>
                            <p>{field.value}</p>
                          </S.MotivationSection>
                        )
                      }
                    })
                  : Object.keys(confirmation?.values).map(fieldId => {
                      let field = confirmation?.values[fieldId]
                      if (field && field?.value) {
                        return (
                          <S.MotivationSection>
                            <h2>
                              {field?.label
                                ? field.label
                                : field?.database_name
                                ? field?.database_name
                                : ""}
                            </h2>
                            <p>{field.value}</p>
                          </S.MotivationSection>
                        )
                      }
                    })}
              </Col>
            </Row>
          </>
        ) : null}
        <Row>
          <Col>
            <hr class="solid" />
            <h2>Uploaded media</h2>
          </Col>
        </Row>
        <Row>
          {confirmation.assets.map(asset => (
            <Col sm={6} md={6} lg={3} className="mb-4">
              <Media asset={asset} />
            </Col>
          ))}
        </Row>
        <Row>
          <Col>
            <DownloadAssets assets={confirmation.assets} />
          </Col>
        </Row>
      </Container>
    </S.Styles>
  ) : (
    <PublicNotFound />
  )
}
