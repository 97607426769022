import styled from "styled-components"

export const Container = styled.div`
  padding: 1rem 15px;
  min-height: 100vh;
  h1 {
    color: #4c4c4c;
    font-size: 1.5rem;
  }
`
export const FileMessageContainer = styled.span`
  display: inline;
  color: var(--reeler-danger);
`

export const ImportContainer = styled.div`
  min-height: 80vh;
  margin-top: 3rem;
`
export const ToggleView = styled.div`
  display: flex;
  align-self: auto;
`
export const TopLeft = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`
export const MediaFile = styled.div`
  position: relative;
  height: 100%;
  border-radius: 3px;
  cursor: pointer;
  background: #f8f9fa !important;
  box-shadow: rgb(0 0 0 / 13%) 0px 3.2px 7.2px 0px,
    rgb(0 0 0 / 11%) 0px 0.6px 1.8px 0px;
  border: ${({ borderStyle }) =>
    borderStyle === "selected"
      ? "1px solid var(--reeler)!important;"
      : borderStyle === "error"
      ? "1px solid var(--reeler-danger)!important;"
      : "1px solid #dee2e6 !important;"};
  :hover {
    border: 1px solid var(--reeler) !important;
  }
`

export const SquareContainer = styled.div`
  width: 14px;
  aspect-ratio: 1/1;
  background-color: transparent;
  /* display: ${({ bulkEditClicked }) =>
    bulkEditClicked ? "block" : "none"}; */
  z-index: 2;
  cursor: default;
  position: relative;
  border-radius: 2px;
  padding: 14px;

  svg {
    position: absolute;
  }
`

export const Square = styled.div`
  width: 14px;
  aspect-ratio: 1;
  background-color: #ffff;
  /* display: ${({ bulkEditClicked }) =>
    bulkEditClicked ? "block" : "none"}; */
  cursor: default;
  z-index: 99;
  position: absolute;
  border-radius: 2px;
  border: 1px solid black;
  top: 7px;
  left: 7px;
`
