import styled, { css } from "styled-components"

export const MediaObject = styled.div`
  user-select: none;
  position: relative;
  cursor: pointer;
  border: ${({ selected }) =>
    selected
      ? "3px solid var(--reeler) !important"
      : "1px solid #dee2e6 !important"};
  /* box-shadow: ${({ selected }) =>
    selected ? "0 0 0 0.2rem var(--reeler-o-25) !important;" : "none"}; */

  background: var(--reeler-white);
`

export const Media = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1/1;
  max-height: 200px;
  padding: 0.5rem;
  margin-bottom: -0.5rem;
`
export const Body = styled.div`
  padding: 0.5rem 0.5rem 0 0.5rem;
`
export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 0.5rem 0.5rem 0.5rem;
`

const truncatedCaption = css`
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
`
export const Caption = styled.div`
  margin-bottom: 5px;
  font-size: 0.75rem;
  ${({ showMore }) => (!showMore ? truncatedCaption : "")};
`
export const TopRight = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 0.5rem;

  background: var(--reeler-grey);
  color: var(--reeler-icon);
  border-radius: 3px;
`
export const Image = styled.img`
  object-fit: contain;
  background: var(--reeler-white);
  display: block;
  aspect-ratio: auto;
  overflow: hidden;
  width: 100%;
  height: 100%;
`
export const VideoContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`

export const Video = styled.video`
  width: 100%;
  height: 100%;
  background: var(--reeler-white);
`

export const PlayIcon = styled.div`
  position: absolute;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
  color: white;
  z-index: 1;
  &:hover {
    color: var(--reeler-grey-darker);
  }
`
