import FilterActionTypes from "./filter.types"
import { ASSET_STATE } from "../../constants"

const INITIAL_STATE = {
  sort_by: "createdAt",
  assetView: "grid",
  activeFilter: null,
  viewerFilterParameters: null,
  filterParameters: null,
  mediaFilterIsOpen: false,
  filtredAssets: [],
  noOfFiltredAssets: 0,
  loading: false,
  forceClearFilter: false,
  showFilter: false,
  showSaveFilter: false,
  showViewers: false,
}

const filterReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FilterActionTypes.SET_ACTIVE_FILTER:
      return {
        ...state,
        activeFilter: action.payload,
      }
    case FilterActionTypes.SET_FILTER_PARAMETERS:
      return {
        ...state,
        filterParameters: { ...state.filterParameters, ...action.payload },
      }
    case FilterActionTypes.SET_VIEWER_FILTER_PARAMETERS:
      return {
        ...state,
        viewerFilterParameters: {
          ...state.viewerFilterParameters,
          ...action.payload,
        },
      }
    case FilterActionTypes.CLEAR_FILTER:
      return {
        ...state,
        activeFilter: null,
        filterParameters: null,
        viewerFilterParameters: null,
        filtredAssets: [],
        subFiltredAssets: [],
        noOfFiltredAssets: 0,
        forceClearFilter: false,
      }
    case FilterActionTypes.CLEAR_FILTER_PARAMETERS:
      return {
        ...state,

        filterParameters: null,
        filtredAssets: [],
        subFiltredAssets: [],
        noOfFiltredAssets: 0,
        forceClearFilter: false,
      }
    case FilterActionTypes.CLEAR_VIEWER_FILTER_PARAMETERS:
      return {
        ...state,
        viewerFilterParameters: null,
      }
    case FilterActionTypes.SET_FILTRED_ASSETS:
      return {
        ...state,
        filtredAssets: action.payload,
      }
    case FilterActionTypes.SET_SUB_FILTRED_ASSETS:
      return {
        ...state,
        subFiltredAssets: action.payload,
      }
    case FilterActionTypes.SET_MEDIA_FILTER_OPEN:
      return {
        ...state,
        mediaFilterIsOpen: true,
      }
    case FilterActionTypes.SET_MEDIA_FILTER_CLOSE:
      return {
        ...state,
        mediaFilterIsOpen: false,
      }
    case FilterActionTypes.UPDATE_EMAIL:
      return {
        ...state,
        email: action.payload,
      }
    case FilterActionTypes.FORCE_CLEAR_FILTER:
      return {
        ...state,
        forceClearFilter: action.payload,
      }
    case FilterActionTypes.DELETE_TAG_SUCCESS:
    case FilterActionTypes.CREATE_TAG_SUCCESS:
      return {
        ...state,
        activeFilter: { ...state.activeFilter, tags: action.payload },
        newTag: "",
      }
    case FilterActionTypes.CLEAR_ALL_TAGS:
      return {
        ...state,
        activeFilter: { ...state.activeFilter, tags: "" },
      }
    case FilterActionTypes.CREATE_ACCOUNT_FILTER_FAILURE:
      return {
        ...state,
        activeFilter: { ...state.activeFilter, error: action.payload },
      }
    case FilterActionTypes.UPDATE_NUMBER_OF_FILTRED_ASSETS:
      return {
        ...state,
        noOfFiltredAssets: action.payload,
      }
    case FilterActionTypes.SET_MEDIA_SORT_BY:
      return {
        ...state,
        sort_by: action.payload,
      }
    case FilterActionTypes.SET_ASSET_VIEW:
      return {
        ...state,
        assetView: action.payload,
      }
    case FilterActionTypes.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      }
    case FilterActionTypes.SET_ACTIVE_ACCOUNT_FILTER:
      return {
        ...state,
        activeAccountFilter: action.payload,
      }
    case FilterActionTypes.SHOW_FILTER:
      return {
        ...state,
        showFilter: !state.showFilter,
      }
    case FilterActionTypes.SET_SHOW_FILTER:
      return {
        ...state,
        showFilter: action.payload,
      }
    case FilterActionTypes.CLEAR_ACTIVE_ACCOUNT_FILTER:
      return {
        ...state,
        activeFilter: null,
      }
    case FilterActionTypes.SET_SHOW_SAVE_FILTER:
      return {
        ...state,
        showSaveFilter: action.payload,
      }
    case FilterActionTypes.SET_SHOW_VIEWERS:
      return {
        ...state,
        showViewers: action.payload,
      }
    case FilterActionTypes.RESET_TO_INITIAL_STATE:
      return {
        ...INITIAL_STATE,
      }
    default:
      return state
  }
}

export default filterReducer
