import React, { useRef, useState } from "react"

// Firebase
import firebase from "firebase/app"
import { database } from "../../../../../../firebase"
// Redux
import { useSelector, useDispatch } from "react-redux"
import { selectActiveAsset } from "../../../../../../redux/asset/asset.selectors"
import { selectCurrentUser } from "../../../../../../redux/user/user.selectors"
import { updateAsset } from "../../../../../../redux/asset/asset.actions"
// Third party
import { Form, Modal } from "react-bootstrap"

// Reeler
import { timeAgo } from "../../../../../../utils/CalculateTimeAgo"
import * as S from "../../styles"
import ReelerButton from "../../../../../commons/ReelerButton"
import FadeAlert from "../../../../../commons/FadeAlert/FadeAlert"
import {
  ASSET_RIGHT_STATUS,
  ASSET_STATUS,
  RIGHTS_REQUEST_TYPE,
} from "../../../../../../constants"
import ReelerAccordation from "../../../../../commons/ReelerAccordation"

export default function AssetChangeStatusManually() {
  const dispatch = useDispatch()
  const asset = useSelector(selectActiveAsset)
  const user = useSelector(selectCurrentUser)
  const noteRef = useRef()
  const statusRef = useRef()
  const [type, setType] = useState(RIGHTS_REQUEST_TYPE.creator)
  const [loading, setLoading] = useState(false)
  const [msg, setMsg] = useState(null)
  const [show, setShow] = useState(false)

  const handleShow = () => {
    setShow(true)
  }
  const handleClose = () => {
    setShow(false)
  }
  const handleSave = () => {
    setLoading(true)
    const note = noteRef.current.value
    const newStatus = statusRef.current.value

    let status_log = {
      from: asset.status,
      to: newStatus,
      user_id: user.id,
      type,
      note,
      user_name:
        user.firstName || user.lastName
          ? user.firstName + " " + user.lastName
          : user.email,
      createdAt: Date.now(),
    }

    let updateObject = {
      status_logs: firebase.firestore.FieldValue.arrayUnion(status_log),
    }

    if (type === RIGHTS_REQUEST_TYPE.creator) {
      updateObject["status"] = newStatus
    } else {
      updateObject["thirdPartyApprovalStatus"] = newStatus
    }
    console.log(status_log)

    if (type === RIGHTS_REQUEST_TYPE.creator) {
      database.assets
        .doc(asset.id)
        .update({
          status: newStatus,
          status_logs: firebase.firestore.FieldValue.arrayUnion(status_log),
        })
        .then(() => {
          if (asset?.status_logs) {
            dispatch(
              updateAsset({
                ...asset,
                status: newStatus,
                status_logs: [...asset?.status_logs, status_log],
              })
            )
          } else {
            dispatch(
              updateAsset({
                ...asset,
                status: newStatus,
                status_logs: [status_log],
              })
            )
          }
        })
        .then(() => {
          setLoading(false)
          setMsg({
            success: true,
            lead: "Success!",
            text: "Your comment was added",
          })
          noteRef.current.value = null
        })
        .catch(err => {
          console.log(err)
          setLoading(false)
          setMsg({
            success: false,
            lead: "Oops!",
            text: "Something went wrong",
          })
        })
    } else {
      database.assets
        .doc(asset.id)
        .update({
          thirdPartyApprovalStatus: newStatus,
          status_logs: firebase.firestore.FieldValue.arrayUnion(status_log),
        })
        .then(() => {
          if (asset?.status_logs) {
            dispatch(
              updateAsset({
                ...asset,
                thirdPartyApprovalStatus: newStatus,
                status_logs: [...asset?.status_logs, status_log],
              })
            )
          } else {
            dispatch(
              updateAsset({
                ...asset,
                thirdPartyApprovalStatus: newStatus,
                status_logs: [status_log],
              })
            )
          }
        })
        .then(() => {
          setLoading(false)
          setMsg({
            success: true,
            lead: "Success!",
            text: "Your comment was added",
          })
          noteRef.current.value = null
        })
        .catch(err => {
          setLoading(false)
          setMsg({
            success: false,
            lead: "Oops!",
            text: "Something went wrong",
          })
        })
    }
  }

  return (
    asset && (
      <div className="mt-3">
        <S.GreyContainer>
          <ReelerAccordation
            visible={false}
            title={
              <S.ModalTabHeading2>Manually change status</S.ModalTabHeading2>
            }
          >
            <Form.Group>
              <Form.Check
                type="radio"
                inline
                id="type-creator"
                label="Creator"
                onChange={() => setType(RIGHTS_REQUEST_TYPE.creator)}
                checked={type === RIGHTS_REQUEST_TYPE.creator ? true : false}
              />
              <Form.Check
                type="radio"
                inline
                id="type-third-party"
                label="3rd party"
                onChange={() => setType(RIGHTS_REQUEST_TYPE.thirdParty)}
                checked={type === RIGHTS_REQUEST_TYPE.thirdParty ? true : false}
              />
            </Form.Group>
            <Form.Group>
              <Form.Control
                className="w-70"
                ref={statusRef}
                as="select"
                size="sm"
                name="status"
              >
                <option value={ASSET_STATUS.new}>Reset status to ”none”</option>
                <option value={ASSET_STATUS.approved}>Approved</option>
                <option value={ASSET_STATUS.pending}>Pending</option>
                <option value={ASSET_STATUS.notApproved}>Not approved</option>
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Control
                className="w-100"
                as="textarea"
                row={3}
                size="sm"
                ref={noteRef}
                placeholder="Add a note on why you are changing status"
                aria-label="Add a note on why you are changing status"
              />
            </Form.Group>
            {msg && !msg?.success && <FadeAlert msg={msg} />}
            <div className="d-flex flex-row justify-content-end">
              <ReelerButton
                size="sm"
                dispatch={() => handleSave()}
                text="Save"
                loading={loading}
              />
              {asset.status_logs ? (
                <ReelerButton
                  size="sm"
                  styleClass="btn-secondary ms-3"
                  dispatch={handleShow}
                  text={`See history (${asset.status_logs.length})`}
                />
              ) : null}
            </div>
          </ReelerAccordation>
        </S.GreyContainer>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Status log</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {asset?.status_logs
              ?.sort((a, b) => b.createdAt - a.createdAt)
              .map((log, index) => (
                <S.GreyContainer key={index}>
                  <S.FieldContainer $direction="row">
                    <S.FieldTitle>Status:</S.FieldTitle>
                    <S.FieldValue>{log?.from + " -> " + log?.to}</S.FieldValue>
                  </S.FieldContainer>
                  <S.FieldContainer>
                    <S.FieldTitle>Note:</S.FieldTitle>
                    <S.FieldValue>{log?.note}</S.FieldValue>
                  </S.FieldContainer>
                  <S.FieldContainer $direction="row" $justify="space-between">
                    <S.FieldValue>{timeAgo(log.createdAt)}</S.FieldValue>
                    <S.FieldValue>{log?.user_name}</S.FieldValue>
                  </S.FieldContainer>
                </S.GreyContainer>
              ))}
          </Modal.Body>
          <Modal.Footer>
            <ReelerButton
              text="Close"
              styleClass="btn-secondary"
              dispatch={handleClose}
            />
          </Modal.Footer>
        </Modal>
      </div>
    )
  )
}
