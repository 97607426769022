import React from "react"
import { useHistory } from "react-router-dom"

import ReelerButton from "../ReelerButton"

export default function BackButton() {
  const history = useHistory()

  const handleGoBack = () => {
    history.goBack()
    console.log("test")
  }
  return (
    <ReelerButton
      small={true}
      dispatch={() => handleGoBack()}
      text="Back"
      styleClass="btn-secondary mx-2"
    />
  )
}
