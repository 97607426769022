import styled from "styled-components"
import { ListGroup } from "react-bootstrap"

export const Product = styled(ListGroup)`
  position: relative;
  display: flex;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  border: 1px solid var(--reeler-grey);
  &:hover {
    border: 1px solid var(--reeler-grey-darker);
  }
`
export const ProductInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 250px;
`
export const ProductName = styled.div`
  font-weight: 600;
`
export const ProductDescription = styled.div`
  font-size: 0.75rem;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`
export const ProductImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  margin-right: 1rem;
`
export const ProductImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`
