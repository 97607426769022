import React, { useRef, useState } from "react"
import { Form, Card } from "react-bootstrap"
import { useAuth } from "../../contexts/AuthContext"
import { Link } from "react-router-dom"
import CenteredContainer from "../commons/CenteredContainer"
import ReelerButton from "../commons/ReelerButton"
import FadeAlert from "../commons/FadeAlert/FadeAlert"
import StaticAlert from "../commons/StaticAlert/StaticAlert"

import { PUBLIC_ROUTES } from "../../constants/routes"

export default function ForgotPassword() {
  const emailRef = useRef()
  const { resetPassword } = useAuth()
  const [error, setError] = useState("")
  const [message, setMessage] = useState("")
  const [loading, setLoading] = useState(false)

  async function handleSubmit(e) {
    e.preventDefault()

    try {
      setMessage("")
      setError("")
      setLoading(true)
      await resetPassword(emailRef.current.value)
      setMessage({
        success: true,
        lead: "Email sent!",
        text: "Check your inbox for further instructions",
      })
    } catch {
      setError({
        lead: "Oops!",
        text: "Something went wrong",
      })
    }

    setLoading(false)
  }

  return (
    <CenteredContainer>
      <Link to={"/"} style={{ textDecoration: "none" }}>
        <h1 className="text-center mb-5 logo">Reeler</h1>
      </Link>
      <Card>
        <Card.Body>
          <h2 className="text-center mb-4">Password Reset</h2>
          {error && <StaticAlert msg={error} />}
          {message && <FadeAlert msg={message} duration={10000} />}
          <Form onSubmit={handleSubmit}>
            <Form.Group id="email">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" ref={emailRef} required />
            </Form.Group>
            <ReelerButton
              text="Reset password"
              styleClass="btn-main w-100"
              type="submit"
              loading={loading}
            />
          </Form>
          <div className="w-100 text-center mt-3">
            <Link className="link" to={PUBLIC_ROUTES.login.path}>
              Login
            </Link>
          </div>
        </Card.Body>
      </Card>
      <div className="w-100 text-center mt-2">
        Need an account?{" "}
        <a href="https://www.reelertech.com/new-account">Sign Up</a>
      </div>
    </CenteredContainer>
  )
}
