import React from "react"
import { Form, InputGroup } from "react-bootstrap"
import { InputUnit } from "./styles"

const UnitInputGroup = ({
  label = "",
  name = "",
  min = 0,
  max,
  steps,
  value,
  dispatch,
  unit = "",
  disabled = false,
}) => {
  return (
    <>
      <Form.Label>{label}</Form.Label>
      <br />
      <InputGroup>
        <Form.Control
          type="number"
          name={name}
          min={min}
          max={max}
          steps={steps}
          value={value}
          onChange={dispatch}
          disabled={disabled}
        />
        <InputUnit>{unit}</InputUnit>
      </InputGroup>
    </>
  )
}

export default UnitInputGroup
