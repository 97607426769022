import React, { useState, useEffect } from "react"
// 3 party
import { v4 as uuidV4 } from "uuid"
import { Form, Col, Row } from "react-bootstrap"

// Redux
import { useDispatch, useSelector } from "react-redux"
import {
  addFormField,
  addNewFormField,
  saveFormField,
  setShowEditModal,
} from "../../../../redux/campaign/campaign.actions"
import {
  selectFormSchema,
  selectedField,
} from "../../../../redux/campaign/campaign.selectors"
import ReelerButton from "../../../commons/ReelerButton"
import ReelerSwitch from "../../../commons/Switch/ReelerSwitch"
import SaveField from "./SaveField"

const FileField = ({ previousFieldId, closeModal }) => {
  const dispatch = useDispatch()
  const formSchema = useSelector(selectFormSchema)
  const fieldId = useSelector(selectedField)

  const [settings, setSettings] = useState({
    widget: "file",
    maxFileSize: 1000000000,
    maxNoFilesPerSubmission: 10,
    btnText: "Choose file",
    export: false,
    description:
      "Accepted file formats: jpg, jpeg, png (images), mp4, mov (videos), mp3, wav (audio)",
  })

  const [saving, setSaving] = useState(false)

  useEffect(() => {
    if (fieldId && formSchema) {
      const defaultValues = formSchema.properties[fieldId]
      setSettings(defaultValues)
    }
  }, [fieldId])

  const handleSubmit = () => {
    setSaving(true)
    const field = settings

    //fieldId is existing if we are updating the field, then we should only update the field and not the fieldId and order.
    if (fieldId) {
      dispatch(saveFormField(fieldId, field))
      setSaving(false)

      //dispatch(setShowEditModal(false))
    } else {
      dispatch(addNewFormField(field, previousFieldId))

      setSaving(false)
      closeModal()
    }
  }

  return (
    <Form>
      <Form.Group>
        <Col md={6} className="mx-n3">
          <Form.Label>Title of the field</Form.Label>
          <Form.Control
            type="text"
            value={settings?.title}
            onChange={e => setSettings({ ...settings, title: e.target.value })}
          />
        </Col>
        <Form.Text muted>
          (Optional) Add a text label above the form field
        </Form.Text>
      </Form.Group>

      <Row>
        <Col>
          <Form.Group controlId="maxFiles">
            <Form.Label>Maximum number of files per submission</Form.Label>
            <Form.Control
              type="number"
              min={1}
              max={25}
              placeholder={settings?.maxNoFilesPerSubmission}
              value={settings?.maxNoFilesPerSubmission}
              onChange={e =>
                setSettings({
                  ...settings,
                  maxNoFilesPerSubmission: Number(e.target.value),
                })
              }
            />
          </Form.Group>
          <Form.Text muted>
            Set the allowed number of files (1-25) in the button field settings.
          </Form.Text>
        </Col>
      </Row>

      <Form.Group>
        <Col md={6} className="mx-n3">
          <Form.Label>Button text</Form.Label>
          <Form.Control
            type="text"
            value={settings?.btnText}
            onChange={e =>
              setSettings({ ...settings, btnText: e.target.value })
            }
          />
          <Form.Text muted>
            (Optional) Change the text on the upload button
          </Form.Text>
        </Col>
      </Form.Group>
      <Form.Group>
        <Form.Label>Small text under the upload button</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          value={settings?.description}
          onChange={e =>
            setSettings({ ...settings, description: e.target.value })
          }
        />
        <Form.Text muted>
          (Optional) Add a short text under the upload button
        </Form.Text>
      </Form.Group>
      {/* <Row>
        <Col xs={6}>
          <Form.Group controlId="formats">
            <Form.Label>Allowed formats</Form.Label>
            <CheckboxGrid>
              {allowedFormats.map(item => {
                return <Form.Check type="checkbox" label={item} id={item} />
              })}
            </CheckboxGrid>
            <Form.Text className="text-muted">
              What file formats do you accept?
            </Form.Text>
          </Form.Group>
        </Col>
      </Row> */}
      <Form.Group>
        <ReelerSwitch
          label="Required field"
          name="required"
          action={() =>
            setSettings({ ...settings, required: !settings?.required })
          }
          checkedBoolean={settings?.required}
        />
      </Form.Group>

      <SaveField saveFunction={handleSubmit} />
    </Form>
  )
}

export default FileField
