import React, { useState, useRef, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { selectAccounts } from "../../../../../../../redux/account/account.selectors"
import {
  selectActiveAsset,
  selectAssetRightsRequest,
} from "../../../../../../../redux/asset/asset.selectors"
import {
  updateAsset,
  setAssetRightsRequest,
} from "../../../../../../../redux/asset/asset.actions"

import { database } from "../../../../../../../firebase"
import { Form } from "react-bootstrap"
// Reeler components
import ReelerButton from "../../../../../../commons/ReelerButton"

import {
  ASSET_RIGHT_STATUS,
  RIGHTS_REQUEST_TYPE,
} from "../../../../../../../constants"
import useUserFeedbackMessage from "../../../../../../../hooks/useUserFeedbackMessage"
import EditMessageTemplate from "../../../../../../../pages/Rights/MessageTemplates/components/EditMessageTemplate"

export default function RightRequestInstagram() {
  const dispatch = useDispatch()
  const [saving, setSaving] = useState(false)
  const rightRequest = useSelector(selectAssetRightsRequest)
  const account = useSelector(selectAccounts)
  const asset = useSelector(selectActiveAsset)
  const { setSuccessMessage, setErrorMessage } = useUserFeedbackMessage()

  const selectTemplateRef = useRef()
  const textMessageRef = useRef()

  useEffect(() => {
    if (
      rightRequest &&
      !rightRequest.textMessage &&
      account?.right_request_templates?.textMessages?.length > 0
    ) {
      const template = account?.right_request_templates?.textMessages[0]

      const defaultMessage = template?.textMessage + " " + rightRequest.link

      dispatch(
        setAssetRightsRequest({
          ...rightRequest,
          textMessage: defaultMessage,
        })
      )

      selectTemplateRef.current.value = template?.templateName
      textMessageRef.current.value = defaultMessage
    }
  }, [rightRequest])

  function openRequestedPopup(url) {
    var content = document.getElementById("rights-message")
    content.select()
    document.execCommand("copy")
    var windowFeatures = "height=650,width=900"
    window.open(url, "Instagram_window", windowFeatures)
  }

  const handleSendRightsRequest = () => {
    setSaving(true)
    const approvalStatusLog = {
      approvalStatus: ASSET_RIGHT_STATUS.pending,
      approvalStatusLog: [
        ...rightRequest?.approvalStatusLog,
        {
          status: ASSET_RIGHT_STATUS.pending,
          note: "Sent request via Instagram",
          createdAt: Date.now(),
        },
      ],
    }
    database.rightRequests
      .doc(rightRequest.id)
      .update(approvalStatusLog)
      .then(() => {
        dispatch(
          setAssetRightsRequest({
            ...rightRequest,
            ...approvalStatusLog,
          })
        )
        const assetRef = database.assets.doc(asset.id)
        if (rightRequest.requestType === RIGHTS_REQUEST_TYPE.thirdParty) {
          assetRef
            .update({
              thirdPartyApprovalStatus: ASSET_RIGHT_STATUS.pending,
            })
            .then(() => {
              dispatch(
                updateAsset({
                  ...asset,
                  thirdPartyApprovalStatus: ASSET_RIGHT_STATUS.pending,
                })
              )
              setSuccessMessage("Rights request marked as sent (pending)")
              openRequestedPopup(asset.media.permalink)
            })
        } else {
          assetRef
            .update({
              status: ASSET_RIGHT_STATUS.pending,
            })
            .then(() => {
              dispatch(
                updateAsset({
                  ...asset,
                  status: ASSET_RIGHT_STATUS.pending,
                })
              )
              setSuccessMessage("Rights request marked as sent (pending)")
              openRequestedPopup(asset.media.permalink)
            })
        }
        setSaving(false)
      })
      .catch(err => {
        console.log(err)
        setErrorMessage("Could mark rights request as sent")
        setSaving(false)
      })
  }

  const handleSelectTextMessageTemplate = templateName => {
    let template = account?.right_request_templates?.textMessages?.filter(
      t => t.templateName === templateName
    )
    console.log(template)

    const textMessage = template[0]?.textMessage
    textMessageRef.current.value = textMessage + " " + rightRequest.link
  }

  return asset?.media &&
    asset.media.permalink &&
    account?.right_request_templates?.textMessages?.length > 0 ? (
    <>
      <Form.Group className="mb-3">
        <Form.Label>Select text message template</Form.Label>
        <Form.Control
          ref={selectTemplateRef}
          as="select"
          name="textMessage"
          onChange={e => handleSelectTextMessageTemplate(e.target.value)}
        >
          <option value="">-</option>
          {account?.right_request_templates?.textMessages.map(
            (textMessage, index) => (
              <option key={index} value={textMessage.templateName}>
                {`${index + 1}: ${
                  textMessage?.templateName
                    ? textMessage.templateName
                    : textMessage.textMessage
                }`}
              </option>
            )
          )}
        </Form.Control>
      </Form.Group>
      <Form.Group className="mb-3" controlId="rights-message">
        <Form.Control
          ref={textMessageRef}
          as="textarea"
          style={{ fontSize: "12px" }}
          rows={3}
          defaultValue={rightRequest?.link}
          className="mt-3"
        />
        <Form.Text muted>Personalize the text message (optional)</Form.Text>
      </Form.Group>
      <Form.Group className="d-flex flex-column b-3">
        <Form.Label>Ask for rights</Form.Label>

        <div className="d-flex flex-row align-items-center">
          <ReelerButton
            text="Send Instagram DM"
            styleClass="btn-main me-3"
            size="sm"
            disabled={textMessageRef?.current?.value === "" ? true : false}
            dispatch={() => handleSendRightsRequest()}
          />

          <span
            style={{ cursor: "pointer", textDecoration: "underline" }}
            className="asset-info-text"
            onClick={() => openRequestedPopup(asset.media.permalink)}
          >
            View post on Instagram
          </span>
        </div>

        <Form.Text muted>
          Click to copy your message and open the Instagram post to send a DM to
          the creator. <strong>Note:</strong> Clicking this button automatically
          changes the approval status to “pending” (yellow). If you just want to
          open the link without changing the approval status, use the link on
          the right instead.
        </Form.Text>
      </Form.Group>
    </>
  ) : (
    <Form.Group className="p-3">
      <Form.Text>
        You haven’t created any Instagram message templates yet. Please first
        create an Instagram message template, later you can edit all templates
        under Rights in the menu.
      </Form.Text>
      <EditMessageTemplate
        btn={
          <ReelerButton
            text="Create new Instagram message template"
            styleClass="btn-main mt-3"
          />
        }
      />
    </Form.Group>
  )
}
