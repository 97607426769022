import React, { useState } from "react"
import { database } from "../../../../../../../firebase"

// Redux
import { useSelector, useDispatch } from "react-redux"
import {
  selectActiveAsset,
  selectAssetRightsRequest,
} from "../../../../../../../redux/asset/asset.selectors"

// Reeler components
import ReelerButton from "../../../../../../commons/ReelerButton"
import {
  APPROVAL_CHANNEL,
  ASSET_RIGHT_STATUS,
  RIGHTS_REQUEST_TYPE,
  RIGHT_REQUEST_STATUS,
  VERIFICATION_METHOD,
} from "../../../../../../../constants"
import withoutProperty from "../../../../../../../utils/withoutProperty"
import useUserFeedbackMessage from "../../../../../../../hooks/useUserFeedbackMessage"
import { setAssetRightsRequest } from "../../../../../../../redux/asset/asset.actions"
import ShortUniqueId from "short-unique-id"
import { selectAccounts } from "../../../../../../../redux/account/account.selectors"
const { randomUUID } = new ShortUniqueId({ length: 6 })

export default function SaveRightRequest() {
  const dispatch = useDispatch()
  const [saving, setSaving] = useState(false)
  const { setSuccessMessage, setErrorMessage } = useUserFeedbackMessage()
  const account = useSelector(selectAccounts)
  const asset = useSelector(selectActiveAsset)

  const rightsRequest = useSelector(selectAssetRightsRequest)

  const handleSave = () => {
    setSaving(true)

    if (rightsRequest?.id) {
      // save existing
      let rightsRequestWithoutId = withoutProperty(rightsRequest, "id")

      database.rightRequests
        .doc(rightsRequest?.id)
        .update(rightsRequestWithoutId)
        .then(() => {
          setSuccessMessage("Saved!")
          setSaving(false)
        })
        .catch(err => {
          console.log(err)
          setErrorMessage("Could not save rights request")

          setSaving(false)
        })
    } else {
      // create new rights request
      const shortId = randomUUID()

      let rightsRequestDoc = {
        ...rightsRequest,
        approvalChannel: APPROVAL_CHANNEL.approvalPage,
        verificationMethod: VERIFICATION_METHOD.none,
        accountId: account.id,
        assetId: asset.id,
        shortId,
        linkStatus: RIGHT_REQUEST_STATUS.created,
        approvalStatus: ASSET_RIGHT_STATUS.created,
        createdAt: database.getCurrentTimestamp(),
        approvalStatusLog: [
          {
            status: RIGHT_REQUEST_STATUS.created,
            note: "New creator rights request created",
            createdAt: Date.now(),
          },
        ],
        link:
          (rightsRequest?.approvalPage?.domain
            ? rightsRequest.approvalPage.domain
            : process.env.REACT_APP_FIREBASE_CREATOR_APP_URL) +
          "/" +
          shortId,
      }

      database.rightRequests
        .add(rightsRequestDoc)
        .then(async doc => {
          dispatch(setAssetRightsRequest({ ...rightsRequestDoc, id: doc.id }))
          setSaving(false)
        })
        .catch(err => {
          console.log(err)
          setSaving(false)
        })
    }
  }
  return (
    <ReelerButton
      loading={saving}
      disabled={!rightsRequest?.approvalPage || saving}
      dispatch={() => handleSave()}
      text={rightsRequest?.id ? "Save" : "Save and continue"}
      styleClass="btn-main"
      spinnerClass="ms-1"
    />
  )
}
