import React, { useEffect, useState } from "react"
import { Form } from "react-bootstrap"
import { database } from "../../firebase"
import "../App.css"
import { useSelector } from "react-redux"
import { selectCurrentUser } from "../../redux/user/user.selectors"
import FadeAlert from "../commons/FadeAlert/FadeAlert"
import ReelerButton from "../commons/ReelerButton"
import Section from "../../layouts/core/Section"
import { Header } from "../../layouts/core/Header"
import Title from "../../layouts/core/Title"
import Body from "../../layouts/core/Body"
import BackButton from "../commons/BackButton"

const UpdateProfile = () => {
  const currentUser = useSelector(selectCurrentUser)
  const [msg, setMsg] = useState(null)
  const [loading, setLoading] = useState(true)
  const [saving, setSaving] = useState(false)
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [phone, setPhone] = useState("")

  useEffect(() => {
    setLoading(true)
    if (currentUser) {
      database.users
        .doc(currentUser.id)
        .get()
        .then(snapshot => {
          if (snapshot) {
            if (snapshot.data().firstName)
              setFirstName(snapshot.data().firstName)
            if (snapshot.data().lastName) setLastName(snapshot.data().lastName)
            if (snapshot.data().phone) setPhone(snapshot.data().phone)
          }
          setLoading(false)
        })
    }
  }, [currentUser])

  function handleSubmit(e) {
    setSaving(true)
    e.preventDefault()
    // Update account
    database.users
      .doc(currentUser.id)
      .update({
        firstName: firstName,
        lastName: lastName,
        phone: phone,
      })
      .then(() => {
        setSaving(false)
        setMsg({
          success: true,
          lead: "Success!",
          text: "Profile information updated succesfully",
        })
      })
      .catch(err => {
        setMsg({
          success: false,
          lead: "Something went wrong!",
          text: "Your changes could not be saved",
        })
        setSaving(false)
      })

    setMsg(null)
  }

  return (
    <Section>
      <Header>
        <Title>Profile</Title>
        <div className="ms-auto">
          <BackButton />
        </div>
      </Header>
      <Body>
        <p>These preferences will only be applied to you. </p>

        {msg && <FadeAlert msg={msg} duration={5000} />}
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="first-name">
            <Form.Label>First name</Form.Label>
            <Form.Control
              type="text"
              value={firstName ? firstName : ""}
              onChange={e => setFirstName(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="last-name">
            <Form.Label>Last name</Form.Label>
            <Form.Control
              type="text"
              value={lastName ? lastName : ""}
              onChange={e => setLastName(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="phone">
            <Form.Label>Phone number</Form.Label>

            <Form.Control
              type="tel"
              value={phone ? phone : ""}
              onChange={e => setPhone(e.target.value)}
            />
            <Form.Text>
              Reeler may use this phone number to contact you about support.
            </Form.Text>
          </Form.Group>
          <div className="d-flex flex-row justify-content-end">
            <ReelerButton
              text="Update"
              styleClass="btn-main"
              disabled={saving || loading}
              loading={saving || loading}
              type="submit"
            />
          </div>
        </Form>
      </Body>
    </Section>
  )
}

export default UpdateProfile
