import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { Container, Row, Col } from "react-bootstrap"
import MetaTags from "react-meta-tags"
import { analytics, database } from "../../firebase"

const LandingPageForm = () => {
  const { campaignId } = useParams()
  const [loading, setLoading] = useState(true)
  const [campaign, setCampaign] = useState(null)

  useEffect(() => {
    if (campaignId) {
      window.location.replace(
        process.env.REACT_APP_FIREBASE_WIDGET_URL + "/form/v2/" + campaignId
      )
    }
  }, [campaignId])

  // useEffect(() => {
  //   setLoading(true)

  //   const loadCampaign = async () => {
  //     const doc = await database.campaigns.doc(campaignId).get()
  //     if (doc) {
  //       setCampaign(database.formatDoc(doc))
  //       analytics.logEvent("view_campaign_landingpage", {
  //         campaignId: doc.id,
  //       })
  //       setLoading(false)
  //     }
  //   }

  //   loadCampaign()
  // }, [campaignId])

  // console.log(campaign)

  // useEffect(() => {
  //   if (campaign) {
  //     const script = document.createElement("script")

  //     script.src =
  //       process.env.REACT_APP_FIREBASE_WIDGET_URL + "/ReelerWidgets.js"
  //     script.async = true

  //     document.body.appendChild(script)

  //     return () => {
  //       document.body.removeChild(script)
  //     }
  //   }
  // }, [campaign])

  return null
}

export default LandingPageForm
