import React, { useEffect, useState } from "react"
import { Alert, Container } from "./styles"

const FadeAlert = ({ msg, duration = 3000 }) => {
  const [visible, setVisible] = useState(true)
  const { success, lead, text, position } = msg

  const bulkEditStyles = {
    position: "absolute",
    top: "50%",
    left: "50%",
    width: "fit-content",
  }

  useEffect(() => {
    setTimeout(() => {
      setVisible(false)
    }, duration)
  }, [duration])

  return msg && visible ? (
    <Container
      style={position && position === "bulkEdit" ? bulkEditStyles : {}}
    >
      <Alert
        duration={duration}
        variant={success ? "var(--reeler" : "var(--reeler-danger"}
      >
        <strong>{lead}</strong> {text}
      </Alert>
    </Container>
  ) : null
}

export default FadeAlert
