import React, { useState } from "react"
// Firebase
import InfiniteScroll from "react-infinite-scroll-component"

import AssetInfoModal from "../../../../../../campaigns/campaign/components/asset-info/AssetInfoModal"
import {
  selectFeed,
  selectAssets,
  selectLoading,
} from "../../../../../../../redux/feed/feed.selectors"
import { useDispatch, useSelector } from "react-redux"
import {
  setIsAssetsDirty,
  setIsFeedDirty,
  setIsSortingAssets,
  loadAssets,
  updateFeed,
} from "../../../../../../../redux/feed/feed.actions"
import FeedAsset from "./feed-asset/FeedAsset"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import * as S from "./FeedAssetsStyle"

import SpinnerComponent from "../../../../../../commons/SpinnerComponent"

// result=draggableId,type and reason=>ex drop
//obj. source=droppableId, index
// obj. desination=droppableId,index
// destination= null if drag outside droppable

export default function FeedAssets() {
  const [limit, setLimit] = useState(25)
  const [showSelected, setShowSelected] = useState("showAll")
  const loading = useSelector(selectLoading)
  const feed = useSelector(selectFeed)
  const assets = useSelector(selectAssets)

  const dispatch = useDispatch()

  const handleChangePosition = result => {
    if (!result.destination) return

    dispatch(setIsSortingAssets(true))
    var arr = [...assets]

    // Make sure a valid array is provided
    if (Object.prototype.toString.call(arr) !== "[object Array]") {
      throw new Error("Please provide a valid array")
    }
    // Delete the item from it's current position
    var item = arr.splice(result.source.index, 1)
    // Make sure there's an item to move
    if (!item.length) {
      throw new Error(
        "There is no item in the array at index " + result.source.index
      )
    }

    // Move the item to its new position
    arr.splice(result.destination.index, 0, item[0])

    const feedAssetOrder = arr.map(asset => asset.id)

    dispatch(updateFeed({ assetOrder: feedAssetOrder }))
    dispatch(loadAssets(arr))

    dispatch(setIsAssetsDirty(true))
    dispatch(setIsFeedDirty(true))

    dispatch(setIsSortingAssets(false))
  }

  return loading ? (
    <SpinnerComponent />
  ) : (
    <>
      <AssetInfoModal />

      <InfiniteScroll
        style={{ overflow: "none" }}
        dataLength={limit} //This is important field to render the next data
        next={() => setLimit(limit + 25)}
        hasMore={assets.length > limit}
        loader={<SpinnerComponent size="lg" />}
      >
        <DragDropContext onDragEnd={handleChangePosition}>
          {assets && assets.length > 0 && (
            <S.ContentContainer>
              {assets
                .filter((a, i) => i < limit)
                .map((asset, index) => (
                  <Droppable
                    droppableId={index.toString()}
                    key={index}
                    direction="horizontal"
                  >
                    {provided => (
                      <div
                        style={{ flexBasis: "200px" }}
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        <Draggable draggableId={index.toString()} index={index}>
                          {provided => (
                            <div
                              index={index}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              ref={provided.innerRef}
                            >
                              <FeedAsset asset={asset} />
                            </div>
                          )}
                        </Draggable>
                      </div>
                    )}
                  </Droppable>
                ))}
            </S.ContentContainer>
          )}
        </DragDropContext>
      </InfiniteScroll>
    </>
  )
}
