import React, { useState, useRef, useEffect } from "react"
// Redux
import { useSelector, useDispatch } from "react-redux"
import { selectActiveAsset } from "../../../../../../redux/asset/asset.selectors"
// Firebase
import { database } from "../../../../../../firebase"

import { Form, InputGroup } from "react-bootstrap"
import { updateAsset } from "../../../../../../redux/asset/asset.actions"
import { FaUserCircle, FaEdit } from "react-icons/fa"
import ReelerButton from "../../../../../commons/ReelerButton"
import ReelerTooltip from "../../../../../commons/ReelerTooltip/ReelerTooltip"
import { ACCOUNT_PLANS } from "../../../../../../constants/routes"
import AuthBasedComponent from "../../../../../authentication/AuthBasedComponent"
import AssetTextField from "./AssetTextField"
import Icon from "../../../../../commons/Icon"
import SingleRowIcon from "./SingleRowIcon"

export default function AssetUsername() {
  const [showEdit, setShowEdit] = useState(false)
  const [saving, setSaving] = useState(false)
  const dispatch = useDispatch()
  const asset = useSelector(selectActiveAsset)
  const usernameRef = useRef()

  const handleSave = () => {
    setSaving(true)

    const username = usernameRef.current.value

    let tags = asset.tags ? asset.tags : {}

    username.split(" ").forEach(tag => {
      if (tag === "") {
        return
      }
      let lowerCasedTag = tag.toLowerCase()
      tags[lowerCasedTag] = true
    })

    const updateData = {
      username,
      tags,
    }

    database.assets
      .doc(asset.id)
      .update(updateData)
      .then(() => {
        dispatch(
          updateAsset({
            ...asset,
            ...updateData,
          })
        )

        setSaving(false)
        setShowEdit(false)
      })
      .catch(err => {
        setSaving(false)
      })
  }
  const InputUsername = () => <></>

  const Username = ({ username }) =>
    username ? (
      <div className="d-flex flex-row justify-content-between align-items-center">
        <span className="asset-info-text me-3">{username}</span>
        <AuthBasedComponent
          plans={[
            ACCOUNT_PLANS.basic,
            ACCOUNT_PLANS.pro,
            ACCOUNT_PLANS.premium,
          ]}
        >
          <Icon
            onClick={() => setShowEdit(true)}
            tooltip="Add/edit social media username"
          >
            <FaEdit className="icon-color" />
          </Icon>
        </AuthBasedComponent>
      </div>
    ) : (
      <div className="btn-link icon-btn" onClick={() => setShowEdit(true)}>
        Add username
      </div>
    )

  return (
    asset && (
      <AssetTextField
        icon={
          <SingleRowIcon tooltip="Social media username">
            <FaUserCircle className="icon-color" />
          </SingleRowIcon>
        }
        text={
          showEdit ? (
            <InputGroup>
              <Form.Control
                ref={usernameRef}
                size="sm"
                type="text"
                placeholder="Username"
                disabled={saving}
                defaultValue={
                  asset?.username
                    ? asset?.username
                    : asset?.media?.username
                    ? asset?.media?.username
                    : ""
                }
                onKeyUp={e => (e.key === "Enter" ? handleSave() : null)}
              />
              <ReelerButton
                size="sm"
                text="Save"
                dispatch={handleSave}
                disabled={saving}
                loading={saving}
              />
            </InputGroup>
          ) : (
            <Username
              username={
                asset?.username
                  ? asset?.username
                  : asset?.media?.username
                  ? asset?.media?.username
                  : ""
              }
            />
          )
        }
      />
    )
  )
}
