import React from "react"
import { useSelector } from "react-redux"
import { selectCurrentUser } from "../../redux/user/user.selectors"
import { selectAccounts } from "../../redux/account/account.selectors"

export default function AuthBasedComponent({
  userRoles = ["owner", "editor"],
  plans = ["basic"],
  children,
}) {
  const currentUser = useSelector(selectCurrentUser)
  const account = useSelector(selectAccounts)

  return currentUser &&
    account &&
    plans?.includes(account?.plan) &&
    userRoles?.includes(currentUser?.role)
    ? children
    : null
}
