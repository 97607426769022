import React, { useState, useEffect } from "react"

import { storage } from "../../../../../../firebase"

import { useSelector, useDispatch } from "react-redux"
import { saveCampaign } from "../../../../../../redux/campaign/campaign.actions"
import { selectCampaign } from "../../../../../../redux/campaign/campaign.selectors"
import { selectAccountId } from "../../../../../../redux/account/account.selectors"
// 3 party
import { useEditor } from "../../../../../../hooks/useEditor"
import {
  Form,
  Col,
  Row,
  Button,
  ButtonGroup,
  ProgressBar,
} from "react-bootstrap"
import { FaAlignCenter, FaAlignLeft, FaAlignRight } from "react-icons/fa"
import { v4 as uuidv4 } from "uuid"
import * as S from "./styles"
import ReelerButton from "../../../../../commons/ReelerButton"
import CopyToClipboard from "../../../../../commons/CopyToClipboard"
import { RIGHTS_EMAIL_CODES } from "../../../../../../constants"
import useUserFeedbackMessage from "../../../../../../hooks/useUserFeedbackMessage"

/**
 * Logo
 * Introduction to approval page (wyziwyg)
 *
 * Choose which fields that should be shown
 *
 * Terms 1
 * Terms 2
 *
 * Email template
 *
 */

export default function ConfirmationTab() {
  const dispatch = useDispatch()
  const campaign = useSelector(selectCampaign)
  const accountId = useSelector(selectAccountId)
  const { setSuccessMessage } = useUserFeedbackMessage()
  const [settings, setSettings] = useState({
    width: 100,
    image_url: "",
    intro: "",
    title: "Confirmation email",
    placeholder: "Email",
    export: true,
    form_fields_on_approval_page: [],
    email_template: {
      subject: "Your Submission Received",
      name_alias: "Reeler",
      body: `Thank you for submitting your content through our platform.

We appreciate your contribution!
      
Your submission has been received successfully.`,
    },
  })
  const [fileMessage, setFileMessage] = useState()
  const [uploadError, setUploadError] = useState(false)
  const [progress, setProgress] = useState(0)
  const [saving, setSaving] = useState(false)
  const [showConfirmationSettings, setShowConfirmationSettings] =
    useState(false)
  const [showConfirmationPageSettings, setShowConfirmationPageSettings] =
    useState(false)

  const { ReelerEditor, getHTML, setDefaultState, htmlTextOnBlur } = useEditor()

  useEffect(() => {
    if (campaign) {
      let defaultValues = campaign?.confirmationEmail
      if (defaultValues) {
        setSettings(defaultValues)
        if (defaultValues?.intro) {
          setDefaultState(defaultValues?.intro)
          setShowConfirmationPageSettings(true)
        }
        setShowConfirmationSettings(true)
      }
    }
  }, [campaign])

  useEffect(() => {
    if (htmlTextOnBlur && htmlTextOnBlur !== settings.intro) {
      const updateObject = {
        ...settings,
        intro: htmlTextOnBlur,
      }
      setSettings(updateObject)
      dispatch(
        saveCampaign({
          ...campaign,
          confirmationEmail: updateObject,
        })
      )
      console.log(updateObject)
    }
  }, [htmlTextOnBlur])

  const handleUploadImage = e => {
    const file = e.target.files[0]
    setSaving(true)

    if (file == null) return

    let format = file.name.split(".").pop().toLowerCase()
    let allowedFormats = ["svg", "png", "jpg", "jpeg"]

    if (!allowedFormats.includes(format)) {
      setFileMessage("File format can only be svg, png, jpg or jpeg")
      return
    }

    if (file.size > 5000000) {
      setFileMessage("File size is too big. File size limit is 5MB")
      return
    }

    setSettings({ ...settings, image_url: URL.createObjectURL(file) })

    // Generate an id for filename
    const filename = uuidv4()

    // Upload image to Storage
    setProgress(0)
    const uploadTask = storage
      .ref(
        `/${accountId}/templates/third-party-approval/${
          filename + "." + format
        }`
      )
      .put(file)

    uploadTask.on(
      "state_changed",
      snapshot => {
        setProgress(snapshot.bytesTransferred / snapshot.totalBytes)
      },
      err => {
        setUploadError(true)
        setSaving(false)
      },
      async () => {
        uploadTask.snapshot.ref.getDownloadURL().then(url => {
          // Update campaign
          dispatch(
            saveCampaign({
              ...campaign,
              confirmationEmail: {
                ...settings,
                image_url: url,
              },
            })
          )
          setSettings({ ...settings, image_url: url })

          setSuccessMessage("Image uploaded!")

          setSaving(false)
          setProgress(0)
        })
      }
    )
  }

  const handleOnBlur = (target, value) => {
    dispatch(
      saveCampaign({
        ...campaign,
        confirmationEmail: {
          ...settings,
          [target]: value,
        },
      })
    )
  }
  const handleOnChange = (target, value) => {
    setSettings({
      ...settings,
      [target]: value,
    })
  }
  const handleOnBlurEmailTemplate = (target, value) => {
    dispatch(
      saveCampaign({
        ...campaign,
        confirmationEmail: {
          ...settings,
          email_template: {
            ...settings.email_template,
            [target]: value,
          },
        },
      })
    )
  }
  const handleOnChangeEmailTemplate = (target, value) => {
    setSettings({
      ...settings,
      email_template: {
        ...settings.email_template,
        [target]: value,
      },
    })
  }

  return (
    <>
      <Form.Group className="mb-3">
        <Form.Label className="mb-3">
          Include a confirmation email upon form submission.
        </Form.Label>
        <Form.Text className="mb-3">
          Generate a confirmation email to be sent to the individual who filled
          out the form and uploaded content. Optionally, include a link to a
          page where the uploader can view the submitted information and
          content.
        </Form.Text>
        <ReelerButton
          dispatch={e => setShowConfirmationSettings(!showConfirmationSettings)}
          text="Add confirmation email"
          className="me-3"
          styleClass="btn-main"
          spinnerClass="ms-1"
        />
      </Form.Group>

      {showConfirmationSettings ? (
        <S.SettingsContainer>
          <S.FormFieldSectionContainer>
            <h3>Personalize the confirmation email</h3>
            <Form.Group>
              <Form.Label>Email sender alias</Form.Label>
              <Form.Control
                type="text"
                value={settings?.email_template?.name_alias}
                onChange={e =>
                  handleOnChangeEmailTemplate("name_alias", e.target.value)
                }
                onBlur={e =>
                  handleOnBlurEmailTemplate("name_alias", e.target.value)
                }
              />
              <Form.Text muted>
                Add an email sender alias to be displayed in the email client,
                such as your company name.
              </Form.Text>
            </Form.Group>
            <Form.Group>
              <Form.Label>Email subject</Form.Label>
              <Form.Control
                type="text"
                value={settings?.email_template?.subject}
                onChange={e =>
                  handleOnChangeEmailTemplate("subject", e.target.value)
                }
                onBlur={e =>
                  handleOnBlurEmailTemplate("subject", e.target.value)
                }
              />
              <Form.Text muted>
                Write the subject line for your confirmation emails
              </Form.Text>
            </Form.Group>
            <Form.Group>
              <Form.Label>Email text</Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                value={settings?.email_template?.body}
                onChange={e =>
                  handleOnChangeEmailTemplate("body", e.target.value)
                }
                onBlur={e => handleOnBlurEmailTemplate("body", e.target.value)}
              />

              <Form.Text muted>
                <span className="me-1">
                  Compose the text for your confirmation email. If you want to
                  include a link to a page where the uploader can see the
                  information and content they uploaded, please insert the
                  placeholder
                </span>
                <bold>{RIGHTS_EMAIL_CODES.confirmationLink}</bold>{" "}
                <CopyToClipboard
                  link={RIGHTS_EMAIL_CODES.confirmationLink}
                  tooltipText={`copy confirmation code`}
                />
                <span className="ms-1">
                  at the location where you want the actual link to appear in
                  the email. If you just want to send a simple thank you email,
                  don’t include any link placeholder.
                </span>
              </Form.Text>
            </Form.Group>
          </S.FormFieldSectionContainer>
          <hr className="solid" />

          <Form.Group className="mb-3">
            <Form.Label className="mb-3">Add a confirmation page.</Form.Label>
            <Form.Text className="mb-3">
              Generate a confirmation page where the uploader can view the
              submitted information and content.
            </Form.Text>
            <ReelerButton
              dispatch={e =>
                setShowConfirmationPageSettings(!showConfirmationPageSettings)
              }
              text="Add confirmation page"
              className="me-3"
              styleClass="btn-main"
              spinnerClass="ms-1"
            />
          </Form.Group>

          {showConfirmationPageSettings ? (
            <S.FormFieldSectionContainer>
              <h4>Customize the content confirmation page</h4>
              <p>
                If you’ve added the
                {RIGHTS_EMAIL_CODES.confirmationLink} placeholder in your email,
                the uploader will receive a link to view their content on a
                customized page. Adjust the page appearance here. If you’re not
                including any link, you can skip this section.
              </p>
              <Form.Group className="mb-3">
                <Form.Label>Upload a splash image for the page</Form.Label>
                <Form.Control type="file" onChange={handleUploadImage} />

                <Form.Text className="text-muted">
                  Valid image formats: jpg, jpeg, png
                </Form.Text>
              </Form.Group>
              {progress > 0 ? (
                <ProgressBar
                  className="mt-3 mb-3"
                  animated={!uploadError}
                  variant={uploadError ? "danger" : "primary"}
                  now={uploadError ? 100 : progress * 100}
                  label={
                    uploadError ? "Error" : `${Math.round(progress * 100)}%`
                  }
                />
              ) : null}
              {settings?.image_url ? (
                <img
                  className="mt-3"
                  alt=""
                  style={{
                    width: settings?.width ? settings?.width + "%" : "100%",
                  }}
                  src={settings.image_url}
                />
              ) : null}

              <Form.Group>
                <Form.Label>Image size</Form.Label>
                <Form.Label>
                  <small>({settings?.width + "%"})</small>
                </Form.Label>
              </Form.Group>
              <Form.Group>
                <input
                  type="range"
                  className="form-range"
                  min="0"
                  max="100"
                  step="5"
                  value={settings?.width}
                  onChange={e => handleOnChange("width", e.target.value)}
                  onBlur={e => handleOnBlur("width", e.target.value)}
                />
              </Form.Group>

              <Form.Label>Position</Form.Label>
              <Form.Group className="mb-3">
                <ButtonGroup aria-label="Basic example">
                  <Button
                    onClick={() => handleOnBlur("align", "start")}
                    variant="outline-secondary"
                    active={settings?.align === "start" ? true : false}
                  >
                    <FaAlignLeft className="icon-color" />
                  </Button>
                  <Button
                    onClick={() => handleOnBlur("align", "center")}
                    variant="outline-secondary"
                    active={settings?.align === "center" ? true : false}
                  >
                    <FaAlignCenter className="icon-color" />
                  </Button>
                  <Button
                    onClick={() => handleOnBlur("align", "end")}
                    variant="outline-secondary"
                    active={settings?.align === "end" ? true : false}
                  >
                    <FaAlignRight className="icon-color" />
                  </Button>
                </ButtonGroup>
              </Form.Group>

              <Form.Group className="mb-3" controlId="cols">
                <Form.Label>Margin</Form.Label>
                <br />
                <Form.Control
                  type="number"
                  min={0}
                  max={100}
                  value={settings?.margin}
                  name="margin"
                  onChange={e => handleOnChange("margin", e.target.value)}
                  onBlur={e => handleOnBlur("margin", e.target.value)}
                />
                <Form.Text muted>Margin</Form.Text>
              </Form.Group>

              <Form.Group className="mb-3" controlId="rows">
                <Form.Label>Padding</Form.Label>
                <br />
                <Form.Control
                  type="number"
                  min={0}
                  max={100}
                  value={settings?.padding}
                  name="padding"
                  onChange={e => handleOnChange("padding", e.target.value)}
                  onBlur={e => handleOnBlur("padding", e.target.value)}
                />
                <Form.Text muted>Padding</Form.Text>
              </Form.Group>

              <Form.Group>
                <Form.Label>
                  Write a page headline and a short introduction text to be
                  showcased above the uploader’s submitted information and
                  content.
                </Form.Label>

                <ReelerEditor />
              </Form.Group>
            </S.FormFieldSectionContainer>
          ) : null}
        </S.SettingsContainer>
      ) : null}
    </>
  )
}
