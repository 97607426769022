import React, { useEffect, useState } from "react"
import { Table, Form } from "react-bootstrap"
import { database } from "../../../firebase"
import UserRow from "./UserRow"
import { useSelector } from "react-redux"
import { selectAccounts } from "../../../redux/account/account.selectors"
import SpinnerComponent from "../../commons/SpinnerComponent"
import InviteRow from "./InviteRow"

const InviteUserTable = () => {
  const [invites, setInvites] = useState([])
  const [loading, setLoading] = useState(true)

  const account = useSelector(selectAccounts)

  useEffect(() => {
    if (account) {
      setLoading(true)
      var unsubscribe = database.invites
        .where("account", "==", account.id)
        .where("valid", "==", true)
        .orderBy("createdAt", "desc")
        .onSnapshot(documentSnapshots => {
          let formnattedInvites = []
          documentSnapshots.docs.forEach(doc => {
            let invite = database.formatDoc(doc)

            formnattedInvites.push(invite)
          })
          setInvites(formnattedInvites)
          setLoading(false)
        })
      return () => unsubscribe()
    }
  }, [account])

  if (loading) return <SpinnerComponent size="lg" spinnerClass="mt-5" />

  return (
    <Table striped hover bordered>
      <thead>
        <tr>
          <th>Email</th>
          <th>Role</th>
          <th>Sent</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {invites &&
          invites.map(invite => {
            return <InviteRow key={invite.id} invite={invite} />
          })}
      </tbody>
    </Table>
  )
}

export default InviteUserTable
