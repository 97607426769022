/**
 * Returns the given `obj` without the `property`.
 *
 * @param {Object} obj
 * @param {String} property
 *
 * @returns {Object}
 */
export default function withoutProperty(obj, property) {
  const { [property]: unused, ...rest } = obj

  return rest
}
