import React, { useState } from "react"
import ReelerButton from "../../../commons/ReelerButton"
import { Modal, Tabs, Tab } from "react-bootstrap"
import PublishTab from "../form-generator/components/campaignInfo/PublishTab"

export default function ShareForm() {
  const [open, setOpen] = useState(false)
  const [key, setKey] = useState("link")

  const openModal = () => setOpen(true)
  const closeModal = () => setOpen(false)

  return (
    <>
      <ReelerButton
        styleClass="btn-secondary me-3"
        text="Publish form"
        dispatch={openModal}
      />
      <Modal show={open} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Publish your form</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PublishTab />
        </Modal.Body>
        <Modal.Footer>
          <ReelerButton
            text="Close"
            dispatch={closeModal}
            styleClass="btn-secondary"
          />
        </Modal.Footer>
      </Modal>
    </>
  )
}
