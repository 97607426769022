import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { database } from "../../firebase"
import { selectAccounts } from "../../redux/account/account.selectors"
import { Table } from "react-bootstrap"
import SpinnerComponent from "../commons/SpinnerComponent"
import Section from "../../layouts/core/Section"
import { Header } from "../../layouts/core/Header"
import Title from "../../layouts/core/Title"
import Body from "../../layouts/core/Body"
import { ACCOUNT_PLANS, USER_ROLES } from "../../constants/routes"
import { selectCurrentUser } from "../../redux/user/user.selectors"
import FilterRow from "./components/FilterRow"
import AuthBasedComponent from "../authentication/AuthBasedComponent"
import { FaPlusCircle } from "react-icons/fa"
import ReelerIconButton from "../commons/reelerIconButton/ReelerIconButton"
import {
  clearFilter,
  setShowSaveFilter,
} from "../../redux/filter/filter.actions"

const FiltersView = () => {
  const dispatch = useDispatch()
  const account = useSelector(selectAccounts)
  const currentUser = useSelector(selectCurrentUser)
  const [loading, setLoading] = useState(true)
  const [filters, setFilters] = useState([])

  const createNewFilter = () => {
    dispatch(clearFilter())
    dispatch(setShowSaveFilter(true))
  }

  useEffect(() => {
    if (account?.id && currentUser) {
      setLoading(true)

      let q = database.accounts.doc(account?.id).collection("filters")

      if (currentUser?.role === USER_ROLES.viewer) {
        q = q.where("userIds", "array-contains", currentUser?.id)
      }

      const unsubscribe = q.orderBy("name", "asc").onSnapshot(snap => {
        const data = snap.docs.map(doc => database.formatDoc(doc))
        setFilters(data)
        setLoading(false)
      })
      return () => unsubscribe()
    }
  }, [account, currentUser])
  return (
    <Section>
      <Header>
        <Title>
          {currentUser?.role !== USER_ROLES.viewer
            ? "Content filters"
            : "Content filters shared with you"}
        </Title>

        <div className="d-flex ms-auto">
          <AuthBasedComponent
            plans={[
              ACCOUNT_PLANS.basic,
              ACCOUNT_PLANS.pro,
              ACCOUNT_PLANS.premium,
            ]}
          >
            <ReelerIconButton
              dispatch={() => createNewFilter()}
              text="Create filter"
              icon={<FaPlusCircle className="me-1" />}
            />
          </AuthBasedComponent>
        </div>
      </Header>
      <Body>
        {!loading ? (
          filters && filters?.length > 0 ? (
            <Table className="mt-3" hover>
              <thead>
                <tr
                  style={{
                    height: "50px",
                    borderBottom: "2px solid #dee2e6",
                  }}
                >
                  <th className="align-middle">Filter name</th>
                  <th className="align-middle">Description</th>
                  <th className="text-center"></th>
                </tr>
              </thead>

              <tbody>
                {filters?.map((filter, index) => {
                  return <FilterRow key={filter.id} filter={filter} />
                })}
              </tbody>
            </Table>
          ) : (
            <div className="pt-5 d-flex flex-column justify-content-center align-items-center">
              <img
                className="mb-4"
                width="200px"
                src="../../no_content_women_dog.svg"
                alt=""
              />
              <p className="text-center">
                {currentUser?.role === USER_ROLES.viewer
                  ? "No content has been shared with you yet."
                  : "You do not have any saved filters yet. Please create your first filter."}
              </p>
            </div>
          )
        ) : (
          <SpinnerComponent size="lg" />
        )}
      </Body>
    </Section>
  )
}

export default FiltersView
