import React from "react"
import * as S from "./skeleton-styles"

const Skeleton = () => {
  return (
    <S.SkeletonContainer>
      <S.ProductSkeleton />
    </S.SkeletonContainer>
  )
}

export default Skeleton
