import { createSelector } from "reselect"

const selectAsset = state => state.asset

export const selectAssets = createSelector([selectAsset], asset => asset.assets)

export const selectAssetIsLoading = createSelector(
  [selectAsset],
  asset => asset.isLoading
)

export const selectLastVisibleAsset = createSelector(
  [selectAsset],
  asset => asset.lastVisible
)

export const selectCampaignAssets = createSelector(
  [selectAsset],
  asset => asset.campaignAssets
)

export const selectActiveAsset = createSelector(
  [selectAsset],
  asset => asset.asset
)

export const selectShowAssetInfoModal = createSelector(
  [selectAsset],
  asset => asset.showAssetInfoModal
)

export const selectActiveSidebarTab = createSelector(
  [selectAsset],
  asset => asset.activeSidebarTab
)

export const selectSelectedVersion = createSelector(
  [selectAsset],
  asset => asset.selectedVersion
)

export const selectVersionToView = createSelector(
  [selectAsset],
  asset => asset.versionToView
)

export const selectShowAssetRightsRequestModal = createSelector(
  [selectAsset],
  asset => asset.showAssetRightsRequestModal
)
export const selectAssetRightsRequest = createSelector(
  [selectAsset],
  asset => asset.assetRightsRequest
)
