import React, { useState, useEffect } from "react"

import { functions } from "../../../firebase"

import { useSelector } from "react-redux"

import { selectAccountId } from "../../../redux/account/account.selectors"

import {
  // selectOrderBy,
  selectFilterLoading,
  selectNumberOfFiltredAssets,
  selectFilterParameters,
  selectSubFiltredAssets,
  selectViewerFilterParameters,
} from "../../../redux/filter/filter.selectors"

import SpinnerComponent from "../../commons/SpinnerComponent"

const NumberOfAssets = () => {
  //FROM MEDIAFILTER
  const accountId = useSelector(selectAccountId)

  // const orderBy = useSelector(selectOrderBy)
  const filterLoading = useSelector(selectFilterLoading)
  const filter = useSelector(selectFilterParameters)
  const viewerFilterParameters = useSelector(selectViewerFilterParameters)
  const subFiltredAssets = useSelector(selectSubFiltredAssets)
  const numberOfFiltredAssets = useSelector(selectNumberOfFiltredAssets)

  const [totalAssets, setTotalAssets] = useState(0)
  const [loadingTotalAssets, setLoadingTotalAssets] = useState(false)

  useEffect(() => {
    if (accountId) {
      // Fetch number of total assets for the account
      setLoadingTotalAssets(true)
      fetchTotalAssets()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchTotalAssets = async () => {
    var CountNumberOfAssetsForAccount = functions.httpsCallable(
      "CountNumberOfAssetsForAccount"
    )

    try {
      const { data } = await CountNumberOfAssetsForAccount({
        accountId: accountId,
      })
      setTotalAssets(data.noOfAssets)
      setLoadingTotalAssets(false)
    } catch (err) {
      setLoadingTotalAssets(false)
      console.log(err)
    }
  }

  return filter ? (
    viewerFilterParameters && subFiltredAssets.length > 0 ? (
      <span> ({subFiltredAssets?.length}) </span>
    ) : filterLoading ? (
      <SpinnerComponent size="sm" />
    ) : (
      <span> ({numberOfFiltredAssets}) </span>
    )
  ) : loadingTotalAssets ? (
    <SpinnerComponent size="sm" />
  ) : (
    <span>({totalAssets})</span>
  )
}

export default NumberOfAssets
