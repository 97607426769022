/**
 * Function to calculate the age of a person given their birthdate
 * @param {*} birthdateString
 * @returns age
 */

const calculateAge = birthdateString => {
  // Parse the birthdate string into a Date object
  const birthdate = new Date(birthdateString)

  // Get today's date
  const today = new Date()

  // Calculate the difference in years
  let age = today.getFullYear() - birthdate.getFullYear()

  // Adjust if the birthday hasn't occurred yet this year
  const monthDiff = today.getMonth() - birthdate.getMonth()
  const dayDiff = today.getDate() - birthdate.getDate()

  if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
    age-- // Subtract one if the birthday hasn't happened yet this year
  }

  return age
}

export { calculateAge }
