import { takeLatest, put, all, call, select } from "redux-saga/effects"
import UserActionTypes from "../user/user.types"
import { fetchAccountsSuccess, fetchAccountsFailure } from "./account.actions"
import { getAccountsRef } from "../../firebase"
import { selectAccountId } from "./account.selectors"

export function* getUserAccounts() {
  try {
    const accountId = yield select(selectAccountId)
    // const ids = yield Object.keys(accountId)
    const accountsRef = yield call(getAccountsRef, accountId)
    yield put(fetchAccountsSuccess(accountsRef))
  } catch (error) {
    yield put(fetchAccountsFailure(error))
  }
}

export function* onEmailSignInStart() {
  yield takeLatest(UserActionTypes.SIGN_IN_SUCCESS, getUserAccounts)
}

export function* accountSagas() {
  yield all([call(onEmailSignInStart)])
}
