import React from "react"
import * as S from "./tag-styles"
import { IoClose } from "react-icons/io5"
import ReelerTooltip from "../ReelerTooltip/ReelerTooltip"
export default function Tag({
  disabled = false,
  highlighted = false,
  tooltip,
  tooltipPlacement = "top",
  showRemoveIcon = true,
  children,
  ...rest
}) {
  const renderTag = () => (
    <S.Tag disabled={disabled} highlighted={highlighted} {...rest}>
      {children}
      {showRemoveIcon && <IoClose size="14px" />}
    </S.Tag>
  )
  if (tooltip)
    return (
      <ReelerTooltip text={tooltip} placement={tooltipPlacement}>
        {renderTag()}
      </ReelerTooltip>
    )

  return renderTag()
}
