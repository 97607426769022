import styled from "styled-components"

export const Carousel = styled.section`
  display: flex;
  flex-direction: row;
  width: 100%;
  position: relative;
`
export const CarouselContainer = styled.div`
  display: flex;
  width: 100%;
  overflow: hidden;
`
export const ItemOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
  transition: all 100ms ease-in-out;
  color: #fff;
  /* center overlay text */
  display: none;
  align-items: center;
  justify-content: center;
  border-radius: ${({ settings: { borderRadius } }) => `${borderRadius}px`};
`

export const CarouselItem = styled.div`
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  transition: transform 0.5s ease-in-out;
  transform: ${({ sliderIndex }) => `translateX(${-sliderIndex * 100}%)`};
  border-radius: ${({ settings: { borderRadius } }) => `${borderRadius}px`};
  padding: ${({ gap }) => (gap ? `0 ${gap / 2}px` : 0)};
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  aspect-ratio: 1/1;
  &:hover ${ItemOverlay} {
    display: flex;
  }

  @media (min-width: 768px) {
    width: 50%;
  }
  @media (min-width: 1024px) {
    width: ${({ slides }) => `${100 / slides}%`};
  }
`
export const CarouselButton = styled.button`
  all: unset;
  outline: none;
  display: block;
  margin: 0 0.5rem;
  cursor: pointer;
  transition: background-color 100ms ease-in-out;
  color: ${({ buttonColor }) => (buttonColor ? `${buttonColor}` : "#000000")};
  &:hover {
    color: ${({ buttonColor }) =>
      buttonColor ? `${buttonColor}90` : "#00000090"};
  }
  &:focus {
    outline: none;
  }
`
