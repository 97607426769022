import React, { useEffect, useState } from "react"
import { Table, Row, Col } from "react-bootstrap"
import { database } from "../../../firebase"
import { useSelector } from "react-redux"
import { selectAccountId } from "../../../redux/account/account.selectors"
import CloseButton from "../../commons/CloseButton"
import ReelerTooltip from "../../commons/ReelerTooltip/ReelerTooltip"
import ProductModal from "./ProductModal"
import * as S from "../Products.styles"

import { FaRegImage, FaEdit, FaTrash } from "react-icons/fa"

export default function ProductList() {
  const [products, setProducts] = useState([])
  const accountId = useSelector(selectAccountId)

  useEffect(() => {
    if (accountId) {
      const productsRef = database.accounts
        .doc(accountId)
        .collection("products")
        .orderBy("createdAt")

      productsRef.onSnapshot(querySnapshot => {
        let productArray = []
        querySnapshot.forEach(doc => {
          productArray.push(database.formatDoc(doc))
        })
        setProducts(productArray)
      })
    }
  }, [accountId])

  const handleRemoveProduct = productId => {
    const productRef = database.accounts
      .doc(accountId)
      .collection("products")
      .doc(productId)

    productRef
      .delete()
      .then(async () => {
        // TODO: Tabort product från alla assets som har aktuell produkt
        const assetsRef = database.assets
          .where("accountId", "==", accountId)
          .where("productIds", "array-contains", productId)

        let assetsWithProduct = []
        try {
          const querySnapshot = await assetsRef.get()
          querySnapshot.forEach(doc => {
            assetsWithProduct.push(database.formatDoc(doc))
          })
        } catch (e) {
          console.log(e)
        }

        return assetsWithProduct
      })
      .then(async assetArray => {
        console.log("Found no of assets with product: " + assetArray.length)
        const promises = assetArray.map(async a => {
          const updatedProductArray = a?.products.filter(
            p => p.id !== productId
          )
          const updatedProductIds = a?.productIds.filter(id => id !== productId)

          const assetRef = database.assets.doc(a.id)

          return assetRef
            .update({
              products: updatedProductArray,
              productIds: updatedProductIds,
            })
            .catch(err => console.log(err))
        })
        await Promise.all(promises).catch(err => console.log(err))
        console.log("Removed products from all assets")
        return true
      })
      .catch(err => {
        console.log(err)
      })

    // Måste även ta bort produkten från assets också
  }

  return (
    <div className="my-4">
      <h5>Products</h5>
      <Table className="mt-3" hover size="sm">
        <thead>
          <tr style={{ height: "50px", borderBottom: "2px solid #dee2e6" }}>
            <th style={{ width: "50px" }}></th>

            <th style={{ width: "250px", borderBottom: "none" }}>Name</th>
            <th style={{ borderBottom: "none" }}>Description</th>

            <th className="text-center"></th>
          </tr>
        </thead>
        <tbody>
          {products?.map((product, index) => (
            <tr>
              <td>
                <S.ProductImageContainer>
                  {product?.imageUrl ? (
                    <S.ProductImage src={product?.imageUrl} />
                  ) : (
                    <FaRegImage
                      size="30px"
                      style={{ color: "var(--reeler-grey-dark)" }}
                    />
                  )}
                </S.ProductImageContainer>
              </td>

              <td>
                <div className="text-truncate">{product?.name}</div>
              </td>
              <td>
                <S.ProductDescription>
                  {product?.description}
                </S.ProductDescription>
              </td>

              <td className="text-center">
                <div className="d-flex flex-row justify-content-end algign-items-center">
                  <span
                    className="d-flex justify-content-center align-items-center me-2"
                    style={{ width: "30px" }}
                  >
                    <ProductModal
                      key={product.id}
                      existingProduct={product}
                      btn={
                        <ReelerTooltip text="Edit product">
                          <FaEdit className="icon-color icon-btn" />
                        </ReelerTooltip>
                      }
                    />
                  </span>

                  <span
                    className="d-flex justify-content-center align-items-center me-2"
                    style={{ width: "30px" }}
                  >
                    <ReelerTooltip text="Delete product">
                      <FaTrash
                        className="icon-color icon-btn"
                        onClick={() => handleRemoveProduct(product.id)}
                      />
                    </ReelerTooltip>
                  </span>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}
