import React from "react"
import { Col } from "react-bootstrap"

const Page = ({ children }) => {
  return (
    <Col md={8}>
      <main>{children}</main>
    </Col>
  )
}

export default Page
