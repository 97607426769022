import React, { useRef, useState } from "react"

// 3rd-party
import { InputGroup, Form, Button } from "react-bootstrap"
import { IoClose } from "react-icons/io5"
import CreatableSelect from "react-select/creatable"

// Firebase
import firebase from "firebase/app"
import { database } from "../../../../../../firebase"

// Redux
import { useSelector, useDispatch } from "react-redux"
import { updateAccounts } from "../../../../../../redux/account/account.actions"

import {
  selectAccountId,
  selectAccounts,
} from "../../../../../../redux/account/account.selectors"
import Tag from "../../../../../commons/Tag"
const customStyles = {
  container: () => ({
    // none of react-select's styles are passed to <Control />
    width: `100%`,
  }),
}
export default function AssetTags({ asset }) {
  const dispatch = useDispatch()
  const account = useSelector(selectAccounts)
  const accountId = useSelector(selectAccountId)
  const [loading, setLoading] = useState(false)
  const [tag, setTag] = useState("")

  const handleNewTag = str => {
    setLoading(true)

    let tags = { tags: {} }
    let accountTags = []

    str
      .toLowerCase()
      .replace(/#+/g, "")
      .split(" ")
      .forEach(tag => {
        if (tag === "") {
          return
        }

        tags.tags[tag] = true
        accountTags.push(tag)
      })

    const recentTags = account?.recent_tags
      ? [
          ...account?.recent_tags.filter(tag => !accountTags.includes(tag)),
          ...accountTags,
        ].slice(-20)
      : [...accountTags].slice(-20)

    database.assets
      .doc(asset.id)
      .set(tags, { merge: true })
      .then(() => {
        // update firebase and redux
        database.accounts
          .doc(accountId)
          .update({
            recent_tags: recentTags,
            tags: firebase.firestore.FieldValue.arrayUnion(...accountTags),
          })
          .then(() => {
            // Update redux
            dispatch(
              updateAccounts({
                recent_tags: recentTags,
                tags: account?.tags
                  ? [...account?.tags, ...accountTags]
                  : accountTags,
              })
            )
            setLoading(false)
          })
          .catch(err => {
            setLoading(false)
          })
      })

      .catch(err => {
        setLoading(false)
      })
  }

  const addTagToAsset = str => {
    setLoading(true)

    let tags = asset.tags ? asset.tags : {}

    str.split(" ").forEach(tag => {
      if (tag === "") {
        return
      }
      let lowerCasedTag = tag.toLowerCase()
      tags[lowerCasedTag] = true
    })

    let tagsArray = Object.keys(tags)

    const recentTags =
      account?.recent_tags?.length > 0
        ? [
            ...account?.recent_tags.filter(tag => !tagsArray.includes(tag)),
            ...tagsArray,
          ].slice(-20)
        : [...tagsArray].slice(-20)

    database.assets
      .doc(asset.id)
      .set({ tags }, { merge: true })

      .then(() => {
        database.accounts
          .doc(accountId)
          .update({
            recent_tags: recentTags,
          })
          .then(() => {
            // Update redux
            dispatch(
              updateAccounts({
                recent_tags: recentTags,
              })
            )
            setLoading(false)
          })
          .catch(err => {
            setLoading(false)
          })
      })
      .catch(err => {
        setLoading(false)
      })
  }

  const handleDeleteTag = tagToDelete => {
    setLoading(true)

    database.assets
      .doc(asset.id)
      .set(
        {
          tags: {
            [tagToDelete]: firebase.firestore.FieldValue.delete(),
          },
        },
        { merge: true }
      )
      .then(() => {
        setLoading(false)
      })
      .catch(err => {
        setLoading(false)
      })
  }

  return (
    <div className="pt-3">
      <InputGroup className="mb-3">
        <CreatableSelect
          styles={customStyles}
          theme={theme => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: "var(--reeler-o-25)",
              primary: "var(--reeler-light)",
            },
          })}
          placeholder="Add a new label..."
          isClearable
          formatCreateLabel={tag =>
            `Add and save "${tag}" as a standard account label`
          }
          value={tag}
          onChange={(selectedOption, action) => {
            if (action.action === "clear") {
              setTag("")
            } else if (action.action === "create-option") {
              setTag(action.option.value?.trim().toLowerCase())
              handleNewTag(action.option.value?.trim().toLowerCase())
            } else {
              setTag(selectedOption.value?.trim().toLowerCase())
              addTagToAsset(selectedOption.value?.trim().toLowerCase())
            }
          }}
          options={account?.tags
            ?.sort((a, b) => {
              if (a.toLowerCase() < b.toLowerCase()) {
                return -1
              }
              if (a.toLowerCase() > b.toLowerCase()) {
                return 1
              }
              return 0
            })
            ?.map(t => ({ value: t, label: t }))}
        />
      </InputGroup>
      <Form.Text mute>
        Account labels appear in a dropdown list when you label assets and when
        you use the search filter.
      </Form.Text>
      <div>
        {asset.tags &&
          Object.keys(asset.tags).map((tag, index) => (
            <Tag key={index} onClick={() => handleDeleteTag(tag)}>
              {tag}
            </Tag>
          ))}
      </div>
    </div>
  )
}
