import styled from "styled-components"

export const VideoListContainer = styled.div`
  display: flex;
  flex-direction: ${({ layout }) =>
    layout === "horizontal" ? "row" : "column"};
  flex-wrap: nowrap;
  overflow-x: scroll;
  padding: 0.5rem 1rem;
`

export const VideoCardOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  top: 0;
  left: 0;
  transition: all 100ms ease-in-out;
  color: #fff;
  /* center overlay text */
  display: none;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
`

export const VideoCard = styled.div`
  position: relative;
  width: 200px;
  min-width: 200px;
  padding: 0.5rem;

  cursor: pointer;
  &:hover ${VideoCardOverlay} {
    display: flex;
  }
`

export const VideoCardLabel = styled.div`
  font-size: 12px;
  font-weight: 400;
  margin-top: 0.75rem;
`

export const Thumbnail = styled.img`
  width: 100%;
  object-fit: cover;
`
