import React from "react"
import { Button } from "react-bootstrap"
import { Link } from "react-router-dom"
import { MAIN_ROUTES } from "../../constants/routes"

export default function ConnectToInstagram() {
  return (
    <div className="pt-5 pb-5 d-flex flex-column justify-content-center align-items-center">
      <img
        className="mb-3"
        height="150px"
        src="../../women_working_out.svg"
        alt=""
      />
      <p className="p-3">
        You are not connected any Instagram Business Account.
      </p>

      <Button
        as={Link}
        className="btn-main"
        to={MAIN_ROUTES.settingsIntegration.path}
      >
        Connect your account here
      </Button>
    </div>
  )
}
