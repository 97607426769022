import React, { useState } from "react"
import { Row, Col, Tabs, Tab, Badge } from "react-bootstrap"
import { useSelector, useDispatch } from "react-redux"
import { selectAccounts } from "../../../redux/account/account.selectors"

import TextMessageTemplates from "./components/TextMessageTemplates"
import ApprovalPageTemplate from "./components/ApprovalPageTemplate"
import EmailTemplates from "./components/EmailTemplates"
import ReelerNotification from "../../commons/ReelerNotification"
import Section from "../../../layouts/core/Section"
import { Header } from "../../../layouts/core/Header"
import Title from "../../../layouts/core/Title"
import Body from "../../../layouts/core/Body"
import BackButton from "../../commons/BackButton"

export default function RightRequestTemplatesView() {
  const [key, setKey] = useState("approval-page")
  const dispatch = useDispatch()
  const account = useSelector(selectAccounts)

  return (
    <Section>
      <Header>
        <Title>Rights management</Title>
        <div className="ms-auto">
          <BackButton />
        </div>
      </Header>
      <Body>
        <Tabs activeKey={key} onSelect={k => setKey(k)}>
          <Tab
            tabClassName="link"
            eventKey="approval-page"
            title={
              <div className="d-flex flex-row">
                <span>Approval pages</span>
                {account?.right_request_templates?.approvalPages ? (
                  <Badge className="d-flex align-items-center ms-1 badge-main">
                    {account?.right_request_templates?.approvalPages?.length}
                  </Badge>
                ) : (
                  <Badge className="badge-main">0</Badge>
                )}
              </div>
            }
          >
            <div
              style={{
                backgroundColor: "#fff",
                border: "1px solid rgba(0, 0, 0, 0.125)",
                borderTop: "none",
                borderRadius: "0 0 0.25rem 0.25rem",
                padding: "0.75rem 0.5rem",
              }}
            >
              <ApprovalPageTemplate />
            </div>
          </Tab>
          <Tab
            tabClassName="link"
            eventKey="text-message"
            title={
              <div className="d-flex flex-row">
                <span>Message on Instagram</span>
                {account?.right_request_templates?.textMessages ? (
                  <Badge className="d-flex align-items-center ms-1 badge-main">
                    {account?.right_request_templates?.textMessages?.length}
                  </Badge>
                ) : (
                  <Badge className="badge-main">0</Badge>
                )}
              </div>
            }
          >
            <div
              style={{
                backgroundColor: "#fff",
                border: "1px solid rgba(0, 0, 0, 0.125)",
                borderTop: "none",
                borderRadius: "0 0 0.25rem 0.25rem",
                padding: "0.75rem 0.5rem",
              }}
            >
              <TextMessageTemplates />
            </div>
          </Tab>
          <Tab
            tabClassName="link"
            eventKey="email"
            title={
              <div className="d-flex flex-row">
                <span>Message by email</span>
                {account?.right_request_templates?.emailTemplates ? (
                  <Badge className="d-flex align-items-center ms-1 badge-main">
                    {account?.right_request_templates?.emailTemplates?.length}
                  </Badge>
                ) : (
                  <Badge className="badge-main">0</Badge>
                )}
              </div>
            }
          >
            <div
              style={{
                backgroundColor: "#fff",
                border: "1px solid rgba(0, 0, 0, 0.125)",
                borderTop: "none",
                borderRadius: "0 0 0.25rem 0.25rem",
                padding: "0.75rem 0.5rem",
              }}
            >
              <EmailTemplates />
            </div>
          </Tab>
        </Tabs>
      </Body>
    </Section>
  )
}
