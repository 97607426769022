import React, { useState, useEffect } from "react"
import { Modal } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { setShowEditModal } from "../../../../redux/campaign/campaign.actions"
import ReelerButton from "../../../commons/ReelerButton"
import { selectIsSavingFormField } from "../../../../redux/campaign/campaign.selectors"

export default function SaveField({ saveFunction }) {
  const dispatch = useDispatch()
  const isSavingFormField = useSelector(selectIsSavingFormField)

  const closeEditModal = () => {
    dispatch(setShowEditModal(false))
  }

  return (
    <Modal.Footer className="d-flex justify-content-end mt-3">
      <ReelerButton
        text="Close"
        dispatch={closeEditModal}
        styleClass="btn-secondary me-2"
      />
      <ReelerButton
        loading={isSavingFormField}
        disabled={isSavingFormField}
        dispatch={saveFunction}
        text="Save"
        styleClass="btn-main"
        spinnerClass="ms-1"
      />
    </Modal.Footer>
  )
}
