import React from "react"
import { Badge } from "./ReelerBadge.syles"

export default function ReelerBadge({ value = "0", variant = "main" }) {
  return (
    <Badge variant={variant}>
      {value > 1000 ? (value / 1000).toFixed(1) + "k" : value}
    </Badge>
  )
}
