import React, { useRef, useState } from "react"

// 3rd-party
import { InputGroup, Form } from "react-bootstrap"
import { IoClose } from "react-icons/io5"
import CreatableSelect from "react-select/creatable"
import { Link } from "react-router-dom"

// Firebase
import firebase from "firebase/app"
import { database } from "../../../../../../firebase"
import {
  ACCOUNT_PLANS,
  MAIN_ROUTES,
  USER_ROLES,
} from "../../../../../../constants/routes"
// Redux
import { useDispatch, useSelector } from "react-redux"
import {
  BulkEditActionSuccess,
  BulkEditMessageReset,
} from "../../../../../../redux/bulk-edit/bulkEdit.actions"
import { selectActiveAsset } from "../../../../../../redux/asset/asset.selectors"
import { updateAsset } from "../../../../../../redux/asset/asset.actions"
import { updateAccounts } from "../../../../../../redux/account/account.actions"

import {
  selectAccountId,
  selectAccounts,
} from "../../../../../../redux/account/account.selectors"
import { ModalTabContainer, ModalTabHeading } from "../../styles"
import AssetClassifyImage from "./AssetObjectAnnotateImage"
import AuthBasedComponent from "../../../../../authentication/AuthBasedComponent"
import { selectCurrentUser } from "../../../../../../redux/user/user.selectors"
import Tag from "../../../../../commons/Tag"
const customStyles = {
  container: () => ({
    // none of react-select's styles are passed to <Control />
    width: `100%`,
  }),
}
const AssetObjectLabels = () => {
  const asset = useSelector(selectActiveAsset)
  const copyLabel = label => {
    navigator.clipboard.writeText(label)
  }

  return asset.objectLabels ? (
    <AuthBasedComponent
      plans={[ACCOUNT_PLANS.premium]}
      userRoles={Object.keys(USER_ROLES)}
    >
      <div className="mt-3">
        <h5>Ai Tags</h5>
        <Form.Text muted>
          Ai tags have been automatically generated by Reeler AI to help
          categorize assets. To search for assets with specific ai tags, you can
          open the “All content” page and use the filter function (under “More
          filters”).
        </Form.Text>
        <div className="d-flex flex-wrap">
          {Object.keys(asset.objectLabels).map((label, index) => (
            <Tag
              key={index}
              showRemoveIcon={false}
              tooltip="Copy tag"
              onClick={() => copyLabel(label)}
            >
              {label}
            </Tag>
          ))}
        </div>
      </div>
    </AuthBasedComponent>
  ) : null
}

export default AssetObjectLabels
