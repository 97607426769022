import InstagramSearchActionsTypes from "./instagramSearch.types"

export const addSelectedMedia = payload => ({
  type: InstagramSearchActionsTypes.ADD_SELECTED_MEDIA,
  payload,
})
export const removeSelectedMedia = payload => ({
  type: InstagramSearchActionsTypes.REMOVE_SELECTED_MEDIA,
  payload,
})
export const openMediaObjectModal = payload => ({
  type: InstagramSearchActionsTypes.OPEN_MEDIA_OBJECT_MODAL,
  payload,
})
export const closeMediaObjectModal = () => ({
  type: InstagramSearchActionsTypes.CLOSE_MEDIA_OBJECT_MODAL,
})
export const resetSelectedMedia = () => ({
  type: InstagramSearchActionsTypes.RESET_SELECTED_MEDIA,
})
