import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { Link } from "react-router-dom"
import ReelerButton from "../commons/ReelerButton"

export default function NotFound() {
  return (
    <Container
      fluid
      className="container_background"
      style={{ minHeight: "100vh" }}
    >
      <Row className="mt-3 mb-3">
        <Col>
          <div className="pt-5 d-flex flex-column justify-content-center align-items-center">
            <img width="200px" src="../no_content_women_dog.svg" alt="" />

            <h1>Page not found</h1>
            <p style={{ width: "400px" }}>
              Whooops! We don't have the page you're looking for...
            </p>
            <Link to="/">
              <ReelerButton text="Back to home" styleClass="btn-main" />
            </Link>
          </div>
        </Col>
      </Row>
    </Container>
  )
}
