import React, { useEffect, useState, useRef } from "react"
import Section from "../../layouts/core/Section"
import {
  Header,
  HeaderGroup,
  HeaderGroupCenter,
  HeaderGroupLeft,
  HeaderGroupRight,
} from "../../layouts/core/Header"
import Body from "../../layouts/core/Body"
import Title from "../../layouts/core/Title"
import Form from "react-bootstrap/Form"
import InputGroup from "react-bootstrap/InputGroup"
import Button from "react-bootstrap/Button"
import SpinnerComponent from "../../components/commons/SpinnerComponent"
import { useSelector } from "react-redux"
import { selectAccounts } from "../../redux/account/account.selectors"
import { SubHeader, SubHeaderGroup } from "../../layouts/core/SubHeader"
import useUserFeedbackMessage from "../../hooks/useUserFeedbackMessage"
import {
  getHashtagId,
  getHashtagName,
  getHashtagRecentMedia,
  getHashtagTopMedia,
  getRecentlySearchedHashtags,
  searchUsername,
} from "../../services/InstagamService"
import ReelerButton from "../../components/commons/ReelerButton"
import * as S from "./InstagramSearch.styles"
import CreatableSelect from "react-select/creatable"
import InfiniteScroll from "react-infinite-scroll-component"
import axios from "axios"
import ReelerTooltip from "../../components/commons/ReelerTooltip/ReelerTooltip"
import MediaObject from "./components/MediaObject"
import MediaObjectModal from "./components/MediaObjectModal/MediaObjectModal"
import NoContent from "../../components/commons/NoContent"
import {
  FaImages,
  FaVideo,
  FaExclamation,
  FaFilter,
  FaSearch,
  FaTimesCircle,
  FaTimes,
} from "react-icons/fa"
import Text from "../../components/commons/Text"
import { Link } from "react-router-dom"
import { MAIN_ROUTES } from "../../constants/routes"
import Icon from "../../components/commons/Icon"
import ReelerPopover from "../../components/commons/ReelerPopover"
import { MEDIA_TYPE } from "../../constants"
import { Col, Row } from "react-bootstrap"
import {
  ToogleGroup,
  Toggle,
} from "../../components/commons/ReelerToogleGroup/ReelerToggleGroup"
import CloseButton from "../../components/commons/CloseButton"
import { add } from "lodash"
import { Input } from "@material-ui/core"

const FETCH_LIMIT = 50
const SEARCH_TYPE = { hashtag: "hashtag", username: "username" }

export default function InstagramSearchPage() {
  const usernameRef = useRef()
  const hashtagRef = useRef()
  const [loading, setLoading] = useState(false)
  const [searchInput, setSearchInput] = useState(null)
  const [stopSearch, setStopSearch] = useState(false)
  const [isSearching, setIsSearching] = useState(false)
  const [showFilter, setShowFilter] = useState(false)
  const [isFetchingMoreObjects, setIsFetchingMoreObjects] = useState(false)
  const [hasMore, setHasMore] = useState(false)
  const [mediaObjects, setMediaObjects] = useState(null)
  const [nextPagingLink, setNextPagingLink] = useState(null)
  const [cursorAfter, setCursorAfter] = useState(null)
  const [hashtagLimitSearch, setHashtagLimitSearch] = useState(null)
  const [previouslySearchedHashtags, setPreviouslySearchedHashtags] = useState(
    []
  )
  const [isClearable, setIsCleaable] = useState(false)
  const [filter, setFilter] = useState({})
  const [searchType, setSearchType] = useState("hashtag")
  const [hashtagSearchType, setHashtagSearchType] = useState("top")
  const [igBusinessAccountId, setIgBusinessAccountId] = useState(null)
  const [accessToken, setAccessToken] = useState(null)
  const [numberOfFetches, setNumberOfFetches] = useState(0)
  const account = useSelector(selectAccounts)
  const { setErrorMessage, setUserFeedbackMessage } = useUserFeedbackMessage()

  const closeFilter = () => setShowFilter(false)
  const toggleFilter = () => setShowFilter(prev => !prev)

  useEffect(() => {
    if (account) {
      setLoading(true)

      setIgBusinessAccountId(
        account?.integrations?.instagram?.accounts[0]
          ?.ig_business_account_profile?.id
      )

      setAccessToken(account?.integrations?.instagram?.long_lived_user_token)

      setLoading(false)
      // Check how many ig search limit
    }
  }, [account])

  useEffect(() => {
    if (stopSearch) return
    if (searchType === SEARCH_TYPE.hashtag) {
      if (
        mediaObjects &&
        mediaObjects?.length < FETCH_LIMIT &&
        nextPagingLink &&
        !isFetchingMoreObjects &&
        !isSearching &&
        numberOfFetches < FETCH_LIMIT
      ) {
        console.log("Fetch more")
        handleLoadMoreMediaObjects()
        setNumberOfFetches(prev => prev + 1)
      }
    } else {
      if (
        mediaObjects &&
        mediaObjects?.length < FETCH_LIMIT &&
        cursorAfter &&
        !isFetchingMoreObjects &&
        !isSearching &&
        numberOfFetches < FETCH_LIMIT
      ) {
        handleLoadMoreMediaObjects()
        setNumberOfFetches(prev => prev + 1)
      }
    }
  }, [mediaObjects, isFetchingMoreObjects, isSearching, searchType, stopSearch])

  const handleSearchInput = () => {
    const hashtag = hashtagRef.current.value
    if (hashtag) {
      handleSearch(hashtagSearchType)
    } else {
      handleClearSearch()
    }
  }

  const filterMediaObjects = mediaObjects => {
    if (!filter) return mediaObjects
    const flattenedMediaObjects = flattenArray(mediaObjects)
    const filteredMediaObjects = applyFilters(flattenedMediaObjects)

    return filteredMediaObjects
  }

  const handleSearch = async type => {
    const hashtag = hashtagRef.current.value
    if (!hashtag) return

    if (!igBusinessAccountId) return

    setStopSearch(false)
    setIsSearching(true)
    setMediaObjects(null)
    setNextPagingLink(null)
    setHasMore(false)
    setIsFetchingMoreObjects(false)
    setNumberOfFetches(0)
    // Get hashtag id

    const searchTerm = hashtag.replaceAll("#", "")

    try {
      const hashtagId = await getHashtagId(
        igBusinessAccountId,
        searchTerm,
        accessToken
      )

      let response

      console.log(type)

      if (type === "top") {
        response = await getHashtagTopMedia(
          hashtagId,
          igBusinessAccountId,
          accessToken
        )
      } else {
        response = await getHashtagRecentMedia(
          hashtagId,
          igBusinessAccountId,
          accessToken
        )
      }

      const filteredMediaObjects = filterMediaObjects(response?.data)

      setMediaObjects(filteredMediaObjects)

      if (!response?.paging?.next) {
        setNextPagingLink(null)
        setHasMore(false)
      } else {
        setNextPagingLink(response?.paging?.next)
        setHasMore(true)
      }

      await checkHashtagLimitSearch(accessToken)

      setIsSearching(false)
    } catch (err) {
      console.log(err)
      let errorMsg = ""
      if (err?.response?.data?.error?.error_subcode === 2207034) {
        errorMsg =
          "You can query a maximum of 30 unique hashtags on behalf of your Instagram Business Account within a rolling, 7 day period. Once you query a hashtag, it will count against this limit for 7 days. Subsequent queries on the same hashtag within this time frame will not count against your limit, and will not reset its initial query 7 day timer."
      } else if (err?.response?.data?.error?.error_subcode === 2207024) {
        errorMsg =
          "The hashtag has to already exist on Instagram. If you want to use a new hashtag, please begin by posting the hashtag anywhere on Instagram – then please try again to register it in Reeler."
      } else if (err?.response?.data?.error?.error_subcode === 463) {
        errorMsg =
          "The connection to Instagram has expired and you need to refresh the Instagram connection. <a class='notification-link' href='/settings/integrations'>Refresh connection here</a>"

        // TODO: Update the account and remove the exisiting connection
      } else if (err?.response?.data?.error?.error_user_title != undefined) {
        errorMsg = err.response.data.error.error_user_title
      } else {
        errorMsg = err?.response?.data?.error?.message
      }
      setErrorMessage(errorMsg, 5000)
      setIsSearching(false)
    }
  }

  const handleLoadMoreMediaObjects = async () => {
    if (searchType === SEARCH_TYPE.hashtag) {
      if (nextPagingLink) {
        setIsFetchingMoreObjects(true)
        try {
          const { data } = await axios({
            url: nextPagingLink,
            method: "get",
          })

          const filteredMediaObjects = filterMediaObjects(data?.data)

          // If we have stoped the search, do not add more media objects
          setMediaObjects(prev =>
            prev ? [...prev, ...filteredMediaObjects] : null
          )

          if (data?.paging?.next && data?.data?.length > 0) {
            setNextPagingLink(data?.paging?.next)
            setHasMore(true)
          } else {
            setNextPagingLink(null)
            setHasMore(false)
          }

          setIsFetchingMoreObjects(false)
          // Delay för att inte överbelasta Facebook
          await new Promise(resolve => setTimeout(resolve, 500))
        } catch (e) {
          console.log("Instagram Error", e)
          setHasMore(false)
          setIsFetchingMoreObjects(false)
          setNextPagingLink(null)
        }
      } else {
        setHasMore(false)
        setIsFetchingMoreObjects(false)
      }
    } else {
      if (cursorAfter) {
        setIsFetchingMoreObjects(true)
        try {
          const username = usernameRef.current.value

          const { business_discovery } = await searchUsername(
            igBusinessAccountId,
            username,
            accessToken,
            cursorAfter
          )

          const { media } = business_discovery

          const { data } = media

          const filteredMediaObjects = filterMediaObjects(data)

          setMediaObjects(prev =>
            prev
              ? [...prev, ...filteredMediaObjects]
              : [...filteredMediaObjects]
          )

          const newCursor = media?.paging?.cursors?.after

          if (newCursor && newCursor !== cursorAfter) {
            setCursorAfter(newCursor)
            setHasMore(true)
          } else {
            setCursorAfter(null)
            setHasMore(false)
          }
          setIsFetchingMoreObjects(false)
        } catch (e) {
          console.log("Instagram Error", e)
          setHasMore(false)
          setIsFetchingMoreObjects(false)
          setCursorAfter(null)
        }
      } else {
        setHasMore(false)
        setIsFetchingMoreObjects(false)
      }
    }
  }

  const handleSearchUsername = async () => {
    // Check if we have been granted all neccessary permissions
    if (
      !account?.integrations?.instagram?.authResponse?.grantedScopes.includes(
        "instagram_manage_insights"
      )
    ) {
      setUserFeedbackMessage(
        "This is a new feature, and your account lacks the necessary permissions. Please refresh your Instagram connection to grant Reeler access to the permissions required for this feature. <a class='notification-link' href='/settings/integrations'>Refresh connection here</a>",
        "warning",
        5000
      )
      return
    }
    setStopSearch(false)
    setCursorAfter(null)
    setIsFetchingMoreObjects(false)
    setIsSearching(true)
    setMediaObjects(null)
    setHasMore(false)
    setNumberOfFetches(0)
    const username = usernameRef.current.value

    if (!username || username.length < 3) return

    if (!igBusinessAccountId) return

    setIsSearching(true)
    try {
      const { business_discovery } = await searchUsername(
        igBusinessAccountId,
        username,
        accessToken
      )

      const { media } = business_discovery

      const { data } = media

      const filteredMediaObjects = filterMediaObjects(data)

      setMediaObjects(filteredMediaObjects)

      const newCursor = media?.paging?.cursors?.after

      if (newCursor && newCursor !== cursorAfter) {
        setCursorAfter(newCursor)
        setHasMore(true)
      }

      setIsSearching(false)
    } catch (err) {
      console.log(err)
      let errorMsg = ""
      let type = "error"
      if (err?.response?.data?.error?.error_subcode === 2207013) {
        errorMsg =
          "No results found. Please note that only usernames for “creator” and “business” accounts can be searched."
        type = "warning"
      } else if (err?.response?.data?.error?.error_subcode === 463) {
        errorMsg =
          "The connection to Instagram has expired and you need to refresh the Instagram connection. <a class='notification-link' href='/settings/integrations'>Refresh connection here</a>"

        // TODO: Update the account and remove the exisiting connection
      } else if (err?.response?.data?.error?.error_user_title != undefined) {
        errorMsg = err.response.data.error.error_user_title
      } else {
        errorMsg = err?.response?.data?.error?.message
      }
      setUserFeedbackMessage(errorMsg, type, 5000)
      setIsSearching(false)
    }
  }

  const applyFilters = mediaObjectsToBeFiltered => {
    if (!filter) return mediaObjectsToBeFiltered
    return mediaObjectsToBeFiltered?.filter(item => {
      for (var key in filter) {
        if (key === "hashtags") {
          const creatorTags = item?.caption
            ?.toLowerCase()
            ?.match(/#[\w\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+/g)
            ?.map(s => s.slice(1))

          if (!filter?.hashtags?.every(e => creatorTags?.includes(e))) {
            return false
          }
        } else if (item[key] === undefined || item[key] !== filter[key]) {
          return false
        }
      }
      return true
    })
  }

  const flattenArray = array => {
    const flattened = [] // Skapa en ny array för resultatet

    // En rekursiv funktion för att lägga till objekt och dess barn
    const addObject = obj => {
      if (obj.media_type === "CAROUSEL_ALBUM") {
        const { children, ...itemWithoutChildren } = obj // Separera children från objektet
        // Om det finns children, lägg till dem rekursivt
        if (children && Array.isArray(children?.data)) {
          children?.data?.forEach(child => {
            let flattendChild = { ...itemWithoutChildren, ...child }
            flattened.push(flattendChild)
          })
        }
      } else {
        flattened.push(obj) // Lägg till objektet utan children i den nya arrayen
      }
    }

    // Gå igenom varje objekt i den ursprungliga arrayen
    array.forEach(obj => addObject(obj))

    return flattened
  }

  const addFilters = (type, value) => {
    if (
      (typeof value === "string" && value !== "showAll") ||
      (Array.isArray(value) && value.length > 0)
    ) {
      setFilter({ ...filter, [type]: value })
      return
    }

    const newFilter = { ...filter }

    delete newFilter[type]
    filter ? setFilter(newFilter) : setFilter({})
  }

  const handleHashtagSearchType = type => {
    setHashtagSearchType(type)
    const searchInput = hashtagRef.current.value
    if (!searchInput) return

    handleSearch(type)
  }

  console.log("mediaObjects", mediaObjects)
  console.log("isSearching", isSearching)

  const handleClearSearch = () => {
    setStopSearch(true)
    setSearchInput(null)
    setMediaObjects(null)
    setHasMore(false)
    setNextPagingLink(null)
    setCursorAfter(null)
    setIsFetchingMoreObjects(false)
    setFilter(null)
    searchType === SEARCH_TYPE.hashtag
      ? (hashtagRef.current.value = "")
      : (usernameRef.current.value = "")
  }

  useEffect(() => {
    if (account && igBusinessAccountId && accessToken) {
      if (accessToken) {
        checkHashtagLimitSearch(accessToken)
      }
    }
  }, [account, igBusinessAccountId, accessToken])

  const checkHashtagLimitSearch = async accessToken => {
    try {
      const recentlySearchHashtags = await getRecentlySearchedHashtags(
        igBusinessAccountId,
        accessToken
      )

      const promises = recentlySearchHashtags?.data.map(async o => {
        const hashtag = await getHashtagName(o.id, accessToken)
        if (!hashtag) return
        return hashtag
      })

      const hashtagArray = await Promise.all(promises)

      hashtagArray.sort((a, b) => {
        if (a.toLowerCase() < b.toLowerCase()) {
          return -1
        }
        if (a.toLowerCase() > b.toLowerCase()) {
          return 1
        }
        return 0
      })

      setPreviouslySearchedHashtags(hashtagArray)

      setHashtagLimitSearch(recentlySearchHashtags?.data?.length)
    } catch (error) {
      console.log(error)
    }
  }
  const formatOption = l => ({
    label: l,
    value: l,
  })

  return (
    <Section>
      <Header>
        <HeaderGroup>
          <HeaderGroupLeft>
            <Title>Instagram search</Title>
          </HeaderGroupLeft>
          <HeaderGroupCenter></HeaderGroupCenter>
          <HeaderGroupRight></HeaderGroupRight>
        </HeaderGroup>
      </Header>
      <SubHeader>
        <SubHeaderGroup>
          <div className="d-flex flex-row align-items-center">
            {account ? (
              <>
                <Form.Control
                  as="select"
                  name="searchType"
                  style={{ width: "200px" }}
                  className="form-select me-3"
                  onChange={e => setSearchType(e.target.value)}
                  defaultValue={searchType}
                >
                  <option value={SEARCH_TYPE.hashtag}>Search by hashtag</option>
                  <option value={SEARCH_TYPE.username}>
                    Search by username
                  </option>
                </Form.Control>

                {searchType === SEARCH_TYPE.hashtag ? (
                  <>
                    <ReelerTooltip text="Find the top Instagram posts and reels, as determined by Meta.">
                      <Form.Check
                        inline
                        label="Top"
                        name="hashtagSearchType"
                        type="radio"
                        id="top-media"
                        checked={hashtagSearchType === "top"}
                        onChange={e => handleHashtagSearchType("top")}
                        disabled={isSearching}
                      />
                    </ReelerTooltip>
                    <ReelerTooltip text="Find recent Instagram posts and reels (from the last 24 hours).">
                      <Form.Check
                        inline
                        label="Recent"
                        name="hashtagSearchType"
                        type="radio"
                        id="recent-media"
                        checked={hashtagSearchType === "recent"}
                        onChange={e => handleHashtagSearchType("recent")}
                        disabled={isSearching}
                      />
                    </ReelerTooltip>
                    <InputGroup className="mx-3" style={{ width: "400px" }}>
                      <Form.Control
                        ref={hashtagRef}
                        onKeyUp={e =>
                          e.key === "Enter"
                            ? handleSearchInput(searchType)
                            : null
                        }
                        placeholder="Search any hashtag on Instagram"
                        disabled={isSearching}
                      />
                      {mediaObjects && !isSearching ? (
                        <InputGroup.Text onClick={() => handleClearSearch()}>
                          <ReelerTooltip text="Clear search">
                            <Icon tooltip={"Clear search"}>
                              <FaTimes className="icon-color icon-btn" />
                            </Icon>
                          </ReelerTooltip>
                        </InputGroup.Text>
                      ) : null}
                      <Button
                        onClick={() => handleSearchInput(searchType)}
                        className="secondary-btn input-group-btn"
                      >
                        {isSearching ? <SpinnerComponent /> : <FaSearch />}
                      </Button>
                    </InputGroup>
                    {/* <InputGroup className="me-3" style={{ width: "400px" }}>
                      <CreatableSelect
                        id="hashtag-search"
                        styles={{
                          container: () => ({
                            // none of react-select's styles are passed to <Control />
                            width: "100%",
                            marginRight: "1rem",
                          }),
                        }}
                        isDisabled={
                          account?.integrations?.instagram === null ||
                          isSearching ||
                          !hashtagLimitSearch
                        }
                        theme={theme => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary25: "var(--reeler-o-25)",
                            primary: "var(--reeler-light)",
                          },
                        })}
                        placeholder="Enter the required hashtag."
                        createOptionPosition="first"
                        formatCreateLabel={hashtag => `Search for ${hashtag}`}
                        isClearable={searchInput}
                        value={searchInput ? formatOption(searchInput) : null}
                        onChange={hashtag => handleSearchInput(hashtag?.value)}
                        onCreateOption={hashtag => handleSearchInput(hashtag)}
                        options={previouslySearchedHashtags?.map(l =>
                          formatOption(l)
                        )}
                      />
                    </InputGroup> */}
                  </>
                ) : (
                  <InputGroup className="me-3" style={{ width: "400px" }}>
                    <Form.Control
                      ref={usernameRef}
                      onKeyUp={e =>
                        e.key === "Enter" ? handleSearchUsername() : null
                      }
                      placeholder="Search creator username on Instagram"
                      disabled={isSearching}
                    />
                    {mediaObjects && !isSearching ? (
                      <InputGroup.Text onClick={() => handleClearSearch()}>
                        <ReelerTooltip text="Clear search">
                          <Icon tooltip={"Clear search"}>
                            <FaTimes className="icon-color icon-btn" />
                          </Icon>
                        </ReelerTooltip>
                      </InputGroup.Text>
                    ) : null}
                    <Button
                      onClick={() => handleSearchUsername()}
                      className="secondary-btn input-group-btn"
                    >
                      {isSearching ? <SpinnerComponent /> : <FaSearch />}
                    </Button>
                  </InputGroup>
                )}

                <div className="mx-1">
                  <Icon
                    notification={filter ? Object.keys(filter).length : null}
                    onClick={toggleFilter}
                    tooltip={showFilter ? "Hide filter" : "Show filter"}
                  >
                    <FaFilter />
                  </Icon>
                </div>
              </>
            ) : null}
          </div>
          <div className="d-flex flex-row align-items-center">
            <ReelerTooltip
              placement="bottom"
              text="From each of your Instagram accounts, you can query up to 30 unique hashtags within a 7-day period, including those from your saved hashtag sources."
            >
              <Form.Control
                as="select"
                name="ig_business_account"
                value={igBusinessAccountId}
                onChange={e => setIgBusinessAccountId(e.target.value)}
                className="me-3"
                disabled={isSearching}
              >
                {account?.integrations?.instagram?.accounts.map(
                  (account, key) => (
                    <option
                      key={key}
                      value={account.ig_business_account_profile.id}
                    >
                      {account.ig_business_account_profile.username}
                    </option>
                  )
                )}
              </Form.Control>
            </ReelerTooltip>
            {hashtagLimitSearch ? (
              <div className="d-flex flex-row align-items-center">
                <FaExclamation size={14} className="icon-color" />
                <Text
                  size="md"
                  color="var(--reeler-icon)"
                >{`${hashtagLimitSearch}/30`}</Text>
              </div>
            ) : null}
          </div>
        </SubHeaderGroup>
      </SubHeader>
      {showFilter && (
        <SubHeader>
          <SubHeaderGroup>
            <div className="d-flex flex-row align-items-center">
              <div className="w-100">
                <Text>Filters:</Text>
              </div>
              <ToogleGroup
                className="mx-3"
                name="media_type"
                value={filter?.media_type ? filter?.media_type : "showAll"}
                onChange={({ name, value }) => addFilters(name, value)}
                disabled={isSearching}
              >
                <Toggle
                  value="showAll"
                  disabled={isSearching}
                  tooltip="Show all types"
                >
                  All
                </Toggle>
                <Toggle
                  value={MEDIA_TYPE.IMAGE}
                  disabled={isSearching}
                  tooltip="Show images only"
                >
                  <FaImages />
                </Toggle>
                <Toggle
                  value={MEDIA_TYPE.VIDEO}
                  disabled={isSearching}
                  tooltip="Show videos only"
                >
                  <FaVideo />
                </Toggle>
              </ToogleGroup>

              <ReelerTooltip text="Add additional hashtags to search for a combination of the required hashtag and any of the additional ones.">
                <Text>Hashtags:</Text>
              </ReelerTooltip>
              <InputGroup className="ms-3">
                <CreatableSelect
                  id="additional-hashtags"
                  styles={{
                    container: () => ({
                      // none of react-select's styles are passed to <Control />
                      width: `400px`,
                    }),
                  }}
                  isMulti
                  isDisabled={isSearching}
                  theme={theme => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: "var(--reeler-o-25)",
                      primary: "var(--reeler-light)",
                    },
                  })}
                  placeholder="Add a hashtag..."
                  createOptionPosition="first"
                  formatCreateLabel={hashtag => `Filter by ${hashtag}`}
                  value={
                    filter?.hashtags
                      ? filter?.hashtags?.map(l => formatOption(l))
                      : null
                  }
                  onChange={newValue =>
                    addFilters(
                      "hashtags",
                      newValue.map(l => l.value)
                    )
                  }
                  onCreateOption={hashtag =>
                    addFilters(
                      "hashtags",
                      filter?.hashtags
                        ? [
                            ...filter?.hashtags,
                            hashtag.replaceAll("#", "").toLowerCase(),
                          ]
                        : [hashtag.replaceAll("#", "").toLowerCase()]
                    )
                  }
                  options={previouslySearchedHashtags?.map(l =>
                    formatOption(l)
                  )}
                />
              </InputGroup>

              <ReelerButton
                styleClass="mx-3 btn-secondary"
                size="sm"
                dispatch={() =>
                  searchType === SEARCH_TYPE.username
                    ? handleSearchUsername()
                    : handleSearch(hashtagSearchType)
                }
                text={
                  <span style={{ whiteSpace: "nowrap" }}>Apply filters</span>
                }
                disabled={isSearching}
                loading={isSearching}
              />
            </div>

            <div onClick={() => closeFilter()}>
              <ReelerTooltip text="Hide filter">
                <CloseButton size="2rem" />
              </ReelerTooltip>
            </div>
          </SubHeaderGroup>
        </SubHeader>
      )}
      <Body>
        <Row className="mx-0">
          <Col className="px-0">
            {account?.integrations?.instagram ? (
              <>
                <MediaObjectModal />
                {isSearching ||
                  (mediaObjects?.length === 0 && isFetchingMoreObjects && (
                    <SpinnerComponent />
                  ))}
                {mediaObjects?.length === 0 &&
                  !isSearching &&
                  !isFetchingMoreObjects && (
                    <NoContent text="No content found" />
                  )}

                {mediaObjects?.length > 0 && (
                  <InfiniteScroll
                    style={{ overflow: "none" }}
                    dataLength={mediaObjects.length}
                    next={() => handleLoadMoreMediaObjects()}
                    hasMore={hasMore}
                    loader={
                      <div className="d-flex justify-content-center py-3">
                        <SpinnerComponent size="sm" />
                      </div>
                    }
                    endMessage={
                      <div className="my-3 text-center">
                        <b>
                          You've reached the end — there's no more content to
                          display.
                        </b>
                      </div>
                    }
                  >
                    <S.MediaObjectsContainer>
                      {mediaObjects.map(mediaObject => {
                        return (
                          <MediaObject
                            key={mediaObject?.id}
                            mediaObject={mediaObject}
                          />
                        )
                      })}
                    </S.MediaObjectsContainer>
                  </InfiniteScroll>
                )}

                {!mediaObjects && !isSearching && (
                  <div className="d-flex justify-content-center">
                    <div className="w-75">
                      <NoContent
                        text="Search for Instagram posts containing a specific hashtag or from specific creator usernames (accounts defined by Instagram as “creators” or “businesses”; you cannot search for “regular” users). Use filters to narrow the results and add additional hashtags for a combined search. Results are not automatically imported into Reeler; scroll through and select which content assets to import and store.
"
                      />
                    </div>
                  </div>
                )}
              </>
            ) : (
              <div className="d-flex flex-column justify-content-center align-items-center pt-5">
                <img
                  className="mb-3"
                  height="150px"
                  src="../../women_working_out.svg"
                  alt=""
                />
                <p className="p-3">
                  You have not connected any Instagram Business Account.
                </p>

                <Button
                  as={Link}
                  className="btn-main"
                  to={MAIN_ROUTES.settingsIntegration.path}
                >
                  Connect your account here
                </Button>
              </div>
            )}
          </Col>
        </Row>
      </Body>
    </Section>
  )
}
