import React, { useState } from "react"
import { Modal, ModalBody, Form } from "react-bootstrap"
import { database, firestore } from "../../../firebase"
import _ from "lodash"
import { FaTrashAlt } from "react-icons/fa"
import { useDispatch } from "react-redux"
import {
  BulkEditActionSuccess,
  BulkEditReset,
} from "../../../redux/bulk-edit/bulkEdit.actions"
import ReelerTooltip from "../../commons/ReelerTooltip/ReelerTooltip"
import ReelerButton from "../../commons/ReelerButton"
import ReelerIconButton from "../../commons/reelerIconButton/ReelerIconButton"
import { ASSET_STATE } from "../../../constants"

export default function BulkDeleteContent({ assets, size = 14, buttonText }) {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [valid, setValid] = useState(false)
  const [loading, setLoading] = useState(false)

  function openModal() {
    setOpen(true)
  }

  function closeModal() {
    setOpen(false)
  }

  const handleBulkDeleteAssets = async () => {
    if (valid && assets.length > 0) {
      setLoading(true)

      // Since we are doing two write operations we have to divide the batches into 250
      const batches = _.chunk(assets, 250).map(assetDocs => {
        const batch = firestore.batch()
        assetDocs.forEach(asset => {
          let originalAssetRef = database.assets.doc(asset.id)
          let newDeletedAssetRef = database.deletedAssets.doc(asset.id)

          let deletedAsset = {
            ...asset,
            deletedAt: database.getCurrentTimestamp(),
          }

          batch.set(newDeletedAssetRef, deletedAsset)
          batch.delete(originalAssetRef)
        })
        return batch.commit()
      })

      await Promise.all(batches).then(() => {
        setLoading(false)

        dispatch(
          BulkEditActionSuccess({
            type: "success",
            duration: 3000,
            lead: "Removed",
            text: "successfully",
            position: "bulkEdit",
          })
        )
        closeModal()
        setTimeout(() => {
          dispatch(BulkEditReset())
        }, 3000)
      })
    }
  }

  return (
    <>
      {buttonText ? (
        <div
          className="d-flex flex-row align-items-center me-3 link"
          style={{ cursor: "pointer" }}
          onClick={openModal}
        >
          <ReelerTooltip placement="bottom" text="Delete assets from Reeler">
            <>
              <FaTrashAlt className="me-2" /> {buttonText}
            </>
          </ReelerTooltip>
        </div>
      ) : (
        <ReelerTooltip
          placement="bottom"
          text={`Delete ${assets.length} assets from Reeler`}
        >
          <FaTrashAlt
            onClick={openModal}
            size={size}
            className="icon-color"
            style={{ cursor: "pointer" }}
          />
        </ReelerTooltip>
      )}
      <Modal centered show={open} onHide={closeModal}>
        <Modal.Header>
          <Modal.Title>
            Are you sure you want to delete {assets.length} assets?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>If you delete these assets you cannot recreate them.</p>
          <p>
            Confirm you want to delete these assets by typing the number of
            assets: {assets.length}
          </p>
          <Form.Group controlId="url-link">
            <Form.Control
              type="text"
              required
              placeholder={assets.length}
              onChange={e =>
                parseInt(e.target.value) === assets.length
                  ? setValid(true)
                  : setValid(false)
              }
            />
          </Form.Group>
          <p>
            Press “delete assets” to start the deletion process. Depending on
            the number of assets, the deletion process might take some time.
            Please wait for the confirmation message before you leave this page.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <ReelerButton
            text="Close"
            dispatch={closeModal}
            styleClass="btn-secondary"
          />
          <ReelerButton
            text="Delete assets"
            styleClass="btn-delete"
            dispatch={() => handleBulkDeleteAssets()}
            loading={loading}
            disabled={!valid}
          />
        </Modal.Footer>
      </Modal>
    </>
  )
}
