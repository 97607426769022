import React from "react"
import { Form, Row, Col } from "react-bootstrap"

import { selectFeed } from "../../../../../redux/feed/feed.selectors"
import { updateFeed } from "../../../../../redux/feed/feed.actions"
import { useDispatch, useSelector } from "react-redux"
import * as S from "../../../styles"

// Settings components
import GridSettings from "./settings/GridSettings"
import SocialWallSettings from "./settings/SocialWallSettings"
import CarouselSettings from "./settings/CarouselSettings"
import SlideshowSettings from "./settings/SlideshowSettings"
import FeedLayoutSettings from "./settings/FeedLayoutSettings"

import CardSettings from "./settings/CardSettings"
import Preview from "./Preview"

const LayoutSection = () => {
  const feed = useSelector(selectFeed)
  const dispatch = useDispatch()

  const handleFeedType = type => {
    if (type === "grid") {
      dispatch(
        updateFeed({
          feedLayout: type,
          feedSettings: {
            columns: 3,
            rows: 3,
            gutter: 0,
            borderRadius: 0,
          },
        })
      )
    } else if (type === "socialWall") {
      dispatch(
        updateFeed({
          feedLayout: type,
          feedSettings: {
            columns: 4,
            gutter: 5,
            borderRadius: 0,
            showCaption: true,
            showShadow: true,
          },
        })
      )
    } else if (type === "carousel") {
      dispatch(
        updateFeed({
          feedLayout: type,
          feedSettings: {
            widgetHeight: 250,
            dots: false,
            infinite: false,
            speed: 500,
            gutter: 0,
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: true,
            arrowColor: "#7f7f7f",
            borderRadius: 0,
            showButtons: true,
          },
        })
      )
    } else if (type === "feed") {
      dispatch(
        updateFeed({
          feedLayout: type,
          feedSettings: {
            autoScroll: false,
            initial: 3,
          },
        })
      )
    }
  }

  return (
    <>
      <Row>
        <Col md={6}>
          <Form.Group controlId="layout">
            <Form.Control
              as="select"
              name="layout"
              defaultValue={feed?.feedLayout}
              onChange={e => handleFeedType(e.target.value)}
            >
              <option value="grid">Grid</option>
              <option value="carousel">Carousel</option>
              <option value="socialWall">Social Wall</option>
            </Form.Control>
            <Form.Text muted>Choose a feed layout</Form.Text>
          </Form.Group>

          <S.SectionContainer>
            <Row>
              <Col>
                {(() => {
                  switch (feed?.feedLayout) {
                    case "grid":
                      return <GridSettings />
                    case "socialWall":
                      return <SocialWallSettings />
                    case "carousel":
                      return <CarouselSettings />
                    case "slideshow":
                      return <SlideshowSettings />
                    case "feed":
                      return <FeedLayoutSettings />
                    default:
                      return null
                  }
                })()}
              </Col>
            </Row>
          </S.SectionContainer>
          <Row>
            <Col>
              <S.SectionHeading>Card design</S.SectionHeading>
            </Col>
          </Row>
          <S.SectionContainer>
            <Row>
              <Col>
                <CardSettings />
              </Col>
            </Row>
          </S.SectionContainer>
        </Col>
        <Col md={6}>
          <Preview />
        </Col>
      </Row>
    </>
  )
}

export default LayoutSection
