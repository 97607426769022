import React from "react"
// 3rd-party libraries
import { Form } from "react-bootstrap"
import { useSelector } from "react-redux"
import { selectFormSchema } from "../../../../../redux/campaign/campaign.selectors"

import * as S from "../../styles"

export default function CheckWidget({ fieldId, field, design }) {
  return (
    <Form.Group className="mb-3">
      {field.title ? (
        <S.ColoredLabel $design={design}>{field.title}</S.ColoredLabel>
      ) : null}
      {field.required ? <S.RequiredMark>*</S.RequiredMark> : null}
      <Form.Check
        type={field.type}
        id={fieldId}
        label={field.label}
        required={field.required}
      />
      {field.description ? (
        <S.ColoredFormText $design={design}>
          {field.description}
        </S.ColoredFormText>
      ) : null}
    </Form.Group>
  )
}
