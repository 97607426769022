import React, { useEffect, useState, useRef } from "react"
import { useSelector } from "react-redux"
import { selectAccounts } from "../../../../../redux/account/account.selectors"
import { selectCreator } from "../../../../../redux/creators/creators.selectors"
import SpinnerComponent from "../../../../../components/commons/SpinnerComponent"
import * as S from "../../../../InstagramSearch/InstagramSearch.styles"
import MediaObject from "../../../../InstagramSearch/components/MediaObject"
import InfiniteScroll from "react-infinite-scroll-component"
import MediaObjectModal from "../../../../InstagramSearch/components/MediaObjectModal/MediaObjectModal"
import NoContent from "../../../../../components/commons/NoContent"
import { searchUsername } from "../../../../../services/InstagamService"
import Text from "../../../../../components/commons/Text"
import { Button } from "react-bootstrap"
import { Link } from "react-router-dom"
import { MAIN_ROUTES } from "../../../../../constants/routes"
import formatNumber from "../../../../../utils/formatNumber"

export default function CreatorInstagramPosts() {
  const creator = useSelector(selectCreator)
  const account = useSelector(selectAccounts)
  const [isFetchingMediaObjects, setIsFetchingMediaObjects] = useState(null)
  const [isFetchingMoreObjects, setIsFetchingMoreObjects] = useState(null)
  const [mediaObjects, setMediaObjects] = useState(null)
  const [insights, setInsights] = useState(null)
  const [hasMore, setHasMore] = useState(null)
  const [afterCursor, setAfterCursor] = useState(null)
  const [instagramBussinessAccountId, setInstagramBussinessAccountId] =
    useState(null)
  const [accessToken, setAccessToken] = useState(null)

  useEffect(() => {
    if (
      !account?.integrations?.instagram?.authResponse?.grantedScopes?.includes(
        "instagram_manage_insights"
      )
    ) {
      return
    }
    if (account?.integrations?.instagram) {
      setInstagramBussinessAccountId(
        account?.integrations?.instagram?.accounts[0]
          ?.ig_business_account_profile?.id
      )

      setAccessToken(account?.integrations?.instagram?.long_lived_user_token)
    }
  }, [account])

  useEffect(() => {
    // Call the Instagram API to get insights
    if (creator?.ig_username && instagramBussinessAccountId && accessToken) {
      const fetchInsights = async () => {
        setIsFetchingMediaObjects(true)

        const response = await searchUsername(
          instagramBussinessAccountId,
          creator?.ig_username,
          accessToken
        )

        console.log("response", response)
        if (response.error) {
          setMediaObjects(null)
          setIsFetchingMediaObjects(false)
          return null
        }

        const businessDiscovery = response.business_discovery

        if (businessDiscovery) {
          const flatArray = flattenArray(businessDiscovery.media.data)
          setMediaObjects(flatArray)
          setInsights(businessDiscovery)

          const newCursor = businessDiscovery?.media?.paging?.cursors?.after

          if (newCursor && newCursor !== afterCursor) {
            setAfterCursor(newCursor)
            setHasMore(true)
          }
        }
        setIsFetchingMediaObjects(false)
      }
      fetchInsights()
    }
  }, [creator, accessToken, instagramBussinessAccountId])

  const handleLoadMoreMediaObjects = async () => {
    if (afterCursor && hasMore) {
      setIsFetchingMoreObjects(true)
      try {
        const { business_discovery } = await searchUsername(
          instagramBussinessAccountId,
          creator?.ig_username,
          accessToken,
          afterCursor
        )

        const { media } = business_discovery

        const { data } = media

        const flatArray = flattenArray(data)

        setMediaObjects(prev =>
          prev ? [...prev, ...flatArray] : [...flatArray]
        )

        const newCursor = media?.paging?.cursors?.after

        if (newCursor && newCursor !== afterCursor) {
          setAfterCursor(newCursor)
          setHasMore(true)
        } else {
          setAfterCursor(null)
          setHasMore(false)
        }
        setIsFetchingMoreObjects(false)
      } catch (e) {
        console.log("Instagram Error", e)
        setHasMore(false)
        setIsFetchingMoreObjects(false)
        setAfterCursor(null)
      }
    }
  }

  const flattenArray = array => {
    const flattened = [] // Skapa en ny array för resultatet

    // En rekursiv funktion för att lägga till objekt och dess barn
    const addObject = obj => {
      if (obj.media_type === "CAROUSEL_ALBUM") {
        const { children, ...itemWithoutChildren } = obj // Separera children från objektet
        // Om det finns children, lägg till dem rekursivt
        if (children && Array.isArray(children?.data)) {
          children?.data?.forEach(child => {
            let flattendChild = { ...itemWithoutChildren, ...child }
            flattened.push(flattendChild)
          })
        }
      } else {
        flattened.push(obj) // Lägg till objektet utan children i den nya arrayen
      }
    }

    // Gå igenom varje objekt i den ursprungliga arrayen
    array.forEach(obj => addObject(obj))

    return flattened
  }

  return creator && creator?.ig_userrname ? (
    <div className="mt-3">
      <div className="mb-3">
        <Text size="md" weight="bold" color="var(--reeler-title)">
          Instagram posts{" "}
          {insights && `(${formatNumber(insights?.media_count)})`}
        </Text>
      </div>

      {account?.integrations?.instagram &&
      account?.integrations?.instagram?.authResponse?.grantedScopes?.includes(
        "instagram_manage_insights"
      ) ? (
        isFetchingMediaObjects ? (
          <div className="d-flex justify-content-center w-100 mt-3">
            <SpinnerComponent />
          </div>
        ) : (
          <>
            <MediaObjectModal />

            {mediaObjects && (
              <InfiniteScroll
                style={{ overflow: "none" }}
                dataLength={mediaObjects.length}
                next={() => handleLoadMoreMediaObjects()}
                hasMore={hasMore}
                loader={
                  <div className="d-flex justify-content-center py-3">
                    <SpinnerComponent size="sm" />
                  </div>
                }
                endMessage={
                  <div className="my-3 text-center">
                    <b>
                      You've reached the end — there's no more content to
                      display.
                    </b>
                  </div>
                }
              >
                <S.MediaObjectsContainer>
                  {mediaObjects.map(mediaObject => {
                    return (
                      <MediaObject
                        key={mediaObject?.id}
                        mediaObject={mediaObject}
                      />
                    )
                  })}
                </S.MediaObjectsContainer>
              </InfiniteScroll>
            )}

            {!mediaObjects && !isFetchingMediaObjects && (
              <div className="d-flex justify-content-center">
                <div className="w-75">
                  <NoContent text="This creator does not have any Instagram posts yet" />
                </div>
              </div>
            )}
          </>
        )
      ) : (
        <div className="d-flex flex-column justify-content-center align-items-center pt-5">
          <img
            className="mb-3"
            height="150px"
            src="../../women_working_out.svg"
            alt=""
          />
          <p className="p-3">
            {!account?.integrations?.instagram &&
              "You need to connect your Instagram Business Account"}

            {!account?.integrations?.instagram?.authResponse?.grantedScopes?.includes(
              "instagram_manage_insights"
            )
              ? "This is a new feature, and your account lacks the necessary permissions. Please refresh your Instagram connection to grant Reeler access to the permissions required for this feature."
              : null}
          </p>
          Link
          <Button
            as={Link}
            className="btn-main"
            to={MAIN_ROUTES.settingsIntegration.path}
          >
            Connect your account here
          </Button>
        </div>
      )}
    </div>
  ) : null
}
