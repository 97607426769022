import styled, { css } from "styled-components"

export const ApproveContainer = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 3px;
  background-color: var(--reeler-grey-middle);
`
export const ApproveIcon = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px 5px;
  cursor: pointer;
  border-radius: ${({ borderRadius }) => borderRadius};
  border: none;
  background-color: ${({ bgColor }) => bgColor};
  &:hover {
    background-color: ${({ bgHover }) => bgHover};
  }
`
