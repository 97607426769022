import React, { useEffect, useState } from "react"

// 3rd-party
import { InputGroup, Form } from "react-bootstrap"
import { IoClose } from "react-icons/io5"
import CreatableSelect from "react-select/creatable"
// Firebase
import firebase from "firebase/app"
import { database } from "../../../../../firebase"

// Redux
import { useDispatch, useSelector } from "react-redux"

import {
  selectFeed,
  selectFeedTags,
} from "../../../../../redux/feed/feed.selectors"
import { updateFeed, setFeedTags } from "../../../../../redux/feed/feed.actions"
import { updateAccounts } from "../../../../../redux/account/account.actions"

import {
  selectAccountId,
  selectAccounts,
} from "../../../../../redux/account/account.selectors"
import Tag from "../../../../commons/Tag"
const customStyles = {
  container: () => ({
    // none of react-select's styles are passed to <Control />
    width: `100%`,
  }),
}
const FeedTags = () => {
  const [loading, setLoading] = useState(false)
  const [tag, setTag] = useState("")
  const account = useSelector(selectAccounts)
  const accountId = useSelector(selectAccountId)
  const feed = useSelector(selectFeed)
  const dispatch = useDispatch()

  const handleAddTag = async str => {
    setLoading(true)

    let tags = {}
    if (feed?.tags) {
      tags = feed.tags
    }

    // To save new tags to the account tags

    let accountTags = []
    str.split(" ").map(tag => {
      let lowerCasedTag = tag?.toLowerCase()
      tags[lowerCasedTag] = true
      if (!account?.tags?.includes(lowerCasedTag)) {
        accountTags.push(lowerCasedTag)
      }
    })
    // update account with new tags in firebase and redux
    if (accountTags?.length > 0) {
      await database.accounts.doc(accountId).update({
        tags: firebase.firestore.FieldValue.arrayUnion(...accountTags),
      })

      // Update account in redux
      dispatch(
        updateAccounts({
          tags: account?.tags
            ? [...account?.tags, ...accountTags]
            : accountTags,
        })
      )
    }

    // Update feed with new tags
    dispatch(updateFeed({ tags: tags }))
    dispatch(setFeedTags({ ...tags }))

    setLoading(false)
  }

  const handleDeleteTag = tagToDelete => {
    setLoading(true)

    const { [tagToDelete]: value, ...tags } = feed.tags
    dispatch(updateFeed({ tags: tags }))
    setLoading(false)
  }
  return (
    <>
      <Form.Label>Labels to source content with</Form.Label>
      <InputGroup className="mb-3">
        <CreatableSelect
          styles={customStyles}
          theme={theme => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: "var(--reeler-o-25)",
              primary: "var(--reeler-light)",
            },
          })}
          placeholder="Add a new label..."
          isClearable
          value={tag}
          onChange={(selectedOption, action) => {
            if (action.action === "clear") {
              setTag("")
            } else if (action.action === "create-option") {
              handleAddTag(action.option.value?.trim().toLowerCase())
            } else {
              handleAddTag(selectedOption.value?.trim().toLowerCase())
            }
          }}
          options={account?.tags
            ?.sort((a, b) => {
              if (a.toLowerCase() < b.toLowerCase()) {
                return -1
              }
              if (a.toLowerCase() > b.toLowerCase()) {
                return 1
              }
              return 0
            })
            ?.map(t => ({ value: t, label: t }))}
        />
        <Form.Text muted>
          To be included in this feed, a content asset must have ALL of the
          specified “content labels". Please note that for email feeds, only
          images can be used (no videos).
        </Form.Text>
      </InputGroup>
      <div>
        {feed?.tags &&
          Object.keys(feed?.tags).map((tag, index) => (
            <Tag
              key={index}
              tag={tag}
              handleDeleteTag={handleDeleteTag}
              onClick={() => handleDeleteTag(tag)}
            >
              {tag}
            </Tag>
          ))}
      </div>
    </>
  )
}

export default FeedTags
