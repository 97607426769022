import React, { useEffect, useState, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { InputGroup, Form } from "react-bootstrap"

import { FaEdit, FaPen } from "react-icons/fa"
import { selectCampaign } from "../../../../../../../redux/campaign/campaign.selectors"
import ReelerTooltip from "../../../../../../commons/ReelerTooltip/ReelerTooltip"
import * as S from "./CampaignName-styles"
import { database } from "../../../../../../../firebase"
import { saveCampaign } from "../../../../../../../redux/campaign/campaign.actions"
import ReelerButton from "../../../../../../commons/ReelerButton"

export default function CampaignName() {
  const campaign = useSelector(selectCampaign)
  const dispatch = useDispatch()
  const [width, setWidth] = useState(0)
  const [name, setName] = useState()
  const [isChanging, setIsChanging] = useState(false)
  const spanRef = useRef()
  const containerRef = useRef()
  const inputRef = useRef()

  const handleSaveCampaignName = () => {
    database.campaigns
      .doc(campaign.id)
      .update({ campaignName: name })
      .then(() => {
        dispatch(
          saveCampaign({
            ...campaign,
            campaignName: name,
          })
        )
      })
      .then(() => {
        containerRef.current.blur()
        inputRef.current.blur()
        setIsChanging(false)
      })
  }

  const handleNameChange = e => {
    setName(e.target.value)
    setIsChanging(true)
  }

  useEffect(() => {
    setName(campaign?.campaignName)
  }, [campaign])

  useEffect(() => {
    setWidth(spanRef.current.offsetWidth + 4)
  }, [name])

  return (
    <ReelerTooltip text="Edit source name" placement="bottom">
      <S.Container ref={containerRef}>
        <S.HiddenSpan ref={spanRef}>{name}</S.HiddenSpan>
        <S.Input
          ref={inputRef}
          width={width}
          defaultValue={name}
          onChange={handleNameChange}
          onKeyUp={e =>
            e.key === "Enter" ? handleSaveCampaignName(e.target.value) : null
          }
        />
        {isChanging ? (
          <ReelerButton text="Save" dispatch={() => handleSaveCampaignName()} />
        ) : (
          <div className="d-flex align-items-center p-1">
            <FaPen />
          </div>
        )}
      </S.Container>
    </ReelerTooltip>
  )
}
