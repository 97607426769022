import React from "react"
import { IMG_SIZES } from "../../../../../../../constants"
import MediaType from "../../../../../../commons/MediaType"
import { selectFeed } from "../../../../../../../redux/feed/feed.selectors"
import { useSelector } from "react-redux"

/**
 *
 * https://webdesign.tutsplus.com/tutorials/creating-a-future-proof-responsive-email-without-media-queries--cms-23919
 */

export default function EmailRowWidget({ assets }) {
  const feed = useSelector(selectFeed)

  const renderEmailRow = () => {
    let content = []

    let columns
    if (feed?.feedSettings?.columns) {
      columns = feed?.feedSettings?.columns
    } else {
      columns = 3
    }

    for (let i = 0; i < columns; i++) {
      if (assets[i]) {
        content.push(
          <div
            style={{
              maxHeight: "200px",
              paddingRight: `${feed?.feedSettings?.cellspacing}px`,
              display: "inline-block",
            }}
          >
            <MediaType
              asset={assets[i]}
              imgSize={IMG_SIZES.thumb}
              objectFit="cover"
              aspectRatio="1/1"
              br={feed?.feedSettings?.borderRadius}
            />
          </div>
        )
      }
    }
    return content
  }
  return (
    feed && (
      <div className="d-flex justify-content-center">
        <div
          style={{
            display: "grid",
            gridTemplateColumns: `repeat(${
              feed?.feedSettings?.columns ? feed?.feedSettings?.columns : 3
            }, 1fr)`,
          }}
        >
          {renderEmailRow()}
        </div>
      </div>
    )
  )

  return (
    <table border="0" cellpadding="0" width="100%" id="templateColumns">
      <tr>
        <td
          align="center"
          valign="top"
          style={{ paddingRight: `${feed?.feedSettings?.cellspacing}px` }}
        >
          <img
            width="100%"
            height="100%"
            style={{
              objectFit: "contain",
              display: "block",
              maxWidth: "100%",
              maxHeight: "100%",
              borderRadius: `${feed?.feedSettings?.borderRadius}px`,
            }}
            src="https://firebasestorage.googleapis.com/v0/b/videoh-mvp-dev.appspot.com/o/dzNPkH5NfGLfA3TCItrX%2FiQDmUryEL5Q9qdaEcbB5%2Fimage%40200_96debf58-70d3-414c-bb7d-2802557e1e58.jpg?alt=media&token=96debf58-70d3-414c-bb7d-2802557e1e58"
            alt="User generated content"
          />
        </td>
        <td
          align="center"
          valign="top"
          style={{ paddingRight: `${feed?.feedSettings?.cellspacing}px` }}
        >
          <img
            width="100%"
            height="100%"
            style={{
              objectFit: "contain",
              display: "block",
              maxWidth: "100%",
              maxHeight: "100%",
              borderRadius: `${feed?.feedSettings?.borderRadius}px`,
            }}
            src="https://firebasestorage.googleapis.com/v0/b/videoh-mvp-dev.appspot.com/o/dzNPkH5NfGLfA3TCItrX%2FJpBRhylKTFqVM4zqcPDt%2Fimage%40200_1e9184ac-7fc3-459d-aca2-ec62061530f9.jpg?alt=media&token=1e9184ac-7fc3-459d-aca2-ec62061530f9"
            alt="User generated content"
          />
        </td>
        <td
          align="center"
          valign="top"
          style={{ paddingRight: `${feed?.emailFeed?.cellspacing}px` }}
        >
          <img
            width="100%"
            height="100%"
            style={{
              objectFit: "contain",
              display: "block",
              maxWidth: "100%",
              maxHeight: "100%",
              borderRadius: `${feed?.feedSettings?.borderRadius}px`,
            }}
            src="https://firebasestorage.googleapis.com/v0/b/videoh-mvp-dev.appspot.com/o/dzNPkH5NfGLfA3TCItrX%2FJpBRhylKTFqVM4zqcPDt%2Fimage%40200_92077d6d-2f47-43d6-b90e-c4809e26c405.jpg?alt=media&token=92077d6d-2f47-43d6-b90e-c4809e26c405"
            alt="User generated content"
          />
        </td>
      </tr>
    </table>
  )
}
