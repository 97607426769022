import React, { useEffect, useState } from "react"
import { Table, Form } from "react-bootstrap"
import { database } from "../../../firebase"
import UserRow from "./UserRow"
import { useSelector } from "react-redux"
import { selectAccountId } from "../../../redux/account/account.selectors"
import SpinnerComponent from "../../commons/SpinnerComponent"
import { USER_STATUS } from "../../../constants"
import { generateFullname } from "../../../utils/GenerateFullName"
// import { FaBuromobelexperte } from "react-icons/fa"

const UserTable = () => {
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(true)

  const currentAccountId = useSelector(selectAccountId)

  useEffect(() => {
    if (currentAccountId) {
      setLoading(true)
      var unsubscribe = database.users
        .where("accounts", "array-contains", currentAccountId)
        .onSnapshot(documentSnapshots => {
          let formattedUsers = []
          documentSnapshots.forEach(doc => {
            formattedUsers.push(database.formatDoc(doc))
          })

          formattedUsers.sort((a, b) => {
            const fullnameA = generateFullname(a).toUpperCase()
            const fullnameB = generateFullname(b).toUpperCase()

            if (fullnameA < fullnameB) {
              return -1
            }
            if (fullnameA > fullnameB) {
              return 1
            }
            return 0
          })

          setUsers(formattedUsers)
          setLoading(false)
        })
      return () => unsubscribe()
    }
  }, [currentAccountId])

  if (loading) return <SpinnerComponent size="lg" spinnerClass="mt-5" />

  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Name</th>
          <th>Email</th>
          <th>Access</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {users &&
          users
            .filter(u => u?.status !== USER_STATUS.DEACTIVATED)
            .filter(u => !u?.email.includes("reelertech.com"))
            .map(user => {
              return <UserRow key={user.id} user={user} />
            })}
      </tbody>
    </Table>
  )
}

export default UserTable
