/**
 * Generate a filename based on asset information
 *
 * @param asset.
 
 * @return Formatted string.
 */
import moment from "moment"
import { CAMPAIGN_TYPE } from "../constants"

export function generateFilename(asset) {
  var filename = ""

  /**
   * Different cases:
   * 1: Upload, use info from asset.values and see if they contain filename:true, then format and add the value to the filename.
   * 2: Instagram, how to generate filenames?
   * */

  // special solution for Cultural Care
  if (asset.accountId === "qeYfKbyJda943dOxNTUe") {
    // Check if it is the aupair of the year campaign or host family
    if (asset.campaignId === "xUU8qtQoadRvqKbPeWmv") {
      // Aupair of the year

      // au pair country b2076a4a-515c-4ef8-a0a7-af714d5ef4f2
      if (asset.values["b2076a4a-515c-4ef8-a0a7-af714d5ef4f2"].value) {
        filename += asset.values["b2076a4a-515c-4ef8-a0a7-af714d5ef4f2"].value
      }
      // Au Pair number 8f53bdbc-93b2-43ea-9bc8-50a5f927c66a
      if (asset.values["8f53bdbc-93b2-43ea-9bc8-50a5f927c66a"].value) {
        filename +=
          "_" + asset.values["8f53bdbc-93b2-43ea-9bc8-50a5f927c66a"].value
      }
      // host family number d0e93c78-db3d-43a3-9a56-1283d0e20344
      if (asset.values["d0e93c78-db3d-43a3-9a56-1283d0e20344"].value) {
        filename +=
          "_" + asset.values["d0e93c78-db3d-43a3-9a56-1283d0e20344"].value
      }
      // state (in that order). 5dad7f0e-fa92-4ef4-8612-8e24dc3fbfea
      if (asset.values["5dad7f0e-fa92-4ef4-8612-8e24dc3fbfea"].value) {
        filename +=
          "_" + asset.values["5dad7f0e-fa92-4ef4-8612-8e24dc3fbfea"].value
      }
      // instagram profile
      if (asset.values["854d0bf6-3c10-4659-8b9f-dc0fd057477b"].value) {
        filename +=
          "_" +
          asset.values["854d0bf6-3c10-4659-8b9f-dc0fd057477b"].value.replace(
            "https://www.instagram.com/",
            ""
          )
      }
      filename += "_R" + asset.id

      filename += "." + asset.extension

      return filename
    } else if (asset.campaignId === "JeP7vbV07Wfkv4gCUomm") {
      // Host family of the year
      // host family numberd  saknas
      // au pair country: b2076a4a-515c-4ef8-a0a7-af714d5ef4f2
      if (asset.values["b2076a4a-515c-4ef8-a0a7-af714d5ef4f2"].value) {
        filename += asset.values["b2076a4a-515c-4ef8-a0a7-af714d5ef4f2"].value
      }
      // Au Pair number: d0e93c78-db3d-43a3-9a56-1283d0e20344
      if (asset.values["d0e93c78-db3d-43a3-9a56-1283d0e20344"].value) {
        filename +=
          "_" + asset.values["d0e93c78-db3d-43a3-9a56-1283d0e20344"].value
      }
      // state (in that order): 5dad7f0e-fa92-4ef4-8612-8e24dc3fbfea
      if (asset.values["5dad7f0e-fa92-4ef4-8612-8e24dc3fbfea"].value) {
        filename +=
          "_" + asset.values["5dad7f0e-fa92-4ef4-8612-8e24dc3fbfea"].value
      }
      // Instagram
      if (asset.values["854d0bf6-3c10-4659-8b9f-dc0fd057477b"].value) {
        filename +=
          "_" +
          asset.values["854d0bf6-3c10-4659-8b9f-dc0fd057477b"].value.replace(
            "https://www.instagram.com/",
            ""
          )
      }
      filename += "_R" + asset.id
      filename += "." + asset.extension

      return filename
    }
  }

  if (asset.campaign_type === CAMPAIGN_TYPE.upload.type) {
    // Get asset values
    Object.keys(asset.values).forEach(valueId => {
      let value = asset.values[valueId]
      if (value.filename) {
        // Handle multiple choice if there is an extra array with values
        if (value.values) {
          value.values.forEach(subValue => {
            filename += subValue.replaceAll(" ", "_") + "_"
          })
        } else {
          filename += value.value.replaceAll(" ", "_") + "_"
        }
      }
    })
  }

  // Add tags to file name
  if (asset.tags) {
    Object.keys(asset.tags).forEach(tag => {
      filename += tag + "_"
    })
  }

  // All new assets should have assetVersions and a main version
  if (asset?.assetVersions?.length > 0) {
    let mainVersion = asset?.assetVersions.filter(
      version => version.main === true
    )
    if (mainVersion.length > 0) {
      filename += mainVersion[0].title + "_"

      let extension
      if (mainVersion[0].extension) {
        extension = mainVersion[0].extension
      } else if (mainVersion[0].file_name) {
        extension = mainVersion[0].file_name.split(".")[1]
      } else {
        extension = asset.type.split("/")[1]
        if (extension === "quicktime") {
          extension = "mov"
        }
      }

      filename += moment(asset.createdAt.toDate()).format("YYYY-MM-DD")
      filename += "_R" + asset.id
      filename += "." + extension
      return filename
    }
  }

  // This is for legacy assets that does not have assetVersions

  if (asset?.createdAt)
    filename += moment(asset.createdAt.toDate()).format("YYYY-MM-DD") + "_"

  filename += "_R" + asset.id
  if (asset.extension) {
    filename += "." + asset.extension
  } else {
    filename += "." + asset.type.split("/")[1]
  }

  return filename
}
