import styled from "styled-components"

export const CircleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;

  height: 50px !important;
`
export const Circle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  background: ${({ active }) =>
    active ? `var(--reeler-light)` : `var(--reeler-grey)`};
  border: var(--reeler-grey-darker) 1px solid;
  border-radius: 50%;
  font-size: 1rem;
  width: 50px !important;
  height: 50px !important;
`

export const VerticalLine = styled.div`
  background: var(--reeler-grey-darker);
  width: 1px;
  height: 100%;
  align-self: center;
`
