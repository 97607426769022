import { all, call } from "redux-saga/effects"
import { campaignSagas } from "./campaign/campaign.sagas"
import { userSagas } from "./user/user.sagas"
import { assetSagas } from "./asset/asset.sagas"
import { accountSagas } from "./account/account.sagas"
import { inviteSagas } from "./invite/invite.sagas"

export default function* rootSaga() {
  yield all([
    call(campaignSagas),
    call(userSagas),
    call(assetSagas),
    call(accountSagas),
    call(inviteSagas),
  ])
}
