import React, { useState } from "react"
import ReelerButton from "../../../../commons/ReelerButton"
import { Modal } from "react-bootstrap"
import EmbedTab from "./EmbedTab"

export default function PublishFeed() {
  const [open, setOpen] = useState(false)

  const openModal = () => setOpen(true)
  const closeModal = () => setOpen(false)

  return (
    <>
      <ReelerButton
        styleClass="btn-secondary"
        text="Publish feed"
        dispatch={openModal}
      />
      <Modal show={open} onHide={closeModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Publish your feed</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EmbedTab />
        </Modal.Body>
        <Modal.Footer>
          <ReelerButton
            text="Close"
            dispatch={closeModal}
            styleClass="btn-secondary"
          />
        </Modal.Footer>
      </Modal>
    </>
  )
}
