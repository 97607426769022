import React, { useState, useRef } from "react"
import { Modal, Form, Container, Row, Col, Alert } from "react-bootstrap"
import { database } from "../../../../firebase"
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { FaTh, FaRegAddressCard } from "react-icons/fa"
import { MdViewCarousel, MdViewDay } from "react-icons/md"
import { Title2 } from "../../styles"
import FeedType from "../commons/FeedType"
import ReelerButton from "../../../commons/ReelerButton"
import { selectCurrentUser } from "../../../../redux/user/user.selectors"
import { selectAccountId } from "../../../../redux/account/account.selectors"
import {
  initiateFeed,
  setFeed,
  setFeedTabKey,
} from "../../../../redux/feed/feed.actions"
import { FEED_PUBLISH_OPTIONS, FEED_TYPES } from "../../../../constants"
import { MAIN_ROUTES } from "../../../../constants/routes"

const CreateFeed = ({ btn }) => {
  const dispatch = useDispatch()
  const accountId = useSelector(selectAccountId)
  const user = useSelector(selectCurrentUser)

  const [open, setOpen] = useState(false)
  const [feedType, setFeedType] = useState(null)
  const [feedName, setFeedName] = useState("")
  const [error, setError] = useState("")
  const history = useHistory()
  const modalRef = useRef()

  const openModal = () => setOpen(true)
  const closeModal = () => setOpen(false)

  const handleSubmit = e => {
    e.preventDefault()

    if (feedName.trim().length < 3) {
      setError("The name has to be at least 3 non-whitespace characters")
      return
    }

    const newFeed = {
      feedName: feedName.trim(),
      accountId,
      userId: user.id,
      createdAt: database.getCurrentTimestamp(),
      publish: FEED_PUBLISH_OPTIONS.manually,
      feedLayout: "grid",
      feedType: FEED_TYPES.web,
      embedType: "div",
      feedSettings: {
        columns: 3,
        rows: 3,
        gutter: 0,
        borderRadius: 0,
      },
    }

    // Clear Feed redux data
    dispatch(initiateFeed())

    database.feeds
      .add(newFeed)
      .then(doc => {
        let feed = { id: doc.id, ...newFeed }
        dispatch(setFeed(feed))
        dispatch(setFeedTabKey("settings"))
        history.push(MAIN_ROUTES.feedsEditFeed.path)
      })
      .catch(err => {})
  }

  const ChooseFeedType = () => {
    return (
      <Container>
        <Form.Group controlId="feed-type-select">
          <Row>
            <Col>
              <Title2>1. Choose feed type:</Title2>
            </Col>
          </Row>
          <Row className="p-1 justify-content-center">
            <Col xs={12} sm={4} className="mb-3 px-2">
              <FeedType
                title="Feed"
                icon={<MdViewDay size={40} className="icon-color" />}
                text="Display your assets in a feed layout."
                dispatch={() => setFeedType("feed")}
                selected={feedType === "feed" ? true : false}
              />
            </Col>
            <Col xs={12} sm={4} className="mb-3 px-2">
              <FeedType
                title="Grid"
                icon={<FaTh size={40} className="icon-color" />}
                text="Display your assets in a grid layout. The number of columns and rows can be customized."
                dispatch={() => setFeedType("grid")}
                selected={feedType === "grid" ? true : false}
              />
            </Col>

            <Col xs={12} sm={4} className="mb-3 px-2">
              <FeedType
                title="Carousel"
                icon={<MdViewCarousel size={40} className="icon-color" />}
                text="Your assets will be displayed in a carousel layout, with one asset focused at a time."
                dispatch={() => setFeedType("carousel")}
                selected={feedType === "carousel" ? true : false}
              />
            </Col>
            <Col xs={12} sm={4} className="mb-3 px-2">
              <FeedType
                title="Social Wall"
                icon={<FaRegAddressCard size={40} className="icon-color" />}
                text="Display your assets in a social wall layout with short captions. The number of columns can be customized."
                dispatch={() => setFeedType("socialWall")}
                selected={feedType === "socialWall" ? true : false}
              />
            </Col>
          </Row>
        </Form.Group>
      </Container>
    )
  }

  return (
    <>
      <div style={{ cursor: "pointer" }}>
        <div onClick={openModal}>{btn}</div>
      </div>
      <Modal
        className="w-80"
        size="lg"
        ref={modalRef}
        show={open}
        onHide={closeModal}
      >
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Create new feed</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Form.Group>
              <Form.Label>Feed Name (internal)</Form.Label>
              {error && <Alert variant="danger">{error}</Alert>}
              <Form.Control
                type="text"
                required
                value={feedName}
                onChange={e => setFeedName(e.target.value)}
                minLength={3}
                maxLength={50}
              />
              <Form.Text muted>Max length: 50 characters.</Form.Text>
              <Form.Text muted>
                Internal use only, to keep track of your content sources.
              </Form.Text>
            </Form.Group>
          </Modal.Body>

          <Modal.Footer>
            <ReelerButton
              text="Close"
              dispatch={closeModal}
              styleClass="btn-secondary"
            />
            <ReelerButton
              type="submit"
              text="Create feed"
              styleClass="btn-main"
              spinnerClass="d-none"
              loading={feedName ? false : true}
            />
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}

export default CreateFeed
