import React, { useState, useRef } from "react"
import { Modal, Form, Container, Row, Col, Alert } from "react-bootstrap"
import { database } from "../../../../../firebase"
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { FaTh } from "react-icons/fa"
import { MdViewCarousel } from "react-icons/md"
import { Title2 } from "../../../styles"
import FeedType from "../../commons/FeedType"
import ReelerButton from "../../../../commons/ReelerButton"
import ReelerTooltip from "../../../../commons/ReelerTooltip/ReelerTooltip"
import { FaRegCopy } from "react-icons/fa"
import { selectCurrentUser } from "../../../../../redux/user/user.selectors"
import { selectAccountId } from "../../../../../redux/account/account.selectors"
import {
  initiateFeed,
  setFeed,
  setFeedTabKey,
} from "../../../../../redux/feed/feed.actions"
import withoutProperty from "../../../../../utils/withoutProperty"
import { MAIN_ROUTES } from "../../../../../constants/routes"

const CopyFeed = ({ feed, btn }) => {
  const dispatch = useDispatch()
  const user = useSelector(selectCurrentUser)
  const [open, setOpen] = useState(false)
  const [feedName, setFeedName] = useState(
    feed ? "Copy of " + feed.feedName : ""
  )
  const [error, setError] = useState("")
  const history = useHistory()
  const modalRef = useRef()

  const openModal = () => setOpen(true)
  const closeModal = () => setOpen(false)

  const handleDuplicateFeed = () => {
    if (feedName.trim().length < 3) {
      setError("The name has to be at least 3 non-whitespace characters")
      return
    }

    const feedCopy = withoutProperty(feed, "id")

    feedCopy["feedName"] = feedName.trim()
    feedCopy["userId"] = user.id
    feedCopy["createdAt"] = database.getCurrentTimestamp()
    // Clear Feed redux data
    dispatch(initiateFeed())
    console.log("duplicating feed")
    database.feeds
      .add(feedCopy)
      .then(doc => {
        console.log("feed duplicated")
        let newFeed = { id: doc.id, ...feedCopy }
        dispatch(setFeed(newFeed))
        closeModal()
        history.push(MAIN_ROUTES.feedsEditFeed.path)
      })
      .catch(err => {
        console.error(err)
      })
  }

  return (
    <>
      <div style={{ cursor: "pointer" }}>
        <div onClick={openModal}>{btn}</div>
      </div>
      <Modal
        className="w-80"
        size="lg"
        ref={modalRef}
        show={open}
        onHide={closeModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Duplicate feed</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form.Group>
            <Form.Label>Feed Name (internal)</Form.Label>
            {error && <Alert variant="danger">{error}</Alert>}
            <Form.Control
              type="text"
              required
              value={feedName}
              onChange={e => setFeedName(e.target.value)}
              minLength={3}
              maxLength={50}
            />
            <Form.Text muted>Max length: 50 characters.</Form.Text>
            <Form.Text muted>
              Internal use only to keep track of your content sources.
            </Form.Text>
          </Form.Group>
        </Modal.Body>

        <Modal.Footer>
          <ReelerButton
            text="Close"
            dispatch={closeModal}
            styleClass="btn-secondary"
          />
          <ReelerButton
            text="Duplicate feed"
            styleClass="btn-main"
            spinnerClass="d-none"
            loading={feedName ? false : true}
            dispatch={() => handleDuplicateFeed()}
          />
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default CopyFeed
