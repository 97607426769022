import ImportActionsTypes from "./import.types"

export const addMedia = payload => ({
  type: ImportActionsTypes.ADD_MEDIA,
  payload,
})
export const addSelectedMedia = payload => ({
  type: ImportActionsTypes.ADD_SELECTED_MEDIA,
  payload,
})
export const removeSelectedMedia = payload => ({
  type: ImportActionsTypes.REMOVE_SELECTED_MEDIA,
  payload,
})
export const updateSelectedMediaFile = payload => ({
  type: ImportActionsTypes.UPDATE_SELECTED_MEDIA_FILE,
  payload,
})
export const updateSettings = payload => ({
  type: ImportActionsTypes.UPDATE_SETTINGS,
  payload,
})
export const addErrorMedia = payload => ({
  type: ImportActionsTypes.ADD_ERROR_MEDIA,
  payload,
})
export const resetImport = () => ({
  type: ImportActionsTypes.RESET_IMPORT,
})
