export function aspectRatio(width, height) {
  const gcd = (...arr) => {
    const _gcd = (x, y) => (!y ? x : gcd(y, x % y))
    return [...arr].reduce((a, b) => _gcd(a, b))
  }

  const gcdResult = gcd(width, height)

  return {
    numerator: width / gcdResult,
    denominator: height / gcdResult,
    aspectRatio: `${width / gcdResult}:${height / gcdResult}`,
  }
}
