import React, { useState, useEffect } from "react"
import { Container, Row, Col, Form, Button, InputGroup } from "react-bootstrap"
import axios from "axios"
import { useHistory, Link } from "react-router-dom"
// Redux
import { useSelector, useDispatch } from "react-redux"
import { selectCampaign } from "../../../redux/campaign/campaign.selectors"
import {
  selectAccounts,
  selectAccountId,
} from "../../../redux/account/account.selectors"
import { saveCampaign } from "../../../redux/campaign/campaign.actions"
import { database } from "../../../firebase"
import SpinnerComponent from "../../commons/SpinnerComponent"
import NoSuchDocument from "../../commons/NoSuchDocument"
import CampaignTagsRedux from "../edit-campaign/components/CampaignTagsRedux"
import ReelerButton from "../../commons/ReelerButton"
import FadeAlert from "../../commons/FadeAlert/FadeAlert"
import DeleteCampagin from "../components/DeleteCampaign"

import { CircleContainer, Circle, VerticalLine } from "./Mentions.styles"
import Section from "../../../layouts/core/Section"
import { Header } from "../../../layouts/core/Header"
import Title from "../../../layouts/core/Title"
import Body from "../../../layouts/core/Body"
import ShowCampaingAssets from "../components/ShowCampaingAssets"

import { MAIN_ROUTES } from "../../../constants/routes"
import SourceCreatorList from "../components/SourceCreatorList"

/**
 * Instagram Mentions webhook
 * https://developers.facebook.com/docs/graph-api/webhooks/getting-started/webhooks-for-instagram
 *
 */

export default function EditMentionsCampaignView() {
  const dispatch = useDispatch()
  const campaign = useSelector(selectCampaign)
  const account = useSelector(selectAccounts)
  const accountId = useSelector(selectAccountId)
  const history = useHistory()
  const [saving, setSaving] = useState(false)
  const [loading, setLoading] = useState(false)
  const [users, setUsers] = useState(null)
  const [msg, setMsg] = useState()
  const [pageId, setPageId] = useState()

  const [open, setOpen] = useState(false)

  useEffect(() => {
    setLoading(true)
    if (campaign && account) {
      setLoading(false)
    }
  }, [account, campaign])

  const enablePageSubscriptions = async (pageId, accessToken) => {
    try {
      const { data } = await axios({
        url: `https://graph.facebook.com/v16.0/${pageId}/subscribed_apps?subscribed_fields=feed&access_token=${accessToken}`,
        method: "post",
      })
      if (data) return data
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    if (account) {
      if (!campaign?.instagram_business_account && !campaign?.page_id) {
        let igBusinessAccount = account?.integrations?.instagram?.accounts[0]

        let tags
        if (campaign.tags) {
          tags = campaign.tags
        } else {
          tags = {}
        }

        let IG_username =
          igBusinessAccount?.ig_business_account_profile?.username
        console.log(IG_username)

        if (IG_username?.trim() !== null) {
          IG_username.split(" ").forEach(tag => {
            if (tag.trim() === "") {
              return
            }
            tags[tag.toLowerCase()] = true
          })
        }

        dispatch(
          saveCampaign({
            ...campaign,
            instagram_business_account: igBusinessAccount,
            tags: tags,
          })
        )
      }
    }
  }, [account])

  useEffect(() => {
    if (accountId) {
      database.users
        .where("accounts", "array-contains", accountId)
        .get()
        .then(querySnapshot => {
          let userArray = []
          querySnapshot.forEach(doc => {
            // doc.data() is never undefined for query doc snapshots
            userArray.push(database.formatDoc(doc))
          })
          setUsers(userArray)
        })
    }
  }, [accountId])

  const handleChangeIGBusinessAccount = id => {
    const igBusinessAccount =
      account?.integrations?.instagram?.accounts?.filter(
        account => account.ig_business_account_profile.id === id
      )

    let tags = {}

    let IG_username =
      igBusinessAccount[0]?.ig_business_account_profile?.username

    if (IG_username?.trim() !== null) {
      IG_username.split(" ").forEach(tag => {
        if (tag.trim() === "") {
          return
        }
        tags[tag.toLowerCase()] = true
      })
    }
    dispatch(
      saveCampaign({
        ...campaign,
        instagram_business_account: igBusinessAccount[0],
        tags: tags,
      })
    )
  }
  const handleSubmit = async () => {
    setMsg(null)
    setSaving(true)

    if (!campaign.campaignName) {
      setMsg({
        success: false,
        lead: "",
        text: "Please fill in a content source name ",
      })
      setSaving(false)
      return
    }

    if (!campaign.instagram_business_account) {
      setMsg({
        success: false,
        lead: "",
        text: "Missing Instagram Business Account",
      })
      setSaving(false)
      return
    }

    //Enable page subscription
    const subscriptionResponse = await enablePageSubscriptions(
      campaign.instagram_business_account.page_id,
      campaign.instagram_business_account.long_lived_page_access_token
    )

    // Check if page subscription is enabled, otherwise abort
    if (!subscriptionResponse?.success) {
      setMsg({
        success: false,
        lead: "",
        text: "Error: Cannot subscribe to mentions from selected page",
      })
      setSaving(false)
      return
    }

    const pageSubscription = subscriptionResponse.success

    // Update campaign
    let { id: value, ...campaignWithoutId } = campaign
    database.campaigns
      .doc(campaign.id)
      .set(
        {
          ...campaignWithoutId,
          page_subscription: pageSubscription,
        },
        { merge: true }
      )
      .then(() => {
        setMsg({
          success: true,
          lead: "Success!",
          text: "Content source updated successfully!",
        })
        setSaving(false)
      })
  }

  if (loading) {
    return (
      <Container>
        <SpinnerComponent size="lg" />
      </Container>
    )
  }

  if (account && !campaign) {
    return (
      <NoSuchDocument
        message="Could not find the campaign."
        img="../../no_content_women_dog.svg"
        alt=""
      />
    )
  }

  return (
    <Section>
      <Header>
        <Title>
          Edit {campaign?.campaignName ? campaign.campaignName : null}
        </Title>
        <div className="d-flex flex-row ms-auto">
          <div className="me-2">
            <ReelerButton
              text="All sources"
              styleClass="btn-secondary"
              dispatch={() => history.push(MAIN_ROUTES.campaigns.path)}
            />
          </div>
          <div className="me-2">
            <ShowCampaingAssets />
          </div>
          <ReelerButton
            text="Save"
            styleClass="btn-main"
            loading={saving}
            dispatch={() => handleSubmit()}
          />
        </div>
      </Header>
      <Body>
        {account && !campaign ? (
          <NoSuchDocument
            message="Could not find the campaign."
            img="../../no_content_women_dog.svg"
            alt=""
          />
        ) : account?.integrations?.instagram?.accounts &&
          account?.integrations?.instagram?.scope?.includes(
            "instagram_manage_comments"
          ) &&
          account?.integrations?.instagram?.scope?.includes(
            "pages_manage_metadata"
          ) ? (
          <>
            <Row>
              <Col sm={3} md={2} className="d-flex flex-column">
                <CircleContainer>
                  <Circle active={campaign.campaignName ? true : false}>
                    1
                  </Circle>
                </CircleContainer>
                <VerticalLine />
              </Col>
              <Col sm={9} md={10}>
                <Form.Group controlId="campaign-name">
                  <Row className="my-3">
                    <Col sm={4} className="d-flex">
                      <Form.Label className="mb-0">
                        Content source name
                      </Form.Label>
                    </Col>
                    <Col sm={8} className="d-flex">
                      <Form.Group>
                        <Form.Control
                          type="text"
                          value={
                            campaign.campaignName ? campaign.campaignName : ""
                          }
                          onChange={e => {
                            dispatch(
                              saveCampaign({
                                ...campaign,
                                campaignName: e.target.value,
                              })
                            )
                          }}
                          required
                        />
                        <Form.Text muted>For internal use only</Form.Text>
                      </Form.Group>
                    </Col>
                  </Row>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={3} md={2} className="d-flex flex-column">
                <CircleContainer>
                  <Circle
                    active={campaign.instagram_business_account ? true : false}
                  >
                    2
                  </Circle>
                </CircleContainer>
                <VerticalLine />
              </Col>
              <Col sm={9} md={10}>
                <Form.Group controlId="search-hashtag">
                  <Row className="my-3">
                    <Col sm={4}>
                      <Form.Label className="mb-0">
                        Choose Instagram account
                      </Form.Label>
                    </Col>
                    <Col sm={8}>
                      <Form.Group controlId="ig_business_account">
                        <InputGroup
                          style={{
                            borderRadius: ".25rem",
                            background: "#fff",
                            border: "1px solid lightgrey",
                          }}
                        >
                          <InputGroup.Text
                            className="pe-0"
                            style={{
                              border: "none",
                              background: "none",
                            }}
                          >
                            @
                          </InputGroup.Text>
                          <Form.Control
                            as="select"
                            style={{ border: "none" }}
                            name="ig_business_account"
                            value={
                              campaign?.instagram_business_account
                                ?.ig_business_account_profile?.id
                            }
                            onChange={e =>
                              handleChangeIGBusinessAccount(e.target.value)
                            }
                          >
                            {account?.integrations?.instagram?.accounts.map(
                              (account, key) => (
                                <option
                                  key={key}
                                  value={account.ig_business_account_profile.id}
                                >
                                  {account.ig_business_account_profile.username}
                                </option>
                              )
                            )}
                          </Form.Control>
                        </InputGroup>
                        <Form.Text muted>
                          Choose which Instagram business account that this
                          hashtag source should be accociated with.
                        </Form.Text>
                      </Form.Group>
                    </Col>
                  </Row>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col sm={3} md={2} className="d-flex flex-column">
                <CircleContainer>
                  <Circle
                    active={
                      campaign?.tags && Object.keys(campaign?.tags).length > 0
                        ? true
                        : false
                    }
                  >
                    3
                  </Circle>
                </CircleContainer>
                <VerticalLine />
              </Col>
              <Col sm={9} md={10}>
                <div className="my-3">
                  <CampaignTagsRedux campaign={campaign} />
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm={3} md={2} className="d-flex flex-column">
                <CircleContainer>
                  <Circle active={campaign?.creatorList ? true : false}>
                    4
                  </Circle>
                </CircleContainer>
                <VerticalLine />
              </Col>
              <Col sm={9} md={10}>
                <div className="my-3">
                  <SourceCreatorList />
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm={3} md={2} className="d-flex flex-column">
                <CircleContainer>
                  <Circle active={campaign?.userId ? true : false}>5</Circle>
                </CircleContainer>
              </Col>
              <Col sm={9} md={10}>
                <Row className="my-3">
                  <Col sm={4}>
                    <Form.Label className="mb-0">Set source owner</Form.Label>
                  </Col>
                  <Col sm={8}>
                    <Form.Group controlId="userId">
                      <Form.Control
                        as="select"
                        name="userId"
                        value={campaign?.userId}
                        onChange={e =>
                          dispatch(
                            saveCampaign({
                              ...campaign,
                              userId: e.target.value,
                            })
                          )
                        }
                      >
                        {users?.map((user, key) => (
                          <option key={key} value={user.id}>
                            {user.email}
                          </option>
                        ))}
                      </Form.Control>
                      <Form.Text muted>
                        Set “Source Owner” to be able to easily find your
                        content, if you are many people or many teams working in
                        the same Reeler account.
                      </Form.Text>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>
            <div>
              {msg && <FadeAlert msg={msg} delay={5000} />}
              <div className="d-flex flex-row justify-content-end py-3 mb-5">
                <div className="me-2">
                  <DeleteCampagin />
                </div>
                <ReelerButton
                  text="Save"
                  styleClass="btn-main"
                  loading={saving}
                  dispatch={() => handleSubmit()}
                />
              </div>
            </div>
          </>
        ) : (
          <div className="d-flex flex-column justify-content-center align-items-center pt-5">
            <img
              className="mb-3"
              height="150px"
              src="../../women_working_out.svg"
              alt=""
            />
            <p className="p-3">
              You need to refresh your Instagram connection to Reeler to make
              sure we have all permissions needed to collect content from
              mentions.
            </p>

            <Button
              as={Link}
              className="btn-main"
              to={MAIN_ROUTES.settingsIntegration.path}
            >
              Connect your account here
            </Button>
            <p className="p-3">Or inactivate the campaign </p>
            <DeleteCampagin />
          </div>
        )}
      </Body>
    </Section>
  )
}
