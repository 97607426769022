import { createSelector } from "reselect"

const selectInvite = state => state.invite

export const selectInviteData = createSelector(
  [selectInvite],
  invite => invite.data
)

export const selectInviteIsLoading = createSelector(
  [selectInvite],
  invite => invite.isLoading
)
