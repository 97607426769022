import React, { useState, useEffect } from "react"
// 3 party
import { v4 as uuidV4 } from "uuid"
import { Dropdown, Form, Row, Col } from "react-bootstrap"
import { FaRegCircle, FaRegSquare, FaPlusCircle } from "react-icons/fa"
// Redux
import { useDispatch, useSelector } from "react-redux"
import {
  addFormField,
  addNewFormField,
  saveFormField,
  setShowEditModal,
} from "../../../../redux/campaign/campaign.actions"
import {
  selectFormSchema,
  selectedField,
} from "../../../../redux/campaign/campaign.selectors"
import ReelerIconButton from "../../../commons/reelerIconButton/ReelerIconButton"
import ReelerButton from "../../../commons/ReelerButton"
import ReelerSwitch from "../../../commons/Switch/ReelerSwitch"

import * as S from "../styles"
import {
  MdArrowDropDownCircle,
  MdCheckBox,
  MdRadioButtonChecked,
} from "react-icons/md"
import { DragDropContext, Droppable } from "react-beautiful-dnd"
import MultipleChoiceOption from "./MultipleChoiceOption"
import SaveField from "./SaveField"

const order = ["radio", "checkbox", "select"]
const types = {
  radio: {
    icon: <MdRadioButtonChecked />,
    label: "Multiple choice",
    type: "radio",
  },
  checkbox: {
    icon: <MdCheckBox />,
    label: "Checkboxes",
    type: "checkbox",
  },
  select: {
    icon: <MdArrowDropDownCircle />,
    label: "Dropdown",
    type: "select",
  },
}

export default function MultipleChoiceField({ previousFieldId, closeModal }) {
  const dispatch = useDispatch()
  const formSchema = useSelector(selectFormSchema)
  const fieldId = useSelector(selectedField)

  const [settings, setSettings] = useState({
    widget: "multiple_choice",
    format: "select",
    options: [{ label: "Option 1", id: "1" }],
    required: true,
    export: true,
  })

  const handleDragEnd = result => {
    if (!result.destination) return
    // destination=index where it moves to
    // source= where it came from
    const fields = Array.from(settings.options)
    const [orderedOptions] = fields.splice(result.source.index, 1)
    fields.splice(result.destination.index, 0, orderedOptions)

    setSettings({ ...settings, options: [...fields] })
  }

  useEffect(() => {
    if (fieldId && formSchema) {
      const defaultValues = formSchema.properties[fieldId]
      setSettings(defaultValues)
    }
  }, [fieldId, formSchema])

  const addOption = () => {
    setSettings({
      ...settings,
      options: [
        ...settings.options,
        {
          label: `Option ${settings.options.length + 1} `,
          id: `${settings.options.length + 1}`,
        },
      ],
    })
  }

  const handleSubmit = () => {
    // Remove last element since it only is Add option and should not be included
    const field = settings
    //fieldId is existing if we are updating the field, then we should only update the field and not the fieldId and order.
    if (fieldId) {
      dispatch(saveFormField(fieldId, field))
      //dispatch(setShowEditModal(false))
    } else {
      dispatch(addNewFormField(field, previousFieldId))

      closeModal()
    }
  }

  return (
    <Form>
      <Row className="mx-n3 mb-3">
        <Col md={7}>
          <Form.Label>Give this field an internal name</Form.Label>
          <Form.Control
            type="text"
            value={settings.dataFieldName}
            onChange={e =>
              setSettings({ ...settings, dataFieldName: e.target.value })
            }
          />
          <Form.Text muted>
            Choose an internal name for this field which will be used inside
            Reeler when viewing or exporting data.
          </Form.Text>
        </Col>
      </Row>
      <Form.Group>
        <Form.Label>Text label above the field</Form.Label>
        <Form.Control
          type="text"
          value={settings?.title}
          onChange={e => setSettings({ ...settings, title: e.target.value })}
        />
        <Form.Text muted>
          (Optional) Add a text label above the form field
        </Form.Text>
      </Form.Group>

      <Form.Group>
        <Form.Label>Format</Form.Label>
        <Dropdown>
          <Dropdown.Toggle variant="light">
            {settings?.format ? (
              <div className="d-inline">
                <S.StyledItemIcon>
                  {types[settings.format].icon}
                </S.StyledItemIcon>
                {types[settings.format].label}
              </div>
            ) : (
              "Select field type"
            )}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {order.map((type, key) => {
              return (
                <S.StyledItem
                  value={type}
                  key={key}
                  onClick={e => {
                    setSettings({ ...settings, format: type })
                  }}
                >
                  <S.StyledItemIcon>{types[type].icon}</S.StyledItemIcon>
                  {types[type].label}
                </S.StyledItem>
              )
            })}
          </Dropdown.Menu>
        </Dropdown>
      </Form.Group>

      <Form.Group>
        <Form.Label>Options</Form.Label>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="optionField">
            {provided => (
              <S.OptionsRow
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {settings.options.map((option, index) => (
                  <MultipleChoiceOption
                    option={option}
                    settings={settings}
                    setSettings={setSettings}
                    key={index}
                    index={index}
                    id={index}
                  ></MultipleChoiceOption>
                ))}
                {provided.placeholder}
              </S.OptionsRow>
            )}
          </Droppable>
        </DragDropContext>
        <ReelerIconButton
          dispatch={() => addOption()}
          text="Add option"
          icon={<FaPlusCircle className="me-3" />}
          styleClass="btn-secondary"
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>Description</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          value={settings?.description}
          onChange={e =>
            setSettings({ ...settings, description: e.target.value })
          }
        />
        <Form.Text muted>
          (Optional) Add a short text below the field to explain why you want
          the user to input information
        </Form.Text>
      </Form.Group>

      <Form.Group>
        <ReelerSwitch
          label="Required field"
          name="required"
          action={() =>
            setSettings({ ...settings, required: !settings?.required })
          }
          checkedBoolean={settings?.required}
        />
        <Form.Text muted>
          In the case of checkboxes: please note that if you set the field as
          “required”, it will obligate the user to check all checkboxes. Disable
          the “required” option if you wish to allow the user the freedom to
          make different selections.
        </Form.Text>
      </Form.Group>
      <Form.Group className="mb-3 mt-3">
        <ReelerSwitch
          label="Add answer to filename when exporting"
          name="filename"
          action={() =>
            setSettings({
              ...settings,
              filename: settings?.filename ? !settings?.filename : true,
            })
          }
          checkedBoolean={settings?.filename}
        />
      </Form.Group>

      <Form.Group className="mb-3 mt-3">
        <ReelerSwitch
          label="Make this field exportable"
          name="export"
          action={() => setSettings({ ...settings, export: !settings?.export })}
          checkedBoolean={settings?.export}
        />
      </Form.Group>

      <SaveField saveFunction={handleSubmit} />
    </Form>
  )
}
