import React, { useState } from "react"
import { Form, Row, Col, InputGroup, FormControl } from "react-bootstrap"
import { IoClose } from "react-icons/io5"
import { useDispatch, useSelector } from "react-redux"
import { selectCampaign } from "../../../../redux/campaign/campaign.selectors"
import { saveCampaign } from "../../../../redux/campaign/campaign.actions"

import ReelerButton from "../../../commons/ReelerButton"
import Tag from "../../../commons/Tag"
const customStyles = {
  container: () => ({
    // none of react-select's styles are passed to <Control />
    width: 300,
  }),
}
export default function ExcludeCreatorHashtags() {
  const campaign = useSelector(selectCampaign)
  const dispatch = useDispatch()
  const [hashtag, setHashtag] = useState("")
  const [loading, setLoading] = useState(false)

  const handleExcludeHashtag = () => {
    setLoading(true)
    let excludeHashtags = campaign.exclude_hashtags
      ? campaign.exclude_hashtags
      : []

    if (hashtag.trim() !== null) {
      hashtag.split(" ").forEach(tag => {
        if (tag.trim() === "") {
          return
        }
        const cleandTag = tag.replace(/#+/g, "")
        excludeHashtags.push(cleandTag.toLowerCase())
      })

      // update firebase and redux for account
      dispatch(
        saveCampaign({
          ...campaign,
          exclude_hashtags: excludeHashtags,
        })
      )
      setHashtag("")
      setLoading(false)
    }
  }

  const handleDeleteTag = tagToDelete => {
    setLoading(true)
    const excludeHashtags = campaign.exclude_hashtags.filter(
      tag => tag !== tagToDelete
    )
    dispatch(
      saveCampaign({
        ...campaign,
        exclude_hashtags: excludeHashtags,
      })
    )
    setLoading(false)
  }

  return (
    campaign && (
      <>
        <Form.Group controlId="add-tag">
          <Row>
            <Col sm={4}>
              <Form.Label className="mb-0">Exclude content</Form.Label>
            </Col>
            <Col sm={8}>
              <InputGroup
                style={{
                  borderRadius: ".25rem",
                  background: "#fff",
                  border: "1px solid lightgrey",
                }}
              >
                <InputGroup.Text
                  className="pe-0"
                  style={{ border: "none", background: "none" }}
                >
                  #
                </InputGroup.Text>
                <FormControl
                  style={{ border: "none" }}
                  placeholder="Exclusion hashtag(s)"
                  value={hashtag}
                  onChange={e => setHashtag(e.target.value)}
                  disabled={loading}
                  onKeyUp={e => {
                    if (e.key === "Enter") {
                      handleExcludeHashtag()
                    }
                  }}
                />
                <ReelerButton
                  text="Add"
                  styleClass="btn-filter"
                  disabled={!(hashtag?.length > 0) || loading}
                  dispatch={() => {
                    handleExcludeHashtag()
                  }}
                />
              </InputGroup>

              <Form.Text muted>
                If a content asset is tagged with any of these hashtags on
                Instagram, the asset won’t be collected. Use this function to
                block out irrelevant content.
              </Form.Text>

              <div>
                {campaign?.exclude_hashtags?.map((tag, index) => (
                  <Tag key={index} onClick={() => handleDeleteTag(tag)}>
                    {tag}
                  </Tag>
                ))}
              </div>
            </Col>
          </Row>
        </Form.Group>
      </>
    )
  )
}
