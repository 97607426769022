import React from "react"
import { Card } from "react-bootstrap"

export default function NoSuchDocument({ message, img }) {
  return (
    <Card className="mt-3 mb-3">
      <Card.Body>
        <div className="d-flex flex-column justify-content-center align-items-center pt-5">
          <img width="200px" src={img} alt="No such document" />
          <p>{message}</p>
        </div>
      </Card.Body>
    </Card>
  )
}
