import styled from "styled-components"

export const DragAndDropContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
`

export const DropArea = styled.div`
  width: 100%;
`

export const ContentContainer = styled.div`
  display: grid;
  grid-gap: ${({ gutter }) => (gutter ? `${gutter}px` : "0.3rem")};
  grid-template-columns: repeat(auto-fill, minmax(min(10rem, 100%), 1fr));
`
