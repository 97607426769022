import React from "react"
import { ListGroup } from "react-bootstrap"
import { database } from "../../../../../../../../firebase"
import { useSelector, useDispatch } from "react-redux"
import { selectActiveAsset } from "../../../../../../../../redux/asset/asset.selectors"
import { updateAsset } from "../../../../../../../../redux/asset/asset.actions"
import CloseButton from "../../../../../../../../components/commons/CloseButton"
import ReelerTooltip from "../../../../../../../commons/ReelerTooltip/ReelerTooltip"
import * as S from "../AssetProducts.styles"

export default function AssetProductList() {
  const dispatch = useDispatch()
  const asset = useSelector(selectActiveAsset)

  const handleRemoveProduct = productId => {
    const assetRef = database.assets.doc(asset.id)

    const updatedProductsArray = asset?.products?.filter(
      p => p.id !== productId
    )
    const updatedProductIdsArray = asset?.productIds?.filter(
      id => id !== productId
    )

    assetRef
      .update({
        productIds: updatedProductIdsArray,
        products: updatedProductsArray,
      })
      .then(() => {
        dispatch(
          updateAsset({
            ...asset,
            productIds: updatedProductIdsArray,
            products: updatedProductsArray,
          })
        )
      })
  }

  return (
    <div className="my-4">
      <h5>Added products</h5>

      <ListGroup as="ol" numbered>
        {asset?.products?.map((p, index) => (
          <S.Product as="li">
            <ReelerTooltip text="Remove product from asset">
              <span
                style={{ position: "absolute", top: "0", right: "0" }}
                onClick={() => handleRemoveProduct(p.id)}
              >
                <CloseButton />
              </span>
            </ReelerTooltip>
            <S.ProductImageContainer>
              <S.ProductImage src={p?.imageUrl} />
            </S.ProductImageContainer>

            <S.ProductInfo>
              <S.ProductName>
                {index + 1}. {p?.name}
              </S.ProductName>
              <S.ProductDescription>{p?.description}</S.ProductDescription>
            </S.ProductInfo>
          </S.Product>
        ))}
      </ListGroup>
    </div>
  )
}
