import styled, { css } from "styled-components"

const truncatedCaption = css`
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
`

export const FeedContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const FeedItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1rem;
`

export const FeedItemImage = styled.img`
  width: 100%;
  vertical-align: middle;
`

export const SocialWallCardBody = styled.div`
  flex: 1 1 auto;
  padding: 0.75rem 0.5rem !important;
`

export const Caption = styled.div`
  margin-bottom: 5px;
  font-size: 0.4rem;
  ${({ showMore }) => (!showMore ? truncatedCaption : "")};
`
export const LoadMoreContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 5px;
`
export const LoadMoreButton = styled.div`
  display: flex;
  justify-content: center;
  border-radius: 3px;
  padding: 5px;
  color: var(--reeler-link);
  box-shadow: var(--reeler-shadow);
  width: 200px;

  :hover {
    cursor: pointer;
    background: var(--reeler-grey);
  }
`
