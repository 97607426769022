import styled, { keyframes } from "styled-components"

const fadeOut = keyframes`
0%,100% { opacity: 0 }
`

export const NotificationBarContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Notification = styled.div`
  position: relative;
  padding: 0.5rem 1rem;
  width: 100%;
  background: ${({ message_type }) =>
    message_type === "warning"
      ? "var(--reeler-warning)"
      : "var(--reeler-super-light)"};
  opacity: 1;
  border-radius: 3px;
  border: ${({ message_type }) =>
    message_type === "warning"
      ? "1px solid #efe85d;"
      : "1px solid var(--reeler-light)"};
  animation: ${fadeOut} "0.3ms linear forwards";
  color: var(--reeler-title);
`
export const NotificationMessage = styled.div`
  color: var(--reeler-title);
`

export const NotificationTitle = styled.div`
  color: var(--reeler-title);
  font-weight: bold;
  margin-right: 5px;
  float: left;
`

export const NotificationDismissButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`
