import React from "react"
// 3rd-party libraries
import { Form } from "react-bootstrap"
import parse from "html-react-parser"

import * as S from "../FormStyles"
import { selectFormSchema } from "../../../../../redux/campaign/campaign.selectors"
import { useSelector } from "react-redux"

export default function MultiplechoiceWidget({
  fieldId,
  field,
  refArray,
  disabled = false,
  design,
}) {
  return (
    <S.WidgetContainer>
      {field.title ? (
        <>
          <S.ColoredLabel $design={design}>{field.title}</S.ColoredLabel>
          {field.required ? <S.RequiredMark>*</S.RequiredMark> : null}
        </>
      ) : null}
      {field.format === "select" ? (
        <S.FormSelect
          required={field?.required}
          ref={el =>
            (refArray.current = { ...refArray.current, [fieldId]: el })
          }
          $focusColor={design?.fieldFocusColor}
          disabled={disabled}
        >
          <option value="">--</option>
          {field.options.map((option, i) => (
            <option key={i} value={option.label}>
              {option.label}
            </option>
          ))}
        </S.FormSelect>
      ) : (
        field.options.map((option, i) => (
          <S.MultiplechoiceOptionContainer key={i}>
            <S.MultiplechoiceOptionInput
              ref={el =>
                (refArray.current[fieldId] = {
                  ...refArray.current[fieldId],
                  [i]: el,
                })
              }
              required={field?.required}
              key={fieldId + i}
              id={fieldId + i}
              name={fieldId}
              type={field.format}
              disabled={disabled}
            />

            <S.MultiplechoiceOptionLabel htmlFor={fieldId + i}>
              <small>
                {!field?.title && field.required ? (
                  <S.RequiredMark>* </S.RequiredMark>
                ) : null}
                {parse(option.label)}
              </small>
            </S.MultiplechoiceOptionLabel>
          </S.MultiplechoiceOptionContainer>

          // <Form.Check
          //   ref={el =>
          //     (refArray.current[fieldId] = {
          //       ...refArray.current[fieldId],
          //       [i]: el,
          //     })
          //   }
          //   required={field?.required}
          //   key={fieldId + i}
          //   type={field.format}
          //   id={option.label}
          //   name={fieldId}
          //   disabled={disabled}
          // >
          //   <Form.Check.Input type={field.format} />
          //   <Form.Check.Label>
          //     <small>{parse(option.label)}</small>
          //   </Form.Check.Label>
          // </Form.Check>
        ))
      )}
      {field.description ? (
        <S.ColoredFormText $design={design}>
          {field.description}
        </S.ColoredFormText>
      ) : null}
    </S.WidgetContainer>
  )
}
