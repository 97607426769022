import InviteActionTypes from "./invite.types"

const INITIAL_STATE = {
  data: null,
  isLoading: false,
  errorMessage: undefined,
}

const inviteReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case InviteActionTypes.FETCH_INVITE_CODE_DATA_START:
      return {
        ...state,
        isLoading: true,
        errorMessage: undefined,
      }
    case InviteActionTypes.FETCH_INVITE_CODE_DATA_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
        errorMessage: undefined,
      }
    case InviteActionTypes.FETCH_INVITE_CODE_DATA_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload,
      }
    default:
      return state
  }
}

export default inviteReducer
