import React, { useState } from "react"
import { FaLink, FaTrashAlt } from "react-icons/fa"
import { database } from "../../../firebase"
import useUserFeedbackMessage from "../../../hooks/useUserFeedbackMessage"
import { renderDate } from "../../../utils/RenderDate"
import Icon from "../../commons/Icon"

export default function InviteRow({ invite }) {
  const { setUserFeedbackMessage } = useUserFeedbackMessage()

  const handleDeleteInvite = async () => {
    database.invites
      .doc(invite.id)
      .delete()
      .then(() => {
        setUserFeedbackMessage("Invite successfully deleted!")
      })
      .catch(err => {
        setUserFeedbackMessage("Invite could not be deleted.", "danger")
      })
  }

  const CopyInviteLink = () => {
    const [tooltip, setTooltip] = useState("Copy invite link")

    const copyInviteLink = () => {
      navigator.clipboard.writeText(
        process.env.REACT_APP_FIREBASE_APP_URL + "/invite/" + invite.id
      )
      setTooltip("Copied!")
    }

    return (
      <Icon onClick={copyInviteLink} tooltip={tooltip}>
        <FaLink />
      </Icon>
    )
  }

  return (
    <tr>
      <td>{invite.email}</td>
      <td className="capitalize">{invite.role}</td>
      <td>{renderDate(invite.createdAt, "YYYY-MM-DD")}</td>
      <td>
        <div className="d-flex flex-row justify-content-center">
          <CopyInviteLink />
          <Icon
            onClick={() => handleDeleteInvite()}
            tooltip="Delete invitation"
          >
            <FaTrashAlt />
          </Icon>
        </div>
      </td>
    </tr>
  )
}
