import React from "react"
import { Row, Col } from "react-bootstrap"
import AssetPublishToInstagram from "./AssetPublishToInstagram"
import AssetPublishToYoutube from "./AssetPublishToYoutube"
import AssetPublishPostToFacebookPage from "./AssetPublishPostToFacebookPage"
import { useAccount } from "../../../../../../hooks/useAccount"
import { useSelector } from "react-redux"
import { selectActiveAsset } from "../../../../../../redux/asset/asset.selectors"
import { ModalTabContainer } from "../../styles"
import { MEDIA_TYPE } from "../../../../../../constants"

export default function AssetPublish() {
  const asset = useSelector(selectActiveAsset)
  const { plan } = useAccount()

  return (
    <ModalTabContainer>
      <Row className="mt-3">
        <Col>
          <div
            className="p-2"
            style={{ borderRadius: "3px", background: "var(--reeler-grey)" }}
          >
            <h5>Publish to social media</h5>
            <Row className="mt-3">
              <Col className="d-flex flex-row">
                <div className="me-3">
                  <AssetPublishToInstagram asset={asset} />
                </div>
                {[MEDIA_TYPE.IMAGE].includes(asset.media_type) ? (
                  <div className="me-3">
                    <AssetPublishPostToFacebookPage asset={asset} />
                  </div>
                ) : null}

                {false &&
                [MEDIA_TYPE.VIDEO, MEDIA_TYPE.AUDIO].includes(
                  asset.media_type
                ) ? (
                  <div className="me-3">
                    <AssetPublishToYoutube asset={asset} />
                  </div>
                ) : null}
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </ModalTabContainer>
  )
}
