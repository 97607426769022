import React, { useState } from "react"
import JSZip from "jszip"
import JSZipUtils from "jszip-utils"
import { saveAs } from "file-saver"
import SpinnerComponent from "../../../commons/SpinnerComponent"

var zip = new JSZip()
var count = 0

export default function DownloadAssets({ zipname = "reeler.zip", assets }) {
  const [loading, setLoading] = useState(false)
  var zipFilename = zipname

  const downloadAssets = () => {
    setLoading(true)
    assets.forEach((asset, index) => {
      let filename = "File_" + (index + 1) + "." + asset.type.split("/")[1]
      let url = asset.url

      // loading a file and add it in a zip file
      JSZipUtils.getBinaryContent(url, function (err, data) {
        count++
        if (err) {
          console.log(err)

          throw err
        }
        zip.file(filename, data, { binary: true })

        if (count === assets.length) {
          zip.generateAsync({ type: "blob" }).then(function (content) {
            saveAs(content, zipFilename)
            setLoading(false)
          })
        }
      })
    })
  }

  return loading ? (
    <SpinnerComponent size="sm" />
  ) : (
    <span onClick={() => downloadAssets()} className="icon-btn">
      [Download all files]
    </span>
  )
}
