import React, { useState } from "react"
import firebase from "firebase/app"
import { database } from "../../../../../../firebase"
// 3rd-party
import { FaPen, FaPlus } from "react-icons/fa"
import { v4 as uuidV4 } from "uuid"
// Redux
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import {
  setVersionToView,
  setSelectedVersion,
  clearSelectedVersion,
  setActiveAsset,
} from "../../../../../../redux/asset/asset.actions"
import {
  selectActiveAsset,
  selectVersionToView,
} from "../../../../../../redux/asset/asset.selectors"

// Reeler components
import VersionsUploadModal from "./VersionsUploadModal"
import MediaType from "../../../../../commons/MediaType"
import SpinnerComponent from "../../../../../commons/SpinnerComponent"
import DeleteVersion from "./DeleteVersion"
import ReelerIconButton from "../../../../../commons/reelerIconButton/ReelerIconButton"
import ReelerHoverIcon from "../../../../../commons/ReelerHoverIcon/ReelerHoverIcon"
import ReelerTooltip from "../../../../../commons/ReelerTooltip/ReelerTooltip"
import * as S from "../../styles"
import { Icon } from "../../../../../styles/styles"
// utils
import { humanFileSize } from "../../../../../../utils/humanFileSize"
import { timeAgo } from "../../../../../../utils/CalculateTimeAgo"
import { renderDate } from "../../../../../../utils/RenderDate"
import { useEffect } from "react"
import AssetCropImage from "./AssetCropImage"
import { IMG_SIZES } from "../../../../../../constants"

const AssetVersions = () => {
  const dispatch = useDispatch()
  const asset = useSelector(selectActiveAsset)
  const versionToView = useSelector(selectVersionToView)

  const [currentVersion, setCurrentVersion] = useState(null)
  const [show, setShow] = useState(false)

  const handleClose = () => {
    setShow(false)
    dispatch(clearSelectedVersion())
  }
  const handleShowModal = version => {
    dispatch(setSelectedVersion(version))
    setShow(true)
  }

  const handleContentClick = version => {
    dispatch(setVersionToView(version))
  }

  useEffect(() => {
    // To be compatibale with existing doc we need to check if the asset has any assetVersions, if not, we need to create the first one.
    if (asset && asset?.assetVersions?.length > 0) {
      // FIlter for the main version, should only be one!!
      let main = asset.assetVersions.filter(version => version.main === true)
      setCurrentVersion(main[0])
    }
    // MB I removed this since all new assets should have an original version, it was legacy assets that was missing.
    //  else {
    //   // If we do not have any existing versions we need to create a new original/main from the asset object and put it in an array
    //   let original = {
    //     id: uuidV4(),
    //     title: "original",
    //     file_name: asset?.file_name ? asset?.file_name : "n/a",
    //     size: asset?.size ? asset?.size : "n/a",
    //     type: asset?.type ? asset?.type : "n/a",
    //     url: asset.url,
    //     modifiedAt: Date.now(),
    //     createdAt: asset.createdAt,
    //     original: true,
    //     main: true,
    //   }

    //   // update asset with currentVersion
    //   var docRef = database.assets.doc(asset.id)
    //   docRef.update({
    //     assetVersions: firebase.firestore.FieldValue.arrayUnion(original),
    //   })
    //   // Dispatch the updated asset
    //   docRef.get().then(asset => {
    //     dispatch(setActiveAsset(database.formatDoc(asset)))
    //   })
    // }
  }, [asset])

  return (
    <>
      <S.ModalTabContainer>
        {currentVersion ? (
          <>
            <S.ModalTabHeading2>Main version</S.ModalTabHeading2>
            <S.MainVersionContainer>
              <S.MediaThumbnailContainer
                $heightPx={100}
                $widthPx={100}
                onClick={() => handleContentClick(currentVersion)}
              >
                <MediaType asset={asset} imgSize={IMG_SIZES.thumb} />
              </S.MediaThumbnailContainer>

              <S.VersionInfoContainer>
                <S.Header>
                  <S.Title>
                    {currentVersion.title ? currentVersion.title : `n/a`}
                  </S.Title>
                  <S.Buttons>
                    <ReelerHoverIcon></ReelerHoverIcon>
                    <ReelerTooltip text="Edit this version">
                      <Icon onClick={() => handleShowModal(currentVersion)}>
                        <FaPen />
                      </Icon>
                    </ReelerTooltip>
                  </S.Buttons>
                </S.Header>
                <S.ContentDataList>
                  <>
                    <S.ListItemVersions>
                      {currentVersion.type}
                    </S.ListItemVersions>
                    <S.ListItemVersions>
                      {humanFileSize(currentVersion.size)}
                    </S.ListItemVersions>
                    <S.ListItemVersions>
                      Modified: {timeAgo(currentVersion.modifiedAt)}
                    </S.ListItemVersions>
                    <S.ListItemVersions>
                      Created:{" "}
                      {currentVersion.createdAt
                        ? `${timeAgo(currentVersion.createdAt)} (${renderDate(
                            currentVersion.createdAt
                          )})`
                        : "N/A"}
                    </S.ListItemVersions>
                    <S.ListItemVersions>
                      by:{" "}
                      {currentVersion?.uploadedBy
                        ? currentVersion.uploadedBy
                        : "N/A"}
                    </S.ListItemVersions>
                  </>
                </S.ContentDataList>
              </S.VersionInfoContainer>
            </S.MainVersionContainer>
          </>
        ) : (
          <S.MainVersionContainer>
            <S.VersionInfoContainer>
              <S.Header>
                <S.Title>No main version</S.Title>
              </S.Header>
            </S.VersionInfoContainer>
          </S.MainVersionContainer>
        )}
        {asset?.type && asset.type.split("/")[0] === "image" ? (
          <div className="mb-3">
            <AssetCropImage asset={asset} />
          </div>
        ) : null}

        <S.OtherVersionsContainer>
          <S.ContainerHeader>
            <S.ModalTabHeading2 className="my-auto">
              Other versions
            </S.ModalTabHeading2>

            <ReelerIconButton
              small={true}
              icon={<FaPlus size={10} className="me-1" />}
              dispatch={() => handleShowModal()}
              text="Upload new version"
            />
          </S.ContainerHeader>

          <S.Scrollable>
            {asset?.assetVersions
              ?.filter(version => version.main !== true)
              .map((version, key) => {
                return (
                  <S.OtherVersionsItem
                    key={key}
                    $active={version.id === versionToView?.id}
                  >
                    <S.MediaThumbnailContainer
                      onClick={() => handleContentClick(version)}
                      $heightPx={100}
                      $widthPx={100}
                    >
                      <MediaType asset={version} imgSize={IMG_SIZES.thumb} />
                    </S.MediaThumbnailContainer>
                    <S.VersionInfoContainer>
                      <S.Header>
                        <S.Title>{version.title}</S.Title>

                        <S.Buttons>
                          <ReelerTooltip text="Edit this version">
                            <Icon onClick={() => handleShowModal(version)}>
                              <FaPen />
                            </Icon>
                          </ReelerTooltip>
                          {!version?.main ? (
                            !version?.original ? (
                              <ReelerTooltip text="Delete version">
                                <Icon>
                                  <DeleteVersion version={version} as="icon" />
                                </Icon>
                              </ReelerTooltip>
                            ) : null
                          ) : null}
                        </S.Buttons>
                      </S.Header>
                      <S.ContentDataList>
                        <S.ListItemVersions>{version.type}</S.ListItemVersions>
                        <S.ListItemVersions>
                          Size:{" "}
                          {version.size ? humanFileSize(version.size) : "N/A"}
                        </S.ListItemVersions>
                        <S.ListItemVersions>
                          Modified:{" "}
                          {version.modifiedAt
                            ? timeAgo(version.modifiedAt) + " ago"
                            : "N/A"}
                        </S.ListItemVersions>
                        <S.ListItemVersions>
                          Created:{" "}
                          {version.createdAt
                            ? timeAgo(version.createdAt) + " ago"
                            : "N/A"}
                        </S.ListItemVersions>
                        <S.ListItemVersions>
                          Uploaded by:{" "}
                          {version.uploadedBy ? version.uploadedBy : "Unknown"}
                        </S.ListItemVersions>
                      </S.ContentDataList>
                    </S.VersionInfoContainer>
                  </S.OtherVersionsItem>
                )
              })}
          </S.Scrollable>
        </S.OtherVersionsContainer>
      </S.ModalTabContainer>
      <VersionsUploadModal show={show} handleClose={handleClose} />
    </>
  )
}

export default AssetVersions
