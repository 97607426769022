import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import { database } from "../../../firebase"
import ReelerButton from "../../commons/ReelerButton"
import firebase from "firebase/app"
import { Modal } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { selectAccounts } from "../../../redux/account/account.selectors"
import { setCreator } from "../../../redux/creators/creators.actions"
export default function DeleteCreatorButton({ btn, creatorId }) {
  const [isDeleting, setIsDeleting] = useState(false)
  const [open, setOpen] = useState(false)
  const history = useHistory()
  const dispatch = useDispatch()
  const account = useSelector(selectAccounts)

  const closeModal = () => {
    setOpen(false)
  }
  const openModal = () => {
    setOpen(true)
  }

  const handleDelete = async () => {
    setIsDeleting(true)
    try {
      console.log("Fetching assets connected to creator")
      const querySnapshot = await database.assets
        .where("accountId", "==", account.id)
        .where("creatorId", "==", creatorId)
        .get()
      console.log(querySnapshot.size, " assets")

      if (querySnapshot.size > 0) {
        const assets = []
        querySnapshot.forEach(doc => {
          assets.push(database.formatDoc(doc))
        })

        const promises = assets.map(async asset => {
          console.log("Removing creato if form asset", asset.id)
          return await database.assets
            .doc(asset.id)
            .update({ creatorId: firebase.firestore.FieldValue.delete() })
        })

        console.log("Total assets updated", promises.length)
        await Promise.all(promises)
        console.log("Done updateing assets")
      }
      console.log("Deleting creator")

      await database.creators.doc(creatorId).delete()
      dispatch(setCreator(null))
      console.log("Creator deleted")
      setIsDeleting(false)
      history.push("/creators")
    } catch (error) {
      setIsDeleting(false)
      console.error("Error removing document: ", error)
    }
  }

  return (
    <>
      <div onClick={openModal}>{btn}</div>

      <Modal centered show={open} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            Are you sure you want to delete this creator?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>This action will delete the creator and cannot be undone.</p>
        </Modal.Body>

        <Modal.Footer>
          <ReelerButton
            text="Close"
            dispatch={closeModal}
            styleClass="btn-secondary"
          />
          <ReelerButton
            text="Delete creator"
            styleClass="btn-delete"
            dispatch={() => handleDelete()}
            loading={isDeleting}
            disabled={isDeleting}
          />
        </Modal.Footer>
      </Modal>
    </>
  )
}
