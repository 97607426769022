import React from "react"
import { FaRegCopy } from "react-icons/fa"
import ReelerTooltip from "./ReelerTooltip/ReelerTooltip"

export default function CopyToClipboard({ link, tooltipText, margin }) {
  const copyUrl = URL => {
    navigator.clipboard.writeText(URL)
  }
  return (
    <ReelerTooltip text={tooltipText}>
      <FaRegCopy
        style={{ strokeWidth: "3", margin: margin }}
        className="icon-color icon-btn"
        onClick={() => copyUrl(link)}
      />
    </ReelerTooltip>
  )
}
