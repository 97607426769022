import React from "react"
import Section from "../../../layouts/core/Section"
import {
  Header,
  HeaderGroup,
  HeaderGroupCenter,
  HeaderGroupLeft,
  HeaderGroupRight,
} from "../../../layouts/core/Header"
import Body from "../../../layouts/core/Body"
import Title from "../../../layouts/core/Title"
import BackButton from "../../../components/commons/BackButton"
import MessageTemplatesTabel from "./components/MessageTemplatesTabel"
import EditMessageTemplate from "./components/EditMessageTemplate"
import ReelerButton from "../../../components/commons/ReelerButton"

export default function MessageTemplatesPage() {
  return (
    <Section>
      <Header>
        <HeaderGroup>
          <HeaderGroupLeft>
            <Title>Instagam message templates</Title>
          </HeaderGroupLeft>
          <HeaderGroupCenter></HeaderGroupCenter>
          <HeaderGroupRight>
            <BackButton />

            <EditMessageTemplate
              btn={<ReelerButton text="New template" styleClass="btn-main" />}
            />
          </HeaderGroupRight>
        </HeaderGroup>
      </Header>
      <Body>
        <p>
          Write one or several texts for social media messages where you ask
          end-users to visit an “approval page” to grant you permission for the
          use of content. The typical case would be a text that you post on
          Instagram as a comment or send as a direct message to the content
          creator. You will be able to tweak or personalize the message text
          each time you post it.
        </p>
        <MessageTemplatesTabel />
      </Body>
    </Section>
  )
}
