import React, { useEffect, useState } from "react"
import { Form, Row, Col } from "react-bootstrap"
import { setSlideshowSettings } from "../../../../../../redux/feed/feed.actions"
import { useDispatch } from "react-redux"
import { SectionHeading } from "../../../../styles"
import UnitInputGroup from "../../../../../commons/UnitInputGroup/UnitInputGroup"

const SlideshowSettings = ({ dbSettings }) => {
  const {
    widgetHeight,
    captions,
    dots,
    effect,
    infinite,
    likes,
    slidesToScroll,
    slidesToShow,
    speed,
    timePerAsset,
    username,
  } = dbSettings
  const dispatch = useDispatch()
  const [settings, setSettings] = useState({
    widgetHeight: widgetHeight ? widgetHeight : "auto",
    captions: captions ? captions : false,
    dots: dots ? dots : false,
    effect: effect ? effect : false,
    infinite: infinite ? infinite : false,
    likes: likes ? likes : false,
    slidesToScroll: slidesToScroll ? slidesToScroll : 1,
    slidesToShow: slidesToShow ? slidesToShow : 1,
    speed: speed ? speed : 0,
    timePerAsset: timePerAsset ? timePerAsset : 0,
    username: username ? username : false,
  })

  const handleChange = ({ target: { name, value, type } }) => {
    if (type === "number") {
      setSettings({ ...settings, [name]: Number(value) })
    } else {
      setSettings({ ...settings, [name]: value })
    }
  }

  useEffect(() => {
    dispatch(setSlideshowSettings(settings))
  }, [settings, dispatch])

  return (
    <>
      <SectionHeading>Slideshow</SectionHeading>
      <Row>
        <Col>
          <div className="custom-control custom-switch">
            <input
              type="checkbox"
              className="custom-control-input"
              id="captions"
              checked={settings.captions}
              name="captions"
              onChange={() =>
                setSettings({ ...settings, captions: !settings.captions })
              }
            />
            <label className="custom-control-label" htmlFor="captions">
              Include captions
            </label>
          </div>

          <div className="custom-control custom-switch">
            <input
              type="checkbox"
              className="custom-control-input"
              id="likes"
              checked={settings.likes}
              name="likes"
              onChange={() =>
                setSettings({ ...settings, likes: !settings.likes })
              }
            />
            <label className="custom-control-label" htmlFor="likes">
              Include likes
            </label>
          </div>
          <div className="custom-control custom-switch">
            <input
              type="checkbox"
              className="custom-control-input"
              id="username"
              checked={settings.username}
              name="username"
              onChange={() =>
                setSettings({ ...settings, username: !settings.username })
              }
              disabled
            />
            <label className="custom-control-label" htmlFor="username">
              Include username
            </label>
          </div>
        </Col>
        <Col>
          <Form.Group controlId="effect">
            <Form.Label>Effect</Form.Label>
            <br />
            <Form.Control
              as="select"
              className="form-select"
              onChange={handleChange}
              name="effect"
            >
              <option defaultValue>{dbSettings.effect}</option>
              <option value="None">None</option>
              <option value="Crossfade">Crossfade</option>
              <option value="Dissolve">Dissolve</option>
              <option value="Flip">Flip</option>
            </Form.Control>
          </Form.Group>
        </Col>
      </Row>
      <Form.Group className="mb-3" controlId="timePerAsset">
        {/* <Form.Label>Time per asset</Form.Label>
        <br />
        <InputGroup>
          <Form.Control
            type="number"
            min="0"
            max="10000"
            value={settings.timePerAsset}
            name="timePerAsset"
            onChange={handleChange}
          />
          <InputUnit>ms</InputUnit>
        </InputGroup> */}
        <UnitInputGroup
          label="Time per asset"
          name="timePerAsset"
          min={500}
          max={100000}
          value={settings.borderRadius ? settings.borderRadius : Number()}
          dispatch={handleChange}
          unit="ms"
        />
        <Form.Text muted>Time each asset is shown</Form.Text>
      </Form.Group>
    </>
  )
}

export default SlideshowSettings
