import React, { useState, useEffect } from "react"
import { Form, Row } from "react-bootstrap"
import { database } from "../../firebase"

import "../App.css"
import { useSelector, useDispatch } from "react-redux"
import { updateAccounts } from "../../redux/account/account.actions"
import { selectAccounts } from "../../redux/account/account.selectors"
import DeleteAccount from "./DeleteAccount"
import ReelerButton from "../commons/ReelerButton"
import Section from "../../layouts/core/Section"
import {
  Header,
  HeaderGroup,
  HeaderGroupLeft,
  HeaderGroupRight,
} from "../../layouts/core/Header"
import Body from "../../layouts/core/Body"
import Title from "../../layouts/core/Title"
import BackButton from "../commons/BackButton"
import withoutProperty from "../../utils/withoutProperty"
import useUserFeedbackMessage from "../../hooks/useUserFeedbackMessage"
import { FaCheck, FaInstagram, FaTimes } from "react-icons/fa"
import { TbZoomCheck } from "react-icons/tb"
import { selectCurrentUser } from "../../redux/user/user.selectors"
import { USER_ROLES } from "../../constants/routes"

const Account = () => {
  const dispatch = useDispatch()
  const { setSuccessMessage, setErrorMessage } = useUserFeedbackMessage()
  const currentUser = useSelector(selectCurrentUser)
  const [name, setName] = useState("")
  const [deleteUnreviewdAssetsAfter, setDeleteUnreviewdAssetsAfter] =
    useState(6)
  const [deleteRejectedAssetsAfter, setDeleteRejectedAssetsAfter] = useState(7)

  const accounts = useSelector(selectAccounts)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (accounts) {
      setName(accounts?.name)
      setDeleteUnreviewdAssetsAfter(
        accounts?.contentRetention?.deleteUnreviewdAssetsAfter
          ? accounts?.contentRetention?.deleteUnreviewdAssetsAfter
          : 6
      )
      setDeleteRejectedAssetsAfter(
        accounts?.contentRetention?.deleteRejectedAssetsAfter
          ? accounts?.contentRetention?.deleteRejectedAssetsAfter
          : 7
      )
    }
  }, [accounts])

  const handleSubmit = () => {
    setLoading(true)

    if (accounts) {
      // Update account

      let data = {
        name,
        contentRetention: {
          deleteUnreviewdAssetsAfter,
          deleteRejectedAssetsAfter,
        },
      }

      database.accounts
        .doc(accounts.id)
        .update(data)
        .then(() => {
          dispatch(updateAccounts(data))
          setSuccessMessage("Your changes were saved")
          setLoading(false)
        })
        .catch(err => {
          setErrorMessage("There was an error saving your changes")
          setLoading(false)
        })
    }
  }

  return (
    <Section>
      <Header>
        <HeaderGroup>
          <HeaderGroupLeft>
            <Title>Account settings</Title>
          </HeaderGroupLeft>
          <HeaderGroupRight>
            <DeleteAccount />
            <BackButton />
            <ReelerButton
              text="Save"
              disabled={loading || currentUser?.role !== USER_ROLES.owner}
              styleClass="btn-main"
              dispatch={() => handleSubmit()}
            />
          </HeaderGroupRight>
        </HeaderGroup>
      </Header>
      <Body>
        <Form.Group controlId="account-name">
          <Form.Label>Account name</Form.Label>
          <Form.Control
            type="text"
            name="name"
            value={name}
            onChange={e => setName(e.target.value)}
            required
            disabled={currentUser?.role !== USER_ROLES.owner}
          />
        </Form.Group>

        <h4 className="mt-5">Content retention settings</h4>
        <div className="d-flex flex-row mb-3">
          <div>
            <FaCheck
              className="me-1"
              style={{
                marginTop: "-0.25rem",
                color: "var(--reeler-dark-green)",
              }}
            />
          </div>
          <div>
            <Form.Group controlId="retention-approved">
              <Form.Label className="d-flex align-items-center">
                <span>Retention of approved content</span>
              </Form.Label>
              <Form.Text className="mb-3">
                Approved content is retained until manually deleted.
              </Form.Text>
            </Form.Group>
          </div>
        </div>
        <div className="d-flex flex-row mb-3">
          <div>
            <TbZoomCheck
              className="icon-color me-1"
              style={{ marginTop: "-0.25rem" }}
            />
          </div>
          <div>
            <Form.Group controlId="retention-instagram">
              <Form.Label className="d-flex align-items-center">
                <span>Retention of unreviewed assets from Instagram</span>
              </Form.Label>
              <Form.Text className="mb-3">
                <span>
                  Automatically delete unreviewed assets from Instagram after:
                </span>
                <strong className="px-1">{deleteUnreviewdAssetsAfter}</strong>
                <span>
                  {deleteUnreviewdAssetsAfter > 1 ? "months" : "month"}
                </span>
              </Form.Text>
              <div style={{ width: "100%", maxWidth: "700px" }}>
                <Form.Control
                  type="range"
                  name="automaticDeleteAfter"
                  min={1}
                  max={24}
                  value={deleteUnreviewdAssetsAfter}
                  onChange={e => setDeleteUnreviewdAssetsAfter(e.target.value)}
                  className="form-range"
                  disabled={currentUser?.role !== USER_ROLES.owner}
                />
                <Form.Text muted>
                  Use the slider to adjust the retention period for unreviewed
                  content assets sourced from Instagram (hashtags and mentions).
                  Please note that unreviewed content from other sources is
                  retained until manually deleted.
                </Form.Text>
              </div>
            </Form.Group>
          </div>
        </div>
        <div className="d-flex flex-row mb-3">
          <div>
            <FaTimes
              className="me-1"
              style={{ marginTop: "-0.25rem", color: "var(--reeler-danger)" }}
            />
          </div>
          <div>
            <Form.Group controlId="retention-rejected">
              <Form.Label className="d-flex align-items-center">
                <span>Retention of rejected assets</span>
              </Form.Label>
              <Form.Text className="mb-3">
                <span>Automatically delete rejected assets after:</span>
                <strong className="px-1">{deleteRejectedAssetsAfter}</strong>
                <span>{deleteRejectedAssetsAfter > 1 ? "days" : "day"}</span>
              </Form.Text>
              <div style={{ width: "100%", maxWidth: "700px" }}>
                <Form.Control
                  type="range"
                  name="automaticDeleteAfter"
                  min={1}
                  max={30}
                  value={deleteRejectedAssetsAfter}
                  onChange={e => setDeleteRejectedAssetsAfter(e.target.value)}
                  className="form-range"
                  disabled={currentUser?.role !== USER_ROLES.owner}
                />
                <Form.Text muted>
                  Use the slider to adjust the retention period for rejected
                  content assets. This setting applies to all rejected content,
                  regardless of the source.
                </Form.Text>
              </div>
            </Form.Group>
          </div>
        </div>
      </Body>
    </Section>
  )
}

export default Account
