import styled from "styled-components"

export const DownloadLink = styled.a`
  color: var(--reeler-dark-green);

  :hover {
    cursor: pointer;
    color: var(--reeler-darkest-green);
    text-decoration: underline;
  }
`
export const Inacvtive = styled.span`
  color: var(--reeler-grey-darker);
`
