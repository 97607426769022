import InviteActionTypes from "./invite.types"

export const fetchInviteCodeDataStart = inviteId => ({
  type: InviteActionTypes.FETCH_INVITE_CODE_DATA_START,
  payload: inviteId,
})

export const fetchInviteCodeDataSuccess = payload => ({
  type: InviteActionTypes.FETCH_INVITE_CODE_DATA_SUCCESS,
  payload,
})

export const fetchInviteCodeDataFailure = error => ({
  type: InviteActionTypes.FETCH_INVITE_CODE_DATA_FAILURE,
  payload: error,
})
