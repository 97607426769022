import AccountActionTypes from "./account.types"

export const fetchAccountsSuccess = payload => ({
  type: AccountActionTypes.FETCH_ACCOUNT_SUCCESS,
  payload,
})

export const fetchAccountsFailure = error => ({
  type: AccountActionTypes.FETCH_ACCOUNT_FAILURE,
  payload: error,
})

export const updateAccounts = payload => ({
  type: AccountActionTypes.UPDATE_ACCOUNTS,
  payload,
})
export const resetToInitialAccountState = () => ({
  type: AccountActionTypes.RESET_TO_INITIAL_STATE,
})
