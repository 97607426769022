import React from "react"
// 3rd-party libraries
import styled from "styled-components"

const StyledHr = styled.hr`
  margin-top: ${props => props.field.height / 2}px;
  margin-bottom: ${props => props.field.height / 2}px;
  width: ${props => (props.field.width ? props.field.width : null)}%;
  /* If line===true: show border-top with props. else hide with border:0 */
  ${props =>
    props.field.line
      ? `border-top: ${props.field.lineThickness}px solid
    ${props.field.lineColor}`
      : `border: 0`}
`

const DividerWidget = ({ field }) => {
  return <StyledHr field={field} />
}
export default DividerWidget
