import React, { useEffect, useState } from "react"
import { Row, Col } from "react-bootstrap"

// Redux
import { useSelector } from "react-redux"
import {
  selectFeed,
  selectLoading,
  selectPreviewAssets,
  selectIsSortingAssets,
} from "../../../../../redux/feed/feed.selectors"

// Reeler components

import SpinnerComponent from "../../../../commons/SpinnerComponent"
import * as S from "../../../styles"
import RenderFeed from "../../../../public-components/feed/render-feed/RenderFeed"
import EmailPreview from "./email-feed/EmailPreview"
import {
  FEED_PUBLISH_OPTIONS,
  FEED_TYPES,
  IMG_SIZES,
} from "../../../../../constants"

const dummyData = []
for (let i = 0; i < 20; i++) {
  dummyData.push({
    id: `${i}`,
    url: "../../assets/images/DummyAsset_optimized.svg",
    type: "image/jpeg",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse eu varius odio. Etiam efficitur, lectus ac ornare elementum, dolor elit facilisis dui, ac mollis nulla tellus ut neque. Proin dictum sapien eu dignissim lacinia.",
  })
}

const Preview = () => {
  const assets = useSelector(selectPreviewAssets)
  const feed = useSelector(selectFeed)
  const [loading, setLoading] = useState(true)
  const isSortingAssets = useSelector(selectIsSortingAssets)
  const [previewAssets, setPreviewAssets] = useState([])

  const formatAsset = (assetId, asset) => {
    let mainVersion = asset?.assetVersions.filter(
      version => version.main === true
    )
    if (mainVersion.length > 0) {
      let type = mainVersion[0].type
      let filePath = mainVersion[0].file_path
      let fileName = mainVersion[0].file_name

      let item = {
        id: assetId,
        caption: asset?.caption ? asset?.caption : asset?.media?.caption,
        edited_caption: asset?.edited_caption ? asset?.edited_caption : "",
        pubDate: asset?.createdAt,
        link: asset?.media?.permalink,
        type: type,
        campaign_type: asset.campaign_type,
        products: asset?.products,
      }
      if (type.split("/")[0] === "image") {
        item["images"] = {
          original: mainVersion[0].url,
          h_500:
            `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/o/` +
            encodeURIComponent(
              filePath + "image@" + IMG_SIZES.preView + "_" + fileName
            ) +
            "?alt=media",
          h_200:
            `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/o/` +
            encodeURIComponent(
              filePath + "image@" + IMG_SIZES.thumb + "_" + fileName
            ) +
            "?alt=media",
        }
      } else {
        item["url"] = mainVersion[0].url
      }
      return item
    }
  }

  useEffect(() => {
    if (assets) {
      setLoading(true)
      if (feed?.publish === FEED_PUBLISH_OPTIONS.manually) {
        const manuallyAddedAssets = assets?.reduce((result, asset) => {
          if (feed?.manuallyAddedAssets?.includes(asset.id)) {
            result.push(formatAsset(asset.id, asset))
          }

          return result
        }, [])
        setPreviewAssets(manuallyAddedAssets)
        setLoading(false)
      } else {
        const withoutExckludedAssets = assets?.reduce((result, asset) => {
          if (!feed?.excludedAssets?.includes(asset.id)) {
            result.push(formatAsset(asset.id, asset))
          }
          return result
        }, [])
        setPreviewAssets(withoutExckludedAssets)
        setLoading(false)
      }
    }
  }, [assets])

  const renderFeedType = () => {
    switch (feed?.feedType) {
      case FEED_TYPES.web:
        return previewAssets && previewAssets.length > 0 ? (
          <RenderFeed feed={feed} assets={previewAssets} />
        ) : (
          <RenderFeed feed={feed} assets={dummyData} />
        )
      case FEED_TYPES.email:
        return <EmailPreview />

      default:
        return previewAssets && previewAssets.length > 0 ? (
          <RenderFeed feed={feed} assets={previewAssets} />
        ) : (
          <RenderFeed feed={feed} assets={dummyData} />
        )
    }
  }

  return loading ? (
    <S.LoadingContainer>
      <p className="mb-0 pb-0">Loading preview...</p>
      <br />
      <SpinnerComponent size="lg" />
    </S.LoadingContainer>
  ) : (
    <S.WidgetContainer>
      {feed && previewAssets && renderFeedType()}
    </S.WidgetContainer>
  )
}
export default Preview
