import React, { useEffect, useState } from "react"

// 3rd-party libraries
import { FaCheck } from "react-icons/fa"

// Redux
import { useDispatch } from "react-redux"
import {
  addSelectedMedia,
  removeSelectedMedia,
} from "../../../redux/import-assets/import.actions"
// Reeler components
import * as S from "../importAssets-style"

export default function SelectMediaFile({ mediaFile, selected }) {
  const dispatch = useDispatch()

  const handleChecked = () => {
    if (selected) {
      dispatch(removeSelectedMedia(mediaFile))
    } else {
      dispatch(addSelectedMedia(mediaFile))
    }
  }

  return (
    <S.SquareContainer onClick={handleChecked}>
      <S.Square>{selected ? <FaCheck size={12} /> : null}</S.Square>
    </S.SquareContainer>
  )
}
