import React from "react"
import { Form, Row, Col, Container, InputGroup } from "react-bootstrap"
import { selectFeed } from "../../../../../../redux/feed/feed.selectors"
import { updateFeed } from "../../../../../../redux/feed/feed.actions"
import { useDispatch, useSelector } from "react-redux"
import * as S from "../../../../styles"
import EmbedEmailFeed from "./components/EmbedEmailFeed"
import EmailPreview from "./EmailPreview"
import { FEED_EMAIL_LAYOUTS } from "../../../../../../constants"

// Settings components

const EmailTypeSection = () => {
  const feed = useSelector(selectFeed)
  const dispatch = useDispatch()

  const handleEmailFeedType = type => {
    switch (type) {
      case FEED_EMAIL_LAYOUTS.single:
        dispatch(updateFeed({ feedLayout: type }))
      case FEED_EMAIL_LAYOUTS.row:
        dispatch(
          updateFeed({
            feedLayout: type,
            feedSettings: { columns: 3, cellspacing: 5 },
          })
        )
      default:
        dispatch(updateFeed({ feedLayout: type }))
    }
  }

  const handleChange = ({ target: { name, value, type } }) => {
    if (type === "number") {
      dispatch(
        updateFeed({
          feedSettings: { ...feed.feedSettings, [name]: Number(value) },
        })
      )
    } else {
      dispatch(
        updateFeed({ feedSettings: { ...feed.feedSettings, [name]: value } })
      )
    }
  }

  return (
    <>
      <Container className="p-0">
        <Form.Group controlId="feed-type-select">
          <Row className="p-1 justify-content-center">
            <Col xs={12} sm={3} className="mb-3">
              <div
                className={
                  feed?.feedLayout === FEED_EMAIL_LAYOUTS.single
                    ? "type-card type-card-selected"
                    : "type-card"
                }
                onClick={() => handleEmailFeedType(FEED_EMAIL_LAYOUTS.single)}
              >
                <div className="d-flex justify-content-center mt-2 mb-2">
                  <span
                    style={{
                      background: "var(--reeler-icon)",
                      borderRadius: "5px",
                      width: "40px",
                      height: "40px",
                    }}
                  ></span>
                </div>
                <div className="d-flex justify-content-center align-items-center mt-2">
                  <span>Single</span>
                </div>
              </div>
            </Col>
            <Col xs={12} sm={3} className="mb-3">
              <div
                className={
                  feed?.feedLayout === FEED_EMAIL_LAYOUTS.row
                    ? "type-card type-card-selected"
                    : "type-card"
                }
                onClick={() => handleEmailFeedType(FEED_EMAIL_LAYOUTS.row)}
              >
                <div
                  className="d-flex flex-row justify-content-center align-items-center mt-2 mb-2"
                  style={{ height: "40px" }}
                >
                  <span
                    style={{
                      background: "var(--reeler-icon)",
                      borderRadius: "1px",
                      width: "10px",
                      height: "10px",
                      marginRight: "5px",
                    }}
                  ></span>
                  <span
                    style={{
                      background: "var(--reeler-icon)",
                      borderRadius: "1px",
                      width: "10px",
                      height: "10px",
                      marginRight: "5px",
                    }}
                  ></span>
                  <span
                    style={{
                      background: "var(--reeler-icon)",
                      borderRadius: "1px",
                      width: "10px",
                      height: "10px",
                    }}
                  ></span>
                </div>
                <div className="d-flex justify-content-center align-items-center mt-2">
                  <span>Row</span>
                </div>
              </div>
            </Col>
          </Row>
          {feed?.feedLayout === FEED_EMAIL_LAYOUTS.single ? (
            <S.SectionContainer>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="borderRadius">
                    <Form.Label>Border radius</Form.Label>
                    <br />
                    <InputGroup>
                      <Form.Control
                        type="number"
                        value={
                          feed.feedSettings.borderRadius
                            ? feed.feedSettings.borderRadius
                            : Number()
                        }
                        name="borderRadius"
                        onChange={handleChange}
                      />
                    </InputGroup>

                    <Form.Text muted>Image corner roundness</Form.Text>
                  </Form.Group>
                </Col>
              </Row>
            </S.SectionContainer>
          ) : (
            <S.SectionContainer>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="columns">
                    <Form.Label>Images</Form.Label>
                    <br />
                    <InputGroup>
                      <Form.Control
                        type="number"
                        min={1}
                        step={1}
                        max={3}
                        value={
                          feed?.feedSettings?.columns
                            ? feed.feedSettings.columns
                            : Number()
                        }
                        name="columns"
                        onChange={handleChange}
                      />
                    </InputGroup>

                    <Form.Text muted>Number of images in a row</Form.Text>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="cellspacing">
                    <Form.Label>Spacing</Form.Label>
                    <br />
                    <InputGroup>
                      <Form.Control
                        type="number"
                        min={0}
                        step={1}
                        max={20}
                        value={
                          feed.feedSettings.cellspacing
                            ? feed.feedSettings.cellspacing
                            : Number()
                        }
                        name="cellspacing"
                        onChange={handleChange}
                      />
                    </InputGroup>

                    <Form.Text muted>Spacing between images</Form.Text>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="borderRadius">
                    <Form.Label>Border radius</Form.Label>
                    <br />
                    <InputGroup>
                      <Form.Control
                        type="number"
                        value={
                          feed?.feedSettings?.borderRadius
                            ? feed.feedSettings.borderRadius
                            : Number()
                        }
                        name="borderRadius"
                        onChange={handleChange}
                      />
                    </InputGroup>

                    <Form.Text muted>Image corner roundness</Form.Text>
                  </Form.Group>
                </Col>
              </Row>
            </S.SectionContainer>
          )}
        </Form.Group>
      </Container>
    </>
  )
}

export default EmailTypeSection
