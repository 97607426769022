import { createSelector } from "reselect"

const campaign = state => state.campaign

export const selectCampaigns = createSelector(
  [campaign],
  campaign => campaign.campaigns
)

export const selectCampaignIsLoading = createSelector(
  [campaign],
  campaign => campaign.isLoading
)

export const selectSelectedCampaign = createSelector(
  [campaign],
  campaign => campaign.selectedCampaign
)

export const selectCampaign = createSelector(
  [campaign],
  campaign => campaign.campaign
)

export const selectFormSchema = createSelector(
  [campaign],
  campaign => campaign.campaign?.formSchema
)

export const selectedField = createSelector(
  [campaign],
  campaign => campaign.selectedField
)

export const selectShowEditModal = createSelector(
  [campaign],
  campaign => campaign.showEditModal
)
export const selectIsSavingFormField = createSelector(
  [campaign],
  campaign => campaign.isSavingFormField
)
