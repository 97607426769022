import React from "react"
// 3rd-party libraries
import * as S from "../FormStyles"

const SubmitButtonWidget = ({ field, disabled = false, design }) => {
  return (
    <S.SubmitContainer>
      <S.SubmitButton
        $btnSize={design?.submitBtnSize ? design.submitBtnSize : "md"}
        $btnColor={
          design?.submitBtnColor
            ? design?.submitBtnColor
            : field?.btnColor
            ? field?.btnColor
            : "#007bff"
        }
        $btnFontColor={
          design?.submitBtnFontColor
            ? design?.submitBtnFontColor
            : field?.btnFontColor
            ? field?.btnFontColor
            : "#ffffff"
        }
      >
        {field?.btnText ? field?.btnText : "Submit"}
      </S.SubmitButton>
    </S.SubmitContainer>
  )
}
export default SubmitButtonWidget
