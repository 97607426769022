import ThirdParyApprovalActionTypes from "./third-party-approval.types"

const INITIAL_STATE = {
  approvedAssets: [],
}

const thirdPartyApprovalReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ThirdParyApprovalActionTypes.SET_APPROVED_ASSETS:
      return {
        ...state,
        approvedAssets: action.payload,
      }
    case ThirdParyApprovalActionTypes.UNCHECK_ALL:
      return {
        ...state,
        approvedAssets: [],
      }
    case ThirdParyApprovalActionTypes.ADD_APPROVED_ASSET:
      return {
        ...state,
        approvedAssets: [...state.approvedAssets, action.payload],
      }
    case ThirdParyApprovalActionTypes.REMOVE_APPROVED_ASSET:
      return {
        ...state,
        approvedAssets: state.approvedAssets.filter(
          a => a.id !== action.payload.id
        ),
      }

    default:
      return state
  }
}

export default thirdPartyApprovalReducer
