import React, { useRef, useState, useEffect } from "react"
import { Form, Button, Card, Alert, Row, Col } from "react-bootstrap"
import { Link, useParams, useHistory } from "react-router-dom"
import CenteredContainer from "../commons/CenteredContainer"
import { useDispatch, useSelector } from "react-redux"
import { fetchInviteCodeDataStart } from "../../redux/invite/invite.actions"
import {
  selectInviteData,
  selectInviteIsLoading,
} from "../../redux/invite/invite.selectors"
import SpinnerComponent from "../commons/SpinnerComponent"
import { signUpWithInviteStart } from "../../redux/user/user.actions"
import { selectCurrentUser } from "../../redux/user/user.selectors"
import { MAIN_ROUTES, PUBLIC_ROUTES } from "../../constants/routes"

// const password = value =>
//   value &&
//   !/^(?=(.*[0-9]))(?=.*[\!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?])(?=(.*)).{6,}$/i.test(
//     value
//   )
//     ? "Invalid password. Your password must be 6 characters long, contain one special character and a number."
//     : undefined

// const email = value =>
//   value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
//     ? "Invalid email address"
//     : undefined

const InviteSignup = () => {
  const { inviteId } = useParams()
  const passwordRef = useRef()
  const passwordConfirmRef = useRef()
  const firstNameRef = useRef()
  const lastNameRef = useRef()
  const [error, setError] = useState("")
  const [message, setMessage] = useState("")
  const [loading, setLoading] = useState(false)
  const currentUser = useSelector(selectCurrentUser)
  const history = useHistory()
  const dispatch = useDispatch()
  const inviteData = useSelector(selectInviteData)
  const isLoading = useSelector(selectInviteIsLoading)

  useEffect(() => {
    dispatch(fetchInviteCodeDataStart({ inviteId }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (currentUser) {
      history.push(MAIN_ROUTES.content.path)
    }
  }, [currentUser])

  const handleSubmit = e => {
    e.preventDefault()

    if (
      passwordRef.current.value &&
      !/^(?=(.*[0-9]))(?=(.*)).{6,}$/i.test(passwordRef.current.value)
    ) {
      return setError(
        "Your password must be at least 6 characters long and contain a number."
      )
    }
    if (passwordRef.current.value.length > 127) {
      return setError({ success: false, text: "Password to long" })
    }

    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError("Passwords do not match")
    }

    setMessage("")
    setError("")
    setLoading(true)
    dispatch(
      signUpWithInviteStart({
        email: inviteData.email,
        password: passwordRef.current.value,
        role: inviteData.role,
        accountId: inviteData.account,
        inviteId,
        firstName: firstNameRef.current.value,
        lastName: lastNameRef.current.value,
      })
    )
  }

  return (
    <CenteredContainer>
      <h1 className="mb-5 text-center logo">Reeler</h1>
      {!isLoading ? (
        <Card>
          <Card.Body>
            {inviteData || inviteData?.valid ? (
              <div>
                <h2 className="mb-4 text-center">You Are Invited</h2>
                <p>
                  You have been invited by{" "}
                  {inviteData?.invitedBy?.firstName
                    ? inviteData?.invitedBy?.firstName
                    : inviteData?.invitedBy?.email}{" "}
                  as a user on Reeler. Please create your account to login.
                </p>
              </div>
            ) : (
              <h2 className="mb-4 text-center text-danger">
                Invalid Invite Code
              </h2>
            )}
            {error && (
              <Alert style={{ textAlign: "center" }} variant="danger">
                {error}
              </Alert>
            )}
            {message && (
              <Alert style={{ textAlign: "center" }} variant="success">
                {message}
              </Alert>
            )}
            <Form onSubmit={handleSubmit}>
              <Form.Group id="name">
                <Form.Label>Name</Form.Label>
                <Row>
                  <Col>
                    <Form.Control
                      type="text"
                      ref={firstNameRef}
                      placeholder="First name"
                    />
                    <Form.Text muted>Optional</Form.Text>
                  </Col>
                  <Col>
                    <Form.Control
                      type="text"
                      ref={lastNameRef}
                      placeholder="Last name"
                    />
                    <Form.Text muted>Optional</Form.Text>
                  </Col>
                </Row>
              </Form.Group>

              <Form.Group id="password">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  ref={passwordRef}
                  required
                  disabled={!inviteData || !inviteData?.valid}
                />
                <Form.Text>
                  At least 6 characters long and contain a number.
                </Form.Text>
              </Form.Group>
              <Form.Group id="password-confirm">
                <Form.Label>Password Confirmation</Form.Label>
                <Form.Control
                  type="password"
                  ref={passwordConfirmRef}
                  required
                  disabled={!inviteData || !inviteData?.valid}
                />
              </Form.Group>
              <Button
                disabled={loading || !inviteData || !inviteData?.valid}
                className="btn-main w-100"
                type="submit"
              >
                Create account and login
              </Button>
            </Form>
          </Card.Body>
        </Card>
      ) : (
        <SpinnerComponent />
      )}
      <div className="mt-2 text-center w-100">
        Already have an account?{" "}
        <Link className="link" to={PUBLIC_ROUTES.login.path}>
          Log In
        </Link>
      </div>
    </CenteredContainer>
  )
}

export default InviteSignup
