import React from "react"
import { LoaderContainer, Dots } from "./styles"

const Loader = () => {
  return (
    <LoaderContainer>
      <Dots>
        <div></div>
        <div></div>
        <div></div>
      </Dots>
    </LoaderContainer>
  )
}

export default Loader
