import React from "react"
import ColorPicker from "./ColorPicker"
import { FaBan } from "react-icons/fa"
import { MdFormatColorFill } from "react-icons/md"
import ReelerTooltip from "./ReelerTooltip/ReelerTooltip"

export default function ColorPicker2({
  selectColor,
  clearColor,
  defaultColor,
  text,
}) {
  const ClearColor = ({ onClick }) => (
    <ReelerTooltip text="Clear color">
      <FaBan className="me-1 icon-btn icon-color" onClick={onClick} />
    </ReelerTooltip>
  )

  return (
    <div className="d-flex flex-row align-items-center">
      {text && <div className="pe-3">{text}</div>}
      <div className="d-flex flex-row align-items-center">
        <ClearColor onClick={() => clearColor()} />
        <ColorPicker state={defaultColor} dispatch={hex => selectColor(hex)} />
      </div>
    </div>
  )
}
