import React, { useState } from "react"
import { Form, InputGroup, Row } from "react-bootstrap"
import { database } from "../../firebase"
import { v4 as uuidv4 } from "uuid"

import "../App.css"
import { useSelector, useDispatch } from "react-redux"
import { selectAccounts } from "../../redux/account/account.selectors"
import ReelerButton from "../commons/ReelerButton"
import Section from "../../layouts/core/Section"
import { Header } from "../../layouts/core/Header"
import Body from "../../layouts/core/Body"
import Title from "../../layouts/core/Title"
import BackButton from "../commons/BackButton"
import { updateAccounts } from "../../redux/account/account.actions"
import useUserFeedbackMessage from "../../hooks/useUserFeedbackMessage"
import Navbar from "./components/Navbar"
import Page from "./components/Page"
import PageSection from "./components/PageSection"
import PageSectionTitle from "./components/PageSectionTitle"

const ManageAPIView = () => {
  const dispatch = useDispatch()
  const account = useSelector(selectAccounts)
  const { setUserFeedbackMessage } = useUserFeedbackMessage()

  const [loading, setLoading] = useState(false)

  function handleGenerateAccesstoken() {
    setLoading(true)

    if (account) {
      // Update account
      const accessToken = uuidv4()
      const api = {
        api: {
          access_token: accessToken,
        },
      }
      database.accounts
        .doc(account.id)
        .update(api)
        .then(() => {
          dispatch(updateAccounts(api))

          setUserFeedbackMessage("Generated access token successfully")
          setLoading(false)
        })
        .catch(err => {
          setUserFeedbackMessage("Could not generate access token", "danger")
          setLoading(false)
        })
    }
  }

  return (
    <Section>
      <Header>
        <Title>Reeler Content API</Title>
        <div className="ms-auto">
          <BackButton />
        </div>
      </Header>
      <Body>
        <Row>
          <Navbar />

          <Page>
            <h1 className="title">Overview</h1>
            <p>
              The Reeler Content API is a collection of endpoints that allow
              Reeler account holders to retrieve subsets of their content assets
              from outside of Reeler.
            </p>

            <PageSection>
              <PageSectionTitle>API Access token</PageSectionTitle>
              <InputGroup className="mb-3">
                <Form.Control
                  disabled={account?.api?.access_token}
                  value={account?.api?.access_token}
                  aria-label="Access token"
                />

                <ReelerButton
                  text="Generate access token"
                  loading={loading}
                  disabled={account?.api?.access_token}
                  styleClass="btn-main"
                  dispatch={() => handleGenerateAccesstoken()}
                />
              </InputGroup>
              <Form.Text muted>
                An “access token” is a unique, opaque string that identifies a
                Reeler account holder. The access token is required to make
                external API calls to the Reeler Content API.
              </Form.Text>
            </PageSection>
            <PageSection>
              <PageSectionTitle>Base URL</PageSectionTitle>

              <Form.Control
                disabled={true}
                value="https://app.reelertech.com/api/v1.0/"
                aria-label="Base URL"
              />
              <Form.Text muted>
                All endpoints can be accessed via the
                https://app.reelertech.com/api/v1.0/ host.
              </Form.Text>
            </PageSection>
          </Page>
        </Row>
      </Body>
    </Section>
  )
}

export default ManageAPIView
