import React, { useEffect, useState } from "react"
import { Modal, Form, ModalBody, Alert, Row, Col } from "react-bootstrap"
import { database } from "../../../firebase"
import { useSelector } from "react-redux"
import { selectAccountId } from "../../../redux/account/account.selectors"
import ReelerButton from "../../commons/ReelerButton"
import withoutProperty from "../../../utils/withoutProperty"

export default function ProductModal({ existingProduct, btn }) {
  const [open, setOpen] = useState(false)
  const [product, setProduct] = useState({})

  const [msg, setMsg] = useState(null)
  const [saving, setSaving] = useState(false)
  const accountId = useSelector(selectAccountId)
  // new Product
  const newProduct = {
    name: "",
    description: "",
    price: "",
    salePrice: "",
    productID: "",
    url: "",
    sku: "",
    imageUrl: "",
    keywords: {},
  }

  function openModal() {
    setMsg(null)
    setOpen(true)
  }

  function closeModal() {
    setOpen(false)
  }

  useEffect(() => {
    if (existingProduct) {
      setProduct(existingProduct)
    } else {
      setProduct(newProduct)
    }
  }, [existingProduct])

  function handleSubmit(e) {
    e.preventDefault()
    setSaving(true)

    if (product.id) {
      // Update exisiting
      const productRef = database.accounts
        .doc(accountId)
        .collection("products")
        .doc(product.id)
      const produtWithoutId = withoutProperty(product)
      productRef
        .update(produtWithoutId)
        .then(async () => {
          // TODO: Uppdatera alla assets som har aktuell produkt med ny produktdata
          console.log("updating all assets containing product id " + product.id)
          const assetsRef = database.assets
            .where("accountId", "==", accountId)
            .where("productIds", "array-contains", product.id)

          let assetsWithProduct = []
          try {
            const querySnapshot = await assetsRef.get()
            querySnapshot.forEach(doc => {
              assetsWithProduct.push(database.formatDoc(doc))
            })
          } catch (e) {
            console.log(e)
          }

          return assetsWithProduct
        })
        .then(async assetArray => {
          console.log("Found no of assets with product: " + assetArray.length)
          const promises = assetArray.map(async a => {
            const updatedProductArray = a?.products.map(p => {
              if (p.id === product.id) {
                return product
              } else {
                return p
              }
            })

            const assetRef = database.assets.doc(a.id)

            return assetRef
              .update({ products: updatedProductArray })
              .catch(err => console.log(err))
          })
          await Promise.all(promises).catch(err => console.log(err))
          console.log("updated all assets")
          return true
        })
        .then(() => {
          setMsg({
            text: "Successfully updated a new product",
            variant: "success",
          })
          setSaving(false)
        })
        .catch(err => {
          console.log(err)
          setMsg({ text: "Could not save the product", variant: "danger" })
          setSaving(false)
        })
    } else {
      // Add new
      const productRef = database.accounts.doc(accountId).collection("products")
      productRef
        .add({ ...product, createdAt: database.getCurrentTimestamp() })
        .then(doc => {
          setMsg({
            text: "Successfully added a new product",
            variant: "success",
          })
          setSaving(false)
          setProduct(newProduct)
        })
        .catch(err => {
          console.log(err)
          setMsg({ text: "Could not add a new product", variant: "danger" })
          setSaving(false)
        })
    }

    console.log(product)
  }
  return (
    <>
      <div style={{ cursor: "pointer" }}>
        <div onClick={openModal}>{btn}</div>
      </div>

      <Modal show={open} onHide={closeModal}>
        <ModalBody>
          <Form onSubmit={handleSubmit}>
            <Modal.Title>Product</Modal.Title>
            <Modal.Body>
              {msg ? <Alert variant={msg.variant}>{msg.text}</Alert> : null}
              <Form.Group>
                <Form.Label>Product name</Form.Label>
                <Form.Control
                  required
                  onChange={e =>
                    setProduct({ ...product, name: e.target.value })
                  }
                  value={product?.name}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Product description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  onChange={e =>
                    setProduct({ ...product, description: e.target.value })
                  }
                  value={product?.description}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>Product link URL</Form.Label>
                <Form.Control
                  onChange={e =>
                    setProduct({ ...product, url: e.target.value })
                  }
                  value={product?.url}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Product image URL</Form.Label>
                <Form.Control
                  onChange={e =>
                    setProduct({ ...product, imageUrl: e.target.value })
                  }
                  value={product?.imageUrl}
                />
              </Form.Group>
              {/* <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Price</Form.Label>
                    <Form.Control
                      onChange={e =>
                        setProduct({ ...product, price: e.target.value })
                      }
                      value={product?.price}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Special offer</Form.Label>
                    <Form.Control
                      onChange={e =>
                        setProduct({ ...product, offer: e.target.value })
                      }
                      value={product?.offer}
                    />
                  </Form.Group>
                </Col>
              </Row> */}
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Product item / SKU</Form.Label>
                    <Form.Control
                      onChange={e =>
                        setProduct({ ...product, sku: e.target.value })
                      }
                      value={product?.sku}
                    />
                    <Form.Text muted>
                      (Optional) Product item number or Stock Keeping Unit
                    </Form.Text>
                  </Form.Group>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <ReelerButton
                text="Close"
                styleClass="btn-secondary"
                dispatch={closeModal}
              />
              <ReelerButton
                text="Save"
                styleClass="btn-main"
                loading={saving}
                type="submit"
              />
            </Modal.Footer>
          </Form>
        </ModalBody>
      </Modal>
    </>
  )
}
