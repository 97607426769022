import React from "react"
// 3rd-party libraries
import { Form } from "react-bootstrap"
import parse from "html-react-parser"

import * as S from "../../styles"
import { selectFormSchema } from "../../../../../redux/campaign/campaign.selectors"
import { useSelector } from "react-redux"

export default function TermsWidget({
  fieldId,
  field,
  refArray,
  disabled = false,
  design,
}) {
  return (
    <Form.Group style={{ marginBottom: "0 !important" }}>
      <div className="d-flex flex-row form-check">
        <input
          ref={el =>
            (refArray.current = { ...refArray.current, [fieldId]: el })
          }
          required={field?.required}
          className="form-check-input"
          key={fieldId}
          id={fieldId}
          name={fieldId}
          type="checkbox"
          disabled={disabled}
        />
        <label
          htmlFor={fieldId}
          className="form-check-label"
          style={{
            color: design?.termsColor ? design?.termsColor : "inherit",
          }}
        >
          <small style={{ fontSize: "12px" }}>
            {field.required ? <S.RequiredMark>* </S.RequiredMark> : null}
            {parse(field.terms)}
          </small>
        </label>
      </div>
    </Form.Group>
  )
}
