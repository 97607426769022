const ImportActionsTypes = {
  ADD_MEDIA: "ADD_MEDIA",
  ADD_SELECTED_MEDIA: "ADD_SELECTED_MEDIA",
  REMOVE_SELECTED_MEDIA: "REMOVE_SELECTED_MEDIA",
  UPDATE_SETTINGS: "UPDATE_SETTINGS",
  ADD_ERROR_MEDIA: "ADD_ERROR_MEDIA",
  UPDATE_SELECTED_MEDIA_FILE: "UPDATE_SELECTED_MEDIA_FILE",
  RESET_IMPORT: "RESET_IMPORT",
}
export default ImportActionsTypes
