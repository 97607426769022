import React from "react"

import Section from "../../../layouts/core/Section"
import { Header } from "../../../layouts/core/Header"
import Title from "../../../layouts/core/Title"
import Body from "../../../layouts/core/Body"
import ManageConsentHashtags from "./ManageConsentHashtags"
import BackButton from "../../commons/BackButton"

export default function CollectConsentHashtagView() {
  return (
    <Section>
      <Header>
        <Title>Consent hashtags</Title>
        <div className="ms-auto">
          <BackButton />
        </div>
      </Header>
      <Body>
        <ManageConsentHashtags />
      </Body>
    </Section>
  )
}
