import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px;
  &:hover {
    background: var(--reeler-grey);
  }
  &:focus-within {
    border: 1px solid var(--reeler);
  }
`
export const Input = styled.input`
  border: 0px;
  background: transparent;
  width: ${({ width }) => (width ? `${width}px` : 0)};
  outline: none;
  margin-right: 0.75rem;
  color: var(--reeler-black);
  font-size: 1.2rem;
  font-weight: 500;
`

export const HiddenSpan = styled.span`
  position: absolute;
  opacity: 0;
  pointer-events: none;
  color: var(--reeler-black);
  font-size: 1.2rem;
  font-weight: 500;
`
