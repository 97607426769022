import React, { useEffect } from "react"
import { useParams } from "react-router-dom"

export default function IframeForm() {
  const { campaignId } = useParams()

  useEffect(() => {
    const script = document.createElement("script")

    script.src = process.env.REACT_APP_FIREBASE_WIDGET_URL + "/ReelerWidgets.js"
    script.async = true

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return <div id="reeler-widget" data-campaign={campaignId}></div>
}
