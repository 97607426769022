import React from "react"
import { useSelector, useDispatch } from "react-redux"

import { setCreatorFilterParameters } from "../../../../../../../redux/creators/creators.actions"
import { selectCreatorFilterParameters } from "../../../../../../../redux/creators/creators.selectors"
import { Form } from "react-bootstrap"

import { FaFilter } from "react-icons/fa"
import CreatorsListFilter from "../../../CreatorsListFilter"
import { GENDER_OPTIONS } from "../../../../../../../constants"
import ReelerTooltip from "../../../../../../../components/commons/ReelerTooltip/ReelerTooltip"

export default function CreatorsFilter() {
  const filterParameters = useSelector(selectCreatorFilterParameters)
  const dispatch = useDispatch()

  const updateFilter = (key, value) => {
    dispatch(setCreatorFilterParameters({ ...filterParameters, [key]: value }))
  }

  const handleblacklistWhitelistFilter = type => {
    if (
      filterParameters &&
      filterParameters[type] !== null &&
      filterParameters[type] === true
    ) {
      updateFilter(type, null)
      return
    }
    updateFilter(type, true)
  }

  const handleSelectGender = e => {
    const value = e.target.value
    if (value === "showAll") {
      updateFilter("gender", null)
      return
    }
    updateFilter("gender", value)
  }

  return (
    <div
      className="d-flex flex-row align-items-center mb-3 p-3"
      style={{ background: "var(--reeler-grey)" }}
    >
      <div className="d-flex flex-row align-items-center me-3">
        <FaFilter className="icon-color" />
        <div>:</div>
      </div>

      <div className="d-flex flex-row align-items-center me-3">
        <div className="me-1 w-100">Creator list:</div>
        <CreatorsListFilter />
      </div>
      <div className="d-flex flex-row align-items-center me-3">
        <div className="me-1 w-100">Gender:</div>
        <Form.Control
          as="select"
          size="sm"
          value={
            filterParameters?.gender ? filterParameters?.gender : "showAll"
          }
          onChange={handleSelectGender}
        >
          <option value="showAll">Show all</option>
          {GENDER_OPTIONS.map((option, index) => {
            return (
              <option key={index} value={option.value}>
                {option.label}
              </option>
            )
          })}
        </Form.Control>
      </div>

      <div className="d-flex flex-row align-items-center">
        <ReelerTooltip text="Whitelisted creators are those you have agreements with. Once whitelisted, their content is auto-marked “green” (approved for use).">
          <Form.Check // prettier-ignore
            type="checkbox"
            id="show-whitelisted-creators"
            className="me-3"
            label="Show only whitelisted creators"
            onChange={() => handleblacklistWhitelistFilter("whitelisted")}
            checked={filterParameters?.whitelisted}
            disabled={filterParameters?.blacklisted}
          />
        </ReelerTooltip>

        <ReelerTooltip text="Blacklisted creators are hidden, and their content is blocked from import (except via web forms)">
          <Form.Check // prettier-ignore
            type="checkbox"
            id="show-blacklisted-creators"
            label="Show only blacklisted creators"
            onChange={() => handleblacklistWhitelistFilter("blacklisted")}
            checked={filterParameters?.blacklisted}
            disabled={filterParameters?.whitelisted}
          />
        </ReelerTooltip>
      </div>
    </div>
  )
}
