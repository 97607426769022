import React, { useState } from "react"
import { database } from "../../../../firebase"
import { FaFileCsv, FaTags } from "react-icons/fa"
import ReelerTooltip from "../../../commons/ReelerTooltip/ReelerTooltip"
import SpinnerComponent from "../../../commons/SpinnerComponent"

const { Parser } = require("json2csv")

export default function ExportCSV({ campaign }) {
  const [loading, setLoading] = useState(false)
  // Modal choose fields
  // Choosr filename
  // Fetch assets
  // Option remove duplicate

  const handleDownloadCSV = async () => {
    setLoading(true)
    // Load all campaign assets
    let assetsRef = database.assets
      .where("accountId", "==", campaign.accountId)
      .where("campaignId", "==", campaign.id)
      .orderBy("createdAt", "desc")

    // Get the fields from campaign formSchema order and loop through
    const formSchema = campaign.formSchema
    let fieldIdsToBeExported = []
    let fields = []
    formSchema.order.forEach(fieldId => {
      let field = formSchema.properties[fieldId]
      if (field?.export) {
        fieldIdsToBeExported.push(fieldId)
        if (field?.dataFieldName) {
          fields.push(field.dataFieldName)
        } else if (field?.title) {
          fields.push(field.title)
        } else {
          fields.push(fieldId)
        }
      }
    })

    // Add column for tags
    fields.push("Tags")

    const opts = { fields, delimiter: ";" }

    const data = []

    await assetsRef.get().then(querySnapshot => {
      querySnapshot.forEach(doc => {
        let dataJSON = {}
        let asset = doc.data()
        fieldIdsToBeExported.forEach((fieldId, index) => {
          // check if field is thirdparty approval
          let field = formSchema.properties[fieldId]
          if (field.widget === "third_party_approval") {
            // Check if asset has thirdPartyApprovals and third_party_email
            if (asset.thirdPartyApprovals) {
              dataJSON[fields[index]] =
                asset?.thirdPartyApprovals?.third_party_email
            }
          } else {
            let assetValue = asset?.values[fieldId]
            if (assetValue) {
              if (assetValue?.values && assetValue.values.length > 0) {
                let values = ""

                assetValue.values.forEach((value, index) => {
                  values += value

                  if (index + 1 < assetValue.values.length) values += ","
                })

                dataJSON[fields[index]] = values
              } else {
                dataJSON[fields[index]] = assetValue.value
              }
            }
          }
        })

        // Add tags to column
        if (asset.tags) {
          let tags = ""
          Object.keys(asset.tags).map((tag, index) => {
            tags = tags + tag
            if (index + 1 < Object.keys(asset.tags).length) {
              tags = tags + ", "
            }
          })

          dataJSON["Tags"] = tags
        }

        // Add data object
        data.push(dataJSON)
      })
    })

    // Parse the JSON to CSV
    try {
      const parser = new Parser(opts)
      const csv = parser.parse(data)

      var fileName = campaign.campaignName
        ? campaign.campaignName + ".csv"
        : "Reeler_export.csv"

      var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" })
      if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, fileName)
      } else {
        var link = document.createElement("a")
        if (link.download !== undefined) {
          // feature detection
          // Browsers that support HTML5 download attribute
          var url = URL.createObjectURL(blob)
          link.setAttribute("href", url)
          link.setAttribute("download", fileName)
          link.style.visibility = "hidden"
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        }
        setLoading(false)
      }
    } catch (err) {
      console.error(err)
      setLoading(false)
    }
  }

  return (
    <ReelerTooltip text="Export data to CSV">
      {!loading ? (
        <FaFileCsv
          className="icon-color icon-btn"
          onClick={() => handleDownloadCSV()}
        />
      ) : (
        <SpinnerComponent />
      )}
    </ReelerTooltip>
  )
}
