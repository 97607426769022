import React, { useRef, useState } from "react"

// 3rd-party
import { InputGroup, FormControl, Button } from "react-bootstrap"
import { IoClose } from "react-icons/io5"
import CreatableSelect from "react-select/creatable"

// Redux
import { useSelector, useDispatch } from "react-redux"

import {
  selectAccountId,
  selectAccounts,
} from "../../../redux/account/account.selectors"
import { selectSettings } from "../../../redux/import-assets/import.selectors"
import { updateSettings } from "../../../redux/import-assets/import.actions"

const customStyles = {
  container: () => ({
    // none of react-select's styles are passed to <Control />
    width: `100%`,
  }),
}
export default function AssetTags() {
  const dispatch = useDispatch()
  const account = useSelector(selectAccounts)
  const settings = useSelector(selectSettings)
  const [loading, setLoading] = useState(false)
  const [tag, setTag] = useState("")

  const addTag = str => {
    setLoading(true)

    let tags = settings.tags

    str
      .toLowerCase()
      .replace(/#+/g, "")
      .split(" ")
      .forEach(tag => {
        if (tag === "") {
          return
        }
        tags[tag] = true
      })
    dispatch(updateSettings({ tags: tags }))
    setLoading(false)
  }

  const handleDeleteTag = tagToDelete => {
    setLoading(true)
    let { [tagToDelete]: unused, ...tags } = settings.tags

    dispatch(updateSettings({ tags: tags }))
  }

  return (
    <div>
      <InputGroup className="mb-3">
        <CreatableSelect
          styles={customStyles}
          theme={theme => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: "var(--reeler-o-25)",
              primary: "var(--reeler-light)",
            },
          })}
          value={tag}
          placeholder="Add tags..."
          isClearable
          onChange={(selectedOption, action) => {
            if (action.action === "clear") {
              setTag("")
            } else if (action.action === "create-option") {
              addTag(selectedOption.value?.trim().toLowerCase())
            } else {
              addTag(selectedOption.value?.trim().toLowerCase())
            }
          }}
          options={account?.tags
            ?.sort((a, b) => {
              if (a.toLowerCase() < b.toLowerCase()) {
                return -1
              }
              if (a.toLowerCase() > b.toLowerCase()) {
                return 1
              }
              return 0
            })
            ?.map(t => ({ value: t, label: t }))}
        />
      </InputGroup>
      <div>
        {settings.tags &&
          Object.keys(settings.tags).map((tag, index) => (
            <span
              key={index}
              className="ps-2 pe-2 m-2 badge badge-pill tag"
              onClick={() => handleDeleteTag(tag)}
            >
              {tag}
              <IoClose size="14px" />
            </span>
          ))}
      </div>
    </div>
  )
}
