import React from "react"

const InputCounter = ({ currentChars, maxChars }) => {
  return (
    <div>
      {currentChars < maxChars ? (
        <span>
          {currentChars}/{maxChars}
        </span>
      ) : (
        <span className="text-danger">
          {currentChars}/{maxChars}
        </span>
      )}
    </div>
  )
}

export default InputCounter
