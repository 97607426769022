import React, { useState } from "react"

import * as S from "../../styles"

export default function FieldValue({ value }) {
  const [showMore, setShowMore] = useState(false)
  return (
    <>
      <S.FieldValue showMore={showMore}>{value ? value : "n/a"}</S.FieldValue>
      {value?.length > 250 ? (
        <S.ShowMoreButtonContainer>
          <S.ShowMoreButton onClick={() => setShowMore(!showMore)}>
            {showMore ? "Show less..." : "Show more.."}
          </S.ShowMoreButton>
        </S.ShowMoreButtonContainer>
      ) : null}
    </>
  )
}
