import { database, functions } from "../firebase"

export const saveCampaign = async campaign => {
  if (!campaign) return

  let { id: value, ...campaignWithoutId } = campaign

  await database.campaigns
    .doc(campaign.id)
    .set(campaignWithoutId, { merge: true })
}

export const deleteCampaignDB = async campaignId => {
  if (!campaignId) return

  await database.campaigns.doc(campaignId).delete()
}

export const countCampaignAssets = async campaignId => {
  if (!campaignId) return
  var CountNumberOfAssetsForCampaign = functions.httpsCallable(
    "CountNumberOfAssetsForCampaign"
  )

  try {
    const { data } = await CountNumberOfAssetsForCampaign({
      campaignId: campaignId,
    })

    return data.noOfAssets
  } catch (err) {
    console.log(err)
  }
}
