import React, { useEffect, useState } from "react"
import { database } from "../../../firebase"
import { useSelector } from "react-redux"

import { Dropdown } from "react-bootstrap"
import { FaCaretDown } from "react-icons/fa"
import { selectAccountId } from "../../../redux/account/account.selectors"
import ReelerTooltip from "../../commons/ReelerTooltip/ReelerTooltip"
import { selectCurrentUser } from "../../../redux/user/user.selectors"

export default function FilterSourceOwner({ state, dispatch }) {
  const accountId = useSelector(selectAccountId)
  const user = useSelector(selectCurrentUser)

  const [users, setUsers] = useState(null)

  useEffect(() => {
    if (accountId) {
      database.users
        .where("accounts", "array-contains", accountId)
        .get()
        .then(querySnapshot => {
          let userArray = []
          querySnapshot.forEach(doc => {
            // doc.data() is never undefined for query doc snapshots
            userArray.push(database.formatDoc(doc))
            userArray.sort((a, b) => {
              if (a.email.toUpperCase() < b.email.toUpperCase()) return -1
              if (a.email.toUpperCase() > b.email.toUpperCase()) return 1
              else return 0
            })
          })
          setUsers(userArray)
        })
    }
  }, [accountId])
  // The forwardRef is important!!
  // Dropdown needs access to the DOM node in order to position the Menu
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
      className="d-flex flex-row align-items-center campaign-status"
      ref={ref}
      onClick={e => {
        e.preventDefault()
        onClick(e)
      }}
    >
      {children}
    </div>
  ))

  const handleFilterOwner = value => {
    dispatch({ ...state, owner: value })
  }

  return (
    user && (
      <Dropdown>
        <Dropdown.Toggle as={CustomToggle} id="source-owner">
          <ReelerTooltip text={"Filter source owner"}>
            <>
              <span>Owner</span>

              <FaCaretDown className="campaign-status-caret " />
            </>
          </ReelerTooltip>
        </Dropdown.Toggle>

        <Dropdown.Menu className="reeler-dropdown">
          <Dropdown.Item onClick={() => handleFilterOwner(user?.id)}>
            My sources
          </Dropdown.Item>
          {users &&
            users.map(u => {
              if (u.id !== user.id) {
                return (
                  <Dropdown.Item
                    key={u.id}
                    onClick={() => handleFilterOwner(u.id)}
                  >
                    {u.email}
                  </Dropdown.Item>
                )
              }
            })}
          <Dropdown.Item onClick={() => handleFilterOwner("showAll")}>
            Show All
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    )
  )
}
