import styled from "styled-components"

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 1rem 0;
  text-align: center;
`

export const Alert = styled.div`
  width: 100%;
  justify-content: center;
  padding: 1em;
  color: var(--reeler-black);
  background-color: ${({ variant }) => `${variant}-light)`};
  border: 1px solid ${({ variant }) => `${variant})`};
  border-radius: 0.25rem;
`
