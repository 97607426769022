const AssetActionTypes = {
  FETCH_ASSETS_START: "FETCH_ASSETS_START",
  FETCH_ASSETS_SUCCESS: "FETCH_ASSETS_SUCCESS",
  FETCH_ASSETS_FAILURE: "FETCH_ASSETS_FAILURE",
  FETCH_MORE_ASSETS_START: "FETCH_MORE_ASSETS_START",
  FETCH_CAMPAIGN_ASSETS_START: "FETCH_CAMPAIGN_ASSETS_START",
  FETCH_CAMPAIGN_ASSETS_SUCCESS: "FETCH_CAMPAIGN_ASSETS_SUCCESS",
  FETCH_CAMPAIGN_ASSETS_FAILURE: "FETCH_CAMPAIGN_ASSETS_FAILURE",
  FETCH_MORE_CAMPAIGN_ASSETS_START: "FETCH_MORE_CAMPAIGN_ASSETS_START",
  SET_ACTIVE_ASSET: "SET_ACTIVE_ASSET",
  SHOW_ASSET_INFO_MODAL: "SHOW_ASSET_INFO_MODAL",
  CLOSE_ASSET_INFO_MODAL: "CLOSE_ASSET_INFO_MODAL",
  UPDATE_ASSET: "UPDATE_ASSET",
  CLEAR_ACTIVE_ASSET: "CLEAR_ACTIVE_ASSET",
  SET_ACTIVE_SIDEBAR_TAB: "SET_ACTIVE_SIDEBAR_TAB",
  SET_SELECTED_VERSION: "SET_SELECTED_VERSION",
  CLEAR_SELECTED_VERSION: "CLEAR_SELECTED_VERSION",
  SET_VERSION_TO_VIEW: "SET_VERSION_TO_VIEW",
  SHOW_ASSET_RIGTHS_REQUEST_MODAL: "SHOW_ASSET_RIGTHS_REQUEST_MODAL",
  CLOSE_ASSET_RIGTHS_REQUEST_MODAL: "CLOSE_ASSET_RIGTHS_REQUEST_MODAL",
  SET_ASSET_RIGTHS_REQUEST: "SET_ASSET_RIGTHS_REQUEST",
  CLEAR_ASSET_RIGTHS_REQUEST: "CLEAR_ASSET_RIGTHS_REQUEST",
  RESET_TO_INITIAL_STATE: "RESET_TO_INITIAL_STATE",
}

export default AssetActionTypes
