import React, { useRef, useState } from "react"

// 3rd-party
import { InputGroup, FormControl } from "react-bootstrap"

// Firebase
import firebase from "firebase/app"
import { database } from "../../../../../../firebase"
import { timeAgo } from "../../../../../../utils/CalculateTimeAgo"

// Redux
import { useSelector, useDispatch } from "react-redux"
import { selectActiveAsset } from "../../../../../../redux/asset/asset.selectors"
import { updateAsset } from "../../../../../../redux/asset/asset.actions"

// Reeler components
import ReelerButton from "../../../../../commons/ReelerButton"
import FadeAlert from "../../../../../commons/FadeAlert/FadeAlert"
import { ModalTabHeading } from "../../styles"
import { selectCurrentUser } from "../../../../../../redux/user/user.selectors"
import AuthBasedComponent from "../../../../../authentication/AuthBasedComponent"
import { ACCOUNT_PLANS } from "../../../../../../constants/routes"

export default function AssetComments() {
  const dispatch = useDispatch()
  const asset = useSelector(selectActiveAsset)
  const user = useSelector(selectCurrentUser)
  const commentRef = useRef()
  const [comment, setComment] = useState(null)
  const [loading, setLoading] = useState(false)
  const [msg, setMsg] = useState()

  const handleNewComment = () => {
    // FIX: handle empty input
    setLoading(true)
    const newComment = {
      id: user.id,
      name:
        user.firstName || user.lastName
          ? user.firstName + " " + user.lastName
          : user.email,
      comment: comment,
      createdAt: Date.now(),
    }
    let comments
    if (asset?.comments) {
      comments = [...asset?.comments, newComment]
    } else {
      comments = [newComment]
    }

    database.assets
      .doc(asset.id)
      .update({
        comments: firebase.firestore.FieldValue.arrayUnion(newComment),
      })
      .then(
        dispatch(
          updateAsset({
            ...asset,
            comments: comments,
          })
        )
      )
      .then(() => {
        setLoading(false)
        setComment(null)
        setMsg({
          success: true,
          lead: "Success!",
          text: "Your comment was added",
        })
        commentRef.current.value = null
      })
      .catch(err => {
        setLoading(false)
        setMsg({
          success: false,
          lead: "Oops!",
          text: "Something went wrong",
        })
      })
    setMsg()
  }

  return (
    <>
      <ModalTabHeading>Comments</ModalTabHeading>
      <AuthBasedComponent
        plans={[ACCOUNT_PLANS.basic, ACCOUNT_PLANS.pro, ACCOUNT_PLANS.premium]}
      >
        <InputGroup className="d-flex flex-column mb-1">
          <FormControl
            className="w-100"
            as="textarea"
            row={3}
            size="sm"
            ref={commentRef}
            placeholder="Add a new comment..."
            aria-label="Add a new comment..."
            onChange={e => setComment(e.target.value)}
          />
        </InputGroup>
        <div className="d-flex justify-content-end">
          <ReelerButton
            text="Add"
            styleClass="btn-main mb-1"
            dispatch={() => handleNewComment()}
            size="sm"
            loading={loading}
            disabled={comment?.trim().length > 1 ? false : true}
          />
        </div>
        {msg && !msg?.success && <FadeAlert msg={msg} />}
      </AuthBasedComponent>
      <div>
        {asset.comments &&
          asset.comments
            .slice(0)
            .reverse()
            .map((comment, index) => (
              <div
                key={index}
                className={
                  comment?.id === user.id
                    ? "d-flex flex-row justify-content-end"
                    : "d-flex flex-row justify-content-start"
                }
              >
                <div
                  className="d-flex flex-column mt-1"
                  style={{
                    fontSize: "0.7rem",
                    borderRadius: "3px",
                    background:
                      comment?.id === user.id
                        ? "var(--reeler-title)"
                        : "var(--reeler-grey)",
                    color:
                      comment?.id === user.id
                        ? "#fdfdfd"
                        : "var(--reeler-icon)",
                    padding: "5px",
                    maxWidth: "75%",
                  }}
                >
                  <div className="d-flex flex-row">
                    <strong>{comment.name ? comment.name : null}</strong>

                    <span className="ms-2">{timeAgo(comment.createdAt)}</span>
                  </div>
                  <span>{comment.comment}</span>
                </div>
              </div>
            ))}
      </div>
    </>
  )
}
