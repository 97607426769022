const InstagramSearchActionsTypes = {
  ADD_SELECTED_MEDIA: "ADD_SELECTED_MEDIA",
  REMOVE_SELECTED_MEDIA: "REMOVE_SELECTED_MEDIA",
  RESET_SELECTED_MEDIA: "RESET_SELECTED_MEDIA",
  OPEN_MEDIA_OBJECT_MODAL: "OPEN_MEDIA_OBJECT_MODAL",
  CLOSE_MEDIA_OBJECT_MODAL: "CLOSE_MEDIA_OBJECT_MODAL",
  SET_ACTIVE_MEDIA_OBJECT: "SET_ACTIVE_MEDIA_OBJECT",
  CLEAR_ACTIVE_MEDIA_OBJECT: "CLEAR_ACTIVE_MEDIA_OBJECT",
}
export default InstagramSearchActionsTypes
