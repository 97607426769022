import styled from "styled-components"

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 60px;
  padding: 16px 0;
  border-bottom: 1px solid var(--reeler-grey-dark);
`
export const MenuSection = styled.div``

export const Logo = styled.span`
  display: inline-block;
  line-height: inherit;
  font-size: 32px;
  text-decoration: none;
  background-color: transparent;
  color: var(--reeler);
  font-family: "Changa One", cursive;
  cursor: pointer;

  &:hover {
    text-decoration: none;
    color: var(--reeler);
  }
`

export const Label = styled.div`
  display: flex;

  align-items: center;
  height: 32px;
  color: var(--reeler-icon);
  font-size: 11px;
  font-weight: 600;
`
export const LableMenuItem = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px 0;
`
export const MenuItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const MenuItemText = styled.span`
  font-size: 12px;
  line-height: 23px;
  font-weight: 300;
`
