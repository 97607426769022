import React, { useEffect, useState } from "react"
import axios from "axios"
import { useParams } from "react-router-dom"

import Loader from "../../commons/loader/Loader"
import { FeedWidgetContainer } from "./styles"
import RenderFeed from "./render-feed/RenderFeed"

export default function FeedWidget() {
  const { feedId } = useParams()
  const [feed, setFeed] = useState()
  const [assets, setAssets] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const fetchFeedData = async () => {
    setIsLoading(true)
    const url = `${process.env.REACT_APP_FIREBASE_API_URL}/feed/${feedId}`

    const { data } = await axios({
      url: url,
      method: "get",
    })
    return data
  }

  useEffect(() => {
    setIsLoading(true)
    fetchFeedData().then(response => {
      setFeed(response.feed)
      setAssets(response.assets)
      console.log(response)
      setIsLoading(false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return isLoading ? (
    <FeedWidgetContainer className="FeedWidgetContainer">
      <Loader />
    </FeedWidgetContainer>
  ) : (
    <FeedWidgetContainer className="FeedWidgetContainer">
      {feed && assets ? <RenderFeed feed={feed} assets={assets} /> : null}
    </FeedWidgetContainer>
  )
}
