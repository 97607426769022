import React, { useEffect, useState } from "react"
import { database } from "../../../../../../firebase"
import Skeleton from "../../../../../commons/skeleton/Skeleton"
import * as S from "./GuideTopicVideo.styles"

export default function GuideTopicVideo({ guideTopicId, guideTopicVideoId }) {
  const [guideTopicVideo, setGuideTopicVideo] = useState(null)
  const [fetchingVideo, setFetchingVideo] = useState(true)

  useEffect(() => {
    if (guideTopicVideoId) {
      database.guides
        .doc(guideTopicId)
        .collection("videos")
        .doc(guideTopicVideoId)
        .get()
        .then(doc => {
          if (doc.exists) {
            setGuideTopicVideo(database.formatDoc(doc))
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!")
          }
          setFetchingVideo(false)
        })
        .catch(error => {
          console.log("Error getting document:", error)
        })
    } else {
      database.guides
        .doc(guideTopicId)
        .collection("videos")
        .orderBy("order")
        .limit(1)
        .get()
        .then(querySnapshot => {
          let videos = []
          querySnapshot.forEach(doc => {
            // doc.data() is never undefined for query doc snapshots
            videos.push(database.formatDoc(doc))
          })
          setGuideTopicVideo(videos[0])
          setFetchingVideo(false)
        })
        .catch(error => {
          console.log("Error getting document:", error)
        })
    }
  }, [guideTopicId, guideTopicVideoId])

  if (fetchingVideo)
    return (
      <S.VideoPlayerContainer>
        <div style={{ width: "100%", height: "375px", aspectRatio: "4/3" }}>
          <Skeleton />
        </div>
        <div style={{ width: "70%", height: "20px", marginTop: "10px" }}>
          <Skeleton />
        </div>
      </S.VideoPlayerContainer>
    )

  return (
    !fetchingVideo &&
    guideTopicVideo && (
      <S.VideoPlayerContainer>
        <video
          width="100%"
          height="100%"
          src={guideTopicVideo?.video?.url}
          type="video/mp4"
          controls
          poster={
            guideTopicVideo.thumbnail
              ? process.env.REACT_APP_FIREBASE_STORAGE_BASE_URL +
                encodeURIComponent(
                  "reeler-guide-videos/thumbnails/image@500_"
                ) +
                guideTopicVideo.thumbnail?.filename +
                "?alt=media"
              : null
          }
        ></video>
        <S.VideoBody>
          {guideTopicVideo?.title ? (
            <S.VideoLabel>{guideTopicVideo.title}</S.VideoLabel>
          ) : null}
          {guideTopicVideo?.description ? (
            <S.VideoDescription>
              {guideTopicVideo.description}
            </S.VideoDescription>
          ) : null}
        </S.VideoBody>
      </S.VideoPlayerContainer>
    )
  )
}
