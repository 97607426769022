import React, { useEffect } from "react"

// 3rd-party components
import { Form, Row, Col, InputGroup } from "react-bootstrap"
import { SectionHeading } from "../../../../styles"

// Redux
import { useDispatch, useSelector } from "react-redux"
import {
  selectFeed,
  selectIsFeedDirty,
  selectGridSettings,
} from "../../../../../../redux/feed/feed.selectors"
import {
  setIsFeedDirty,
  updateFeed,
} from "../../../../../../redux/feed/feed.actions"
import UnitInputGroup from "../../../../../commons/UnitInputGroup/UnitInputGroup"

const GridSettings = () => {
  const dispatch = useDispatch()
  const feed = useSelector(selectFeed)
  const isDirty = useSelector(selectIsFeedDirty)

  const handleChange = ({ target: { name, value, type } }) => {
    if (type === "number") {
      dispatch(
        updateFeed({
          feedSettings: { ...feed.feedSettings, [name]: Number(value) },
        })
      )
    } else {
      dispatch(
        updateFeed({ feedSettings: { ...feed.feedSettings, [name]: value } })
      )
    }

    dispatch(setIsFeedDirty(true))
  }

  return (
    <>
      <SectionHeading>Grid</SectionHeading>
      <Row>
        <Col>
          <Form.Group className="mb-3" controlId="cols">
            <Form.Label>Columns</Form.Label>
            <br />
            <Form.Control
              type="number"
              min={0}
              max={10}
              value={
                feed?.feedSettings?.columns ? feed?.feedSettings?.columns : 3
              }
              name="columns"
              onChange={handleChange}
            />
            <Form.Text muted>Number of assets in a row</Form.Text>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3" controlId="rows">
            <Form.Label>Rows</Form.Label>
            <br />
            <Form.Control
              type="number"
              min={0}
              max={10}
              value={feed?.feedSettings?.rows ? feed?.feedSettings?.rows : 3}
              name="rows"
              onChange={handleChange}
            />
            <Form.Text muted>Number of rows</Form.Text>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3" controlId="gutter">
            <UnitInputGroup
              label="Spacing"
              name="gutter"
              min={0}
              max={20}
              value={feed.feedSettings.gutter ? feed.feedSettings.gutter : 0}
              dispatch={handleChange}
              unit="px"
            />

            <Form.Text muted>Spacing between assets</Form.Text>
          </Form.Group>
        </Col>
      </Row>
    </>
  )
}

export default GridSettings
