import React from "react"
import * as S from "./CloseButton.styles"
import { IoIosClose } from "react-icons/io"

export default function CloseButton({ size = "1.5rem" }) {
  return (
    <S.CloseButton>
      <IoIosClose size={size} />
    </S.CloseButton>
  )
}
