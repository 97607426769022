import React, { useState, useEffect } from "react"
import { database } from "../../../firebase"
import { useAuth } from "../../../contexts/AuthContext"
import { FaCloudDownloadAlt } from "react-icons/fa"
import { Modal, Alert } from "react-bootstrap"
import { v4 as uuidv4 } from "uuid"
import { Link } from "react-router-dom"
import parse from "html-react-parser"
import { useSelector } from "react-redux"

import SpinnerComponent from "../../commons/SpinnerComponent"
import ReelerButton from "../../commons/ReelerButton"

// Redux

import { generateFilename } from "../../../utils/GenerateFilename"
import ReelerTooltip from "../../commons/ReelerTooltip/ReelerTooltip"
import {
  CAMPAIGN_TYPE,
  DOWNLOAD_FILE_STATES,
  MEDIA_TYPE,
} from "../../../constants"
import { MAIN_ROUTES } from "../../../constants/routes"
import { selectCurrentUser } from "../../../redux/user/user.selectors"
import useUserFeedbackMessage from "../../../hooks/useUserFeedbackMessage"

export default function BulkMassDownloadAssets({ assets }) {
  const [open, setOpen] = useState(false)
  const [msg, setMsg] = useState(null)
  const [valid, setValid] = useState(true)
  const [loading, setLoading] = useState(false)
  const { account, userDetails } = useAuth()
  const { setErrorMessage } = useUserFeedbackMessage()
  const currentUser = useSelector(selectCurrentUser)

  function openModal() {
    setOpen(true)
  }

  function closeModal() {
    setOpen(false)
  }

  useEffect(() => {
    setMsg(null)
    // Check download limits
    let total_size = 0
    const size_limit = 2000000000
    const quantity_limit = 500

    if (assets.length > quantity_limit) {
      setValid(false)
      setMsg({
        type: "error",
        text: "You have selected too many assets. Please note that you can download up to 500 assets / 2GB at a time. Please remove some of the assets.",
      })

      return
    }

    assets.forEach(asset => {
      if (asset.size) {
        total_size += asset.size
      }
    })

    if (total_size > size_limit) {
      setValid(false)
      setMsg({
        type: "error",
        text: "You have selected too many assets. Please note that you can download up to 500 assets / 2GB at a time. Please remove some of the assets.",
      })

      return
    }
  }, [assets])

  const createDownloadJob = () => {
    setLoading(true)
    setMsg(null)

    // Format assets to only retrive the most important fields to minimize size
    const downloadAssets = assets.filter(asset => {
      if (asset?.media_type !== MEDIA_TYPE.TEXT) {
        let formatedAsset = { id: asset.id, type: asset.type, url: asset.url }
        if (asset.cloudinary) {
          formatedAsset["cloudinary"] = asset.cloudinary
        }

        if (asset.campaign_type === CAMPAIGN_TYPE.upload.type) {
          formatedAsset["accountId"] = asset.accountId
          formatedAsset["campaignId"] = asset.campaignId
          formatedAsset["campaign_type"] = asset.campaign_type
          formatedAsset["values"] = asset.values
          formatedAsset["extension"] = asset.extension
        }

        if (asset.assetVersions && asset.assetVersions.length) {
          formatedAsset["assetVersions"] = asset.assetVersions
        }

        if (asset.tags) {
          formatedAsset["tags"] = asset.tags
        }

        return formatedAsset
      }
    })

    if (downloadAssets?.length > 0) {
      let username = ""
      if (userDetails?.firstName) {
        username += userDetails?.firstName
      }
      if (userDetails?.lastName) {
        username += " " + userDetails?.lastName
      }

      if (username.length === 0) {
        username = "n/a"
      }
      // create a temp file to stream archive data to.
      let fileId = uuidv4()
      const filename = `Reeler_${fileId}.zip`
      const filepath = `${account.id}/download/`
      const accessToken = uuidv4()

      database.downloadJobs
        .add({
          accountId: account.id,
          createdAt: database.getCurrentTimestamp(),
          accessToken: accessToken,
          file_path: filepath,
          filename: filename,
          email: userDetails.email,
          username: username ? username : "n/a",
          status: DOWNLOAD_FILE_STATES.processing,
          assets: downloadAssets,
          userId: currentUser.id,
        })
        .then(docRef => {
          console.log("Download job has been created: " + docRef.id)
          setValid(false)

          setMsg({
            type: "success",
            text: (
              <div>
                <p>
                  <strong>Processing download</strong>
                </p>
                <p>
                  You will recieve an email when the file is ready to download.
                  You can also find a list of your recent downloads{" "}
                  <Link to={MAIN_ROUTES.download.path}>here</Link>.
                </p>
              </div>
            ),
          })
          setLoading(false)
        })
        .catch(err => {
          console.log(err)
          setLoading(false)
        })
    } else {
      setErrorMessage(
        "There are no valid assets to be downloaded. Each asset needs to have a video, image, or audio media file."
      )
      setLoading(false)
    }
  }

  const downloadOneAsset = () => {
    const asset = assets[0]
    if (asset?.media_type !== MEDIA_TYPE.TEXT) {
      let id = asset.id
      let url = asset.url
      // if we find a main version, then set its values, otherwise use old values in asset.
      if (asset?.assetVersions?.length > 0) {
        let mainVersion = asset?.assetVersions.filter(
          version => version.main === true
        )
        if (mainVersion.length > 0) {
          id = mainVersion[0].id
          url = mainVersion[0].url
        }
      }
      setLoading(true)

      let filename = generateFilename(asset)
      fetch(url)
        .then(res => res.blob())
        .then(blob => {
          const previewUrl = window.URL.createObjectURL(blob)
          const anchor = document.createElement("a")
          anchor.download = filename
          anchor.crossOrigin = "Anonymous"
          anchor.href = URL.createObjectURL(blob)
          anchor.click()

          window.URL.revokeObjectURL(previewUrl)
          setLoading(false)
        })
    } else {
      setErrorMessage("You cannot download a text based asset")
    }
  }

  const handleDownload = () => {
    if (assets?.length === 1) {
      downloadOneAsset()
    } else {
      openModal()
    }
  }

  return (
    <>
      <ReelerTooltip placement="bottom" text="Download content">
        <FaCloudDownloadAlt
          className="icon-color icon-btn"
          onClick={() => handleDownload()}
        />
      </ReelerTooltip>
      <Modal centered show={open} onHide={closeModal}>
        <Modal.Header>
          <Modal.Title>Bulk download assets</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            You are about to download {assets.length} content assets as a zip
            file.{" "}
          </p>

          <p>
            You will receive an email when the assets are ready for download.
            Please note that you can download up to 500 assets / 2GB at a time.
          </p>

          {msg ? (
            <Alert
              style={{ textAlign: "center" }}
              variant={msg.type === "error" ? "danger" : "success"}
            >
              {msg.text}
            </Alert>
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          <ReelerButton
            text="Close"
            dispatch={closeModal}
            styleClass="btn-secondary"
          />
          <ReelerButton
            text="Prepare download"
            styleClass="btn-main"
            dispatch={() => createDownloadJob()}
            loading={loading}
            disabled={!valid || assets.length === 0}
          />
        </Modal.Footer>
      </Modal>
    </>
  )
}
