import React, { useState, useRef, useEffect } from "react"

import { Dropdown } from "react-bootstrap"
import {
  draggable,
  dropTargetForElements,
} from "@atlaskit/pragmatic-drag-and-drop/element/adapter"
import {
  attachClosestEdge,
  extractClosestEdge,
} from "@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge"
import { DropIndicator } from "@atlaskit/pragmatic-drag-and-drop-react-drop-indicator/box"
import { combine } from "@atlaskit/pragmatic-drag-and-drop/combine"
import invariant from "tiny-invariant"
// 3 parties
import {
  FaEllipsisV,
  FaChevronUp,
  FaChevronDown,
  FaGripVertical,
} from "react-icons/fa"

// Redux
import { useDispatch, useSelector } from "react-redux"
import {
  changeFieldPosition,
  selectField,
  setShowEditModal,
} from "../../../../redux/campaign/campaign.actions"
import { selectFormSchema } from "../../../../redux/campaign/campaign.selectors"

// Reeler
import ReelerTooltip from "../../../commons/ReelerTooltip/ReelerTooltip"
import * as S from "../styles"

import CopyWidget from "./CopyWidget"
import RemoveWidgetFromForm from "./RemoveWidgetFromForm"
import AddNewFieldDropdown from "./AddNewFieldDropdown"
import { Draggable } from "react-beautiful-dnd"

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <S.FormFieldControl
    ref={ref}
    onClick={e => {
      e.preventDefault()
      onClick(e)
    }}
  >
    <ReelerTooltip text="More options">
      <FaEllipsisV />
    </ReelerTooltip>
  </S.FormFieldControl>
))

export default function FormField(props) {
  const { fieldId, field, children, disableHover, index } = props
  const fieldRef = useRef(null)

  const [isDragging, setIsDragging] = useState(false)
  const [active, setActive] = useState(false)
  const dispatch = useDispatch()
  const formSchema = useSelector(selectFormSchema)
  // State to track the closest edge during drag over
  const [closestEdge, setClosestEdge] = useState(null)
  const editField = () => {
    dispatch(selectField(fieldId))
    dispatch(setShowEditModal(true))
  }

  useEffect(() => {
    const element = fieldRef.current
    invariant(element)

    const fieldData = { fieldId, field, closestEdge }

    return combine(
      draggable({
        element,
        getInitialData: () => fieldData,
        onDragStart: () => setIsDragging(true),
        onDrop: () => setIsDragging(false), // NEW
      }),
      dropTargetForElements({
        element,
        canDrop({ source }) {
          // not allowing dropping on yourself
          if (source.element === element) {
            return false
          }
          // only allowing tasks to be dropped on me
          return source.data
        },
        getData({ input }) {
          return attachClosestEdge(fieldData, {
            element,
            input,
            allowedEdges: ["top", "bottom"],
          })
        },
        getIsSticky() {
          return true
        },
        onDragEnter(args) {
          const closestEdge = extractClosestEdge(args.self.data)

          if (args.source.data.fieldId !== fieldId) {
            setClosestEdge(closestEdge)
            setIsDragging(true)
          }
        },
        onDrag(args) {
          const closestEdge = extractClosestEdge(args.self.data)

          // Only need to update react state if nothing has changed.
          // Prevents re-rendering.
          if (args.source.data.fieldId !== fieldId) {
            setClosestEdge(closestEdge)
            setIsDragging(true)
          }
        },
        onDragLeave() {
          setIsDragging(false)
          setClosestEdge(null)
        },
        onDrop() {
          setIsDragging(false)
          setClosestEdge(null)
        },
      })
    )
  }, [])

  return (
    <div ref={fieldRef} style={{ position: "relative" }}>
      <S.FormFieldContainer
        data-field-id={fieldId}
        className="draggable-container"
        onMouseEnter={() => setActive(true)}
        onMouseLeave={() => setActive(false)}
        $disableHover={active && disableHover}
        selected={isDragging}
      >
        <S.FormFieldDragNDrop>
          {active ? (
            <ReelerTooltip text="Drag and drop">
              <FaGripVertical className="icon-btn" />
            </ReelerTooltip>
          ) : null}
        </S.FormFieldDragNDrop>

        <S.ChildrenContainer>{children}</S.ChildrenContainer>

        <S.FormFieldControlsContainer $active={active && !disableHover}>
          {/* <S.FormFieldControl>
             <AddNewFieldDropdown previousFieldId={fieldId} />
           </S.FormFieldControl> */}
          {/* Hide copy for upload, email & submit fields */}
          {!["submitButton", "file", "email"].includes(fieldId) ? (
            <S.FormFieldControl>
              <CopyWidget previousFieldId={fieldId} field={field} />
            </S.FormFieldControl>
          ) : null}
          {/* <S.FormFieldControl>
             <ReelerTooltip text="Move up">
               <FaChevronUp onClick={() => changePosition(-1)} />
             </ReelerTooltip>
           </S.FormFieldControl>
           <S.FormFieldControl>
             <ReelerTooltip text="Move down">
               <FaChevronDown onClick={() => changePosition(1)} />
             </ReelerTooltip>
           </S.FormFieldControl> */}
          <S.FormFieldControl>
            <ReelerTooltip text="Edit field">
              <small
                style={{ color: "var(--reeler-icon)" }}
                onClick={() => editField()}
              >
                Edit
              </small>
            </ReelerTooltip>
          </S.FormFieldControl>

          {/* Hide delete for upload & submit buttons */}
          {!["submitButton", "email"].includes(fieldId) ? (
            <Dropdown>
              <Dropdown.Toggle
                as={CustomToggle}
                id="more-options"
              ></Dropdown.Toggle>
              <Dropdown.Menu className="super-colors">
                <Dropdown.Item eventKey="2">
                  <RemoveWidgetFromForm fieldId={fieldId} />
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          ) : null}
        </S.FormFieldControlsContainer>
        {/* render the DropIndicator if there's a closest edge */}
      </S.FormFieldContainer>
      {closestEdge && <S.DropIndicator edge={closestEdge} />}
    </div>
  )
}
