import { createSelector } from "reselect"

const importAssets = state => state.importAssets

export const selectMedia = createSelector(
  [importAssets],
  importAssets => importAssets?.media
)
export const selectSelectedMedia = createSelector(
  [importAssets],
  importAssets => importAssets?.selectedMedia
)
export const selectSettings = createSelector(
  [importAssets],
  importAssets => importAssets?.settings
)
export const selectErrorMedia = createSelector(
  [importAssets],
  importAssets => importAssets?.mediaErrors
)
