import BulkEditActionTypes from "./bulkEdit.types"

export const BulkEditAddItem = payload => ({
  type: BulkEditActionTypes.ADD_ITEM_TO_BULK_EDIT,
  payload,
})
export const BulkEditAddItems = payload => ({
  type: BulkEditActionTypes.ADD_ITEMS,
  payload,
})

export const BulkEditRemoveItem = payload => ({
  type: BulkEditActionTypes.REMOVE_ITEM_FROM_BULK_EDIT,
  payload,
})

export const BulkEditReset = () => ({
  type: BulkEditActionTypes.BULK_EDIT_RESET,
})
export const SelectAll = payload => ({
  type: BulkEditActionTypes.BULK_SELECT_ALL,
  payload,
})

export const BulkEditActionSuccess = payload => ({
  type: BulkEditActionTypes.BULK_EDIT_ACTION_SUCCESS,
  payload,
})

export const BulkEditMessageReset = () => ({
  type: BulkEditActionTypes.BULK_EDIT_MESSAGE_RESET,
})
