import React, { useState } from "react"
import CreatableSelect from "react-select/creatable"
import { capitalize } from "lodash"
import { useDispatch, useSelector } from "react-redux"
import { selectAccounts } from "../../../redux/account/account.selectors"
import { Form, InputGroup } from "react-bootstrap"
import { database } from "../../../firebase"
import firebase from "firebase/app"
import { updateAccounts } from "../../../redux/account/account.actions"

export default function SelectCreatorList({ value, handleOnChange }) {
  const [loading, setLoading] = useState(false)
  const account = useSelector(selectAccounts)
  const dispatch = useDispatch()

  const formatOption = l => ({
    label: capitalize(l),
    value: l,
  })

  const handleNewList = list => {
    setLoading(true)
    const newList = list.toLowerCase().replace(/\W/g, "")
    database.accounts
      .doc(account.id)
      .update({
        creatorLists: firebase.firestore.FieldValue.arrayUnion(newList),
      })
      .then(() => {
        dispatch(
          updateAccounts({
            creatorLists: account?.creatorLists
              ? [...account?.creatorLists, newList]
              : [newList],
          })
        )

        handleOnChange([...value, newList])
        setLoading(false)
      })
  }

  return (
    <InputGroup className="mb-3">
      <CreatableSelect
        styles={{
          container: () => ({
            // none of react-select's styles are passed to <Control />
            width: `100%`,
          }),
        }}
        isMulti
        isDisabled={loading}
        theme={theme => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: "var(--reeler-o-25)",
            primary: "var(--reeler-light)",
          },
        })}
        placeholder="Add a list..."
        createOptionPosition="first"
        formatCreateLabel={list => `Add and save "${list}" as a standard list`}
        value={value.map(l => formatOption(l))}
        onChange={newValue => handleOnChange(newValue.map(l => l.value))}
        onCreateOption={handleNewList}
        options={account?.creatorLists
          ?.sort((a, b) => {
            if (a.toLowerCase() < b.toLowerCase()) {
              return -1
            }
            if (a.toLowerCase() > b.toLowerCase()) {
              return 1
            }
            return 0
          })
          ?.map(l => formatOption(l))}
      />
    </InputGroup>
  )
}
