import React from "react"
import { useParams, Link, useHistory } from "react-router-dom"

import { Row, Col } from "react-bootstrap"
import GuideTopicVideoList from "./components/GuideTopicVideoList/GuideTopicVideoList"
import GuideTopicVideo from "./components/GuideTopicVideo/GuideTopicVideo"
import Section from "../../../../layouts/core/Section"
import {
  Header,
  HeaderGroup,
  HeaderGroupLeft,
  HeaderGroupRight,
} from "../../../../layouts/core/Header"
import Title from "../../../../layouts/core/Title"
import Body from "../../../../layouts/core/Body"
import { MAIN_ROUTES } from "../../../../constants/routes"
import ReelerButton from "../../../commons/ReelerButton"

export default function GuideTopic() {
  const { guideTopicId, guideTopicVideoId } = useParams()
  const history = useHistory()

  return (
    <Section>
      <Header>
        <HeaderGroup>
          <HeaderGroupLeft>
            <Title>Reeler Guides </Title>
          </HeaderGroupLeft>
          <HeaderGroupRight>
            <div className="me-2">
              <ReelerButton
                text="All guides"
                styleClass="btn-secondary"
                dispatch={() => history.push(MAIN_ROUTES.help.path)}
              />
            </div>
          </HeaderGroupRight>
        </HeaderGroup>
      </Header>
      <Body>
        <Row>
          <Col md="8 d-flex justify-content-end">
            <div className="d-flex flex-column">
              {guideTopicId && guideTopicVideoId ? (
                <GuideTopicVideo
                  guideTopicId={guideTopicId}
                  guideTopicVideoId={guideTopicVideoId}
                />
              ) : guideTopicId ? (
                <GuideTopicVideo
                  guideTopicId={guideTopicId}
                  guideTopicVideoId={null}
                />
              ) : (
                <div>No guide topic selected </div>
              )}
            </div>
          </Col>
          <Col md="4">
            <GuideTopicVideoList
              guideTopicId={guideTopicId}
              layout="vertical"
            />
          </Col>
        </Row>
      </Body>
    </Section>
  )
}
