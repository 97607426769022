import styled from "styled-components"

export const VideoPlayerContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 640px;
  margin-left: 1rem;
`
export const VideoBody = styled.div`
  margin: 1rem 0;
`
export const VideoLabel = styled.div`
  font-size: 18px;
  font-weight: 600;
`
export const VideoDescription = styled.div`
  font-size: 12px;
  line-height: 1.7rem;
`
