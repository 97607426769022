import React, { useState } from "react"

// 3rd-party libraries
import { useHistory } from "react-router-dom"

// Redux
import { useSelector, useDispatch } from "react-redux"
import { database } from "../../../../../firebase"
import { initiateFeed } from "../../../../../redux/feed/feed.actions"
import { selectFeed } from "../../../../../redux/feed/feed.selectors"

// 3rd-party components
import { Row, Modal, ModalBody } from "react-bootstrap"
import { FaTrashAlt } from "react-icons/fa"

// Reeler components
import ReelerButton from "../../../../commons/ReelerButton"
import ReelerIconButton from "../../../../commons/reelerIconButton/ReelerIconButton"

import { MAIN_ROUTES } from "../../../../../constants/routes"
import useUserFeedbackMessage from "../../../../../hooks/useUserFeedbackMessage"

const DeleteFeed = () => {
  const { setSuccessMessage, setErrorMessage } = useUserFeedbackMessage()
  const [isDeleting, setIsDeleting] = useState(false)
  const [open, setOpen] = useState(false)
  const handleClose = () => setOpen(false)
  const handleOpen = () => setOpen(true)

  // states

  const history = useHistory()

  const feed = useSelector(selectFeed)

  const dispatch = useDispatch()

  const handleDeleteFeed = feedId => {
    setIsDeleting(true)
    database.feeds
      .doc(feedId)
      .delete()
      .then(() => {
        dispatch(initiateFeed())
        setIsDeleting(false)
        setSuccessMessage("Feed deleted successfully")
        history.push(MAIN_ROUTES.feeds.path)
      })
      .catch(err => {
        console.error(err)
        setErrorMessage("Error deleting feed")
      })
  }

  return (
    <>
      <ReelerIconButton
        icon={<FaTrashAlt />}
        dispatch={handleOpen}
        text="Delete feed"
        styleClass="btn-delete me-2"
      />

      {/* MODAL */}
      <Modal centered show={open} onHide={handleClose}>
        <ModalBody>
          <Modal.Title>Are you sure you want to delete this feed?</Modal.Title>
          <Modal.Body>
            If you delete this feed you cannot recreate it.
          </Modal.Body>
          <Modal.Footer>
            <ReelerButton
              text="Close"
              styleClass="btn-secondary"
              dispatch={handleClose}
            />
            <ReelerIconButton
              icon={<FaTrashAlt className="me-1" />}
              dispatch={e => handleDeleteFeed(feed?.id)}
              text="Delete feed"
              styleClass="btn-delete"
            />
          </Modal.Footer>
        </ModalBody>
      </Modal>
    </>
  )
}

export default DeleteFeed
