import React, { useState, useEffect } from "react"
import { FaChevronUp, FaChevronDown } from "react-icons/fa"
export default function ReelerAccordation({ visible = true, title, children }) {
  const [open, setOpen] = useState(visible)

  useEffect(() => {
    setOpen(visible)
  }, [visible])

  return (
    <>
      <div
        className="d-flex flex-row justify-content-between align-items-center icon-btn "
        onClick={() => setOpen(!open)}
      >
        {title}
        <span className="p-2">
          {open ? (
            <FaChevronUp className="icon-color" />
          ) : (
            <FaChevronDown className="icon-color" />
          )}
        </span>
      </div>
      {open ? children : null}
    </>
  )
}
