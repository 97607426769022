import React, { useState, useCallback } from "react"
import { useEffect } from "react"
import { useSelector } from "react-redux"

import { functions } from "../../../firebase"
import SpinnerComponent from "../../commons/SpinnerComponent"
import { selectAccounts } from "../../../redux/account/account.selectors"
import ReelerBadge from "../ReelerBadge"

export default function CountAssetsInFilter({ filter }) {
  const account = useSelector(selectAccounts)
  const [loading, setLoading] = useState(true)
  const [assets, setAssets] = useState(0)

  useEffect(() => {
    if (filter && account) {
      let isMounted = true

      const countNumberOfAssetsInFilter = async () => {
        var countNumberOfAssetsInFilter = functions.httpsCallable(
          "countNumberOfAssetsInFilter"
        )

        try {
          const { data } = await countNumberOfAssetsInFilter({
            filter,
            accountId: account.id,
          })
          if (isMounted) {
            setAssets(data.noOfAssets)
            setLoading(false)
          }
        } catch (err) {
          setLoading(false)
          console.log(err)
        }
      }

      countNumberOfAssetsInFilter()
      return () => {
        isMounted = false
      }
    }
  }, [])

  if (loading) return <SpinnerComponent size="sm" />

  return <ReelerBadge value={assets} />
}
