import React, { useState, useEffect } from "react"
import { Modal } from "react-bootstrap"
import ReelerButton from "../commons/ReelerButton"
import { database } from "../../firebase"
import { useSelector, useDispatch } from "react-redux"
import { selectCurrentVersion } from "../../redux/platform/platform.selectors"
import { setCurrentPlatformVersion } from "../../redux/platform/platform.actions"

export default function ForceBrowserReload() {
  const [show, setShow] = useState(false)
  const currentVersion = useSelector(selectCurrentVersion)
  const dispatch = useDispatch()

  useEffect(() => {
    database.platform.doc("reeler_version").onSnapshot(doc => {
      const latestVersion = doc.data().version
      if (currentVersion && currentVersion !== latestVersion) {
        dispatch(setCurrentPlatformVersion(latestVersion))

        setShow(true)
      } else {
        dispatch(setCurrentPlatformVersion(latestVersion))
      }
    })
  }, [])

  const closeModal = () => {
    setShow(false)
  }

  const handleReloadBrowserWindow = () => {
    window.location.reload()
  }

  return (
    <Modal centered show={show} onHide={closeModal}>
      <Modal.Header>
        <Modal.Title>A new version of Reeler is available.</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Please refresh your browser to get the latest update.</p>
        <p>
          We recommend refreshing your browser as soon as possible to avoid any
          disruptions.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <ReelerButton
          text="Reload manually later"
          dispatch={closeModal}
          styleClass="btn-secondary"
        />
        <ReelerButton
          text="Reload browser window now"
          styleClass="btn-main"
          dispatch={() => handleReloadBrowserWindow()}
        />
      </Modal.Footer>
    </Modal>
  )
}
