import styled, { css } from "styled-components"

export const MediaObject = styled.div`
  user-select: none;
  position: relative;
  cursor: pointer;
  border: ${({ selected }) =>
    selected
      ? "3px solid var(--reeler) !important"
      : "1px solid #dee2e6 !important"};
  /* box-shadow: ${({ selected }) =>
    selected ? "0 0 0 0.2rem var(--reeler-o-25) !important;" : "none"}; */

  background: var(--reeler-white);
`

export const Media = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1/1;
  max-height: 500px;
  padding: 0.5rem;
  margin-bottom: -0.5rem;
`
export const Body = styled.div`
  padding: 0.5rem;
`

const truncatedCaption = css`
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
`
export const Caption = styled.div`
  margin-bottom: 5px;
  ${({ showMore }) => (!showMore ? truncatedCaption : "")};
`
