import ThirdPartyApprovalActionTypes from "./third-party-approval.types"

export const uncheckAll = () => ({
  type: ThirdPartyApprovalActionTypes.UNCHECK_ALL,
})

export const setApprovedAssets = payload => ({
  type: ThirdPartyApprovalActionTypes.SET_APPROVED_ASSETS,
  payload: payload,
})
export const addAsset = payload => ({
  type: ThirdPartyApprovalActionTypes.ADD_APPROVED_ASSET,
  payload: payload,
})
export const removeAsset = payload => ({
  type: ThirdPartyApprovalActionTypes.REMOVE_APPROVED_ASSET,
  payload: payload,
})
