import React from "react"
import { LinkContainer, Content } from "./styles"

const IconLink = ({ url, text = "", icon, styleClass }) => {
  return (
    <LinkContainer to={url} className={styleClass}>
      <Content>
        {icon}
        {text}
      </Content>
    </LinkContainer>
  )
}

export default IconLink
