import React from "react"
import { database } from "../../../../firebase"
import { FaFileCsv } from "react-icons/fa"
import ReelerTooltip from "../../../commons/ReelerTooltip/ReelerTooltip"
const { Parser } = require("json2csv")

const fields = [
  "name",
  "firstName",
  "lastName",
  "email",
  "phone",
  "street",
  "postalCode",
  "city",
  "state",
  "facebook",
  "instagram",
  "youtube",
  "titleOfTheWork",
  "campaign_type",
  "media_type",
  "status",
  "contentType",
  "contentLocation",
  "description",
]
const opts = { fields }

export default function CampaignDownloadCSV({ campaign }) {
  // Modal choose fields
  // Choosr filename
  // Fetch assets
  // Option remove duplicate

  const handleDownloadCSV = async () => {
    // Load all campaign assets
    let assetsRef = database.assets
      .where("accountId", "==", campaign.accountId)
      .where("campaignId", "==", campaign.id)
      .orderBy("createdAt", "desc")

    let formattedDocs = await assetsRef.get().then(querySnapshot => {
      let docs = []
      querySnapshot.forEach(doc => {
        // doc.data() is never undefined for query doc snapshots
        docs.push(doc.data())
      })
      return docs
    })

    // Parse the JSON to CSV
    try {
      const parser = new Parser(opts)
      const csv = parser.parse(formattedDocs)

      var fileName = campaign.campaignName
        ? campaign.campaignName + ".csv"
        : "Reeler_export.csv"

      var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" })
      if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, fileName)
      } else {
        var link = document.createElement("a")
        if (link.download !== undefined) {
          // feature detection
          // Browsers that support HTML5 download attribute
          var url = URL.createObjectURL(blob)
          link.setAttribute("href", url)
          link.setAttribute("download", fileName)
          link.style.visibility = "hidden"
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        }
      }
    } catch (err) {
     
    }
  }

  return (
    <ReelerTooltip text="Export data to CSV">
      <FaFileCsv
        className="icon-color icon-btn"
        onClick={() => handleDownloadCSV()}
      />
    </ReelerTooltip>
  )
}
