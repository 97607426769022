import React, { useState, useEffect } from "react"
import { Alert, Form, Modal } from "react-bootstrap"
import { database, firestore } from "../../../firebase"
import _ from "lodash"
import { useHistory } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { selectCampaign } from "../../../redux/campaign/campaign.selectors"
import {
  deleteCampaign,
  updateCampaignsWithCampaign,
} from "../../../redux/campaign/campaign.actions"
import ReelerTooltip from "../../commons/ReelerTooltip/ReelerTooltip"
import ReelerButton from "../../commons/ReelerButton"
import { ASSET_STATE, CAMPAIGN_STATUS } from "../../../constants"
import { MAIN_ROUTES } from "../../../constants/routes"
import {
  countCampaignAssets,
  deleteCampaignDB,
} from "../../../services/CampaignsService"
import SpinnerComponent from "../../commons/SpinnerComponent"

const DELETE_OPTIONS = {
  keepAll: "keep-all",
  keepApproved: "keep-approved",
  deleteAll: "delete-all",
}

export default function DeleteCampaign() {
  const history = useHistory()
  const dispatch = useDispatch()
  const [deleteOption, setDeleteOption] = useState(DELETE_OPTIONS.deleteAll)
  const [open, setOpen] = useState(false)
  const [confirmed, setConfirmed] = useState(false)
  const campaign = useSelector(selectCampaign)
  const [numberOfSourceAssets, setNumberOfSourceAsset] = useState(0)
  const [numberOfDeletedAssets, setNumberOfDeletedAssets] = useState(0)
  const [isDeleting, setIsDeleting] = useState(false)
  const [loading, setLoading] = useState(false)

  function openModal() {
    setOpen(true)
  }

  function closeModal() {
    setOpen(false)
  }

  useEffect(() => {
    if (campaign?.id) {
      setLoading(true)

      countCampaignAssets(campaign.id)
        .then(noOfAssets => {
          setNumberOfSourceAsset(noOfAssets)
          setLoading(false)
        })
        .catch(err => {
          console.log(err)
          setLoading(false)
        })
    }
  }, [campaign?.id])

  const deleteAssets = async keepApproved => {
    const batchSize = 250 // Antal dokument att hantera per batch

    let lastDoc = null
    let hasMore = true

    while (hasMore) {
      // Hämta en batch av dokument
      let assetsRef = database.assets
        .where("accountId", "==", campaign.accountId)
        .where("campaignId", "==", campaign.id)

      if (keepApproved) {
        assetsRef = assetsRef.where("state", "!=", ASSET_STATE.approved)
      }

      if (lastDoc) {
        assetsRef = assetsRef.startAfter(lastDoc)
      }

      const snapshot = await assetsRef.limit(batchSize).get()

      if (snapshot.empty) {
        hasMore = false
        break
      }

      let assetsToDelete = []

      snapshot.forEach(doc => {
        // doc.data() is never undefined for query doc snapshots
        let asset = database.formatDoc(doc)

        assetsToDelete.push(asset)
      })

      const batchPromises = _.chunk(assetsToDelete, 125).map(assetDocs => {
        const batch = firestore.batch()
        assetDocs.forEach(asset => {
          let originalAssetRef = database.assets.doc(asset.id)
          let newDeletedAssetRef = database.deletedAssets.doc(asset.id)

          let deletedAsset = {
            ...asset,
            deletedAt: database.getCurrentTimestamp(),
          }

          batch.set(newDeletedAssetRef, deletedAsset)
          batch.delete(originalAssetRef)
        })
        return batch.commit()
      })

      await Promise.all(batchPromises)

      // Uppdatera status
      setNumberOfDeletedAssets(prev => prev + snapshot.docs.length)

      // Sätt nästa startpunkt
      lastDoc = snapshot.docs[snapshot.docs.length - 1]

      // Delay för att inte överbelasta Firebase
      await new Promise(resolve => setTimeout(resolve, 300))
    }
  }

  const handleDeleteContentSource = async () => {
    if (!confirmed) return

    setIsDeleting(true)

    if (deleteOption === DELETE_OPTIONS.keepAll) {
      // Case 1, keep all assets, onbly delete source
      handleDeleteCampaign()
    } else {
      // Case 2, delete assets
      deleteAssets(deleteOption === DELETE_OPTIONS.keepApproved)
        .then(() => {
          handleDeleteCampaign()
        })
        .catch(err => {
          console.log(err)
          setIsDeleting(false)
        })
    }
  }

  const handleDeleteCampaign = async () => {
    if (deleteOption === DELETE_OPTIONS.deleteAll) {
      await deleteCampaignDB(campaign.id)
      dispatch(deleteCampaign(campaign.id))
    } else {
      // If we are keeping assets, we do not want to delete the souce, just make the sourrce inactive
      await database.campaigns.doc(campaign.id).update({
        status: CAMPAIGN_STATUS.inactive,
      })
      dispatch(
        updateCampaignsWithCampaign({
          ...campaign,
          status: CAMPAIGN_STATUS.inactive,
        })
      )
    }

    setIsDeleting(false)
    closeModal()
    history.push(MAIN_ROUTES.campaigns.path)
  }

  return (
    <>
      <ReelerTooltip text={"Delete content source"}>
        <ReelerButton
          text="Delete"
          styleClass="btn-delete"
          dispatch={() => openModal()}
        />
      </ReelerTooltip>

      <Modal size="lg" centered show={open} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            Are you sure you want to delete this content source and its
            associated content?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isDeleting ? (
            <>
              <Alert variant="warning">
                <Alert.Heading>Attention</Alert.Heading>
                <p>
                  Please remain on this page until the process is complete to
                  avoid interrupting the deletion.
                </p>
              </Alert>
              <div className="mt-3">
                <p>
                  {`Deleting assets. Deleted ${numberOfDeletedAssets} assets of ${numberOfSourceAssets}`}
                </p>
              </div>
            </>
          ) : loading ? (
            <div className="d-flex flex-row align-items-center">
              <p>Loading...</p>
            </div>
          ) : (
            <>
              <p>
                This content source has <strong>{numberOfSourceAssets}</strong>{" "}
                associated assets.
              </p>
              <p>Please choose how you would like to proceed:</p>
              <Form.Group className="mb-3">
                <Form.Check
                  type="radio"
                  id={`delete-all`}
                  label={
                    <div className="flex flex-row">
                      <strong className="me-2">
                        Delete the source and all associated assets, including
                        approved ones.
                      </strong>
                    </div>
                  }
                  onChange={() => setDeleteOption(DELETE_OPTIONS.deleteAll)}
                  checked={deleteOption === DELETE_OPTIONS.deleteAll}
                />
                <Form.Check
                  type="radio"
                  id={`keep-approved`}
                  label={
                    <div className="flex flex-row">
                      <strong className="me-2">
                        Pause the source and keep only approved assets.
                      </strong>
                      <span>All other assets will be deleted.</span>
                    </div>
                  }
                  onChange={() => setDeleteOption(DELETE_OPTIONS.keepApproved)}
                  checked={deleteOption === DELETE_OPTIONS.keepApproved}
                />
                <Form.Check
                  type="radio"
                  id={`keep-all`}
                  label={
                    <div className="flex flex-row">
                      <strong className="me-2">
                        Pause the source and keep all associated assets.
                      </strong>
                    </div>
                  }
                  onChange={() => setDeleteOption(DELETE_OPTIONS.keepAll)}
                  checked={deleteOption === DELETE_OPTIONS.keepAll}
                />
              </Form.Group>
              <p>
                A paused source will stop collecting new content, but will
                remain listed under “All sources”.
              </p>
              <p>Confirm your choice by typing yes in the field below.</p>
              <Form.Group controlId="url-link">
                <Form.Control
                  type="text"
                  required
                  placeholder="Type yes"
                  onChange={e =>
                    e.target.value === "yes"
                      ? setConfirmed(true)
                      : setConfirmed(false)
                  }
                />
              </Form.Group>
            </>
          )}
        </Modal.Body>

        <Modal.Footer>
          <ReelerButton
            text="Close"
            dispatch={closeModal}
            styleClass="btn-secondary"
          />
          <ReelerButton
            text="Delete content source"
            styleClass="btn-delete"
            dispatch={() => handleDeleteContentSource()}
            loading={isDeleting}
            disabled={!confirmed || loading || isDeleting}
          />
        </Modal.Footer>
      </Modal>
    </>
  )
}
