import PlatformActionTypes from "./platform.types"

const INITIAL_STATE = {
  currentVersion: null,
}

const platformReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PlatformActionTypes.SET_CURRENT_VERSION:
      return {
        ...state,
        currentVersion: action.payload,
      }

    default:
      return state
  }
}

export default platformReducer
