import React, { useState } from "react"
import { useEffect } from "react"
import { functions } from "../../../firebase"
import SpinnerComponent from "../../commons/SpinnerComponent"
import ReelerBadge from "../../commons/ReelerBadge"
import { countCampaignAssets } from "../../../services/CampaignsService"

export default function CampaignNumberOfAssets({ campaignId }) {
  const [loading, setLoading] = useState(true)
  const [assets, setAssets] = useState(0)

  useEffect(() => {
    if (campaignId) {
      let isMounted = true
      setLoading(true)

      countCampaignAssets(campaignId)
        .then(noOfAssets => {
          if (isMounted) {
            setAssets(noOfAssets)
            setLoading(false)
          }
        })
        .catch(err => {
          console.log(err)
          setLoading(false)
        })

      return () => {
        isMounted = false
      }
    }
  }, [campaignId])

  if (loading) return <SpinnerComponent size="sm" />

  return <ReelerBadge value={assets} />
}
