import React, { useState } from "react"

// Firebase
import { database } from "../../../../../../firebase"

// 3rd-party
import { InputGroup, Form } from "react-bootstrap"

// Redux
import { useSelector, useDispatch } from "react-redux"
import { updateAsset } from "../../../../../../redux/asset/asset.actions"
import { selectActiveAsset } from "../../../../../../redux/asset/asset.selectors"

// Reeler components
import ReelerButton from "../../../../../commons/ReelerButton"
import FadeAlert from "../../../../../commons/FadeAlert/FadeAlert"
import { useRef } from "react"
import { ModalTabHeading } from "../../styles"
import { ACCOUNT_PLANS, USER_ROLES } from "../../../../../../constants/routes"
import AuthBasedComponent from "../../../../../authentication/AuthBasedComponent"
import { selectCurrentUser } from "../../../../../../redux/user/user.selectors"

export default function AssetNotes() {
  const asset = useSelector(selectActiveAsset)
  const dispatch = useDispatch()
  const inputRef = useRef(null)
  const currentUser = useSelector(selectCurrentUser)

  const [loading, setLoading] = useState(false)
  const [msg, setMsg] = useState()

  const handleUpdateNotes = () => {
    setLoading(true)
    database.assets
      .doc(asset.id)
      .update({
        notes: inputRef.current.value,
      })
      .then(dispatch(updateAsset({ ...asset, notes: inputRef.current.value })))
      .then(() => {
        setLoading(false)
        setMsg({ success: true, lead: "Success!", text: "Your note was added" })
      })
      .catch(err => {
        setLoading(false)
        setMsg({ success: false, lead: "Oops!", text: "Something went wrong" })
      })

    setMsg()
  }

  return (
    // <ModalTabContainer>
    <>
      <ModalTabHeading>Notes</ModalTabHeading>
      <InputGroup className="mb-1">
        <Form.Control
          as="textarea"
          rows={5}
          disabled={loading || currentUser.role === USER_ROLES.viewer}
          placeholder="Leave a note"
          defaultValue={asset?.notes}
          ref={inputRef}
        />
      </InputGroup>
      <AuthBasedComponent
        plans={[ACCOUNT_PLANS.basic, ACCOUNT_PLANS.pro, ACCOUNT_PLANS.premium]}
      >
        <div className="d-flex justify-content-end">
          <ReelerButton
            text="Save"
            styleClass="btn-main"
            size="sm"
            dispatch={() => handleUpdateNotes()}
            loading={loading}
          />
        </div>
        {msg && <FadeAlert msg={msg} />}
      </AuthBasedComponent>
    </>
  )
}
