import React, { useState } from "react"
import * as S from "./Carousel.styles"
import CarouselItem from "./components/CarouselItem"
import { FaChevronLeft, FaChevronRight } from "react-icons/fa"

const Carousel = ({ feed, assets }) => {
  const [sliderIndex, setSliderIndex] = useState(0)
  const [columns, setColumns] = useState(3)

  const handleShowNext = () => {
    setSliderIndex(index => {
      if (index === assets.length - columns) return 0
      return index + 1
    })
  }
  const handlePrevNext = () => {
    setSliderIndex(index => {
      if (index === 0) return assets.length - columns
      return index - 1
    })
  }

  return (
    assets && (
      <S.Carousel>
        {feed?.feedSettings?.showButtons ? (
          <S.CarouselButton
            onClick={() => handlePrevNext()}
            buttonColor={feed?.feedSettings?.buttonColor}
          >
            <FaChevronLeft />
          </S.CarouselButton>
        ) : null}
        <S.CarouselContainer sliderIndex={sliderIndex}>
          {assets.map((asset, index) => (
            <CarouselItem
              key={index}
              sliderIndex={sliderIndex}
              feed={feed}
              asset={asset}
            />
          ))}
        </S.CarouselContainer>
        {feed?.feedSettings?.showButtons ? (
          <S.CarouselButton
            onClick={() => handleShowNext()}
            buttonColor={feed?.feedSettings?.buttonColor}
          >
            <FaChevronRight />
          </S.CarouselButton>
        ) : null}
      </S.Carousel>
    )
  )
}

export default Carousel
