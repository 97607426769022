import styled, { css } from "styled-components"

export const NotificationContainer = styled.div`
  aspect-ratio: 1/1;

  width: ${props => (props.$width ? `${props.$width}px` : "20px")};
  height: ${props => (props.$height ? `${props.$height}px` : "20px")};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  background-color: var(--reeler-light);
  color: var(--reeler-icon);
  border-radius: 50%;
  font-size: 10px;

  position: absolute;
  top: ${props => (props.$top ? `${props.$top}px` : "-6px")};
  right: ${props => (props.$right ? `${props.$right}px` : "-6px")};
`
