/**
 * Check if an img src is valid
 * @param path to image
 * @returns Promise
 */

export const checkImage = path =>
  new Promise(resolve => {
    const img = new Image()
    img.onload = () => resolve({ path, status: "ok" })
    img.onerror = () => resolve({ path, status: "error" })

    img.src = path
  })
