import React, { useEffect, useState } from "react"
import { Col, Form, Row } from "react-bootstrap"
import { database } from "../../../../../../firebase"
// import FadeAlert from "../../../../../commons/FadeAlert/FadeAlert"

// Redux
import { saveCampaign } from "../../../../../../redux/campaign/campaign.actions"
import { selectCampaign } from "../../../../../../redux/campaign/campaign.selectors"
import { selectAccountId } from "../../../../../../redux/account/account.selectors"
import { useDispatch, useSelector } from "react-redux"

const SourceOwner = () => {
  const dispatch = useDispatch()
  const [users, setUsers] = useState(null)
  const campaign = useSelector(selectCampaign)
  const accountId = useSelector(selectAccountId)

  useEffect(() => {
    if (accountId) {
      database.users
        .where("accounts", "array-contains", accountId)
        .get()
        .then(querySnapshot => {
          let userArray = []
          querySnapshot.forEach(doc => {
            // doc.data() is never undefined for query doc snapshots
            userArray.push(database.formatDoc(doc))
          })
          setUsers(userArray)
        })
    }
  }, [accountId])

  return (
    <Form.Group controlId="source-owner">
      <Row className="p-3">
        <Col sm={4}>
          <Form.Label className="mb-0">Set source owner</Form.Label>
        </Col>
        <Col sm={8}>
          <Form.Control
            as="select"
            name="userId"
            value={campaign?.userId}
            onChange={e =>
              dispatch(
                saveCampaign({
                  ...campaign,
                  userId: e.target.value,
                })
              )
            }
          >
            {users?.map((user, key) => (
              <option key={key} value={user.id}>
                {user.email}
              </option>
            ))}
          </Form.Control>
          <Form.Text muted>
            Set “Source Owner” to be able to easily find your content, if you
            are many people or many teams working in the same Reeler account.
          </Form.Text>
        </Col>
      </Row>
    </Form.Group>
  )
}

export default SourceOwner
