import React, { useState, useEffect } from "react"
import { database } from "../../../../firebase"
import { useHistory } from "react-router-dom"

// Redux
import { useDispatch, useSelector } from "react-redux"
import {
  selectAccountId,
  selectAccounts,
} from "../../../../redux/account/account.selectors"

import {
  setLoading,
  loadAssets,
  setDefaultAssets,
  updateFeed,
} from "../../../../redux/feed/feed.actions"
import {
  selectFeed,
  selectAssets,
  selectFeedTabKey,
} from "../../../../redux/feed/feed.selectors"

// 3rd-party components
import { Row, Col, Form, Nav, Container } from "react-bootstrap"

// Reeler components
import * as S from "../../styles"
import LayoutSection from "./components/LayoutSection"
import Preview from "./components/Preview"
import SaveButton from "./components/SaveButton"
import FeedAssets from "./components/assets-tab/components/FeedAssets"
import FeedSettings from "./components/settings/FeedSettings"
import FeedTags from "./components/FeedTags"
import {
  FEED_PUBLISH_OPTIONS,
  FEED_TYPES,
  MEDIA_TYPE,
} from "../../../../constants"
import {
  Circle,
  CircleContainer,
  VerticalLine,
} from "../../../commons/common-styles"

import Section from "../../../../layouts/core/Section"
import {
  Header,
  HeaderGroup,
  HeaderGroupCenter,
  HeaderGroupLeft,
  HeaderGroupRight,
} from "../../../../layouts/core/Header"
import Title from "../../../../layouts/core/Title"
import Body from "../../../../layouts/core/Body"
import EmailTypeSection from "./components/email-feed/EmailTypeSection"
import EmailPreview from "./components/email-feed/EmailPreview"
import { ACCOUNT_PLANS, MAIN_ROUTES } from "../../../../constants/routes"
import ReelerButton from "../../../commons/ReelerButton"
import FeedAPITab from "./components/FeedAPITab"
import {
  SubHeader,
  SubHeaderGroup,
  SubHeaderGroupCenter,
  SubHeaderGroupLeft,
  SubHeaderGroupRight,
} from "../../../../layouts/core/SubHeader"
import ReelerBadge from "../../../commons/ReelerBadge"
import PublishFeed from "./components/PublishFeed"
import FeedActions from "./components/FeedActions"
import { capitalize } from "lodash"

const EditFeed = () => {
  const [key, setKey] = useState("settings")
  const history = useHistory()
  const feed = useSelector(selectFeed)
  const accountId = useSelector(selectAccountId)
  const account = useSelector(selectAccounts)
  const assets = useSelector(selectAssets)
  const feedTab = useSelector(selectFeedTabKey)

  useEffect(() => {
    if (feedTab) {
      setKey(feedTab)
    }
  }, [feedTab])

  const dispatch = useDispatch()

  useEffect(() => {
    if (feed && accountId) {
      // Clear Feed redux data
      dispatch(setLoading(true))

      if (feed?.tags && Object.keys(feed?.tags).length > 0) {
        let query = database.assets.where("accountId", "==", accountId)
        //.where("state", "==", ASSET_STATE.approved)

        if (feed.feedType === FEED_TYPES.email) {
          query = query.where("media_type", "==", MEDIA_TYPE.IMAGE)
        }
        let tags = Object.keys(feed?.tags)

        tags.forEach(tag => {
          query = query.where(`tags.${tag}`, "==", true)
        })

        var unsubscribe = query.onSnapshot(querySnapshots => {
          const snapshotArray = []

          querySnapshots.docs.forEach(doc => {
            snapshotArray.push(database.formatDoc(doc))
          })

          /**
           * 1) first sort on created date
           */
          snapshotArray.sort((a, b) => {
            if (a?.createdAt?.seconds > b?.createdAt?.seconds) {
              return -1
            }
            if (a?.createdAt?.seconds < b?.createdAt?.seconds) {
              return 1
            }
            return 0
          })

          /**
           * 2) Then sort assets based on their order in feed.assetOrder
           */
          snapshotArray.sort((a, b) => {
            return (
              feed?.assetOrder?.indexOf(a.id) - feed?.assetOrder?.indexOf(b.id)
            )
          })

          // assets behöver sorteras

          // set assets with sorted array
          //var reversed = sorted.reverse()
          dispatch(loadAssets(snapshotArray))
          dispatch(setDefaultAssets(snapshotArray))
          dispatch(setLoading(false))
        })
        return () => unsubscribe()
      } else {
        dispatch(loadAssets([]))
        dispatch(setLoading(false))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feed.id, feed.tags, feed.publish, feed.feedType])

  return (
    <Section>
      <Header>
        <HeaderGroup>
          <HeaderGroupLeft>
            <ReelerButton
              text="All feeds"
              styleClass="btn-secondary me-2"
              dispatch={() => history.push(MAIN_ROUTES.feeds.path)}
            />
          </HeaderGroupLeft>
          <HeaderGroupCenter>
            <Title>{capitalize(feed?.feedName)}</Title>
          </HeaderGroupCenter>
          <HeaderGroupRight>
            <SaveButton />
          </HeaderGroupRight>
        </HeaderGroup>

        <div className="d-flex flex-row align-items-center ms-auto"></div>
      </Header>
      <SubHeader>
        <SubHeaderGroup>
          <SubHeaderGroupLeft></SubHeaderGroupLeft>
          <SubHeaderGroupCenter>
            <Nav activeKey={key} onSelect={selectedKey => setKey(selectedKey)}>
              <Nav.Item>
                <Nav.Link
                  eventKey="settings"
                  style={{ color: "var(--reeler-icon)" }}
                >
                  Settings
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link
                  eventKey="assets"
                  style={{ color: "var(--reeler-icon)" }}
                >
                  <div className="d-flex flex-row align-items-center">
                    <div className="me-2">Assets</div>

                    <ReelerBadge value={assets?.length} />
                  </div>
                </Nav.Link>
              </Nav.Item>
              {feed?.feedType && feed?.feedType === FEED_TYPES.email ? (
                <Nav.Item>
                  <Nav.Link
                    eventKey="emailLayout"
                    style={{ color: "var(--reeler-icon)" }}
                  >
                    Email layout
                  </Nav.Link>
                </Nav.Item>
              ) : (
                <Nav.Item>
                  <Nav.Link
                    eventKey="webLayout"
                    style={{ color: "var(--reeler-icon)" }}
                  >
                    Web layout
                  </Nav.Link>
                </Nav.Item>
              )}

              <Nav.Item>
                <Nav.Link
                  eventKey="preview"
                  style={{ color: "var(--reeler-icon)" }}
                >
                  Preview
                </Nav.Link>
              </Nav.Item>

              {account?.plan === ACCOUNT_PLANS.premium ? (
                <Nav.Item>
                  <Nav.Link
                    eventKey="api"
                    style={{ color: "var(--reeler-icon)" }}
                  >
                    API
                  </Nav.Link>
                </Nav.Item>
              ) : null}
            </Nav>
          </SubHeaderGroupCenter>
          <SubHeaderGroupRight>
            <PublishFeed />
            <div className="ms-3">
              <FeedActions />
            </div>
          </SubHeaderGroupRight>
        </SubHeaderGroup>
      </SubHeader>
      <Body>
        {(() => {
          switch (key) {
            case "settings":
              return (
                <Container>
                  <Row>
                    <Col sm={3} md={2} className="d-flex flex-column">
                      <CircleContainer>
                        <Circle active={true}>1</Circle>
                      </CircleContainer>
                      <VerticalLine />
                    </Col>
                    <Col className="pb-3">
                      <S.SectionHeading>Settings</S.SectionHeading>
                      <FeedSettings />
                    </Col>
                  </Row>

                  <Row>
                    <Col sm={3} md={2} className="d-flex flex-column">
                      <CircleContainer>
                        <Circle active={feed?.feedType}>2</Circle>
                      </CircleContainer>
                      <VerticalLine />
                    </Col>
                    <Col className="pb-3">
                      <S.SectionHeading>Feed type</S.SectionHeading>
                      <Form.Group>
                        <Form.Check
                          type="radio"
                          inline
                          id="feed-type-web"
                          label="Web Feed"
                          onChange={() =>
                            dispatch(
                              updateFeed({
                                feedType: FEED_TYPES.web,
                              })
                            )
                          }
                          checked={
                            feed?.feedType === FEED_TYPES.web ? true : false
                          }
                        />

                        <Form.Check
                          type="radio"
                          inline
                          id="feed-type-email"
                          label="Email Feed"
                          onChange={() =>
                            dispatch(
                              updateFeed({
                                feedType: FEED_TYPES.email,
                              })
                            )
                          }
                          checked={
                            feed?.feedType === FEED_TYPES.email ? true : false
                          }
                        />
                        <Form.Text muted>
                          Choose whether you’d like to display the feed on
                          websites or in emails.
                        </Form.Text>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={3} md={2} className="d-flex flex-column">
                      <CircleContainer>
                        <Circle
                          active={
                            feed?.tags && Object.keys(feed?.tags).length > 0
                          }
                        >
                          3
                        </Circle>
                      </CircleContainer>
                      <VerticalLine />
                    </Col>
                    <Col className="pb-3">
                      <S.SectionHeading>Add content</S.SectionHeading>
                      <FeedTags />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={3} md={2} className="d-flex flex-column">
                      <CircleContainer>
                        <Circle active={feed?.publish}>4</Circle>
                      </CircleContainer>
                    </Col>
                    <Col className="pb-3">
                      <S.SectionHeading>Automatic publishing</S.SectionHeading>
                      <Form.Group className="mb-3">
                        <Form.Check
                          type="radio"
                          label="No, curate manually before publishing"
                          onChange={() =>
                            dispatch(
                              updateFeed({
                                publish: FEED_PUBLISH_OPTIONS.manually,
                              })
                            )
                          }
                          checked={
                            feed?.publish === FEED_PUBLISH_OPTIONS.manually
                              ? true
                              : false
                          }
                        />
                        <Form.Text muted>
                          Tagged content will be added to the feed but will
                          remain hidden from sight until you manually make each
                          content asset visible (and have a chance to change the
                          order before publishing). Please note that for web
                          feeds, the embed code will remain the same even when
                          assets are updated. However, for email feeds, the
                          embed code will change each time the feed is updated.
                        </Form.Text>
                      </Form.Group>
                      <Form.Group>
                        <Form.Check
                          type="radio"
                          label="Yes, publish automatically"
                          onChange={() =>
                            dispatch(
                              updateFeed({
                                publish: FEED_PUBLISH_OPTIONS.automatically,
                              })
                            )
                          }
                          checked={
                            feed?.publish === FEED_PUBLISH_OPTIONS.automatically
                              ? true
                              : false
                          }
                        />
                        <Form.Text muted>
                          Your feed will automatically update with the latest
                          content at the top of the feed, as soon as new content
                          is tagged with the required content tags. Please note
                          that for web feeds, the embed code will remain the
                          same even when assets are updated. However, for email
                          feeds, the embed code will change each time the feed
                          is updated.
                        </Form.Text>
                      </Form.Group>
                    </Col>
                  </Row>
                </Container>
              )
            case "assets":
              return <FeedAssets />
            case "emailLayout":
              return (
                <>
                  <Row>
                    <Col>
                      <S.SectionHeading>Email feed layout</S.SectionHeading>
                    </Col>
                  </Row>
                  <Row className="justify-content-center">
                    <Col sm={12} md={6}>
                      <EmailTypeSection />
                    </Col>
                    <Col sm={12} md={6}>
                      <EmailPreview />
                    </Col>
                  </Row>
                </>
              )
            case "webLayout":
              return (
                <>
                  <Row>
                    <Col>
                      <S.SectionHeading>Web feed layout</S.SectionHeading>
                    </Col>
                  </Row>
                  <LayoutSection />
                </>
              )
            case "preview":
              return (
                <>
                  <Row>
                    <Col>
                      <S.SectionHeading>Preview</S.SectionHeading>
                    </Col>
                  </Row>
                  <Preview />
                </>
              )

            case "api":
              return (
                <>
                  <FeedAPITab />
                </>
              )
          }
        })()}
      </Body>
    </Section>
  )
}

export default EditFeed
