import React, { useState, useCallback } from "react"
import { useEffect } from "react"
import { useSelector } from "react-redux"

import { functions } from "../../../../../firebase"
import SpinnerComponent from "../../../../../components/commons/SpinnerComponent"
import { selectAccounts } from "../../../../../redux/account/account.selectors"
import ReelerBadge from "../../../../../components/commons/ReelerBadge"
import { selectCurrentUser } from "../../../../../redux/user/user.selectors"

export default function CountAssetsForCreator({
  creatorId,
  lastViewed,
  variant,
}) {
  const account = useSelector(selectAccounts)
  const [loading, setLoading] = useState(true)
  const [assets, setAssets] = useState(0)

  const countNumberOfAssetsForCreator = async () => {
    setLoading(true)

    var countAssetsForCreator = functions.httpsCallable("countassetsforcreator")

    try {
      if (creatorId && account) {
        let query = {
          creatorId,
          accountId: account.id,
        }

        if (lastViewed) {
          query.lastViewed = lastViewed
        }

        const { data } = await countAssetsForCreator(query)
        setAssets(data.noOfAssets)
        setLoading(false)
      }
    } catch (err) {
      setLoading(false)
      console.log(err)
    }
  }

  useEffect(() => {
    if (creatorId && account) {
      countNumberOfAssetsForCreator()
    } else {
      setLoading(false)
    }
  }, [])

  if (loading) return <SpinnerComponent size="sm" />

  return assets > 0 ? <ReelerBadge value={assets} variant={variant} /> : null
}
