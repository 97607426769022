import React from "react"
import SourceOwner from "../form-generator/components/campaignInfo/SourceOwner"
import CampaignTags from "../../../campaigns/edit-campaign/components/CampaignTagsRedux"
import SourceCreatorList from "../../../campaigns/components/SourceCreatorList"

export default function FormOptions() {
  return (
    <>
      <CampaignTags />
      <div className="p-3">
        <SourceCreatorList />
      </div>
      <SourceOwner />
    </>
  )
}
