import React, { useState } from "react"

// Redux
import { useSelector, useDispatch } from "react-redux"
import {
  setActiveAsset,
  setShowAssetInfoModal,
  setActiveSidebarTab,
} from "../../../../../../../../redux/asset/asset.actions"
import { selectFeed } from "../../../../../../../../redux/feed/feed.selectors"

// 3rd-party components
import { FaGripVertical, FaEdit } from "react-icons/fa"
import { IoMdPlay } from "react-icons/io"
import { BsBoxFill } from "react-icons/bs"
import { MdOutlineEditNote } from "react-icons/md"
// Reeler components
import ReelerTooltip from "../../../../../../../commons/ReelerTooltip/ReelerTooltip"
import PublishFeedAsset from "./card-components/PublishFeedAsset"
import * as S from "./styles"
import MediaType from "../../../../../../../commons/MediaType"
import {
  FEED_PUBLISH_OPTIONS,
  IMG_SIZES,
  MEDIA_TYPE,
} from "../../../../../../../../constants"
import { AddTagsToAsset } from "../../../../../../../campaigns/campaign/components/AddTagsToAsset"
import Icon from "../../../../../../../commons/Icon"

const FeedAsset = ({ asset }) => {
  const dispatch = useDispatch()

  const feed = useSelector(selectFeed)
  const [open, setOpen] = useState(false)

  const handleClickOnAsset = activeSidebarTab => {
    if (activeSidebarTab) {
      dispatch(setActiveSidebarTab(activeSidebarTab))
    }
    dispatch(setActiveAsset(asset))
    dispatch(setShowAssetInfoModal())
  }

  return (
    <S.AssetPreviewContainer>
      <PublishFeedAsset asset={asset} />

      {feed?.publish === FEED_PUBLISH_OPTIONS.manually ? (
        <>
          {!feed?.manuallyAddedAssets?.includes(asset.id) ? (
            <S.ContainerOverlay></S.ContainerOverlay>
          ) : null}
        </>
      ) : (
        <>
          {feed?.excludedAssets?.includes(asset.id) ? (
            <S.ContainerOverlay></S.ContainerOverlay>
          ) : null}
        </>
      )}
      <S.ThumbnailContainer onClick={() => handleClickOnAsset()}>
        {asset.media_type === MEDIA_TYPE.VIDEO ? (
          <S.AssetMediaTypeVideoPlayIcon>
            <IoMdPlay size="2.5rem" />
          </S.AssetMediaTypeVideoPlayIcon>
        ) : null}
        <MediaType
          asset={asset}
          imgSize={IMG_SIZES.thumb}
          showVideoControls={false}
        />
      </S.ThumbnailContainer>
      <S.Iconbar>
        <ReelerTooltip text="Drag and drop the asset to new position">
          <FaGripVertical className="icon-color icon-btn" />
        </ReelerTooltip>
        <AddTagsToAsset asset={asset} />

        <Icon
          onClick={() => handleClickOnAsset()}
          tooltip="Edited caption"
          notification={asset?.edited_caption?.length > 0 ? 1 : null}
        >
          <MdOutlineEditNote className="icon-color" />
        </Icon>
        <Icon
          onClick={() => handleClickOnAsset("products")}
          tooltip="Connected products"
          notification={
            asset?.products?.length > 0 ? asset?.products?.length : null
          }
        >
          <BsBoxFill className="icon-color" />
        </Icon>
      </S.Iconbar>
    </S.AssetPreviewContainer>
  )
}

export default FeedAsset
