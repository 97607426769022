import React from "react"
import Section from "../../../layouts/core/Section"
import {
  Header,
  HeaderGroup,
  HeaderGroupCenter,
  HeaderGroupLeft,
  HeaderGroupRight,
} from "../../../layouts/core/Header"
import Body from "../../../layouts/core/Body"
import Title from "../../../layouts/core/Title"
import BackButton from "../../../components/commons/BackButton"
import CreatorsTable from "./components/CreatorsTable/CreatorsTable"
import CreatorsStats from "./components/CreatorsStats/CreatorsStats"
import EditCreator from "../components/EditCreator/EditCreator"
import ReelerButton from "../../../components/commons/ReelerButton"
import CreatorsTextSearch from "./components/CreatorTextSearch"

export default function CreatorsPage() {
  return (
    <Section>
      <Header>
        <HeaderGroup>
          <HeaderGroupLeft>
            <Title>Creators</Title>
          </HeaderGroupLeft>
          <HeaderGroupCenter>
            <CreatorsTextSearch />
          </HeaderGroupCenter>
          <HeaderGroupRight>
            <EditCreator
              title="New creator"
              creator={null}
              btn={<ReelerButton className="btn-main" text="Add new creator" />}
            />
          </HeaderGroupRight>
        </HeaderGroup>
      </Header>
      <Body>
        <CreatorsTable />
      </Body>
    </Section>
  )
}
