import BulkEditActionTypes from "./bulkEdit.types"

const INITIAL_STATE = {
  selectedAssets: [],
  msg: undefined,
  lastSelectedAsset: null,
}

const bulkEditReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case BulkEditActionTypes.ADD_ITEM_TO_BULK_EDIT:
      return {
        ...state,
        selectedAssets: [...state.selectedAssets, action.payload],
        lastSelectedAsset: action.payload,
      }

    case BulkEditActionTypes.REMOVE_ITEM_FROM_BULK_EDIT:
      return {
        ...state,
        lastSelectedAsset: action.payload,
        selectedAssets: state.selectedAssets.filter(
          asset => asset.id !== action.payload.id
        ),
      }
    case BulkEditActionTypes.BULK_EDIT_RESET:
      return {
        ...state,
        selectedAssets: [],
        msg: undefined,
        lastSelectedAsset: null,
      }
    case BulkEditActionTypes.BULK_SELECT_ALL:
      return {
        ...state,
        selectedAssets: action.payload,
      }
    case BulkEditActionTypes.BULK_EDIT_ACTION_SUCCESS:
      return {
        ...state,
        msg: action.payload,
      }
    case BulkEditActionTypes.BULK_EDIT_MESSAGE_RESET:
      return {
        ...state,
        msg: undefined,
      }
    default:
      return state
  }
}

export default bulkEditReducer
