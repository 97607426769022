import styled from "styled-components"

export const Badge = styled.div`
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  color: var(--reeler-black);
  background-color: ${({ variant }) =>
    variant === "main" ? "var(--reeler-light)" : "var(--reeler-grey)"};
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0;
`
