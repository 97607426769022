import React, { useState } from "react"
import { database, firestore } from "../../../firebase"
import _ from "lodash"
// Third party
import { FaTimes, FaCheck } from "react-icons/fa"
import { TbZoomCheck } from "react-icons/tb"
// Redux
import { useDispatch } from "react-redux"
import { BulkEditActionSuccess } from "../../../redux/bulk-edit/bulkEdit.actions"

// Reeler
import { ASSET_STATE } from "../../../constants"
import SpinnerComponent from "../../commons/SpinnerComponent"
import ReelerTooltip from "../../commons/ReelerTooltip/ReelerTooltip"
import * as S from "./BulkAssetApproveStyles"

const BulkAssetApprove = ({ assets }) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)

  const handleBulkState = async state => {
    setLoading(true)

    dispatch(
      BulkEditActionSuccess({
        type: "info",
        lead: "Loading",
        text: "This will take a while – please wait for the bulk editing to finish",
      })
    )
    console.log("Batch updating")

    const batches = _.chunk(assets, 500).map(assetDocs => {
      const batch = firestore.batch()
      assetDocs.forEach(asset => {
        let assetRef = database.assets.doc(asset.id)

        batch.set(
          assetRef,
          {
            state: state,
            stateChangedAt: database.getCurrentTimestamp(),
          },
          { merge: true }
        )
      })
      return batch.commit()
    })

    await Promise.all(batches)
      .then(() => {
        if (state === ASSET_STATE.approved) {
          dispatch(
            BulkEditActionSuccess({
              type: "success",
              duration: 3000,
              lead: "Approved",
              text: "Assets have been approved",
            })
          )
        } else if (state === ASSET_STATE.rejected) {
          dispatch(
            BulkEditActionSuccess({
              type: "success",
              duration: 3000,
              lead: "Rejected",
              text: "Assets have been rejected",
            })
          )
        } else if (state === ASSET_STATE.unreviewed) {
          dispatch(
            BulkEditActionSuccess({
              type: "success",
              duration: 3000,
              lead: "Successfully",
              text: "Assets have been set to unreviewed",
            })
          )
        }
        setLoading(false)
      })
      .catch(err => {
        console.log(err)
      })
  }

  return loading ? (
    <SpinnerComponent />
  ) : (
    <S.ApproveContainer>
      <ReelerTooltip
        placement="bottom"
        text={assets.length === 0 ? "Select assets first" : "Approve assets"}
      >
        <S.ApproveIcon
          onClick={() => handleBulkState(ASSET_STATE.approved)}
          borderRadius="3px 0 0 3px"
          bgHover="var(--reeler-grey-darker)"
          bgColor="var(--reeler-grey-middle)"
          disabled={assets.length === 0}
        >
          <FaCheck
            style={{
              color: "var(--reeler-icon)",
            }}
            size={14}
          />
        </S.ApproveIcon>
      </ReelerTooltip>
      <ReelerTooltip
        placement="bottom"
        text={
          assets.length === 0
            ? "Select assets first"
            : "Set assets to unreviewed"
        }
      >
        <S.ApproveIcon
          onClick={() => handleBulkState(ASSET_STATE.unreviewed)}
          borderRadius="0 0 0 0"
          bgHover="var(--reeler-grey-darker)"
          bgColor="var(--reeler-grey-middle)"
          disabled={assets.length === 0}
        >
          <TbZoomCheck
            style={{
              color: "var(--reeler-icon)",
            }}
            size={14}
          />
        </S.ApproveIcon>
      </ReelerTooltip>
      <ReelerTooltip
        placement="bottom"
        text={assets.length === 0 ? "Select assets first" : "Reject assets"}
      >
        <S.ApproveIcon
          onClick={() => handleBulkState(ASSET_STATE.rejected)}
          borderRadius="0 3px 3px 0"
          bgHover="var(--reeler-grey-darker)"
          bgColor="var(--reeler-grey-middle)"
          disabled={assets.length === 0}
        >
          <FaTimes
            style={{
              color: "var(--reeler-icon)",
            }}
            size={14}
          />
        </S.ApproveIcon>
      </ReelerTooltip>
    </S.ApproveContainer>
  )
}

export default BulkAssetApprove
