import React from "react"
import { Form, Row, Col } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import {
  updateFeed,
  setValidationMessage,
  setIsValidToSave,
} from "../../../../../../redux/feed/feed.actions"
import {
  selectValidationMsg,
  selectFeed,
} from "../../../../../../redux/feed/feed.selectors"
import FeedTags from "../FeedTags"
import * as S from "../../../../styles"

export default function FeedSettings() {
  const dispatch = useDispatch()

  const feed = useSelector(selectFeed)
  const validationMsg = useSelector(selectValidationMsg)

  const validateName = e => {
    const name = e.target.value
    var regEx = /\s*(\S\s*){3,}/
    var nameResult = regEx.test(name)

    if (nameResult === false) {
      dispatch(setIsValidToSave(false))
      dispatch(
        setValidationMessage(
          "The name has to be at least 3 non-whitespace characters"
        )
      )
      dispatch(updateFeed({ feedName: name }))
    } else {
      dispatch(setIsValidToSave(true))
      dispatch(setValidationMessage(null))
      dispatch(updateFeed({ feedName: name }))
    }
  }

  return (
    feed && (
      <Row>
        <Col>
          <Form.Group controlId="feed-name">
            <Form.Label>Feed name</Form.Label>
            <Form.Control
              type="text"
              required
              value={feed.feedName}
              onChange={e => validateName(e)}
              minLength={3}
              maxLength={50}
            />
            {validationMsg && <S.ErrorText>{validationMsg}</S.ErrorText>}

            <Form.Text muted>
              For internal use only, to keep track of your feeds.
            </Form.Text>
          </Form.Group>
        </Col>
      </Row>
    )
  )
}
