import React from "react"
import { Spinner } from "react-bootstrap"

export default function SpinnerComponent({
  size = "sm",
  style = "spinner-color",
  spinnerClass,
}) {
  return (
    <div className="d-flex justify-content-center align-items-center">
      <Spinner
        animation="border"
        size={size}
        role="status"
        className={style + " " + spinnerClass}
      >
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  )
}
