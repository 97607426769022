import React from "react"

// 3rd-party
import { IoMdPlay } from "react-icons/io"
import { BsBoxFill } from "react-icons/bs"
import moment from "moment"

// Redux
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { selectCurrentUser } from "../../../../redux/user/user.selectors"
import {
  setActiveAsset,
  setShowAssetInfoModal,
} from "../../../../redux/asset/asset.actions"

// Reeler components
import MediaType from "../../../commons/MediaType"
import ReelerTooltip from "../../../commons/ReelerTooltip/ReelerTooltip"
import AssetStatus from "./asset-info/components/AssetStatus"
import { AddTagsToAsset } from "./AddTagsToAsset"
import AssetBulkEdit from "./AssetBulkEdit"

import AuthBasedComponent from "../../../authentication/AuthBasedComponent"
import { ACCOUNT_PLANS } from "../../../../constants/routes"

// styles

import * as S from "./styles"

// Utils
import { timeAgo } from "../../../../utils/CalculateTimeAgo"
import AssetApprove from "./asset-info/components/asset-approve/AssetApprove"
import { IMG_SIZES, MEDIA_TYPE } from "../../../../constants"
import { selectBulkEditArray } from "../../../../redux/bulk-edit/bulkEdit.selectors"

const ContentGridAsset = ({ asset, disableBulkEdit = false }) => {
  const assetArray = useSelector(selectBulkEditArray)
  const dispatch = useDispatch()

  const handleClickOnAsset = () => {
    dispatch(setActiveAsset(asset))
    dispatch(setShowAssetInfoModal())
  }
  return asset ? (
    <S.AssetPreviewContainer
      selected={assetArray.find(item => item.id === asset.id)}
    >
      <S.TopLeft>
        {!disableBulkEdit && <AssetBulkEdit asset={asset} />}
      </S.TopLeft>

      <S.TopRight>
        <AssetStatus asset={asset} />
      </S.TopRight>

      <S.AssetMediaPreviewContainer onClick={() => handleClickOnAsset()}>
        {asset.media_type === MEDIA_TYPE.VIDEO ? (
          <S.AssetMediaTypeVideoPlayIcon>
            <IoMdPlay size="2.5rem" />
          </S.AssetMediaTypeVideoPlayIcon>
        ) : null}

        <MediaType
          asset={asset}
          objectFit="contain"
          imgSize={IMG_SIZES.thumb}
          showVideoControls={false}
        />
      </S.AssetMediaPreviewContainer>
      <S.AssetPreviewFooter>
        <ReelerTooltip
          text={`Collected on ${moment(asset.createdAt.toDate()).format(
            "YYYY-MM-DD"
          )}`}
        >
          <span
            style={{
              alignSelf: "center",
              fontSize: "12px",
            }}
            onClick={() => handleClickOnAsset()}
          >
            {asset?.createdAt &&
              !isNaN(asset?.createdAt) &&
              timeAgo(asset?.createdAt.toDate())}
          </span>
        </ReelerTooltip>
        <AuthBasedComponent
          plans={[
            ACCOUNT_PLANS.basic,
            ACCOUNT_PLANS.pro,
            ACCOUNT_PLANS.premium,
          ]}
        >
          <AddTagsToAsset asset={asset} />
        </AuthBasedComponent>
        <AuthBasedComponent
          plans={[
            ACCOUNT_PLANS.basic,
            ACCOUNT_PLANS.pro,
            ACCOUNT_PLANS.premium,
          ]}
        >
          <ReelerTooltip text="Connected products">
            <div style={{ position: "relative" }}>
              <BsBoxFill className="icon-color icon-btn" />

              {asset?.products?.length > 0 ? (
                <S.Notification $top="0" $width="15" $height="15">
                  {asset?.products?.length}
                </S.Notification>
              ) : null}
            </div>
          </ReelerTooltip>
        </AuthBasedComponent>

        <div>
          <AssetApprove asset={asset} />
        </div>
      </S.AssetPreviewFooter>
    </S.AssetPreviewContainer>
  ) : null
}

export default ContentGridAsset
