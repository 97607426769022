import React from "react"
import Form from "react-bootstrap/Form"

import "./styles.css"

const ReelerSwitch = ({ label, name, action, checkedBoolean }) => {
  return (
    <Form>
      <Form.Check // prettier-ignore
        type="switch"
        id={name}
        name={name}
        label={label}
        checked={Boolean(checkedBoolean)}
        onChange={action}
      />
    </Form>
  )
}

export default ReelerSwitch
