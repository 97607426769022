import styled from "styled-components"

export const SearchButton = styled.div`
  background-color: #ffff;
  padding: 7px 15px;
  margin-left: -50px;
  color: #707070;
  border-radius: 0 5px 5px 0;
  z-index: 10;
  cursor: pointer;
`

export const PopupSubmenu = styled.div`
  position: relative;
`
export const Content = styled.div`
  display: ${({ open }) => (open ? "block" : "none")};
  position: ${({ isCollapsed }) => (isCollapsed ? "fixed" : "fixed")};
  inset: -50px auto auto 0px;
  width: 350px;
  margin: 0px;
  transform: ${({ isCollapsed }) =>
    isCollapsed
      ? `translate3d(80px, calc(100vh - 200px), 0px)`
      : `translate3d(300px, calc(100vh - 200px), 0px)`};
  height: 100%;
  overflow: hidden;
  z-index: 999;
  transition: height 300ms;
  background-color: white;
  box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014,
    0 9px 28px 8px #0000000d;
  padding-left: 0px;

  visibility: visible;
  font-size: 14px;
  max-height: 600px;
  overflow-y: scroll;
  padding-bottom: 5px;
`

export const Menu = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`
export const ToggleButton = styled.span``
