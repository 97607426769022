import React from "react"
import * as S from "../styles"
import { IoClose } from "react-icons/io5"

const PopupModal = ({ message, onConfirm, closeModal }) => {
  return (
    <S.ModalContainer>
      <S.CloseModal onClick={() => closeModal(false)}>
        <IoClose size="16px" />
      </S.CloseModal>
      <S.ModalBody>
        {message}
        <S.ButtonContainer>
          <S.ModalButton className="btn-delete " onClick={() => onConfirm()}>
            Yes
          </S.ModalButton>
          <S.ModalButton onClick={() => closeModal(false)}>No</S.ModalButton>
        </S.ButtonContainer>
      </S.ModalBody>
    </S.ModalContainer>
  )
}

export default PopupModal
