import React from "react"
import { Col, Form, Row } from "react-bootstrap"

import { saveCampaign } from "../../../redux/campaign/campaign.actions"
import { selectCampaign } from "../../../redux/campaign/campaign.selectors"
import { useDispatch, useSelector } from "react-redux"
import SelectCreatorList from "../../creators/SelectCreatorList"

const SourceCreatorList = () => {
  const dispatch = useDispatch()
  const campaign = useSelector(selectCampaign)

  const handleSelect = lists => {
    let creatorLists = {}

    lists.forEach(l => {
      if (l === "") {
        return
      }
      creatorLists[l.toLowerCase()] = true
    })

    dispatch(
      saveCampaign({
        ...campaign,
        creatorLists,
      })
    )
  }

  return (
    <Form.Group controlId="source-creator-list">
      <Row>
        <Col sm={4}>
          <Form.Label className="mb-0">Add creators to a list</Form.Label>
        </Col>
        <Col sm={8}>
          <SelectCreatorList
            value={
              campaign?.creatorLists ? Object.keys(campaign?.creatorLists) : []
            }
            handleOnChange={handleSelect}
          />

          <Form.Text muted>
            Automatically add creators who share content through this source to
            one or multiple creator lists to track their contributions.
          </Form.Text>
        </Col>
      </Row>
    </Form.Group>
  )
}

export default SourceCreatorList
