import React from "react"

// 3rd-party libraries
import { Dropdown } from "react-bootstrap"
import { FaEllipsisV, FaVideoSlash } from "react-icons/fa"

//Redux

import Icon from "../../../../../components/commons/Icon"
import DeleteCreatorButton from "../../../../../components/creators/DeleteCreatorButton/DeleteCreatorButton"
import { useSelector } from "react-redux"
import { selectCreator } from "../../../../../redux/creators/creators.selectors"
import CreatorBlacklistWhitelist from "../../../../../components/creators/CreatorBlacklistWhitelist"
import ReelerTooltip from "../../../../../components/commons/ReelerTooltip/ReelerTooltip"
import ReelerButton from "../../../../../components/commons/ReelerButton"

export default function CreatorMoreActions() {
  const creator = useSelector(selectCreator)
  // The forwardRef is important!!
  // Dropdown needs access to the DOM node in order to position the Menu
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
      ref={ref}
      onClick={e => {
        e.preventDefault()
        onClick(e)
      }}
    >
      {children}
    </div>
  ))

  return (
    <Dropdown>
      <Dropdown.Toggle as={CustomToggle} id="form-actions">
        <Icon>
          <FaEllipsisV />
        </Icon>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item>
          <CreatorBlacklistWhitelist
            type="whitelisted"
            btn={
              creator?.whitelisted ? (
                <div>Remove from whitelist</div>
              ) : (
                <ReelerTooltip text="Approve assets">
                  Add to whitelist
                </ReelerTooltip>
              )
            }
          />
        </Dropdown.Item>

        <Dropdown.Item>
          <CreatorBlacklistWhitelist
            type="blacklisted"
            btn={
              creator?.blacklisted ? (
                <div>Remove from blacklist</div>
              ) : (
                <ReelerTooltip text="Hide creator and stop collecing assets">
                  Add to blacklist
                </ReelerTooltip>
              )
            }
          />
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item>
          <DeleteCreatorButton
            creatorId={creator.id}
            btn={<ReelerButton styleClass="btn-danger" text="Delete" />}
          />
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}
