import React from "react"
import { ModalTabContainer } from "../../styles"
import AssetComments from "./AssetComments"
import AssetNotes from "./AssetNotes"
import AssetRate from "./AssetRate"

const AssetNotesComments = () => {
  return (
    <ModalTabContainer>
      <AssetRate />
      <div
        style={{
          border: "1px solid var(--reeler-grey)",
          margin: "0.5rem 0 0.25rem 0",
        }}
      ></div>
      <AssetNotes />
      <div
        style={{
          border: "1px solid var(--reeler-grey)",
          margin: "0.5rem 0 0.25rem 0",
        }}
      ></div>
      <AssetComments />
    </ModalTabContainer>
  )
}

export default AssetNotesComments
