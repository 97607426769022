import React, { useEffect, useCallback } from "react"

// 3rd-party
import { Row, Col } from "react-bootstrap"
import { monitorForElements } from "@atlaskit/pragmatic-drag-and-drop/element/adapter"
import { extractClosestEdge } from "@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge"
import { reorderWithEdge } from "@atlaskit/pragmatic-drag-and-drop-hitbox/util/reorder-with-edge"
import { triggerPostMoveFlash } from "@atlaskit/pragmatic-drag-and-drop-flourish/trigger-post-move-flash"
import { flushSync } from "react-dom"
// Reeler components

import * as S from "./styles"
import { v4 as uuidV4 } from "uuid"

import AddFormFields from "./components/AddFormFields"
import PreviewForm from "./components/PreviewForm"
import { useSelector, useDispatch } from "react-redux"
import { selectFormSchema } from "../../../redux/campaign/campaign.selectors"
import {
  addFormField,
  addNewFormField,
  changeFieldPosition,
} from "../../../redux/campaign/campaign.actions"
import { FORM_FIELDS } from "./Fields"
import useUserFeedbackMessage from "../../../hooks/useUserFeedbackMessage"

export default function () {
  const formSchema = useSelector(selectFormSchema)
  const dispatch = useDispatch()
  const { setErrorMessage } = useUserFeedbackMessage()

  // Function to handle drop events
  const handleDrop = useCallback(
    ({ source, location }) => {
      // Logic to handle the drop event will be added here
      console.log("source", source)
      console.log("location", location)
      // Early return if there are no drop targets in the current location
      const target = location.current.dropTargets[0]
      if (!target) {
        return
      }

      // Retrieve the ID of the filed being dragged

      const targetData = target.data
      const sourceData = source.data

      let indexOfTarget = formSchema.order.findIndex(
        fieldId => fieldId === targetData.fieldId
      )

      if (indexOfTarget < 0) {
        return
      }

      const closestEdgeOfTarget = extractClosestEdge(targetData)

      if (sourceData.fieldId) {
        // Moving an existing field

        const indexOfSource = formSchema.order.findIndex(
          fieldId => fieldId === sourceData.fieldId
        )

        if (indexOfSource < 0) {
          return
        }

        // Using `flushSync` so we can query the DOM straight after this line

        const newOrder = reorderWithEdge({
          list: formSchema.order,
          startIndex: indexOfSource,
          indexOfTarget,
          closestEdgeOfTarget,
          axis: "vertical",
        })

        flushSync(() => {
          dispatch(changeFieldPosition(newOrder))
        })

        // Being simple and just querying for the task after the drop.
        // We could use react context to register the element in a lookup,
        // and then we could retrieve that element after the drop and use
        // `triggerPostMoveFlash`. But this gets the job done.
        const element = document.querySelector(
          `[data-field-id="${sourceData.fieldId}"]`
        )
        if (element instanceof HTMLElement) {
          triggerPostMoveFlash(element)
        }
      } else {
        // Adding a new field

        // if dest and source is different, add new form field

        const { icon, label, type, fieldId, ...field } = sourceData.field

        if (fieldId && formSchema.order.includes(fieldId)) {
          setErrorMessage("Field already exists")
          return
        }

        const newFieldId = fieldId ? fieldId : uuidV4()

        let initialOrder = formSchema.order
        initialOrder.push(newFieldId)

        const newOrder = reorderWithEdge({
          list: initialOrder,
          startIndex: initialOrder.length - 1,
          indexOfTarget,
          closestEdgeOfTarget,
          axis: "vertical",
        })

        flushSync(() => {
          dispatch(addFormField(newFieldId, field, newOrder))
        })
      }
    },
    [formSchema?.order]
  )

  useEffect(() => {
    return monitorForElements({
      onDrop: handleDrop,
    })
  }, [handleDrop])

  return (
    <S.FormBuilderContainer>
      <Row>
        <Col
          md={4}
          style={{ borderRight: "1px solid var(--reeler-grey-dark)" }}
        >
          <div className="m-2">
            <strong>Add form fields</strong>
          </div>
          <AddFormFields />
        </Col>
        <Col md={8} style={{ overflowY: "scroll", height: "100vh" }}>
          <PreviewForm />
        </Col>
      </Row>
    </S.FormBuilderContainer>
  )
}
