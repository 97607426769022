import React, { useRef, useState } from "react"
import { database } from "../../../../firebase"
import firebase from "firebase/app"
import {
  Form,
  Row,
  Col,
  InputGroup,
  FormControl,
  Button,
} from "react-bootstrap"
import { IoClose } from "react-icons/io5"

export default function CampaignTags({ campaign }) {
  const tagRef = useRef()
  const [tag, setTag] = useState(null)
  const [loading, setLoading] = useState(false)

  const handleNewTag = () => {
    setLoading(true)

    let tags = { tags: {} }

    tag.split(" ").forEach(tag => {
      tags.tags[tag.toLowerCase()] = true
    })

    database.campaigns
      .doc(campaign.id)
      .set(tags, { merge: true })
      .then(() => {
        setLoading(false)
        setTag(null)
        tagRef.current.value = null
      })
      .catch(err => {
        setLoading(false)
      })
  }

  const handleDeleteTag = tagToDelete => {
    setLoading(true)

    database.campaigns
      .doc(campaign.id)
      .set(
        {
          tags: {
            [tagToDelete]: firebase.firestore.FieldValue.delete(),
          },
        },
        { merge: true }
      )
      .then(() => {
        setLoading(false)
      })
      .catch(err => {
        setLoading(false)
      })
  }

  return (
    campaign && (
      <>
        <Form.Group controlId="add-tag">
          <Row className="p-3">
            <Col sm={4}>
              <Form.Label className="mb-0">Add content source tags</Form.Label>
            </Col>
            <Col sm={8}>
              <InputGroup className="mb-3">
                <FormControl
                  type="text"
                  size="sm"
                  ref={tagRef}
                  placeholder="Add a new tag..."
                  onChange={e => setTag(e.target.value)}
                  onKeyUp={e => (e.key === "Enter" ? handleNewTag() : null)}
                />
                <Button
                  size="sm"
                  onClick={() => handleNewTag()}
                  disabled={loading}
                  className="secondary-btn input-group-btn"
                >
                  Add
                </Button>
              </InputGroup>
              <Form.Text muted>
                Tags are for finding content later, in your Reeler content
                library. Here you set tags you want automatically added to all
                content in the content source. Hit enter after typing each tag. Later
                you can manually add additional tags to individual content
                assets, as you review them.
              </Form.Text>

              <div>
                {campaign.tags &&
                  Object.keys(campaign.tags).map((tag, index) => (
                    <span
                      key={index}
                      className="badge badge-pill py-1 px-2 m-2 tag"
                      onClick={() => handleDeleteTag(tag)}
                    >
                      {tag}
                      <IoClose size="14px" />
                    </span>
                  ))}
              </div>
            </Col>
          </Row>
        </Form.Group>
      </>
    )
  )
}
