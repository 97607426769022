const BulkEditActionTypes = {
  ADD_ITEM_TO_BULK_EDIT: "ADD_ITEM_TO_BULK_EDIT",
  ADD_ITEMS: "ADD_ITEMS",
  REMOVE_ITEM_FROM_BULK_EDIT: "REMOVE_ITEM_FROM_BULK_EDIT",
  BULK_EDIT_RESET: "BULK_EDIT_RESET",
  BULK_SELECT_ALL: "BULK_SELECT_ALL",
  BULK_EDIT_ACTION_SUCCESS: "BULK_EDIT_ACTION_SUCCESS",
  BULK_EDIT_MESSAGE_RESET: "BULK_EDIT_MESSAGE_RESET",
}

export default BulkEditActionTypes
