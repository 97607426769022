import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { selectCampaign } from "../../../redux/campaign/campaign.selectors"
import {
  saveCampaign,
  updateCampaignsWithCampaign,
} from "../../../redux/campaign/campaign.actions"

import { Dropdown } from "react-bootstrap"
import { FaCircle, FaCaretDown } from "react-icons/fa"
import SpinnerComponent from "../../commons/SpinnerComponent"
import { database } from "../../../firebase"
import ReelerTooltip from "../../commons/ReelerTooltip/ReelerTooltip"

export default function CampaignToggleStatus({ campaignId, status }) {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [sourceStatus, setSourceStatus] = useState(status)
  const [show, setShow] = useState(false)
  const selectedCampaign = useSelector(selectCampaign)

  useEffect(() => {
    if (status) {
      setSourceStatus(status)
    }
  }, [status])

  const handleToggleStatus = async status => {
    setLoading(true)
    console.log("saving campaign status")
    try {
      await database.campaigns.doc(campaignId).update({
        status: status,
      })
      console.log("saved campaign status")

      console.log(selectedCampaign)
      if (selectedCampaign !== null && selectedCampaign?.id === campaignId) {
        console.log(selectedCampaign)
        const updatedCampaign = {
          ...selectedCampaign,
          status: status,
        }
        dispatch(saveCampaign(updatedCampaign))
        dispatch(updateCampaignsWithCampaign(updatedCampaign))
      }
      setLoading(false)
    } catch (e) {
      setLoading(false)
      console.log(e)
    }
  }

  // The forwardRef is important!!
  // Dropdown needs access to the DOM node in order to position the Menu
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
      className="d-flex flex-row justify-content-center campaign-status"
      ref={ref}
      onMouseOver={() => setShow(true)}
      onMouseOut={() => setShow(false)}
      onClick={e => {
        e.preventDefault()
        onClick(e)
      }}
    >
      {children}

      {show && <FaCaretDown className="campaign-status-caret " />}
    </div>
  ))

  if (status === "deleting") {
    return <span>Deleting</span>
  }

  return loading ? (
    <SpinnerComponent size="sm" />
  ) : (
    <Dropdown style={{ width: "30px" }}>
      <Dropdown.Toggle as={CustomToggle} id="campaign-status">
        <ReelerTooltip
          text={
            sourceStatus === "active"
              ? "Pause source and stop collecting content"
              : "Make source active and start collect content"
          }
        >
          <FaCircle
            className={
              sourceStatus === "active"
                ? "icon-campaign-active"
                : "icon-campaign-inactive"
            }
          />
        </ReelerTooltip>
      </Dropdown.Toggle>

      <Dropdown.Menu className="reeler-dropdown">
        <Dropdown.Item onClick={() => handleToggleStatus("active")}>
          <FaCircle className="me-2 mb-1 icon-campaign-active" />
          Activate
        </Dropdown.Item>
        <Dropdown.Item onClick={() => handleToggleStatus("inactive")}>
          <FaCircle className="me-2 mb-1 icon-campaign-inactive" />
          Pause
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}
