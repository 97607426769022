import React, { useEffect } from "react"
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { selectCurrentUser } from "../redux/user/user.selectors"
import { checkUserSession } from "../redux/user/user.actions"
import { fetchCampaignsStart } from "../redux/campaign/campaign.actions"
import { AuthProvider } from "../contexts/AuthContext"

import PrivateRoute from "./authentication/PrivateRoute"

import NotFound from "./not-found/NotFound"

import { MAIN_ROUTES, PUBLIC_ROUTES, USER_ROLES } from "../constants/routes"

import MainLayout from "../layouts/MainLayout"
import LoadingScreen from "./commons/LoadingScreen"

const privatePaths = Object.keys(MAIN_ROUTES).map(key => {
  const route = MAIN_ROUTES[key]
  let path = route.path
  if (route?.params) {
    const params = route?.params?.join("/:")
    path = path + "/:" + params
  }

  return path
})
const publicPaths = Object.keys(PUBLIC_ROUTES).map(key => {
  const route = PUBLIC_ROUTES[key]
  let path = route.path
  if (route?.params) {
    const params = route?.params?.join("/:")
    path = path + "/:" + params
  }

  return path
})

const App = () => {
  const dispatch = useDispatch()
  const currentUser = useSelector(selectCurrentUser)

  useEffect(() => {
    dispatch(checkUserSession())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!currentUser) return
    dispatch(fetchCampaignsStart())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser])

  // if (!currentUser) {
  //   return <LoadingScreen />
  // }

  return (
    <Router>
      <AuthProvider>
        <Switch>
          {/* Public routes */}
          {/* New upload form routes */}
          {Object.keys(PUBLIC_ROUTES).map(key => {
            const route = PUBLIC_ROUTES[key]
            let path = route.path
            if (route?.params) {
              const params = route?.params?.join("/:")
              path = path + "/:" + params
            }

            return (
              <Route
                key={key}
                path={path}
                exact={route?.exact}
                component={route.component}
              />
            )
          })}

          <PrivateRoute path={privatePaths}>
            <MainLayout>
              <Switch>
                {Object.keys(MAIN_ROUTES).map(key => {
                  const route = MAIN_ROUTES[key]
                  let path = route.path
                  if (route?.params) {
                    const params = route?.params?.join("/:")
                    path = path + "/:" + params
                  }

                  return (
                    <Route key={key} path={path} component={route.component} />
                  )
                })}
              </Switch>
            </MainLayout>
          </PrivateRoute>
          <Route exact path="/">
            <Redirect
              to={
                currentUser && currentUser?.role === USER_ROLES.viewer
                  ? MAIN_ROUTES.filters.path
                  : MAIN_ROUTES.content.path
              }
            />
          </Route>
          <PrivateRoute component={NotFound} />
        </Switch>
      </AuthProvider>
    </Router>
  )
}

export default App
