import React from "react"
import { FaHashtag } from "react-icons/fa"
import Tag from "../../../../../commons/Tag"
import ReelerTooltip from "../../../../../commons/ReelerTooltip/ReelerTooltip"

export default function AssetCreatorHashtags({ asset }) {
  const copyTag = tag => {
    navigator.clipboard.writeText(tag)
  }

  return (
    <div className="d-flex flex-row align-items-center mt-3">
      <div className="d-flex h-100 mt-1">
        <ReelerTooltip text="Creator hashtags">
          <FaHashtag className="icon-color" />
        </ReelerTooltip>
      </div>
      <div className="d-flex flex-wrap px-3 w-100">
        {asset?.creator_tags &&
          Object.keys(asset?.creator_tags)
            ?.sort((a, b) => {
              if (a < b) {
                return -1
              }
              if (a > b) {
                return 1
              }
              return 0
            })
            .map((tag, index) => (
              <Tag
                key={index}
                tooltip="Copy tag"
                onClick={() => copyTag(tag)}
                showRemoveIcon={false}
              >
                {tag}
              </Tag>
            ))}
      </div>
    </div>
  )
}
