import styled from "styled-components"
import { Form } from "react-bootstrap"

import "../App.css"

export const FeedsContainer = styled.div`
  padding: 0 15px;
  min-height: 100vh;
`

export const Title1 = styled.h1`
  color: var(--reeler-title);
  font-size: 1.5rem;
  padding: 10px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const Title2 = styled.p`
  color: var(--reeler-title);
  font-size: 1rem;
  padding: 10px 0;
`

export const SortContainer = styled(Form.Group)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 1em;
`

export const HeaderBadge = styled.div`
  margin-bottom: 1em;
`

export const FeedTypeIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 5em;
`

export const BoxShadowContainer = styled.div`
  box-shadow: rgb(0 0 0 / 13%) 0px 3.2px 7.2px 0px,
    rgb(0 0 0 / 11%) 0px 0.6px 1.8px 0px;
`

// EDIT FEED STYLES
export const EditTabContainer = styled.div`
  width: 100%;
  padding: 1em;
  background: white;
  border-left: var(--bootstrap-border);
  border-right: var(--bootstrap-border);
`

export const SubmitSectionContainer = styled.div`
  padding: 1em;
  background: white;
  border: var(--bootstrap-border);
  border-top: none;
`

export const SectionHeading = styled.h2`
  font-size: 1.2em;
  margin-top: 1em;
  color: var(--reeler-title);
`

export const SectionContainer = styled.div`
  background: var(--reeler-grey);
  padding: 10px;
  margin-bottom: 1rem;
  border-radius: 5px;
`

export const InputUnit = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #495057;
  background-color: #e9ecef;
  padding: 0 0.75em;
  border: 1px solid #ced4da;
  border-left: none;
  border-radius: 0 0.25em 0.25em 0;
`

export const ErrorText = styled.small`
  color: var(--reeler-danger);
  margin: 0.5rem 0;
`
export const PreviewSticky = styled.div`
  position: sticky;
  top: 56px;
`

export const PreviewContainer = styled.div`
  display: flex
  justify-content: center

  width: 100%;
  padding: 1em;
  z-index: 0;
`

export const WidgetContainer = styled.div`
  background-color: #fff;
  width: 100%;
  padding: 0 1em;
  z-index: 0;
`

export const LoadingContainer = styled.div`
  text-align: center;
  padding: 5em;
  min-height: fit-content;
`
export const TopRight = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 999;
`

export const CampaignType = styled.div`
  display: flex;
  align-self: center;
  justify-content: center;

  padding: 0.2rem;
  margin: 0.3rem;
  border-radius: 5px;
`
