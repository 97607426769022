import React, { useState } from "react"
import * as S from "./styles"
export default function Submenu({ isCollapsed, component, children }) {
  const [open, setOpen] = useState(false)

  return (
    <S.PopupSubmenu onMouseLeave={() => setOpen(false)}>
      <S.ToggleButton onClick={() => setOpen(!open)}>
        {component}
      </S.ToggleButton>
      <S.Content
        onClick={() => setOpen(false)}
        open={open}
        isCollapsed={isCollapsed}
      >
        <S.Menu>{children}</S.Menu>
      </S.Content>
    </S.PopupSubmenu>
  )
}
