import styled from "styled-components"

export const TabCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-top: none;
  border-radius: 0 0 0.25rem 0.25rem;
`
export const TabCardBody = styled.div`
  flex: 1 1 auto;
  min-height: 1px;
  padding: 0.75rem 0.5rem;
`

export const SettingsContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  margin-bottom: 1rem;
`
export const SettingsSection = styled.div`
  background: #fff;
  padding: 1rem;
  border-radius: 3px;
  margin-top: 1rem;
  border: 1px solid var(--reeler-grey-dark);
`
export const FormFieldSectionContainer = styled.div`
  background: var(--reeler-grey);
  padding: 10px;
  border-radius: 3px;
`
export const EditorContainer = styled.div`
  background-color: rgb(255, 255, 255);
  padding: 10px;
  border-radius: 3px;
`
