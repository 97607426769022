import React, { useState } from "react"
import { Modal } from "react-bootstrap"
// Firebase
import firebase from "firebase/app"

import { database } from "../../../../../../../firebase"
import { FaTrashAlt } from "react-icons/fa"
import ReelerTooltip from "../../../../../../commons/ReelerTooltip/ReelerTooltip"
import ReelerButton from "../../../../../../commons/ReelerButton"
import {
  RIGHTS_REQUEST_TYPE,
  ASSET_STATUS,
} from "../../../../../../../constants"

// Redux
import { useSelector, useDispatch } from "react-redux"
import { selectActiveAsset } from "../../../../../../../redux/asset/asset.selectors"
import { selectCurrentUser } from "../../../../../../../redux/user/user.selectors"
import { updateAsset } from "../../../../../../../redux/asset/asset.actions"
import Icon from "../../../../../../commons/Icon"
import useUserFeedbackMessage from "../../../../../../../hooks/useUserFeedbackMessage"

export default function DeleteRightRequest({ rightsRequest }) {
  const [open, setOpen] = useState(false)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const asset = useSelector(selectActiveAsset)
  const user = useSelector(selectCurrentUser)
  const { setSuccessMessage, setErrorMessage } = useUserFeedbackMessage()

  function openModal() {
    setOpen(true)
  }

  function closeModal() {
    setOpen(false)
  }

  const handleDelete = async () => {
    setLoading(true)
    const { id, assetId, requestType } = rightsRequest

    // Check if the right request is creator or third party
    if (requestType === RIGHTS_REQUEST_TYPE.creator) {
      // If it is creator
      const newStatus = ASSET_STATUS.new
      let status_log = {
        from: asset.status,
        to: newStatus,
        user_id: user.id,
        type: RIGHTS_REQUEST_TYPE.creator,
        note: "Creator right request deleted",
        user_name:
          user.firstName || user.lastName
            ? user.firstName + " " + user.lastName
            : user.email,
        createdAt: Date.now(),
      }

      database.rightRequests
        .doc(id)
        .delete()
        .then(() => {
          database.assets
            .doc(assetId)
            .update({
              status: newStatus,
              status_logs: firebase.firestore.FieldValue.arrayUnion(status_log),
            })
            .then(() => {
              if (asset?.status_logs) {
                dispatch(
                  updateAsset({
                    ...asset,
                    status: newStatus,
                    status_logs: [...asset?.status_logs, status_log],
                  })
                )
              } else {
                dispatch(
                  updateAsset({
                    ...asset,
                    status: newStatus,
                    status_logs: [status_log],
                  })
                )
              }
              setSuccessMessage("Deleted successfully!")
            })
            .then(() => {
              setLoading(false)
              closeModal()
            })
        })
        .catch(err => {
          console.log(err)
          setErrorMessage("Could not delete rights request!")

          setLoading(false)
        })
    } else {
      // if it is a third party request

      // 1 load all third party request for that specific asset

      const querySnapshot = await database.rightRequests
        .where("assetId", "==", assetId)
        .where("requestType", "==", RIGHTS_REQUEST_TYPE.thirdParty)
        .get()

      let thirdPartyRightRequests = []
      querySnapshot.forEach(doc => {
        // exclude the right request to be deleted
        if (doc.id !== id) {
          thirdPartyRightRequests.push({ id: doc.id, ...doc.data() })
        }
      })
      // 2 check if this is the last third party
      if (thirdPartyRightRequests.length === 0) {
        // Delete the rights request and remove asset third party status
        let status_log = {
          from: asset.thirdPartyApprovalStatus,
          to: "Removed third party rights status",
          user_id: user.id,
          type: RIGHTS_REQUEST_TYPE.thirdParty,
          note: "Deleted a third party rights request",
          user_name:
            user.firstName || user.lastName
              ? user.firstName + " " + user.lastName
              : user.email,
          createdAt: Date.now(),
        }

        database.rightRequests
          .doc(id)
          .delete()
          .then(() => {
            database.assets
              .doc(assetId)
              .update({
                thirdPartyApprovalStatus:
                  firebase.firestore.FieldValue.delete(),
                status_logs:
                  firebase.firestore.FieldValue.arrayUnion(status_log),
              })
              .then(() => {
                delete asset.thirdPartyApprovalStatus
                if (asset?.status_logs) {
                  dispatch(
                    updateAsset({
                      ...asset,
                      status_logs: [...asset?.status_logs, status_log],
                    })
                  )
                } else {
                  dispatch(
                    updateAsset({
                      ...asset,
                      status_logs: [status_log],
                    })
                  )
                }
                setSuccessMessage("Deleted successfully!")
              })
              .then(() => {
                setLoading(false)
                closeModal()
              })
          })
          .catch(err => {
            console.log(err)
            setLoading(false)
          })
      } else {
        // Check how many approved third party rights request
        const noApprovedThirdPartyRequests = thirdPartyRightRequests.filter(
          request => request.approvalStatus === ASSET_STATUS.approved
        )

        console.log(
          "noApprovedThirdPartyRequests.length = " +
            noApprovedThirdPartyRequests.length
        )
        console.log(
          "thirdPartyRightRequests.size = " + thirdPartyRightRequests.length
        )

        if (
          noApprovedThirdPartyRequests.length ===
            thirdPartyRightRequests.length &&
          asset.thirdPartyApprovalStatus !== ASSET_STATUS.approved
        ) {
          const thirdPartyApprovalStatus = ASSET_STATUS.approved

          let status_log = {
            from: asset.thirdPartyApprovalStatus,
            to: thirdPartyApprovalStatus,
            user_id: user.id,
            type: RIGHTS_REQUEST_TYPE.thirdParty,
            note: "Removed a third party rights request",
            user_name:
              user.firstName || user.lastName
                ? user.firstName + " " + user.lastName
                : user.email,
            createdAt: Date.now(),
          }

          database.rightRequests
            .doc(id)
            .delete()
            .then(() => {
              database.assets
                .doc(assetId)
                .update({
                  thirdPartyApprovalStatus: thirdPartyApprovalStatus,
                  status_logs:
                    firebase.firestore.FieldValue.arrayUnion(status_log),
                })
                .then(() => {
                  if (asset?.status_logs) {
                    dispatch(
                      updateAsset({
                        ...asset,
                        thirdPartyApprovalStatus: thirdPartyApprovalStatus,
                        status_logs: [...asset?.status_logs, status_log],
                      })
                    )
                  } else {
                    dispatch(
                      updateAsset({
                        ...asset,
                        thirdPartyApprovalStatus: thirdPartyApprovalStatus,
                        status_logs: [status_log],
                      })
                    )
                  }
                  setSuccessMessage("Deleted successfully!")
                })
                .then(() => {
                  setLoading(false)
                  closeModal()
                })
            })
            .catch(err => {
              console.log(err)
              setErrorMessage("Could not delete rights request!")

              setLoading(false)
            })
        } else {
          database.rightRequests
            .doc(id)
            .delete()
            .then(() => {
              setLoading(false)
              closeModal()
            })
            .catch(err => {
              console.log(err)
              setLoading(false)
            })
        }
      }
    }
  }

  return (
    <>
      <Icon onClick={openModal} tooltip="Delete request">
        <FaTrashAlt />
      </Icon>

      <Modal centered show={open} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            Are you sure you want to delete this rights request?
          </Modal.Title>
        </Modal.Header>

        <Modal.Footer>
          <ReelerButton
            text="Close"
            dispatch={closeModal}
            styleClass="btn-secondary"
          />
          <ReelerButton
            text="Delete request"
            styleClass="btn-delete"
            dispatch={() => handleDelete()}
            loading={loading}
            disabled={loading}
          />
        </Modal.Footer>
      </Modal>
    </>
  )
}
