import React, { useState } from "react"

// Reeler components
import SocialWallItem from "./components/SocialWallItem"

import * as S from "./SocialWall.styles"

const SocialWall = ({ assets, feed }) => {
  const [noOfAssets, setNoOfAssets] = useState(12)
  const settings = feed.feedSettings

  return (
    <>
      <S.SocialWallContainer settings={settings}>
        {assets &&
          assets
            .filter((item, index) => index < noOfAssets)
            .map(asset => <SocialWallItem asset={asset} feed={feed} />)}
      </S.SocialWallContainer>
      {noOfAssets < assets.length ? (
        <S.LoadMoreContainer>
          <S.LoadMoreButton
            onClick={() => setNoOfAssets(noOfAssets + settings.columns * 2)}
          >
            Load More
          </S.LoadMoreButton>
        </S.LoadMoreContainer>
      ) : null}
    </>
  )
}

export default SocialWall
