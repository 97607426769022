import styled from "styled-components"

export const ToggleButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

export const ToogleButton = styled.button`
  display: flex;
  justify-content: center;
  flex: 1;
  color: var(--reeler-title);
  border: 1px solid var(--reeler-grey-dark);
  font-size: 0.8em;
  margin: 0 0.25rem 0 0;

  border-radius: 5px;

  outline: none;

  box-shadow: var(--reeler-shadow-inset);

  background-color: ${({ selected }) =>
    selected ? "var(--reeler-super-light)" : "#fff"};

  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
  &:hover {
    background-color: #d2d6dc;
  }
  &:focus {
    border-color: #4a5568;
  }
`
