import { all, call, put, takeLatest, select } from "redux-saga/effects"
import AssetActionTypes from "./asset.types"
import { getAssetsRef, getCampaignAssetsRef } from "../../firebase"
import {
  fetchAssetsSuccess,
  fetchAssetsFailure,
  fetchCampaignAssetsFailure,
  fetchCampaignAssetsSuccess,
} from "./asset.actions"
import { selectAccountId } from "../account/account.selectors"
import {
  selectAssets,
  selectCampaignAssets,
  selectLastVisibleAsset,
} from "./asset.selectors"

export function* fetchAssetsAsync() {
  try {
    const accountId = yield select(selectAccountId)
    const assetRef = yield call(getAssetsRef, accountId)
    const { assets, lastVisible } = assetRef
    yield put(fetchAssetsSuccess({ assets, lastVisible }))
  } catch (error) {
    yield put(fetchAssetsFailure(error))
  }
}

export function* fetchMoreAssetsAsync() {
  try {
    const currentAssets = yield select(selectAssets)
    const startAfter = yield select(selectLastVisibleAsset)
    const accountId = yield select(selectAccountId)
    const assetRef = yield call(getAssetsRef, accountId, startAfter)
    const { assets, lastVisible } = assetRef
    yield put(
      fetchAssetsSuccess({
        assets: currentAssets.concat(assets),
        lastVisible,
      })
    )
  } catch (error) {
    yield put(fetchAssetsFailure(error))
  }
}

export function* fetchCampaignAssetsAsync({ payload: campaignId }) {
  try {
    const accountId = yield select(selectAccountId)
    const assetRef = yield call(getCampaignAssetsRef, campaignId, accountId)
    const { assets, lastVisible } = assetRef
    yield put(
      fetchCampaignAssetsSuccess({
        [campaignId]: { assets, lastVisible },
      })
    )
  } catch (error) {
    yield put(fetchCampaignAssetsFailure(error))
  }
}

export function* fetchMoreCampaignAssetsAsync({ payload: campaignId }) {
  try {
    const currentAssets = yield select(selectCampaignAssets)
    const startAfter = yield currentAssets[campaignId].lastVisible
    const assetsArray = yield currentAssets[campaignId].assets
    const accountId = yield select(selectAccountId)
    const assetRef = yield call(
      getCampaignAssetsRef,
      campaignId,
      accountId,
      startAfter
    )
    const { assets, lastVisible } = assetRef
    yield put(
      fetchCampaignAssetsSuccess({
        [campaignId]: {
          assets: assetsArray.concat(assets),
          lastVisible,
        },
      })
    )
  } catch (error) {
    yield put(fetchCampaignAssetsFailure(error))
  }
}

export function* fetchAssetsStart() {
  yield takeLatest(AssetActionTypes.FETCH_ASSETS_START, fetchAssetsAsync)
}

export function* fetchMoreAssetsStart() {
  yield takeLatest(
    AssetActionTypes.FETCH_MORE_ASSETS_START,
    fetchMoreAssetsAsync
  )
}

export function* fetchCampaignAssetsStart() {
  yield takeLatest(
    AssetActionTypes.FETCH_CAMPAIGN_ASSETS_START,
    fetchCampaignAssetsAsync
  )
}

export function* fetchMoreCampaignAssetsStart() {
  yield takeLatest(
    AssetActionTypes.FETCH_MORE_CAMPAIGN_ASSETS_START,
    fetchMoreCampaignAssetsAsync
  )
}

export function* assetSagas() {
  yield all([
    call(fetchAssetsStart),
    call(fetchMoreAssetsStart),
    call(fetchCampaignAssetsStart),
    call(fetchMoreCampaignAssetsStart),
  ])
}
