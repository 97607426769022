import { createSelector } from "reselect"

const selectedBulkEdit = state => state.bulkEdit

export const selectBulkEditArray = createSelector(
  [selectedBulkEdit],
  bulkEdit => bulkEdit.selectedAssets
)

export const selectBulkEditMsg = createSelector(
  [selectedBulkEdit],
  bulkEdit => bulkEdit.msg
)

export const selectLastSelected = createSelector(
  [selectedBulkEdit],
  bulkEdit => bulkEdit.lastSelectedAsset
)
