import { createSelector } from "reselect"

const instagramSearch = state => state.instagramSearch

export const selectOpenMediaObjectModal = createSelector(
  [instagramSearch],
  instagramSearch => instagramSearch?.openMediaObjectModal
)
export const selectActiveMediaObject = createSelector(
  [instagramSearch],
  instagramSearch => instagramSearch?.activeMediaObject
)
