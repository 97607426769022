import { createStore, applyMiddleware } from "redux"
import { persistStore, persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage" // defaults to localStorage for web
import logger from "redux-logger"
import createSagaMiddleware from "redux-saga"
import rootSaga from "./root-saga"

import rootReducer from "./root-reducer"

const persistConfig = {
  key: "root",
  storage: storage,
  whitelist: ["campaign", "feed", "navbar", "filter", "platform"], // only campaign will be persisted
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const sagaMiddleware = createSagaMiddleware()

const middlewares = [sagaMiddleware]

if (process.env.NODE_ENV === "development") {
  middlewares.push(logger)
}

export const store = createStore(
  persistedReducer,
  applyMiddleware(...middlewares)
)
sagaMiddleware.run(rootSaga)
export const persistor = persistStore(store)
