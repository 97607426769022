import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/storage"
import "firebase/analytics"
import "firebase/functions"

const app = firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
})

export const updateEmail = async email => {
  const user = auth.currentUser
  return await user.updateEmail(email)
}

export const updateEmailSuccess = async (id, email) => {
  const userRef = firestore.doc(`users/${id}`)

  try {
    await userRef.update({
      email,
    })
  } catch (error) {}
}

export const updatePassword = async password => {
  const user = auth.currentUser
  return await user.updatePassword(password)
}

export const createUserProfileDoc = async (userAuth, data) => {
  if (!userAuth) return

  const userRef = firestore.doc(`users/${userAuth.uid}`)
  const snapshot = await userRef.get()

  if (!snapshot.exists) {
    const { email, uid } = userAuth
    const createdAt = new Date()

    const accountRef = firestore.collection("accounts").doc()
    const { name } = data

    try {
      await accountRef.set({
        userId: uid,
        createdAt,
        name,
        plan: "basic",
      })
    } catch (error) {}

    const setAccountPrivateRef = accountRef.collection("private").doc("private")

    try {
      await setAccountPrivateRef.set({
        roles: { [uid]: "owner" },
        createdAt,
      })
    } catch (error) {}

    try {
      await userRef.set({
        email,
        createdAt,
        role: "owner",
        // accounts: { [accountRef.id]: "owner" },
        accounts: [accountRef.id],
        notifications: {
          dailyNotification: false,
          weeklyNotification: true,
        },
        ...data,
      })
    } catch (error) {}
  }

  return userRef
}

export const createInvitedUserProfileDoc = async (userAuth, data) => {
  if (!userAuth) return

  const userRef = firestore.doc(`users/${userAuth.uid}`)
  const snapshot = await userRef.get()
  const { role, accountId, inviteId } = data

  if (!snapshot.exists) {
    const { email, uid } = userAuth
    const createdAt = new Date()

    const accountRef = firestore
      .collection("accounts")
      .doc(accountId)
      .collection("private")
      .doc("private")

    try {
      await accountRef.update({
        [`roles.${uid}`]: role,
      })
    } catch (error) {
      console.log(error)
    }

    try {
      await userRef.set({
        email,
        createdAt,
        role,
        accounts: [accountId],
        notifications: {
          dailyNotification: false,
          weeklyNotification: true,
        },
        ...data,
      })
    } catch (error) {
      console.log(error)
    }

    const inviteRef = database.invites.doc(inviteId)

    try {
      await inviteRef.update({
        valid: false,
      })
    } catch (error) {}
  }

  return userRef
}

export const getAccountCampaignsRef = async accountId => {
  const campaignRef = database.campaigns.where("accountId", "==", accountId)
  const snapshot = await campaignRef.get()

  const campaigns = Array.from(
    new Set(
      snapshot.docs.map(campaign => ({ id: campaign.id, ...campaign.data() }))
    )
  )

  return { campaigns }
}

export const getAssetsRef = async (accountId, startAfter) => {
  const LIMIT = 25
  const query = database.assets
    .where("accountId", "==", accountId)
    .orderBy("createdAt", "desc")
    .limit(LIMIT)

  if (!startAfter) {
    const assetRef = query
    const snapshot = await assetRef.get()

    const assets = Array.from(new Set(snapshot.docs.map(asset => asset.data())))
    const lastVisible = snapshot.docs[snapshot.docs.length - 1]

    return { assets, lastVisible }
  }

  const assetRef = query.startAfter(startAfter)
  const snapshot = await assetRef.get()

  const assets = Array.from(new Set(snapshot.docs.map(asset => asset.data())))
  const lastVisible = snapshot.docs[snapshot.docs.length - 1]

  return { assets, lastVisible }
}

export const getCampaignAssetsRef = async (
  campaignId,
  accountId,
  startAfter
) => {
  const LIMIT = 25
  const query = database.assets
    .where("accountId", "==", accountId)
    .where("campaignId", "==", campaignId)
    .orderBy("createdAt", "desc")
    .limit(LIMIT)

  if (!startAfter) {
    const assetRef = query
    const snapshot = await assetRef.get()

    const assets = Array.from(new Set(snapshot.docs.map(asset => asset.data())))
    const lastVisible = snapshot.docs[snapshot.docs.length - 1]

    return { assets, lastVisible }
  }

  const assetRef = query.startAfter(startAfter)
  const snapshot = await assetRef.get()

  const assets = Array.from(new Set(snapshot.docs.map(asset => asset.data())))
  const lastVisible = snapshot.docs[snapshot.docs.length - 1]

  return { assets, lastVisible }
}

export const getAccountsRef = async accountId => {
  const accountsRef = database.accounts.doc(accountId)
  const snapshot = await accountsRef.get()

  return database.formatDoc(snapshot)
}

export const getCurrentUser = () => {
  /**
   * When we return this promise the promise will resolve to the correct user value if there is one,
   * else it will return null
   * Reject will catch any errors inside the API listener
   */
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged(userAuth => {
      unsubscribe()
      resolve(userAuth)
    }, reject)
  })
}

export const getInviteCodeData = async inviteId => {
  const inviteRef = database.invites.doc(inviteId)
  const snapshot = await inviteRef.get()

  if (!snapshot.exists) return false
  return { inviteId: snapshot.id, ...snapshot.data() }
}

export const firestore = app.firestore()

export const database = {
  accessTokens: firestore.collection("api_access_tokens"),
  campaigns: firestore.collection("campaigns"),
  accounts: firestore.collection("accounts"),
  users: firestore.collection("users"),
  invites: firestore.collection("invites"),
  assets: firestore.collection("assets"),
  feeds: firestore.collection("feeds"),
  streams: firestore.collection("streams"),
  notifications: firestore.collection("notifications"),
  thirdPartyApprovals: firestore.collection("third_party_approvals"),
  rightRequests: firestore.collection("right_requests"),
  deletedAssets: firestore.collection("deleted_assets"),
  confirmations: firestore.collection("confirmations"),
  publishingJobs: firestore.collection("publishing_jobs"),
  downloadJobs: firestore.collection("download_jobs"),
  instagramMediaTask: firestore.collection("ig_recent_media_tasks"),
  guides: firestore.collection("guides"),
  platform: firestore.collection("platform"),
  creators: firestore.collection("creators"),
  instagramMedia: firestore.collection("instagram_media"),
  formatDoc: doc => {
    return { id: doc.id, ...doc.data() }
  },
  increment: firebase.firestore.FieldValue.increment(1),
  decrement: firebase.firestore.FieldValue.increment(-1),
  getCurrentTimestamp: firebase.firestore.FieldValue.serverTimestamp,
  getCurrentUser,
}
export const storage = app.storage()
export const auth = app.auth()
export const analytics = app.analytics()
export const functions = app.functions()

export default app
