import React, { useEffect, useState } from "react"
import Section from "../../../layouts/core/Section"
import {
  Header,
  HeaderGroup,
  HeaderGroupCenter,
  HeaderGroupLeft,
  HeaderGroupRight,
} from "../../../layouts/core/Header"
import Body from "../../../layouts/core/Body"
import Title from "../../../layouts/core/Title"
import { useParams } from "react-router-dom"
import { database } from "../../../firebase"
import { useSelector, useDispatch } from "react-redux"
import { setCreator } from "../../../redux/creators/creators.actions"
import SpinnerComponent from "../../../components/commons/SpinnerComponent"
import { selectCreator } from "../../../redux/creators/creators.selectors"
import {
  FaChevronLeft,
  FaEdit,
  FaMinus,
  FaRegEdit,
  FaRegTrashAlt,
  FaTrash,
  FaVideoSlash,
} from "react-icons/fa"

import EditCreator from "../components/EditCreator/EditCreator"
import ReelerButton from "../../../components/commons/ReelerButton"
import CreatorInfo from "./components/CreatorInfo/CreatorInfo"
import CreatorAssets from "./components/CreatorAssets"
import { MAIN_ROUTES } from "../../../constants/routes"
import IconLink from "../../../components/commons/IconLink/IconLink"
import ReelerTooltip from "../../../components/commons/ReelerTooltip/ReelerTooltip"
import { calculateAge } from "../../../utils/HelpFunctions"
import { selectCurrentUser } from "../../../redux/user/user.selectors"
import DeleteCreatorButton from "../../../components/creators/DeleteCreatorButton/DeleteCreatorButton"
import Icon from "../../../components/commons/Icon"
import CreatorMoreActions from "./components/CreatorMoreActions"
import CreatorInstagramPosts from "./components/CreatorInstagramPosts"

export default function CreatorPage() {
  const [loading, setLoading] = useState(true)
  const { creatorId } = useParams()
  const creator = useSelector(selectCreator)
  const user = useSelector(selectCurrentUser)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!creator || !user) return
    // Update latest viewd
    let lastViewed = {}
    if (creator?.lastViewed) {
      lastViewed = { ...creator.lastViewed }
    }
    lastViewed[user.id] = database.getCurrentTimestamp()

    database.creators.doc(creator.id).update({
      lastViewed,
    })
  }, [creator, user])

  useEffect(() => {
    // Fetch creator data
    if (creatorId) {
      setLoading(true)

      database.creators
        .doc(creatorId)
        .get()
        .then(
          doc => {
            const c = database.formatDoc(doc)

            dispatch(setCreator(c))
            setLoading(false)
          },
          error => {
            console.log("Error getting document:", error)
          }
        )
    }
  }, [creatorId])

  return (
    creator && (
      <Section>
        <Header>
          <HeaderGroup>
            <HeaderGroupLeft>
              <IconLink
                url={MAIN_ROUTES.creators.path}
                text="Back to all creators"
                icon={<FaChevronLeft />}
              />
            </HeaderGroupLeft>
            <HeaderGroupCenter></HeaderGroupCenter>
            <HeaderGroupRight></HeaderGroupRight>
          </HeaderGroup>
        </Header>
        <Body>
          {loading && <SpinnerComponent />}
          {!loading && !creator && <p>Creator not found</p>}
          {!loading && creator && (
            <>
              <CreatorInfo />
              <CreatorAssets />
              <CreatorInstagramPosts />
            </>
          )}
        </Body>
      </Section>
    )
  )
}
