import React, { useRef, useState, useEffect } from "react"
import { draggable } from "@atlaskit/pragmatic-drag-and-drop/element/adapter"
import invariant from "tiny-invariant"

import { FORM_FIELDS } from "../Fields"
import { useSelector } from "react-redux"

import * as S from "../styles"
import { FaGripVertical } from "react-icons/fa"
import ReelerTooltip from "../../../commons/ReelerTooltip/ReelerTooltip"
import ReelerAccordation from "../../../commons/ReelerAccordation"
import { selectFormSchema } from "../../../../redux/campaign/campaign.selectors"

const isFieldAlreadyPresent = (fieldId, formSchema) => {
  if (!fieldId) return false
  return formSchema.order.includes(fieldId)
}

const Field = ({ field }) => {
  const fieldRef = useRef(null)
  const formSchema = useSelector(selectFormSchema)
  const [isDragging, setIsDragging] = useState(false)

  useEffect(() => {
    const el = fieldRef.current
    invariant(el)

    return draggable({
      element: el,
      canDrag: () => !isFieldAlreadyPresent(field?.fieldId, formSchema),
      getInitialData: () => ({ field }),
      onDragStart: () => setIsDragging(true),
      onDrop: () => setIsDragging(false),
    })
  }, [])

  return (
    <div ref={fieldRef}>
      <ReelerTooltip
        text={
          isFieldAlreadyPresent(field?.fieldId, formSchema)
            ? "Field already used in form"
            : "Drag and drop field in form"
        }
      >
        <S.FormFieldItem
          selected={
            isFieldAlreadyPresent(field?.fieldId, formSchema) || isDragging
          }
        >
          <FaGripVertical style={{ color: "var(--reeler-grey)" }} />
          <div className="icon-color mx-3">{field.icon}</div>

          <S.FormFieldItemText>{field.label}</S.FormFieldItemText>
        </S.FormFieldItem>
      </ReelerTooltip>
    </div>
  )
}

const FormFieldGroup = ({ fields, groupType, label, visible }) => {
  const formSchema = useSelector(selectFormSchema)

  return formSchema ? (
    <ReelerAccordation
      title={<S.FormFieldTitle>{label}</S.FormFieldTitle>}
      visible={visible}
    >
      <S.FormFieldGroupContainer>
        {Object.keys(fields).map((fieldKey, index) => {
          const field = fields[fieldKey]
          return (
            field?.type === groupType && <Field key={fieldKey} field={field} />
          )
        })}
      </S.FormFieldGroupContainer>
    </ReelerAccordation>
  ) : null
}

export default function AddFormFields() {
  return (
    <div>
      <FormFieldGroup
        label="Creator contact information"
        fields={FORM_FIELDS}
        visible={false}
        groupType="creator"
      />
      <FormFieldGroup
        label="Content information"
        fields={FORM_FIELDS}
        visible={false}
        groupType="content"
      />
      <FormFieldGroup
        label="Social media fields"
        fields={FORM_FIELDS}
        visible={false}
        groupType="some"
      />
      <FormFieldGroup
        label="Additional input fields"
        fields={FORM_FIELDS}
        visible={false}
        groupType="input"
      />
      <FormFieldGroup
        label="Text & Layout"
        fields={FORM_FIELDS}
        visible={false}
        groupType="layout"
      />
      <FormFieldGroup
        label="Legal"
        fields={FORM_FIELDS}
        visible={false}
        groupType="legal"
      />
    </div>
  )
}
