import React, { useState, useRef } from "react"
import { Modal, Form, ModalBody } from "react-bootstrap"
import { database } from "../../../firebase"
import { useDispatch } from "react-redux"
import { loadCampaign } from "../../../redux/campaign/campaign.actions"
import { useHistory } from "react-router-dom"
import { FaRegCopy } from "react-icons/fa"
import { useSelector } from "react-redux"
import { selectCurrentUser } from "../../../redux/user/user.selectors"
import ReelerTooltip from "../../commons/ReelerTooltip/ReelerTooltip"
import ReelerButton from "../../commons/ReelerButton"
import { CAMPAIGN_TYPE } from "../../../constants"
import { MAIN_ROUTES } from "../../../constants/routes"

const CopyCampaign = ({
  campaign,
  btn = (
    <ReelerTooltip text="Clone content source">
      <FaRegCopy style={{ strokeWidth: "3" }} className="icon-color" />
    </ReelerTooltip>
  ),
}) => {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [name, setName] = useState(
    campaign ? "Copy of " + campaign.campaignName : ""
  )
  const history = useHistory()
  const modalRef = useRef()
  const currentUser = useSelector(selectCurrentUser)

  function openModal() {
    setOpen(true)
  }

  function closeModal() {
    setOpen(false)
  }

  function handleSubmit(e) {
    e.preventDefault()

    let newCampaign = {}

    if (campaign) {
      // Copy the existing campaign

      newCampaign = campaign
      newCampaign["campaignName"] = name
      newCampaign["createdAt"] = database.getCurrentTimestamp()
      newCampaign["userId"] = currentUser.id
      newCampaign["assets"] = 0
      newCampaign["tags"] = {}

      // remove id
      delete newCampaign["id"]
      delete newCampaign["hashtag"]
      delete newCampaign["consent_hashtag"]
      //delete newCampaign["tags"]
    }
    console.log(newCampaign)
    database.campaigns
      .add(newCampaign)
      .then(docRef => {
        docRef.get().then(doc => {
          dispatch(loadCampaign(database.formatDoc(doc)))
          console.log("Redirect to edit page")
          if (campaign.campaignType === CAMPAIGN_TYPE.landingpage) {
            history.push(MAIN_ROUTES.campaignsEditCampaign.path)
          } else if (campaign.campaignType === CAMPAIGN_TYPE.igHashtag.type) {
            history.push(MAIN_ROUTES.campaignsEditHashtag.path)
          } else if (campaign.campaignType === CAMPAIGN_TYPE.email.type) {
            history.push(MAIN_ROUTES.campaignsEditEmail.path)
          } else if (campaign.campaignType === CAMPAIGN_TYPE.igMentions.type) {
            history.push(MAIN_ROUTES.igMentions.path)
          } else if (campaign.campaignType === CAMPAIGN_TYPE.upload.type) {
            history.push(MAIN_ROUTES.campaignsFormBuilder.path)
          }
        })
      })
      .catch(err => {
        console.log(err)
      })
  }

  return (
    <>
      <div style={{ cursor: "pointer" }}>
        <div onClick={openModal}>{btn}</div>
      </div>
      <Modal ref={modalRef} show={open} onHide={closeModal}>
        <ModalBody>
          <Form onSubmit={handleSubmit}>
            <Modal.Title>Copy content source</Modal.Title>
            <Modal.Body>
              <Form.Group>
                <Form.Label>Content source ame</Form.Label>
                <Form.Control
                  type="text"
                  required
                  value={name}
                  onChange={e => setName(e.target.value)}
                />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <ReelerButton
                text="Close"
                styleClass="btn-secondary"
                dispatch={closeModal}
              />
              <ReelerButton
                text="Clone content source"
                styleClass="btn-main"
                type="submit"
              />
            </Modal.Footer>
          </Form>
        </ModalBody>
      </Modal>
    </>
  )
}

export default CopyCampaign
