import React, { useState, useEffect } from "react"

// 3rd-party libraries
import { BiCameraOff } from "react-icons/bi"

import Skeleton from "./skeleton/Skeleton"
import { checkImage } from "./checkImage"
import { IMG_SIZES } from "../constants"

export default function MediaType({
  asset,
  br,
  imgSize,
  background = "none",
  objectFit = "contain",
  aspectRatio = "auto",
  videoSettings = {
    autoplay: "autoplay",
    muted: true,
    loop: true,
    controls: false,
    playsinline: true,
  },
}) {
  const [loading, setLoading] = useState(true)
  const [url, setUrl] = useState(null)

  useEffect(() => {
    // Check if imgage exists and is done with its transformation in the backend
    if (asset) {
      if (asset?.type?.split("/")[0] === "image") {
        let tmpUrl
        if (imgSize === IMG_SIZES.thumb) {
          tmpUrl = asset?.images?.h_200
        } else if (imgSize === IMG_SIZES.preView) {
          tmpUrl = asset?.images?.h_500
        } else {
          tmpUrl = asset?.images?.original
        }

        checkImage(tmpUrl).then(result => {
          if (result.status === "ok") {
            setUrl(tmpUrl)
            setLoading(false)
          } else {
            setUrl(tmpUrl)
            setLoading(false)
          }
        })
      } else {
        setUrl(asset.url)
        setLoading(false)
      }
    }
  }, [asset])

  // Fetch main version

  const renderMedia = () => {
    switch (asset.type) {
      case "video/mp4":
        return (
          <video
            width="100%"
            height="100%"
            src={url ? url : url}
            type="video/mp4"
            {...videoSettings}
            style={{
              background: "#fff",
              borderRadius: br,
            }}
          ></video>
        )
        break
      case "video/quicktime":
        return (
          <video
            width="100%"
            height="100%"
            src={url}
            type="video/mp4"
            {...videoSettings}
            style={{
              background: "#fff",
              borderRadius: br,
            }}
          ></video>
        )
        break
      case "image/jpeg":
      case "image/jpg":
      case "image/png":
        return (
          <img
            width="100%"
            height="100%"
            src={url}
            style={{
              objectFit: objectFit,
              background: background,
              borderRadius: br,
              display: "block",
              aspectRatio: aspectRatio,
              overflow: "hidden",
            }}
            alt=""
          />
        )

        break
      case "audio/mpeg":
      case "audio/ogg":
      case "audio/wav":
      case "audio/x-m4a":
        return (
          <div className="d-flex justify-content-center align-items-center h-100 w-100">
            <audio controls>
              <source src={url} type={asset.type} />
              <p>Your browser doesn't support HTML5 audio. </p>
            </audio>
          </div>
        )

        break
      default:
        return (
          <div
            style={{
              width: "100%",
              height: "100%",
              color: "#fff",
              background: background,
              borderRadius: br,
            }}
            className="d-flex flex-column justify-content-center align-items-center"
          >
            <BiCameraOff size={25} />
            <p>Unsupported Format</p>
          </div>
        )
    }
  }

  if (loading) return <Skeleton />

  return <div style={{ width: "100%", height: "100%" }}>{renderMedia()}</div>
}
