import React, { useState, useEffect } from "react"

// 3 party

import { v4 as uuidV4 } from "uuid"
import { Form } from "react-bootstrap"

// Redux
import { useDispatch, useSelector } from "react-redux"
import {
  addFormField,
  addNewFormField,
  saveFormField,
  setShowEditModal,
} from "../../../../redux/campaign/campaign.actions"
import {
  selectFormSchema,
  selectedField,
} from "../../../../redux/campaign/campaign.selectors"
import ReelerButton from "../../../commons/ReelerButton"
import { useEditor } from "../../../../hooks/useEditor"
import SaveField from "./SaveField"

export default function TextField({ previousFieldId, closeModal }) {
  const dispatch = useDispatch()
  const formSchema = useSelector(selectFormSchema)
  const fieldId = useSelector(selectedField)

  /**
   * Fields:
   * Type (string, integer)
   * format: "email"
   * Description (Above everything)
   * Title (Above input field)
   * Placeholder
   * Requierd
   * Help text
   */

  const { ReelerEditor, getHTML, setDefaultState } = useEditor()

  // Update controlled elements if there is a selected field for edit
  useEffect(() => {
    if (fieldId && formSchema) {
      setDefaultState(formSchema.properties[fieldId].htmlText)
    }
  }, [fieldId])

  const handleSubmit = () => {
    const field = {
      widget: "text",
      htmlText: getHTML(),
    }
    //fieldId is existing if we are updating the field, then we should only update the field and not the fieldId and order.
    if (fieldId) {
      dispatch(saveFormField(fieldId, field))

      //dispatch(setShowEditModal(false))
    } else {
      dispatch(addNewFormField(field, previousFieldId))

      closeModal()
    }
  }
  return (
    <Form>
      <Form.Group>
        <ReelerEditor />
      </Form.Group>

      <SaveField saveFunction={handleSubmit} />
    </Form>
  )
}
