export const FORM_DEFAULT_SETTINGS = {
  formBackground: "transparent",
  fontFamily: "inherit",
  fileBtnFontColor: "#ffffff",
  fileBtnColor: "#6c757d",
  fileBtnBorderColor: "#6c757d",
  fileBtnSize: "md",
  submitBtnFontColor: "#ffffff",
  submitBtnColor: "#000000",
  submitBtnBorderColor: "#000000",
  submitBtnSize: "md",
}
