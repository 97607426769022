import { createSelector } from "reselect"
import { selectCurrentUser } from "../user/user.selectors"

const selectAccount = state => state.account

export const selectAccounts = createSelector(
  [selectAccount],
  account => account.accounts
)

export const selectAccountName = createSelector(
  [selectAccount],
  account => account?.accounts?.name
)

export const selectAccountsIsLoading = createSelector(
  [selectAccount],
  account => account.isLoading
)

export const selectAccountId = createSelector(
  [selectCurrentUser],
  user => user?.accounts[0]
)

export const selectAccountMediaFilter = createSelector(
  [selectAccounts],
  account => account?.mediaFilter
)
