import AccountActionTypes from "./account.types"
import UserActionTypes from "../user/user.types"
import FilterActionTypes from "../filter/filter.types"

const INITIAL_STATE = {
  accounts: null,
  isLoading: true,
  errorMessage: undefined,
}

const accountReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UserActionTypes.EMAIL_SIGN_IN_START:
      return {
        ...state,
        isLoading: true,
        errorMessage: undefined,
      }
    case AccountActionTypes.FETCH_ACCOUNT_SUCCESS:
      return {
        ...state,
        accounts: action.payload,
        isLoading: false,
        errorMessage: undefined,
      }
    case AccountActionTypes.FETCH_ACCOUNT_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload,
      }
    case AccountActionTypes.UPDATE_ACCOUNTS:
      return {
        ...state,
        accounts: { ...state.accounts, ...action.payload },
      }
    case AccountActionTypes.RESET_TO_INITIAL_STATE:
      return {
        ...INITIAL_STATE,
      }
    case UserActionTypes.SIGN_OUT_SUCCESS:
      return {
        ...state,
        accounts: null,
      }
    case FilterActionTypes.CREATE_ACCOUNT_FILTER_SUCCESS:
      return {
        ...state,
        accounts: { ...state.accounts, mediaFilter: action.payload },
      }
    case FilterActionTypes.DELETE_ACCOUNT_FILTER_SUCCESS:
      return {
        ...state,
        accounts: { ...state.accounts, mediaFilter: action.payload },
      }
    default:
      return state
  }
}

export default accountReducer
