import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { database } from "../../../../firebase"
import {
  selectAccountId,
  selectAccounts,
} from "../../../../redux/account/account.selectors"
import CTA from "./components/CTA"
import { Table } from "react-bootstrap"
import { FaPlusCircle } from "react-icons/fa"
import { useHistory } from "react-router-dom"
import FeedsListCard from "./components/FeedsListCard"
import SpinnerComponent from "../../../commons/SpinnerComponent"
import ReelerIconButton from "../../../commons/reelerIconButton/ReelerIconButton"
import Section from "../../../../layouts/core/Section"
import { Header } from "../../../../layouts/core/Header"
import Title from "../../../../layouts/core/Title"
import CreateFeed from "../header/CreateFeed"
import Body from "../../../../layouts/core/Body"
import ReelerButton from "../../../commons/ReelerButton"
import { ACCOUNT_PLANS, MAIN_ROUTES } from "../../../../constants/routes"
import AuthBasedComponent from "../../../authentication/AuthBasedComponent"

const FeedsListView = () => {
  const history = useHistory()
  const accountId = useSelector(selectAccountId)
  const account = useSelector(selectAccounts)
  const [loading, setLoading] = useState(false)
  const [feeds, setFeeds] = useState([])

  useEffect(() => {
    setLoading(true)
    if (accountId) {
      const unsubscribe = database.feeds
        .where("accountId", "==", accountId)
        .orderBy("createdAt", "desc")
        .onSnapshot(snap => {
          const data = snap.docs.map(doc => database.formatDoc(doc))
          setFeeds(data)
          setLoading(false)
        })
      return () => unsubscribe()
    }
  }, [accountId])

  return (
    <Section>
      <Header>
        <Title>Feeds</Title>
        <div className="d-flex ms-auto">
          <CreateFeed
            btn={
              <ReelerIconButton
                text="Create feed"
                icon={<FaPlusCircle className="me-1" />}
              />
            }
          />
          <AuthBasedComponent plans={[ACCOUNT_PLANS.premium]}>
            <ReelerButton
              text="Manage API"
              styleClass="btn-secondary ms-3"
              dispatch={() => history.push(MAIN_ROUTES.manageApiView.path)}
            />
          </AuthBasedComponent>
        </div>
      </Header>
      <Body>
        {!loading ? (
          feeds && feeds.length > 0 ? (
            <Table className="mt-3" hover>
              <thead>
                <tr
                  style={{
                    height: "50px",
                    borderBottom: "2px solid #dee2e6",
                  }}
                >
                  <th className="align-middle" style={{ width: "300px" }}>
                    Name
                  </th>
                  <th style={{ width: "150px", borderBottom: "none" }}>Type</th>
                  <th style={{ width: "150px", borderBottom: "none" }}>
                    Layout
                  </th>

                  <th className="icon-btn">Created</th>

                  <th className="text-center"></th>
                </tr>
              </thead>

              <tbody>
                {feeds.map((feed, index) => {
                  return <FeedsListCard key={index} feed={feed} />
                })}
              </tbody>
            </Table>
          ) : (
            <CTA />
          )
        ) : (
          <SpinnerComponent size="lg" />
        )}
      </Body>
    </Section>
  )
}

export default FeedsListView
