import React from "react"
import { FaInstagram } from "react-icons/fa"
import * as S from "../styles-render-feed"
import { CAMPAIGN_TYPE } from "../constants/index"

export default function FeedItemSoMeIcon({ asset }) {
  if (
    [CAMPAIGN_TYPE.igHashtag.type, CAMPAIGN_TYPE.igMentions.type].includes(
      asset?.campaign_type
    )
  ) {
    return (
      <S.TopRight>
        <S.CampaignType className="icon-btn">
          <FaInstagram size="1.5rem" />
        </S.CampaignType>
      </S.TopRight>
    )
  } else return null
}
