import React, { useEffect } from "react"

// Redux
import { useDispatch, useSelector } from "react-redux"
import {
  setIsFeedDirty,
  updateFeed,
} from "../../../../../../redux/feed/feed.actions"
import { selectFeed } from "../../../../../../redux/feed/feed.selectors"

// 3rd-party components
import { Row, Col, Form } from "react-bootstrap"
import UnitInputGroup from "../../../../../commons/UnitInputGroup/UnitInputGroup"

// Reeler components
import { SectionHeading, InputUnit } from "../../../../styles"
import ColorPicker from "../../../../../commons/ColorPicker"
import ColorPicker2 from "../../../../../commons/ColorPicker2"

const CarouselSettings = () => {
  const dispatch = useDispatch()
  const feed = useSelector(selectFeed)

  const handleChange = ({ target: { name, value, type } }) => {
    if (type === "number") {
      dispatch(
        updateFeed({
          feedSettings: { ...feed.feedSettings, [name]: Number(value) },
        })
      )
    } else {
      dispatch(
        updateFeed({ feedSettings: { ...feed.feedSettings, [name]: value } })
      )
    }
    dispatch(setIsFeedDirty(true))
  }

  const selectColor = hex => {
    dispatch(
      updateFeed({
        feedSettings: {
          ...feed.feedSettings,
          buttonColor: hex,
        },
      })
    )
  }
  const clearColor = () => {
    dispatch(
      updateFeed({
        feedSettings: {
          ...feed.feedSettings,
          buttonColor: "#000",
        },
      })
    )
  }

  return (
    <>
      <SectionHeading>Carousel</SectionHeading>
      <Row className="mb-3">
        <Col md={4}>
          <div className="custom-control custom-switch">
            <input
              type="checkbox"
              className="custom-control-input"
              id="showButtons"
              checked={Boolean(feed.feedSettings.showButtons)}
              name="showButtons"
              onChange={() =>
                dispatch(
                  updateFeed({
                    feedSettings: {
                      ...feed.feedSettings,
                      showButtons: !feed.feedSettings.showButtons,
                    },
                  })
                )
              }
            />
            <label className="custom-control-label" htmlFor="showButtons">
              Show buttons
            </label>
          </div>
        </Col>
        <Col>
          {feed.feedSettings.showButtons ? (
            <ColorPicker2
              defaultColor={feed?.feedSettings?.buttonColor}
              selectColor={selectColor}
              clearColor={clearColor}
              text="Button color:"
            />
          ) : null}
        </Col>
        {/* <div className="custom-control custom-switch">
            <input
              type="checkbox"
              className="custom-control-input"
              id="dots"
              checked={Boolean(feed.feedSettings.dots)}
              name="dots"
              onChange={() =>
                dispatch(
                  updateFeed({
                    feedSettings: {
                      ...feed.feedSettings,
                      dots: !feed.feedSettings.dots,
                    },
                  })
                )
              }
            />
            <label className="custom-control-label" htmlFor="dots">
              Dots
            </label>
          </div> */}
      </Row>
      <Row className="mb-3">
        <Col>
          <Form.Group className="mb-3" controlId="slidesToShow">
            <Form.Label>Assets in a row</Form.Label>
            <br />
            <Form.Control
              type="number"
              min={1}
              max={50}
              value={
                feed.feedSettings.slidesToShow
                  ? feed.feedSettings.slidesToShow
                  : 3
              }
              name="slidesToShow"
              onChange={handleChange}
            />
            <Form.Text muted>Number of assets displayed</Form.Text>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3" controlId="gutter">
            <UnitInputGroup
              label="Spacing"
              name="gutter"
              min={0}
              max={20}
              value={feed.feedSettings.gutter ? feed.feedSettings.gutter : 0}
              dispatch={handleChange}
              unit="px"
            />

            <Form.Text muted>Spacing between assets</Form.Text>
          </Form.Group>
        </Col>
      </Row>
    </>
  )
}

export default CarouselSettings
