import styled from "styled-components"

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--reeler-icon);
  width: ${({ $size }) => ($size ? $size : "30px")};
  height: ${({ $size }) => ($size ? $size : "30px")};
  margin: 2px;
  &:hover {
    cursor: pointer;
    border-radius: 50%;
    background-color: var(--reeler-grey);
  }
`

export const EditorContainer = styled.div`
  background-color: rgb(255, 255, 255);
  padding: 10px;
  border-radius: 3px;
  border: 1px solid var(--reeler-grey-middle);
`

export const Tag = styled.div`
  display: inline-block;
  margin: 0.5rem;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background: var(--reeler-grey);
  border-radius: 10rem;
  cursor: pointer;
`

export const IconNotification = styled.div`
  aspect-ratio: 1/1;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  border-radius: 50%;
  font-size: 10px;

  position: absolute;
  top: 4px;
  right: -12px;
`
export const StatusIcon = styled.span`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background: ${({ color }) => (color ? color : "var(--reeler-grey)")};
  width: ${({ size }) => (size ? size : "20px")};
  height: ${({ size }) => (size ? size : "20px")};
`

export const ThirdPartyStatusIcon = styled.span`
  position: absolute;
  top: -4px;
  right: -4px;
  display: flex;
  border-radius: 10px;
  background: ${({ color }) => color};
  width: ${({ size }) => (size ? size : "10px")};
  height: ${({ size }) => (size ? size : "10px")};
`
