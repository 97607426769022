import styled from "styled-components"

export const Styles = styled.body`
  background-color: #d9d9d9;
  h1 {
    font-size: 32px;
    font-weight: 800;
    line-height: 56px;
    text-align: center;
    color: #00314d;
    margin-bottom: 2rem;
  }

  h2 {
    text-align: ${props => props.$center && "center"};
    font-size: 20px;
    font-weight: bold;
    color: #00314d;
  }

  p {
    font-size: 1rem;
    font-family: Roboto;
    color: #00314d;
    font-weight: 300;
    span {
      font-style: italic;
    }
  }

  small {
    font-family: Roboto;
    color: #00314d;
  }

  .text {
    padding: 10px;
  }
`
// Culture care colors
// #00314D
// #FA329B
// #FF7873
// #D9D9D9

const getWidthString = span => {
  if (!span) {
    return
  }

  let width = (span / 12) * 100
  return `width: ${width}%`
}

export const Row = styled.div`
  &::after {
    content: "";
    clear: both;
    display: table;
  }
`

export const LoadingScreen = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
`

export const Col = styled.div`
  /* background-color: rgba(255, 0, 0, 0.2); */
  float: left;
  ${({ xs }) => (xs ? getWidthString(xs) : "width:100%")}

  @media only screen and (min-width: 768px) {
    ${({ sm }) => sm && getWidthString(sm)}
  }

  @media only screen and (min-width: 992px) {
    ${({ md }) => md && getWidthString(md)}
  }

  @media only screen and (min-width: 1200px) {
    ${({ lg }) => lg && getWidthString(lg)}
  }
`

export const PageContainer = styled.div`
  /* background-color: var(--reeler-grey-darker); */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* width: 80vw; */
  margin: 0 auto;
  padding: 1rem;

  /* Typography */
  font-family: Roboto;
  color: #252525;
`

export const SplashContainer = styled.div`
  width: 300px;
  margin-bottom: 4rem;
`

export const Heading = styled.h1`
  font-size: 48px;
  font-weight: 900;
  line-height: 56px;
  text-align: center;
  color: #00314d;
  margin-bottom: 2rem;
`

export const CcIntroSection = styled.section`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  width: 100%;
  /* margin-top: ; */
  /* line-height: 28px; */
`

export const Paragraph = styled.p`
  font-size: 1.5rem;
  span {
    font-style: italic;
  }
`

export const Divider = styled.div`
  margin: 5rem 0;
  /* width: 80vw; */
  border-top: 2px solid #d9d9d9;
`

export const MotivationSection = styled.section`
  margin-top: 1rem;
`

export const CheckboxContainer = styled.div`
  /* height: 40px; */
  background-color: #d9d9d9;
  padding: 1rem;
  margin: 1rem 1rem 1rem 0;
  border-radius: 5px;
  /* width: 218px; */
`

export const Heading2 = styled.h2`
  text-align: ${props => props.$center && "center"};
  font-size: 1rem;
  font-weight: 800;
`

export const ConsentSection = styled.section``

export const AssetContainer = styled.div`
  position: relative !important;
  border-radius: 3px;
  cursor: pointer;
  border: 1px solid #dee2e6 !important;
  background: #f8f9fa !important;
  box-shadow: rgb(0 0 0 / 13%) 0px 3.2px 7.2px 0px,
    rgb(0 0 0 / 11%) 0px 0.6px 1.8px 0px;
`

export const MediaContainer = styled.div`
  height: 200px;
  cursor: pointer;
  text-align: center !important;
`

export const TopLeft = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`
export const ImageContainer = styled.div`
  display: flex;
  justify-content: ${({ $align }) => ($align ? $align : "center")};
  width: 100%;
`
