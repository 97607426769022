import React from "react"
import Section from "../../../layouts/core/Section"
import {
  Header,
  HeaderGroup,
  HeaderGroupCenter,
  HeaderGroupLeft,
  HeaderGroupRight,
} from "../../../layouts/core/Header"
import Body from "../../../layouts/core/Body"
import Title from "../../../layouts/core/Title"
import BackButton from "../../../components/commons/BackButton"
import EmailTemplatesTabel from "./components/EmailTemplatesTabel"
import EditEmailTemplate from "./components/EditEmailTemplate"
import ReelerButton from "../../../components/commons/ReelerButton"

export default function EmailTemplatesPage() {
  return (
    <Section>
      <Header>
        <HeaderGroup>
          <HeaderGroupLeft>
            <Title>Email templates</Title>
          </HeaderGroupLeft>
          <HeaderGroupCenter></HeaderGroupCenter>
          <HeaderGroupRight>
            <BackButton />
            <EditEmailTemplate
              btn={<ReelerButton text="New template" styleClass="btn-main" />}
            />
          </HeaderGroupRight>
        </HeaderGroup>
      </Header>
      <Body>
        <p>
          Write one or several email templates that you will send to end-users,
          asking them to visit an “approval page” to grant you permission for
          the use of content. For instance, you could create one email template
          for content creators (granting permission to use their work) and
          another email template for 3rd parties (granting permission for the
          use of images where they appear). At the moment of sending an email,
          you define if it’s a creator or a 3rd party you are emailing to, and
          which email template to use. You will be able to tweak or personalize
          the email text each time you send it.
        </p>
        <EmailTemplatesTabel />
      </Body>
    </Section>
  )
}
