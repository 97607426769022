import React from "react"
import { FaInstagram, FaLink } from "react-icons/fa"
import AssetTextField from "./AssetTextField"
import ReelerTooltip from "../../../../../commons/ReelerTooltip/ReelerTooltip"
import SingleRowIcon from "./SingleRowIcon"
export default function AssetViewIGPost({ permalink }) {
  var windowFeatures = "height=650,width=900"

  function openRequestedPopup(url) {
    window.open(url, "Instagram_window", windowFeatures)
  }

  return (
    <AssetTextField
      icon={
        <SingleRowIcon tooltip="View post on Instagram">
          <FaInstagram className="icon-color" />
        </SingleRowIcon>
      }
      text={
        <span
          style={{ cursor: "pointer", textDecoration: "underline" }}
          className="asset-info-text"
          onClick={() => openRequestedPopup(permalink)}
        >
          View post on Instagram
        </span>
      }
    />
  )
}
