import React, { useState } from "react"

// 3rd-party
import { Modal, ModalBody } from "react-bootstrap"
import { FaTrashAlt } from "react-icons/fa"

// Firebase
import "firebase/firestore"
import { database, storage } from "../../../../../../firebase"

// Redux
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { selectActiveAsset } from "../../../../../../redux/asset/asset.selectors"
import {
  clearSelectedVersion,
  updateAsset,
} from "../../../../../../redux/asset/asset.actions"

// Reeler components
import ReelerButton from "../../../../../commons/ReelerButton"
import ReelerIconButton from "../../../../../commons/reelerIconButton/ReelerIconButton"
import ReelerTooltip from "../../../../../commons/ReelerTooltip/ReelerTooltip"

const DeleteVersion = ({ version, as = "icon", size = 14 }) => {
  const dispatch = useDispatch()
  const asset = useSelector(selectActiveAsset)
  const [open, setOpen] = useState(false)

  const openModal = () => setOpen(true)
  const closeModal = () => {
    setOpen(false)
    dispatch(clearSelectedVersion)
  }

  const handleDeleteVersion = versionToDelete => {
    let currentArr = asset.assetVersions
    // filter out the version to delete
    let updatedArr = currentArr.filter(
      version => version.id !== versionToDelete.id
    )
    var docRef = database.assets.doc(asset.id)

    // remove file from storage
    storage
      .refFromURL(`${versionToDelete.url}`)
      .delete()
      .then(() => {})
      .catch(error => {})

    docRef
      .update({ ...asset, assetVersions: updatedArr })
      .then(dispatch(updateAsset({ ...asset, assetVersions: updatedArr })))
  }
  return (
    <>
      {as !== "icon" ? (
        <div
          className="d-flex flex-row align-items-center me-3 link"
          style={{ cursor: "pointer" }}
          onClick={openModal}
        >
          <ReelerTooltip text="Delete this version of the asset">
            <>
              <FaTrashAlt className="me-2" />
            </>
          </ReelerTooltip>
        </div>
      ) : (
        <ReelerTooltip text="Delete this version of the asset">
          <FaTrashAlt
            onClick={openModal}
            size={size}
            style={{ cursor: "pointer", color: "var(--reeler-icon)" }}
          />
        </ReelerTooltip>
      )}
      <Modal centered show={open} onHide={closeModal}>
        <ModalBody>
          <Modal.Title>
            Are you sure you want to delete this content?
          </Modal.Title>
          <Modal.Body>Deleted content cannot be recreated</Modal.Body>
          <Modal.Footer>
            <ReelerButton
              text="Close"
              dispatch={closeModal}
              styleClass="btn-secondary"
            />
            <ReelerIconButton
              text="Delete content"
              icon={<FaTrashAlt className="me-1" />}
              styleClass="btn-delete"
              dispatch={() => handleDeleteVersion(version)}
            />
          </Modal.Footer>
        </ModalBody>
      </Modal>
    </>
  )
}

export default DeleteVersion
