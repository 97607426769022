import React, { useEffect, useState } from "react"
import { database } from "../../../../../firebase"
import { useSelector, useDispatch } from "react-redux"
import { selectAccounts } from "../../../../../redux/account/account.selectors"
import SpinnerComponent from "../../../../../components/commons/SpinnerComponent"
import { selectCreator } from "../../../../../redux/creators/creators.selectors"
import ContentGridAsset from "../../../../../components/campaigns/campaign/components/ContentGridAsset"
import AssetInfoModal from "../../../../../components/campaigns/campaign/components/asset-info/AssetInfoModal"
import Text from "../../../../../components/commons/Text"

export default function CreatorAssets() {
  const [loading, setLoading] = useState(true)
  const [assets, setAssets] = useState(null)
  const account = useSelector(selectAccounts)
  const creator = useSelector(selectCreator)

  useEffect(() => {
    if (account && creator) {
      setLoading(true)

      const unsubscribe = database.assets
        .where("accountId", "==", account.id)
        .where("creatorId", "==", creator.id)
        .orderBy("createdAt", "desc")
        .onSnapshot(snapshot => {
          const data = snapshot.docs.map(doc => database.formatDoc(doc))
          setAssets(data)
          setLoading(false)
        })

      return () => unsubscribe()
    }
  }, [account.id, creator.id])

  if (loading) {
    return <SpinnerComponent />
  }

  return (
    <div className="">
      <AssetInfoModal />
      <div className="mb-3">
        <Text size="md" weight="bold" color="var(--reeler-title)">
          Reeler assets <span>({assets?.length})</span>
        </Text>
      </div>
      {assets.length > 0 ? (
        <div
          style={{
            display: "grid",
            gridGap: "0.3rem",
            gridTemplateColumns:
              "repeat(auto-fill, minmax(min(10rem, 100%), 1fr))",
          }}
        >
          {assets.map(asset => {
            return (
              <ContentGridAsset
                key={asset.id}
                asset={asset}
                disableBulkEdit={true}
              />
            )
          })}
        </div>
      ) : (
        <Text size="md" color="var(--reeler-text)">
          No assets connected to this creator yet
        </Text>
      )}
    </div>
  )
}
