import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { selectAccounts } from "../../../../../../../redux/account/account.selectors"
import {
  selectActiveAsset,
  selectAssetRightsRequest,
} from "../../../../../../../redux/asset/asset.selectors"
import {
  updateAsset,
  setAssetRightsRequest,
  clearAssetRightsRequest,
  setCloseAssetRightsRequestModal,
} from "../../../../../../../redux/asset/asset.actions"
// Firebase
import { database, functions } from "../../../../../../../firebase"

import { Form } from "react-bootstrap"
// Reeler components
import ReelerButton from "../../../../../../commons/ReelerButton"
import CopyToClipboard from "../../../../../../commons/CopyToClipboard"

import * as S from "../../../styles"
import {
  ASSET_RIGHT_STATUS,
  RIGHTS_REQUEST_TYPE,
  RIGHTS_EMAIL_CODES,
} from "../../../../../../../constants"
import FadeAlert from "../../../../../../commons/FadeAlert/FadeAlert"
import useUserFeedbackMessage from "../../../../../../../hooks/useUserFeedbackMessage"
import EditEmailTemplate from "../../../../../../../pages/Rights/EmailTemplates/components/EditEmailTemplate"

export default function RightRequestEmail() {
  const dispatch = useDispatch()
  const account = useSelector(selectAccounts)
  const rightsRequest = useSelector(selectAssetRightsRequest)
  const asset = useSelector(selectActiveAsset)
  const [loading, setLoading] = useState(false)
  const { setUserFeedbackMessage } = useUserFeedbackMessage()

  const [emailTemplate, setEmailTemplate] = useState({
    emailTemplateName: "",
    senderAlias: "",
    subject: "",
    body: "",
  })

  const [recipientsEmail, setRecipientsEmail] = useState(
    rightsRequest?.contactDetails?.email
      ? rightsRequest?.contactDetails?.email
      : null
  )

  useEffect(() => {
    if (rightsRequest?.contactDetails?.email) {
      setRecipientsEmail(rightsRequest?.contactDetails?.email)
    }
  }, [rightsRequest?.contactDetails?.email])

  const handleSendEmail = async email => {
    var handleSendEmail = functions.httpsCallable("handleSendEmail")

    const { data } = await handleSendEmail({
      email,
    })
    return data
  }

  const handleSendRightRequestWithEmail = async () => {
    setLoading(true)

    let body = emailTemplate?.body

    if (!body.includes(RIGHTS_EMAIL_CODES.link)) {
      setUserFeedbackMessage(
        "Make sure to include " + RIGHTS_EMAIL_CODES.link + " in the body text",
        "danger"
      )

      setLoading(false)
      return
    }

    let regex = new RegExp(RIGHTS_EMAIL_CODES.link, "g")
    body = body.replace(regex, rightsRequest?.link)

    let regexName = new RegExp(RIGHTS_EMAIL_CODES.approverFirstName, "g")
    if (rightsRequest?.contactDetails?.name) {
      body = body.replace(regexName, rightsRequest?.contactDetails?.name)
    } else {
      body = body.replace(regexName, "")
    }

    body = body.replace(/\r\n|\r|\n/g, "<br />")

    const email = {
      to: recipientsEmail,
      from: {
        email: "noreply@reelertech.com",
        name: emailTemplate?.senderAlias,
      },
      subject: emailTemplate?.subject,
      text: body,
      html: body,
    }

    try {
      await handleSendEmail(email)

      const approvalStatusLog = {
        approvalStatus: ASSET_RIGHT_STATUS.pending,
        approvalStatusLog: [
          ...rightsRequest?.approvalStatusLog,
          {
            status: ASSET_RIGHT_STATUS.pending,
            note: "Sent email through Reeler",
            createdAt: Date.now(),
          },
        ],
      }

      database.rightRequests
        .doc(rightsRequest.id)
        .update(approvalStatusLog)
        .then(() => {
          dispatch(
            setAssetRightsRequest({
              ...rightsRequest,
              ...approvalStatusLog,
            })
          )
          const assetRef = database.assets.doc(asset.id)
          if (rightsRequest.requestType === RIGHTS_REQUEST_TYPE.thirdParty) {
            assetRef
              .update({
                thirdPartyApprovalStatus: ASSET_RIGHT_STATUS.pending,
              })
              .then(() => {
                dispatch(
                  updateAsset({
                    ...asset,
                    thirdPartyApprovalStatus: ASSET_RIGHT_STATUS.pending,
                  })
                )
              })
          } else {
            assetRef
              .update({
                status: ASSET_RIGHT_STATUS.pending,
              })
              .then(() => {
                dispatch(
                  updateAsset({
                    ...asset,
                    status: ASSET_RIGHT_STATUS.pending,
                  })
                )
              })
          }
          setUserFeedbackMessage("Email sent!")
          dispatch(setCloseAssetRightsRequestModal())
          setLoading(false)
        })
        .catch(err => {
          setLoading(false)
          setUserFeedbackMessage("The message could not be saved", "danger")
        })
    } catch (err) {
      console.log(err)
      setUserFeedbackMessage("Something went wrong", "danger")

      setLoading(false)
    }
  }

  const handleChange = e => {
    const index = e.target.value
    let template = {
      ...account?.right_request_templates?.emailTemplates[index],
    }

    setEmailTemplate(template)
  }

  return account && asset ? (
    <>
      {account?.right_request_templates?.emailTemplates?.length > 0 ? (
        <Form.Group>
          <Form.Label>Select email template (optional)</Form.Label>
          <Form.Control as="select" name="textMessage" onChange={handleChange}>
            <option>Choose predefined email template</option>
            {account?.right_request_templates?.emailTemplates?.map(
              (emailTemplate, index) => (
                <option key={index} value={index}>
                  {`${index + 1}: ${emailTemplate.emailTemplateName}`}
                </option>
              )
            )}
          </Form.Control>
        </Form.Group>
      ) : (
        <Form.Group className="p-3">
          <Form.Text>
            You haven’t created any email templates yet. Please create an email
            template, later you can edit all templates under Rights in the menu.
          </Form.Text>
          <EditEmailTemplate
            btn={
              <ReelerButton
                text="Create new email template"
                styleClass="btn-main mt-3"
              />
            }
          />
        </Form.Group>
      )}

      <Form.Group>
        <Form.Label>Recipients email</Form.Label>

        <Form.Control
          type="email"
          value={recipientsEmail}
          onChange={e => setRecipientsEmail(e.target.value)}
        />
        <Form.Text muted>Add only one email</Form.Text>
      </Form.Group>
      <Form.Group>
        <Form.Label>Email sender alias</Form.Label>

        <Form.Control
          type="text"
          value={emailTemplate?.senderAlias ? emailTemplate?.senderAlias : ""}
          onChange={e =>
            setEmailTemplate({
              ...emailTemplate,
              senderAlias: e.target.value,
            })
          }
        />
        <Form.Text muted>
          Add an email sender alias that will be shown in the email client
        </Form.Text>
      </Form.Group>
      <Form.Group>
        <Form.Label>Email subject</Form.Label>
        <Form.Control
          type="text"
          value={emailTemplate?.subject ? emailTemplate?.subject : ""}
          onChange={e =>
            setEmailTemplate({
              ...emailTemplate,

              subject: e.target.value,
            })
          }
        />
        <Form.Text muted>What should the email subject be?</Form.Text>
      </Form.Group>
      <Form.Group>
        <Form.Label>Email body</Form.Label>
        <Form.Control
          as="textarea"
          rows={5}
          value={emailTemplate?.body ? emailTemplate?.body : ""}
          onChange={e =>
            setEmailTemplate({
              ...emailTemplate,

              body: e.target.value,
            })
          }
        />

        <Form.Text muted>
          Choose one of your email templates, then tweak or personalize your
          text as you’d like for this individual. Alternatively, you can write a
          completely new email. Ensure that your final email text includes the
          element{" "}
          <span style={{ fontWeight: "bold" }}>{RIGHTS_EMAIL_CODES.link}</span>{" "}
          <CopyToClipboard
            link={RIGHTS_EMAIL_CODES.link}
            tooltipText="Copy link code and past in the email body."
          />{" "}
          this is where the link to the recipient’s personal approval page will
          be automatically inserted when you send the email. If you want to
          personalize the email include{" "}
          <span style={{ fontWeight: "bold" }}>
            {RIGHTS_EMAIL_CODES.approverFirstName}
          </span>{" "}
          <CopyToClipboard
            link={RIGHTS_EMAIL_CODES.approverFirstName}
            tooltipText="Copy name code and past in the email body."
          />{" "}
          to use the first name in the contact details.
        </Form.Text>
      </Form.Group>
      <div className="d-flex">
        <ReelerButton
          disabled={!recipientsEmail}
          loading={loading}
          text="Send email"
          styleClass="btn-main"
          size="sm"
          dispatch={() => handleSendRightRequestWithEmail()}
        />
      </div>
    </>
  ) : null
}
