import React, { useState } from "react"

// 3rd-party
import { Row, Col } from "react-bootstrap"

// Redux
import { useSelector } from "react-redux"
import {
  selectCampaign,
  selectFormSchema,
} from "../../../../../redux/campaign/campaign.selectors"

import * as S from "../../styles"
import { FormDesign } from "./campaignInfo/FormDesign"
import PreviewForm from "../../components/PreviewForm"

export default function () {
  const [mobileView, setMobileView] = useState(false)
  const formSchema = useSelector(selectFormSchema)
  const campaign = useSelector(selectCampaign)

  return (
    <S.FormBuilderContainer>
      <Row>
        <Col
          md={4}
          style={{ borderRight: "1px solid var(--reeler-grey-dark)" }}
        >
          <FormDesign />
        </Col>
        <Col md={8}>
          <PreviewForm />
        </Col>
      </Row>
    </S.FormBuilderContainer>
  )
}
