import styled, { keyframes } from "styled-components"

const fadeIn = keyframes`
0% { opacity: 0; }
  100% { opacity: 1; }
`
const slideIn = keyframes`
    from {
      transform: translateY(-100%);
    }
    to {
      transform: translateY(0%);
    }
  
`

export const AlertContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

export const Alert = styled.div`
  position: fixed;
  top: 20px;
  border-radius: 0px;
  text-align: center;
  animation: ${slideIn} 500ms;
  padding: 1rem;
  color: var(--reeler-title);
  background-color: ${({ bgColor }) => `${bgColor}`};
  border: 1px solid ${({ borderColor }) => `${borderColor}`};
  z-index: 99999999;
  box-shadow: var(--reeler-shadow);
`

export const AlertBodyContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 2rem;
`
export const AlertLead = styled.span`
  margin-right: 0.5rem;
  font-weight: bolder;
`
export const AlertText = styled.span``

export const CloseButtonContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`
