import React, { useState, useEffect } from "react"
import { database } from "../../firebase"

// Third-party
import { Table } from "react-bootstrap"
import { FaCircle } from "react-icons/fa"
import moment from "moment"
import { useSelector } from "react-redux"

// Reeler components
import Loader from "../commons/loader/Loader"
import { useAuth } from "../../contexts/AuthContext"
import { DOWNLOAD_FILE_STATES } from "../../constants"
import * as S from "./download-styles"
import { humanFileSize } from "../../utils/humanFileSize"
import Section from "../../layouts/core/Section"
import { Header } from "../../layouts/core/Header"
import Title from "../../layouts/core/Title"
import Body from "../../layouts/core/Body"
import BackButton from "../commons/BackButton"
import { selectCurrentUser } from "../../redux/user/user.selectors"
import { USER_ROLES } from "../../constants/routes"

export default function DownloadView() {
  const { account } = useAuth()
  const [loading, setLoading] = useState(true)
  const [downloadJobs, setDownloadJobs] = useState()
  const currentUser = useSelector(selectCurrentUser)

  useEffect(() => {
    if ((account, currentUser)) {
      let q = database.downloadJobs.where("accountId", "==", account.id)

      if (currentUser?.role === USER_ROLES.viewer) {
        q = q.where("userId", "==", currentUser?.id)
      }

      q.orderBy("createdAt", "desc").onSnapshot(querySnapshot => {
        var jobs = []
        querySnapshot.forEach(doc => {
          jobs.push(database.formatDoc(doc))
        })
        setDownloadJobs(jobs)
        setLoading(false)
      })
    }
  }, [account, currentUser])

  const renderJobs = () => (
    <Table className="mt-3" responsive hover>
      <thead>
        <tr>
          <th>Name</th>
          <th>Size</th>
          <th>Assets</th>
          <th>User</th>
          <th>Date</th>
        </tr>
      </thead>
      <tbody>
        {downloadJobs?.map((job, index) => {
          return (
            <tr key={index}>
              <td>
                <div className="d-flex flex-column">
                  <span>
                    {job.status === DOWNLOAD_FILE_STATES.finished ? (
                      <S.DownloadLink href={job?.download_url}>
                        {job.filename}
                      </S.DownloadLink>
                    ) : (
                      <S.Inacvtive>{job.filename}</S.Inacvtive>
                    )}
                  </span>
                  {job.status === DOWNLOAD_FILE_STATES.processing ? (
                    <span>
                      <FaCircle
                        className="me-1"
                        style={{ color: "var(--reeler-warning)" }}
                      />
                      <small>Processing...</small>
                    </span>
                  ) : job.status === DOWNLOAD_FILE_STATES.finished ? (
                    <span>
                      <FaCircle
                        className="me-1"
                        style={{ color: "var(--reeler-light)" }}
                      />
                      <small>Download available</small>
                    </span>
                  ) : (
                    <span>
                      <FaCircle
                        className="me-1"
                        style={{ color: "var(--reeler-danger)" }}
                      />
                      <small>Error</small>
                    </span>
                  )}
                </div>
              </td>
              <td>
                <small>
                  {job?.file_size ? humanFileSize(job?.file_size, true) : null}
                </small>
              </td>
              <td>
                <small>{job.assets.length}</small>
              </td>
              <td>
                <small>{job.username}</small>
              </td>
              <td>
                <small>
                  {" "}
                  {moment(job.createdAt.toDate()).format(
                    "YYYY-MM-DD hh:ss"
                  )}{" "}
                </small>
              </td>
            </tr>
          )
        })}
      </tbody>
    </Table>
  )

  return (
    <Section>
      <Header>
        <Title>Downloads</Title>
        <div className="ms-auto">
          <BackButton />
        </div>
      </Header>
      <Body>{loading ? <Loader /> : renderJobs()}</Body>
    </Section>
  )
}
