import React, { useEffect, useState } from "react"
import { Row, Col, Form } from "react-bootstrap"

import { Link } from "react-router-dom"
// Redux
import { useSelector, useDispatch } from "react-redux"
import { selectAssetRightsRequest } from "../../../../../../../redux/asset/asset.selectors"
import { setAssetRightsRequest } from "../../../../../../../redux/asset/asset.actions"
import { selectAccounts } from "../../../../../../../redux/account/account.selectors"

// Reeler components
import ReelerButton from "../../../../../../commons/ReelerButton"

import { useEditor } from "../../../../../../../hooks/useEditor"

import * as S from "../../../styles"

import ReelerAccordation from "../../../../../../commons/ReelerAccordation"
import { MAIN_ROUTES } from "../../../../../../../constants/routes"
import SaveRightRequest from "./SaveRightRequest"
import EditApprovalPageTemplate from "../../../../../../../pages/Rights/AppovalPageTemplates/components/EditApprovalPageTemplate"

export default function SelectApprovalPageTemplate() {
  const dispatch = useDispatch()
  const [saving, setSaving] = useState(false)

  const { ReelerEditor, getHTML, setDefaultState, editorState } = useEditor()

  const account = useSelector(selectAccounts)
  const rightsRequest = useSelector(selectAssetRightsRequest)

  useEffect(() => {
    if (rightsRequest) {
      if (rightsRequest?.approvalPage?.rightsRequestText) {
        setDefaultState(rightsRequest?.approvalPage?.rightsRequestText)
      } else {
        const approvalPage = account?.right_request_templates?.approvalPages[0]

        dispatch(
          setAssetRightsRequest({
            ...rightsRequest,
            approvalPage: approvalPage,
          })
        )

        setDefaultState(approvalPage?.rightsRequestText)
      }
    }
  }, [])

  useEffect(() => {
    if (editorState && rightsRequest?.approvalPage) {
      console.log("Updating editor state")
      dispatch(
        setAssetRightsRequest({
          ...rightsRequest,
          approvalPage: {
            ...rightsRequest.approvalPage,
            rightsRequestText: getHTML(),
          },
        })
      )
    }
  }, [editorState])

  const handleChangeLinkTemplate = value => {
    if (value !== "showAll") {
      const approvalPage =
        account?.right_request_templates?.approvalPages[value]
      console.log(approvalPage)
      dispatch(
        setAssetRightsRequest({
          ...rightsRequest,
          approvalPage,
        })
      )
      setDefaultState(approvalPage?.rightsRequestText)
    }
  }

  return (
    <Row>
      <Col className="pb-4">
        {account?.right_request_templates?.approvalPages.length > 0 ? (
          <>
            <Form.Group controlId="userId">
              <Form.Label className="form-label-larger">
                Select approval page template
              </Form.Label>
              <Form.Control
                as="select"
                name="link_templates"
                value={
                  account?.right_request_templates?.approvalPages
                    ? account?.right_request_templates?.approvalPages
                        .map(function (e) {
                          return e.link_name
                        })
                        .indexOf(rightsRequest?.approvalPage?.link_name)
                    : 0
                }
                onChange={e => handleChangeLinkTemplate(e.target.value)}
              >
                <option value="showAll">--</option>
                {account?.right_request_templates?.approvalPages?.map(
                  (link, index) => (
                    <option key={index} value={index}>
                      {link.link_name}
                    </option>
                  )
                )}
              </Form.Control>
              <Form.Text muted>
                Select which approval page template to use.
              </Form.Text>
            </Form.Group>

            <div
              style={{
                margin: "0 0 0 1rem",
                padding: "0rem 1rem",
                border: "1px solid var(--reeler-grey-dark)",
              }}
            >
              <ReelerAccordation
                visible={false}
                title={
                  <span
                    style={{
                      fontSize: "1rem",
                      color: "var(--reeler-title)",
                    }}
                  >
                    Personalize approval page text (optional)
                  </span>
                }
              >
                <Form.Group className="mb-3">
                  <div style={{ maxHeight: "400px", overflow: "auto" }}>
                    <ReelerEditor />
                  </div>

                  <Form.Text className="text-muted">
                    Here you can personalize the text on the approval page, to
                    boost your chances of getting approval (optional).
                  </Form.Text>
                </Form.Group>
              </ReelerAccordation>
            </div>
          </>
        ) : (
          <Form.Group className="p-3">
            <Form.Text>
              You haven’t created any approval page templates yet. To start
              generating individual approval pages, please first create an
              approval page template, you can edit all templates under Rights in
              the menu.
            </Form.Text>
            <EditApprovalPageTemplate
              btn={
                <ReelerButton
                  text="Create new approval page template"
                  styleClass="btn-main mt-3"
                />
              }
            />
            {/* <Link className="mt-1" to={MAIN_ROUTES.rightsApprovalPages.path}>
              <ReelerButton text="Go here" />
            </Link> */}
          </Form.Group>
        )}
      </Col>
    </Row>
  )
}
