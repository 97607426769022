import React from "react"

import {
  FaHeading,
  FaAlignLeft,
  FaImage,
  FaGripLines,
  FaFileSignature,
  FaRegImages,
} from "react-icons/fa"

import {
  MdDateRange,
  MdNotes,
  MdOutlineAlternateEmail,
  MdPhone,
  MdShortText,
  MdArrowDropDownCircle,
  MdCheckBox,
  MdRadioButtonChecked,
} from "react-icons/md"

import { IoMdGlobe } from "react-icons/io"
import { ReactComponent as RlNumbers } from "../../../assets/icons/RlNumbers.svg"
export const FORM_FIELDS = {
  string: {
    type: "input",
    widget: "input",
    icon: <MdShortText />,
    label: "Single-line text answer",
    format: "string",
    title: "Text label",
    placeholder: "Placeholder text",
    description: "Description text",
    maxChars: true,
    required: false,
    tag: false,
    export: true,
  },
  textarea: {
    type: "input",
    widget: "input",
    icon: <MdNotes />,
    label: "Multi-line text answer",
    title: "Text label",
    placeholder: "Placeholder text",
    description: "Description text",
    format: "textarea",
    maxChars: true,
    required: false,
    tag: false,
    rows: 3,
    export: true,
  },
  dropdown: {
    type: "input",
    widget: "multiple_choice",
    format: "select",
    label: "Dropdown",
    title: "Text label",
    placeholder: "Placeholder text",
    description: "Description text",
    options: [{ label: "Option 1", id: "1" }],
    required: true,
    export: true,
    icon: <MdArrowDropDownCircle />,
  },
  checkbox: {
    type: "input",
    widget: "multiple_choice",
    format: "checkbox",
    label: "Checkboxes",
    title: "Text label",
    placeholder: "Placeholder text",
    description: "Description text",
    options: [{ label: "Option 1", id: "1" }],
    required: true,
    export: true,
    icon: <MdCheckBox />,
  },
  radio: {
    type: "input",
    widget: "multiple_choice",
    format: "radio",
    label: "Radio buttons",
    title: "Text label",
    placeholder: "Placeholder text",
    description: "Description text",
    options: [{ label: "Option 1", id: "1" }],
    required: true,
    export: true,
    icon: <MdRadioButtonChecked />,
  },
  tel: {
    type: "input",
    widget: "input",
    icon: <MdPhone />,
    label: "Additional phone",
    title: "Text label",
    placeholder: "Placeholder text",
    description: "Description text",
    format: "tel",
    required: false,
    tag: false,
    export: true,
  },
  email: {
    type: "input",
    widget: "input",
    icon: <MdOutlineAlternateEmail />,
    label: "Additional email",
    title: "Text label",
    placeholder: "Placeholder text",
    description: "Description text",
    format: "email",
    required: false,
    tag: false,
    export: true,
  },
  // float: {
  //   type: "input",
  //   widget: "input",
  //   icon: <RlNumbers />,
  //   label: "Decimal",
  //   format: "float",
  //   required: false,
  //   tag: false,
  //   export: true,
  // },
  integer: {
    type: "input",
    widget: "input",
    icon: <RlNumbers />,
    label: "Number",
    title: "Text label",
    placeholder: "Placeholder text",
    description: "Description text",
    format: "integer",
    required: false,
    tag: false,
    export: true,
  },
  date: {
    type: "input",
    widget: "input",
    icon: <MdDateRange />,
    label: "Date",
    title: "Text label",
    placeholder: "Placeholder text",
    description: "Description text",
    format: "date",
    required: false,
    tag: false,
    export: true,
  },
  country: {
    type: "input",
    widget: "input",
    icon: <IoMdGlobe />,
    label: "Country dropdown",
    title: "Text label",
    placeholder: "Placeholder text",
    description: "Description text",
    format: "country",
    required: false,
    tag: false,
    export: true,
  },
  state: {
    type: "input",
    widget: "input",
    icon: <IoMdGlobe />,
    label: "U.S. state",
    title: "Text label",
    placeholder: "Placeholder text",
    description: "Description text",
    format: "state",
    required: false,
    tag: false,
    export: true,
  },
  heading: {
    type: "layout",
    widget: "text",
    htmlText: "<h1>This is a sample header</h1>",
    icon: <FaHeading />,
    label: "Heading",
    format: "heading",
  },
  text: {
    type: "layout",
    widget: "text",
    htmlText:
      "<p>This is a rich text area, you can add whatever copy you like</p>",
    icon: <FaAlignLeft />,
    label: "Paragraph",
    format: "text",
  },
  image: {
    type: "layout",
    widget: "image",
    width: 100,
    image_url: "",
    export: false,
    icon: <FaImage />,
    label: "Image",
    format: "image",
  },
  divider: {
    type: "layout",
    widget: "divider",
    height: 24,
    line: false,
    lineColor: "#252525",
    lineThickness: 1,
    width: 100,
    export: false,
    icon: <FaGripLines />,
    label: "Spacing or divider",
    format: "divider",
  },
  newTerms: {
    type: "legal",
    widget: "terms",
    format: "check",
    label: "Terms",
    terms:
      "Add your company terms here. You can include links to external pages by adding an <a> tag",
    required: true,
    export: true,
    icon: <FaFileSignature />,
  },
  thirdParty: {
    type: "legal",
    widget: "third_party_approval",
    title: "Email for approval",
    placeholder: "Email",
    export: true,
    form_fields_on_approval_page: [],
    icon: <FaFileSignature />,
    label: "Third party approval",
  },
  firstName: {
    type: "creator",
    dataFieldName: "First name",
    fieldId: "firstName",
    icon: <MdShortText />,
    label: "First name",

    widget: "input",
    title: "First name",
    placeholder: "First name",
    format: "string",
    required: false,
    export: true,
  },
  lastName: {
    type: "creator",
    dataFieldName: "Last name",
    fieldId: "lastName",
    icon: <MdShortText />,
    label: "Last name",
    widget: "input",
    title: "Last name",
    placeholder: "Last name",
    format: "string",
    required: false,
    export: true,
  },
  street1: {
    type: "creator",
    fieldId: "street1",
    dataFieldName: "Street address line 1",
    icon: <MdShortText />,
    label: "Street address line 1",
    widget: "input",
    title: "Street address line 1",
    placeholder: "Street address line 1",
    format: "string",
    required: false,
    export: true,
  },
  street2: {
    type: "creator",
    fieldId: "street2",
    dataFieldName: "Street address line 2",
    icon: <MdShortText />,
    label: "Street address line 2",
    widget: "input",
    title: "Street address line 2",
    placeholder: "Street address line 2",
    format: "string",
    required: false,
    export: true,
  },
  postalCode: {
    type: "creator",
    fieldId: "postalCode",
    dataFieldName: "Postal code",
    icon: <MdShortText />,
    label: "Postal code",
    widget: "input",
    title: "Postal code",
    placeholder: "Postal code",
    format: "string",
    required: false,
    export: true,
  },
  city: {
    type: "creator",
    fieldId: "city",
    dataFieldName: "City",
    icon: <MdShortText />,
    label: "City",
    widget: "input",
    title: "City",
    placeholder: "City",
    format: "string",
    required: false,
    export: true,
  },
  creatorState: {
    type: "creator",
    fieldId: "state",
    dataFieldName: "State",
    icon: <MdShortText />,
    label: "State/region",
    widget: "input",
    title: "State/region",
    placeholder: "State/region",
    format: "string",
    required: false,
    export: true,
  },
  creatorCountry: {
    type: "creator",
    fieldId: "country",
    dataFieldName: "Country",
    icon: <MdShortText />,
    label: "Country",
    widget: "input",
    title: "Country",
    placeholder: "Country",
    format: "country",
    required: false,
    export: true,
  },
  phone: {
    type: "creator",
    fieldId: "phone",
    dataFieldName: "Phone",
    icon: <MdShortText />,
    label: "Phone",
    widget: "input",
    title: "Phone",
    placeholder: "Phone",
    format: "tel",
    required: false,
    export: true,
  },
  birthDate: {
    type: "creator",
    fieldId: "birthDate",
    dataFieldName: "Birthdate",
    icon: <MdShortText />,
    label: "Birthdate",
    widget: "input",
    title: "Birthdate",
    placeholder: "Birthdate",
    format: "date",
    required: false,
    export: true,
  },
  file: {
    type: "content",
    fieldId: "file",
    label: "Upload file button",
    icon: <FaRegImages />,
    required: true,
    widget: "file",
    maxFileSize: 1000000000,
    maxNoFilesPerSubmission: 10,
    btnText: "Choose file",
    export: false,
    description:
      "Accepted file formats: jpg, jpeg, png (images), mp4, mov (videos), mp3, wav (audio)",
  },
  titleOfTheWork: {
    type: "content",
    fieldId: "titleOfTheWork",
    dataFieldName: "Title",
    icon: <MdShortText />,
    label: "Title of content",
    widget: "input",
    title: "Title of content",
    placeholder: "Please fill in the title of the content",
    format: "string",
    required: false,
    export: true,
  },
  contentDescription: {
    type: "content",
    fieldId: "contentDescription",
    dataFieldName: "Description",
    icon: <MdShortText />,
    label: "Content description",
    widget: "input",
    title: "Content description",
    placeholder: "Please tell us more about your content.",
    format: "textarea",
    rows: 3,
    required: false,
    export: true,
  },
  contentLocation: {
    type: "content",
    fieldId: "contentLocation",
    dataFieldName: "Location",
    icon: <MdShortText />,
    label: "Content location",
    widget: "input",
    title: "Content location",
    placeholder: "Where did you take your photo/video?",
    format: "string",
    required: false,
    export: true,
  },

  ig_username: {
    type: "some",
    fieldId: "ig_username",
    dataFieldName: "Instagram",
    icon: <MdShortText />,
    label: "Instagram username",
    widget: "input",
    title: "Instagram username",
    placeholder: "Instagram username",
    format: "string",
    required: false,
    export: true,
  },
  facebook: {
    type: "some",
    fieldId: "facebookUsername",
    dataFieldName: "Facebook",
    icon: <MdShortText />,
    label: "Facebook profile",
    widget: "input",
    title: "Facebook profile",
    placeholder: "Facebook profile",
    format: "string",
    required: false,
    export: true,
  },
  tiktok: {
    type: "some",
    fieldId: "tiktokUsername",
    dataFieldName: "TikTok",
    icon: <MdShortText />,
    label: "TikTok profile",
    widget: "input",
    title: "TikTok profile",
    placeholder: "TikTok profile",
    format: "string",
    required: false,
    export: true,
  },
  youtube: {
    type: "some",
    fieldId: "youtubeUsername",
    dataFieldName: "Youtube",
    icon: <MdShortText />,
    label: "Youtube channel",
    widget: "input",
    title: "Youtube channel",
    placeholder: "Youtube channel",
    format: "string",
    required: false,
    export: true,
  },
  snapchat: {
    type: "some",
    fieldId: "snapchatUsername",
    dataFieldName: "Snapchat",
    icon: <MdShortText />,
    label: "Snapchat profile",
    widget: "input",
    title: "Snapchat profile",
    placeholder: "Snapchat profile",
    format: "string",
    required: false,
    export: true,
  },
  twitter: {
    type: "some",
    fieldId: "xUsername",
    dataFieldName: "X",
    icon: <MdShortText />,
    label: "X profile",
    widget: "input",
    title: "X profile",
    placeholder: "X profile",
    format: "string",
    required: false,
    export: true,
  },
}
