import React, { useState, useEffect } from "react"
import { FaRegEnvelope, FaHashtag } from "react-icons/fa"
import { FiUpload } from "react-icons/fi"
import { useSelector } from "react-redux"
import { CAMPAIGN_TYPE } from "../../../../../../constants"
import { selectActiveAsset } from "../../../../../../redux/asset/asset.selectors"
import ReelerTooltip from "../../../../../commons/ReelerTooltip/ReelerTooltip"
import * as S from "./styles/styles"

const AssetStatusRedux = () => {
  const asset = useSelector(selectActiveAsset)

  const [text, setText] = useState("Collection channel and rights status")

  useEffect(() => {
    if (asset.status) {
      switch (asset.campaign_type) {
        case CAMPAIGN_TYPE.igHashtag.type:
          setText("Instagram Hashtag source. Rights Status: " + asset.status)
          break
        case CAMPAIGN_TYPE.upload.type:
          setText("Web form source. Rights Status: " + asset.status)
          break
        case CAMPAIGN_TYPE.email.type:
          setText("Email source. Rights Status: " + asset.status)
          break
        case CAMPAIGN_TYPE.igMentions.type:
          setText("Instagram Mentions source. Rights Status: " + asset.status)
          break
        case CAMPAIGN_TYPE.whatsapp.type:
          setText("WhatsApp: " + asset.status)
          break
        case CAMPAIGN_TYPE.import.type:
          setText("Imported: " + asset.status)
          break
        default:
      }
    } else {
      switch (asset.campaign_type) {
        case CAMPAIGN_TYPE.igHashtag.type:
          setText("Instagram Hashtag campaign.")
          break
        case "UPLOAD":
          setText("Web form campaign. Rights Status: ")
          break
        case CAMPAIGN_TYPE.upload.type:
          setText("Web form campaign. Rights Status: ")
          break
        case CAMPAIGN_TYPE.email.type:
          setText("Email campaign. Rights Status: ")
          break
        case CAMPAIGN_TYPE.igMentions.type:
          setText("Instagram Mentions source. Rights Status: ")
          break
        case CAMPAIGN_TYPE.whatsapp.type:
          setText("WhatsApp. Rights Status: ")
          break
        case CAMPAIGN_TYPE.import.type:
          setText("Imported")
          break
        default:
      }
    }
  }, [asset])
  return (
    <ReelerTooltip text={text}>
      <>
        <S.StatusBox status={asset.status}>
          {asset.campaign_type === "UPLOAD" ? (
            <FiUpload
              style={{
                color: "var(--reeler-black",
                strokeWidth: "3px",
              }}
            />
          ) : asset.campaign_type === CAMPAIGN_TYPE.upload.type ? (
            CAMPAIGN_TYPE.upload.icon
          ) : asset.campaign_type === "INSTAGRAM" ||
            asset.campaign_type === CAMPAIGN_TYPE.igHashtag.type ? (
            CAMPAIGN_TYPE.igHashtag.icon
          ) : asset.campaign_type === CAMPAIGN_TYPE.email.type ? (
            CAMPAIGN_TYPE.email.icon
          ) : asset.campaign_type === CAMPAIGN_TYPE.igMentions.type ? (
            CAMPAIGN_TYPE.igMentions.icon
          ) : asset.campaign_type === CAMPAIGN_TYPE.import.type ? (
            CAMPAIGN_TYPE.import.icon
          ) : asset.campaign_type === CAMPAIGN_TYPE.whatsapp.type ? (
            CAMPAIGN_TYPE.whatsapp.icon
          ) : null}
        </S.StatusBox>
        {asset?.thirdPartyApprovalStatus ? (
          <S.ThirdPartyStatusBox status={asset.thirdPartyApprovalStatus} />
        ) : null}
      </>
    </ReelerTooltip>
  )
}

export default AssetStatusRedux
