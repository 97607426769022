import { Button } from "react-bootstrap"
import styled from "styled-components"

export const ButtonContainer = styled(Button)`
  padding: ${props => (props.$small ? "0.25rem 0.5rem" : null)};
  font-size: ${props => (props.$small ? "0.8rem" : null)};
  display: flex;
  align-items: center;
  justify-content: center;

  :hover {
    background-color: red;
  }
`

export const IconContainer = styled.div`
  display: flex;
  margin-right: 5px;
`
