import styled, { css } from "styled-components"
import { FORM_DEFAULT_SETTINGS } from "../../../../constants/formDefaultSettings"

export const Styles = styled.section`
  background-color: ${props =>
    props.design?.formBackground ? props.design.formBackground : "transparent"};
  color: ${props => (props.design?.fontColor ? props.design.fontColor : null)};
  font-family: ${props =>
    props.design?.fontFamily ? props.design.fontFamily : null};

  h1 {
    font-size: ${props =>
      props.design?.h1Size ? `${props.design.h1Size}px` : null};
    color: ${props => (props.design?.h1Color ? props.design.h1Color : null)};
  }

  h2 {
    font-size: ${props =>
      props.design?.h2Size ? `${props.design.h2Size}px` : null};
    color: ${props => (props.design?.h2Color ? props.design.h2Color : null)};
  }

  p {
    font-size: ${props =>
      props.design?.pSize ? `${props.design.pSize}px` : null};
    color: ${props => (props.design?.pColor ? props.design.pColor : null)};
    span {
      font-style: italic;
    }
  }
  /*
  label {
    font-size: 12px;
  }

  li {
    font-size: 12px;
  }

  small {
    color: ${props => (props.fontColor ? props.fontColor : "#000")};
  }

  .text {
    padding: 10px;
  } */
  /*
  .form-control::placeholder {
    color: #a7a7a7;
  }
  Most modern browsers support this now. */
`
const smButton = css`
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
`
const mdButton = css`
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
`

const lgButton = css`
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
`

export const LoadingScreen = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
`

export const Container = styled.div`
  background-color: ${props =>
    props.formBackground ? props.formBackground : "#fff"};
  color: ${props => (props.fontColor ? props.fontColor : "#000")};
  padding: 15px;
  margin-right: auto;
  margin-left: auto;
`

export const FileMessageContainer = styled.span`
  display: inline;
  color: var(--reeler-danger);
`

export const FilesContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap !important;
  margin: 5px;
  margin-bottom: 1rem;
`

export const File = styled.div`
  margin: 5px 5px;
  display: flex;
  flex-direction: row;
  padding: 0.25em 0.4em;
  padding: 0 0.5rem !important;
  font-size: 75%;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 10rem;
  background: var(--reeler-grey);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
`
export const FileName = styled.div`
  margin-right: 5px;
`
export const RemoveFile = styled.div`
  text-decoration: underline;
  cursor: pointer;
`

export const UploadButton = styled.label`
  background-color: ${props =>
    props.btnColor ? props.btnColor : FORM_DEFAULT_SETTINGS.fileBtnColor};
  color: ${props =>
    props.btnFontColor
      ? props.btnFontColor
      : FORM_DEFAULT_SETTINGS.fileBtnFontColor};
  border: ${props =>
    props.fileBtnBorderColor
      ? props.fileBtnBorderColor
      : FORM_DEFAULT_SETTINGS.fileBtnBorderColor};
  cursor: pointer;
  font-size: 12px;
  margin-right: 0.5rem !important;
  margin: 0 !important;
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
`

export const FormBuilderContainer = styled.div`
  padding: 0;
  min-height: 100vh;
  margin: 0 auto;
`
export const FormBuilderHeader = styled.div`
  margin-bottom: 0.5rem;
`
export const FormBuilderFooter = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5rem;
  background-color: white;
  padding: 0.75rem 0.5rem;
  border: var(--bootstrap-border);
  border-radius: 0.25rem;
`

export const FormHeaderMenu = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const FormContainer = styled.div`
  width: 70%;
  background-color: ${props =>
    props.formBackground ? props.formBackground : "#fff"};
  color: ${props => (props.fontColor ? props.fontColor : "#000")};
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  padding: 1rem;
  margin: 0 auto 0.75rem auto;
  max-width: ${props => (props.mobileView ? "375px" : null)};
`

export const FormFieldContainer = styled.div`
  position: relative;
  padding: 0.25rem;
  width: 100%;
  ${({ $disableHover }) =>
    $disableHover
      ? null
      : `&:hover {
    border-radius: 0.25rem;
    border: 1px solid var(--reeler-grey-dark);
    background-color: var(--reeler-light-grey);
  }`}
`

export const FormFieldSectionContainer = styled.div`
  background: var(--reeler-grey);
  padding: 10px;
  border-radius: 3px;
`

export const FormFieldControlsContainer = styled.div`
  display: ${({ $active }) => (!$active ? "none" : "flex")};
  flex-direction: row;
  position: absolute;
  top: 0;
  right: 0;
  margin-top: -15px;
  border-radius: 0.25rem;
  border: 1px solid rgba(0, 0, 0, 0.125);
  padding: 0.25rem;
  background: #fff;
`

export const FormFieldControl = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--reeler-grey-darker);
  width: 30px;
  height: 30px;
  margin: 2px;
  &:hover {
    cursor: pointer;
    border-radius: 50%;
    background-color: var(--reeler-grey);
    color: var(--reeler-icon);
  }
`
export const OptionsRow = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-left: -24px;
  margin-top: 5px;
  margin-bottom: 5px;
  border: ${({ $selected }) =>
    $selected ? "1px solid rgba(0, 0, 0, 0.125)" : "none"};
  border-radius: 0.25rem;
`
export const LabelRow = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: row;
`

export const TagsRow = styled.div`
  display: flex;
  padding: 5px;
  justify-content: space-between;
  flex-direction: column;
  /* margin-left: 48px; */
  margin-left: 2rem;
  align-items: center;
`

export const RowIconItem = styled.div`
  height: 24px;
  width: 24px;
  color: var(--reeler-icon);
  display: flex;
  justify-content: start;
  align-items: center;
`
export const RowInputItem = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
`

export const InputOption = styled.input`
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &:hover {
    border-bottom: 1px solid var(--reeler-input);
  }
  :focus {
    outline: none;
    border-bottom: 1px solid var(--reeler);
  }
`
export const AddOption = styled.span`
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &:hover {
    cursor: pointer;
  }
`

export const AddFieldContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 0.25rem;
  margin: 1rem 0;
  /* background-color: cyan; */
  border: 2px dashed var(--reeler-grey);
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  &:hover {
    border: 2px dashed var(--reeler-grey-dark);
  }
`
export const FullWidthCenter = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

export const AddFieldControl = styled.div`
  display: flex;
  align-items: center;
  /* background-color: red; */
  width: 100%;
  justify-content: center;
  width: 30px;
  height: 30px;
  color: var(--reeler-grey-darker);
  &:hover {
    color: var(--reeler-icon);
  }
`

export const ImageContainer = styled.div`
  display: flex;
  justify-content: ${({ $align }) => ($align ? $align : "center")};
  width: 100%;
`

export const RotateIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(${({ open }) => (open ? "180deg" : "0deg")});
  transition: all ease-in-out 0.2s;
`
export const AdvancedContainer = styled.div`
  border: 1px solid var(--reeler-grey-dark);
  background-color: var(--reeler-grey);
  padding: 0.5em !important;
  border-radius: 5px;
  margin-bottom: 0.75rem;
`

export const DeviceSwitchContainer = styled.div`
  display: flex;
  justify-content: end;
  margin-bottom: 0.75rem;
`

export const CheckboxGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 0.25rem;
`
export const EditorContainer = styled.div`
  background-color: rgb(255, 255, 255);
  padding: 10px;
  border-radius: 3px;
`

export const ColoredLabel = styled.label`
  font-weight: 500;
  color: ${({ $design }) =>
    $design?.titleColor ? `${$design?.titleColor}` : null};
  font-size: ${({ $design }) =>
    $design?.fieldTitleSize ? `${$design?.fieldTitleSize}px` : null};
  margin-right: 4px;
  display: inline-block;
  margin-bottom: 0.5rem;
`

export const ColoredFormText = styled.small`
  margin-top: 0.25rem;
  color: ${({ $design }) =>
    $design?.descriptionColor
      ? `${$design?.descriptionColor} !important`
      : null};
  font-size: ${({ $design }) =>
    $design?.fieldDescriptionSize
      ? `${$design?.fieldDescriptionSize}px`
      : null};
`

export const InputCounterContainer = styled.div`
  display: flex;
  justify-content: end;
`

export const RequiredMark = styled.span`
  color: #f97676;
  font-size: ${({ $size }) => ($size ? `${$size}rem` : "0.8rem")};
  font-weight: bolder;
`

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--reeler-icon);
  width: ${({ $size }) => ($size ? $size : "30px")};
  height: ${({ $size }) => ($size ? $size : "30px")};
  margin: 2px;
  &:hover {
    cursor: pointer;
    border-radius: 50%;
    background-color: var(--reeler-grey);
  }

  position: relative;
`

export const Notification = styled.div`
  width: 12px;
  height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  color: #fff;
  background-color: var(--reeler-danger);
  border-radius: 50%;
  font-size: 8px;

  position: absolute;
  top: -1px;
  right: -1px;
`

export const PowerdByContainer = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  margin: 1rem 0;
`

export const PowerdByText = styled.span`
  color: var(--reeler-grey-darker);
  font-size: 0.5rem;
`
export const PowerdByLogo = styled.a`
  display: flex;
  align-items: center;
  margin-left: 0.25rem !important;
  font-family: "Changa One";
  color: var(--reeler-grey-darker);
`
export const Logo = styled.img`
  object-fit: contain;
  width: 40px;
`

export const WidgetContainer = styled.div`
  margin-bottom: ${({ $marginBottom }) =>
    $marginBottom ? $marginBottom : "1rem !important"};
`

export const FormInput = styled.input`
  display: block;
  width: 100%;
  font-family: inherit;
  font-weight: 400;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
  box-sizing: border-box;
  &:focus {
    color: #495057;
    background-color: #fff;
    border-color: ${({ $focusColor }) =>
      $focusColor ? $focusColor : "var(--reeler-black)"};
    outline: 0;
    box-shadow: ${({ $focusColor }) =>
      $focusColor ? `0 0 0 0.2rem ${$focusColor}40` : `0 0 0 0.2rem #00000040`};
  }
`

export const FormTextArea = styled.textarea`
  display: block;
  font-family: inherit;
  width: 100%;
  font-weight: 400;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
  box-sizing: border-box;
  &:focus {
    color: #495057;
    background-color: #fff;
    border-color: ${({ $focusColor }) =>
      $focusColor ? $focusColor : "var(--reeler-black)"};
    outline: 0;
    box-shadow: ${({ $focusColor }) =>
      $focusColor ? `0 0 0 0.2rem ${$focusColor}40` : `0 0 0 0.2rem #00000040`};
  }
`

export const FormSelect = styled.select`
  display: block;
  font-family: inherit;
  width: 100%;
  font-weight: 400;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
  box-sizing: border-box;
  &:focus {
    color: #495057;
    background-color: #fff;
    border-color: ${({ $focusColor }) =>
      $focusColor ? $focusColor : "var(--reeler-black)"};
    outline: 0;
    box-shadow: ${({ $focusColor }) =>
      $focusColor ? `0 0 0 0.2rem ${$focusColor}40` : `0 0 0 0.2rem #00000040`};
  }
`

export const FormDescriptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const MultiplechoiceOptionContainer = styled.div`
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
`

export const MultiplechoiceOptionLabel = styled.label`
  margin-left: 0.5rem;
`

export const MultiplechoiceOptionInput = styled.input`
  float: left;
  margin-left: -1.5em;
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  print-color-adjust: exact;
`

export const SubmitButton = styled.button`
  color: ${({ $btnFontColor }) =>
    $btnFontColor ? $btnFontColor : FORM_DEFAULT_SETTINGS.submitBtnFontColor};
  border: ${({ $submitBtnBorderColor }) =>
    $submitBtnBorderColor
      ? $submitBtnBorderColor
      : FORM_DEFAULT_SETTINGS.submitBtnBorderColor};
  background-color: ${({ $btnColor }) =>
    $btnColor ? $btnColor : FORM_DEFAULT_SETTINGS.submitBtnColor};
  width: fit-content;
  display: inline-block;
  cursor: pointer;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  ${({ $btnSize }) =>
    $btnSize === "sm" ? smButton : $btnSize === "lg" ? lgButton : mdButton};
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  @media screen and (max-width: 575px) {
    width: 100%;
  }

  &:disabled {
    opacity: 0.65;
    cursor: auto;
  }
`
export const SubmitContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
`
export const SuccessMessageContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  font-size: 1.25rem;
`
export const SuccessMessage = styled.span`
  padding: 0.5rem;
  background: #c7e8b4;
  border-radius: 5px;
`
export const ErrorMessageContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`
export const ErrorMessage = styled.span`
  padding: 0.5rem;
  background: var(--reeler-danger-light);
  border-radius: 5px;
  font-size: 1.25rem;
`
export const ProgressBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
  padding: 0.75rem 0.5rem;
  margin: 1rem 0;
  border: 1px solid #d9d9d9;
`
