import styled, { css } from "styled-components"

export const Carousel = styled.div`
  position: relative;
  max-width: 100%;
  height: 100%;
  overflow: hidden;
`

const buttonStyles = css`
  position: absolute;
  top: 0;
  height: 100%;
  background-color: transparent;
  color: white;
  font-size: 1.5rem;
  border: none;
  padding: 1rem;
  cursor: pointer;
  z-index: 1;
  transition: background-color 0.3s;

  :hover {
    background-color: rgba(0, 0, 0, 0.3);
  }
`
export const PrevButton = styled.button`
  ${buttonStyles}
  ${({ show }) => !show && "display: none"};

  left: 0;
`
export const NextButton = styled.button`
  ${buttonStyles}
  ${({ show }) => !show && "display: none"};
  right: 0;
`
export const Slide = styled.div`
  display: ${({ show }) => (show ? "flex" : "none")};
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  transition: transform 0.5s;
`
export const Image = styled.img`
  object-fit: contain;
  background: var(--reeler-white);
  display: block;
  aspect-ratio: auto;
  overflow: hidden;
  width: 100%;
  height: 100%;
`
export const Video = styled.video`
  width: 100%;
  height: 100%;
  background: var(--reeler-white);
`

export const Dots = styled.div`
  position: absolute;
  bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
`

export const Dot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${({ active }) => (active ? "var(--reeler)" : "white")};
  border: 1px solid var(--reeler-icon);
  margin: 0 5px;
  cursor: pointer;
`
