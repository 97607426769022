/**
 * Calculate time difference between two dates
 *
 * @param createdAt Historical date.
 
 * @return Formatted string.
 */
export function timeAgo(createdAt) {
  let diffInMilliSeconds = Math.abs(new Date() - createdAt) / 1000

  // calculate years
  const years = Math.floor(diffInMilliSeconds / 31557600)
  if (years > 0) return years + "y"

  // calculate months
  const months = Math.floor(diffInMilliSeconds / 2629746)
  if (months > 0) return months + "mo"

  // calculate days
  const days = Math.floor(diffInMilliSeconds / 86400)
  if (days > 0) return days + "d"

  // calculate hours
  const hours = Math.floor(diffInMilliSeconds / 3600) % 24
  if (hours > 0) return hours + "h"

  // calculate hours
  const minutes = Math.floor(diffInMilliSeconds / 60) % 60
  return minutes + "'"
}
