import { createSelector } from "reselect"

const navbar = state => state.navbar

export const selectIsCollapsed = createSelector(
  [navbar],
  navbar => navbar?.isCollapsed
)
export const selectSubmenuStates = createSelector(
  [navbar],
  navbar => navbar?.subMenuStates
)
