import React from "react"
import DeleteUser from "./DeleteUser"
import { useSelector } from "react-redux"
import { selectUserId } from "../../../redux/user/user.selectors"
import EditUser from "./EditUser"
import { capitalize } from "lodash"
import { ACCOUNT_PLANS, USER_ROLES } from "../../../constants/routes"
import AuthBasedComponent from "../../authentication/AuthBasedComponent"

export default function UserRow({ user }) {
  const userId = useSelector(selectUserId)
  return (
    user && (
      <tr>
        <td>
          {user.firstName ? user.firstName : null}
          {user.lastName ? " " + user.lastName : null}
        </td>
        <td>{user.email}</td>
        <td>{user.role ? capitalize(user.role) : "n/a"}</td>
        <td>
          <div className="d-flex flex-row justify-content-center">
            <AuthBasedComponent
              userRoles={[USER_ROLES.owner]}
              plans={[
                ACCOUNT_PLANS.basic,
                ACCOUNT_PLANS.pro,
                ACCOUNT_PLANS.premium,
              ]}
            >
              <EditUser user={user} />
            </AuthBasedComponent>
            <AuthBasedComponent
              userRoles={[USER_ROLES.owner]}
              plans={[
                ACCOUNT_PLANS.basic,
                ACCOUNT_PLANS.pro,
                ACCOUNT_PLANS.premium,
              ]}
            >
              {userId !== user?.id && <DeleteUser userId={user?.id} />}
            </AuthBasedComponent>
          </div>
        </td>
      </tr>
    )
  )
}
