import React from "react"
import Section from "../../../layouts/core/Section"
import {
  Header,
  HeaderGroup,
  HeaderGroupCenter,
  HeaderGroupLeft,
  HeaderGroupRight,
} from "../../../layouts/core/Header"
import Body from "../../../layouts/core/Body"
import Title from "../../../layouts/core/Title"
import BackButton from "../../../components/commons/BackButton"
import ApprovalPageTemplatesTable from "./components/AppovalPageTemplatesTabel"
import ReelerButton from "../../../components/commons/ReelerButton"
import EditApprovalPageTemplate from "./components/EditApprovalPageTemplate"

export default function AppovalPageTemplatesPage() {
  return (
    <Section>
      <Header>
        <HeaderGroup>
          <HeaderGroupLeft>
            <Title>Approval page templates</Title>
          </HeaderGroupLeft>
          <HeaderGroupCenter></HeaderGroupCenter>
          <HeaderGroupRight>
            <BackButton />
            <EditApprovalPageTemplate
              btn={<ReelerButton text="New template" styleClass="btn-main" />}
              existingIndex={null}
            />
          </HeaderGroupRight>
        </HeaderGroup>
      </Header>
      <Body>
        <p>
          Design one or several templates for “approval pages” where end-users
          grant you permission for the use of content. For instance, you could
          create one type of approval page for content creators (granting
          permission to use their work) and another type of approval page for
          3rd parties (granting permission for the use of images where they
          appear).
        </p>
        <ApprovalPageTemplatesTable />
      </Body>
    </Section>
  )
}
