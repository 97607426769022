import React, { useEffect, useState } from "react"
import { database } from "../../../firebase"
import firebase from "firebase/app"

// Redux
import { useSelector, useDispatch } from "react-redux"
import {
  selectSelectedMedia,
  selectSettings,
  selectErrorMedia,
} from "../../../redux/import-assets/import.selectors"
import {
  removeSelectedMedia,
  addErrorMedia,
  updateSelectedMediaFile,
} from "../../../redux/import-assets/import.actions"
import { selectAccountId } from "../../../redux/account/account.selectors"

// Reeler
import * as S from "../importAssets-style"
import { humanFileSize } from "../../../utils/humanFileSize"

import MediaType from "./MediaType"
import { TopLeft } from "../importAssets-style"
import SelectMediaFile from "./SelectMediaFile"

export default function MediaFile({ mediaFile }) {
  const dispatch = useDispatch()
  const accountId = useSelector(selectAccountId)
  const [borderStyle, setBorderStyle] = useState("")
  const selectedMedia = useSelector(selectSelectedMedia)
  const settings = useSelector(selectSettings)
  const errorMedia = useSelector(selectErrorMedia)
  const file = mediaFile.file
  const selected = selectedMedia.find(item => item.id === mediaFile.id)

  const getId = s => {
    const indexStart = s.lastIndexOf("_R") + 2
    const indexEnd = s.lastIndexOf(".")
    return s.slice(indexStart, indexEnd)
  }

  useEffect(() => {
    if (settings.replace) {
      // Parse filename and check if asset exists
      let filename = file.name
      const assetId = getId(filename)

      database.assets
        .where("accountId", "==", accountId)
        .where(firebase.firestore.FieldPath.documentId(), "==", assetId)
        .get()
        .then(querySnapshot => {
          if (querySnapshot.empty) {
            // Cannot find any asset document
            // Deselect the asset and set error borde
            handleError()
            console.log("could not find any assets")
          } else {
            setBorderStyle("selected")
            console.log("Found an asset with correct id")
            mediaFile["assetId"] = assetId
            dispatch(updateSelectedMediaFile(mediaFile))
          }
        })
        .catch(err => {
          console.log(
            "Kan inte ladda asset vilket innebär att den inte finns tillgänglig",
            err
          )

          handleError()
        })
    } else {
      if (selected) {
        setBorderStyle("selected")
      }
    }
  }, [settings])

  const handleError = () => {
    // Do not add media to error if it is already
    dispatch(removeSelectedMedia(mediaFile))
    setBorderStyle("error")
    if (!errorMedia.some(m => m.id === mediaFile.id)) {
      dispatch(addErrorMedia(mediaFile))
    }
  }

  return (
    <S.MediaFile borderStyle={borderStyle}>
      <TopLeft>
        {borderStyle !== "error" ? (
          <SelectMediaFile mediaFile={mediaFile} selected={selected} />
        ) : null}
      </TopLeft>
      <div style={{ height: "100px" }}>
        <MediaType url={URL.createObjectURL(file)} type={file.type} />
      </div>

      <div className="flex-row d-flex justify-content-between align-items-center p-2 mt-1">
        <span className="text-break" style={{ fontSize: "8px" }}>{`${
          file.name
        } (${humanFileSize(file.size)})`}</span>
      </div>
    </S.MediaFile>
  )
}
