import React, { useState } from "react"
import { Modal, Form, ModalBody, Alert } from "react-bootstrap"
import { database } from "../../../firebase"
import { capitalize } from "lodash"
import ReelerButton from "../../commons/ReelerButton"
import Icon from "../../commons/Icon"
import useUserFeedbackMessage from "../../../hooks/useUserFeedbackMessage"
import { FaEdit } from "react-icons/fa"
import { ACCOUNT_PLANS, USER_ROLES } from "../../../constants/routes"
import AuthBasedComponent from "../../authentication/AuthBasedComponent"

const EditUser = ({ user }) => {
  const { setUserFeedbackMessage } = useUserFeedbackMessage()
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [role, setRole] = useState(user?.role)

  function openModal() {
    setOpen(true)
  }

  function closeModal() {
    setOpen(false)
  }

  function handleSubmit(e) {
    e.preventDefault()

    const userData = { role }

    database.users
      .doc(user.id)
      .update(userData)
      .then(() => {
        setUserFeedbackMessage(`User updated successfully`, "success")
        setLoading(false)
      })
      .catch(err => {
        setUserFeedbackMessage(`Failed to update user`, "danger")
        setLoading(false)
      })
  }

  return (
    <>
      <AuthBasedComponent
        userRoles={[USER_ROLES.owner, USER_ROLES.editor]}
        plans={[ACCOUNT_PLANS.basic, ACCOUNT_PLANS.pro, ACCOUNT_PLANS.premium]}
      >
        <Icon onClick={openModal} tooltip="Edit user">
          <FaEdit />
        </Icon>
      </AuthBasedComponent>

      <Modal show={open} onHide={closeModal}>
        <ModalBody>
          <Form onSubmit={handleSubmit}>
            <Modal.Title>Edit user {user?.email}</Modal.Title>
            <Modal.Body>
              <Form.Group>
                <Form.Text>Firs name: {user?.firstName}</Form.Text>
                <Form.Text>Last name: {user?.lastName}</Form.Text>
                <Form.Text>Email: {user?.email}</Form.Text>
              </Form.Group>
              <Form.Group>
                <Form.Label>Role</Form.Label>
                <Form.Control
                  as="select"
                  defaultValue={role}
                  required
                  onChange={e => setRole(e.target.value)}
                >
                  {Object.keys(USER_ROLES).map((role, index) => (
                    <option key={index} value={role}>
                      {capitalize(role)}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <ReelerButton
                text="Close"
                styleClass="btn-secondary"
                dispatch={closeModal}
              />
              <ReelerButton
                text="Save"
                styleClass="btn-main"
                loading={loading}
                type="submit"
              />
            </Modal.Footer>
          </Form>
        </ModalBody>
      </Modal>
    </>
  )
}

export default EditUser
