import React, { useState } from "react"

import { database } from "../../../../../firebase"

// Redux
import { useSelector, useDispatch } from "react-redux"
import { selectCampaign } from "../../../../../redux/campaign/campaign.selectors"
import { updateCampaignsWithCampaign } from "../../../../../redux/campaign/campaign.actions"

// Reeler components
import ReelerButton from "../../../../commons/ReelerButton"
import useUserFeedbackMessage from "../../../../../hooks/useUserFeedbackMessage"

const SaveCampaign = () => {
  const dispatch = useDispatch()
  const [saving, setSaving] = useState(false)
  const { setUserFeedbackMessage } = useUserFeedbackMessage()

  const campaign = useSelector(selectCampaign)

  const handleSave = () => {
    if (campaign) {
      setSaving(true)
      // Update campaign
      let { id: value, ...campaignWithoutId } = campaign
      database.campaigns
        .doc(campaign.id)
        .set(campaignWithoutId, { merge: true })
        .then(() => {
          dispatch(updateCampaignsWithCampaign(campaign))
          setUserFeedbackMessage("Saved!")
          setSaving(false)
        })
        .catch(error => {
          setUserFeedbackMessage(
            "Error, could not save campaign!",
            "danger",
            null
          )

          setSaving(false)
        })
    }
  }

  return (
    <div>
      <ReelerButton
        loading={saving}
        text={"Save"}
        dispatch={() => handleSave()}
      />
    </div>
  )
}

export default SaveCampaign
