import React, { useEffect, useState, useRef } from "react"
import { Modal, Form, Row, Col } from "react-bootstrap"

import { useSelector, useDispatch } from "react-redux"
import {
  selectAccountId,
  selectAccounts,
} from "../../../../redux/account/account.selectors"
import withoutProperty from "../../../../utils/withoutProperty"
import { storage, database } from "../../../../firebase"
import ReelerButton from "../../../../components/commons/ReelerButton"
import { useEditor } from "../../../../hooks/useEditor"
import PreviewRequestLink from "./PreviewRequestLink"
import useUserFeedbackMessage from "../../../../hooks/useUserFeedbackMessage"
import { v4 as uuidv4 } from "uuid"
import { updateAccounts } from "../../../../redux/account/account.actions"

export default function EditApprovalPageTemplate({
  existingTemplate,
  existingIndex,
  btn,
}) {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [template, setTemplate] = useState({})
  const [progress, setProgress] = useState(0)
  const [image, setImage] = useState()
  const [index, setIndex] = useState(null)
  const [msg, setMsg] = useState(null)
  const [saving, setSaving] = useState(false)
  const [fileMessage, setFileMessage] = useState()
  const { setSuccessMessage, setErrorMessage } = useUserFeedbackMessage()

  const account = useSelector(selectAccounts)
  const { ReelerEditor, getHTML, setDefaultState } = useEditor()
  const fileRef = useRef()

  // new Product
  const newTemplate = {
    link_name: "",
    logo_url: "",
    rightsRequestText: "",
    privacyPolicy: "",
    terms: "",
  }

  function openModal() {
    setMsg(null)
    setOpen(true)
  }

  function closeModal() {
    setOpen(false)
  }

  useEffect(() => {
    if (existingTemplate) {
      setDefaultState(existingTemplate?.rightsRequestText)
      setIndex(existingIndex)
      setTemplate(existingTemplate)
    } else {
      setTemplate(newTemplate)
    }
  }, [existingTemplate])

  const handleUpdateTemplate = (property, value) => {
    setTemplate({ ...template, [property]: value })
  }

  const handleUploadImage = e => {
    const file = e.target.files[0]
    setImage(null)

    if (file == null) return

    let format = file.name.split(".").pop().toLowerCase()
    let allowedFormats = ["svg", "png", "jpg", "jpeg"]

    if (!allowedFormats.includes(format)) {
      setFileMessage("File format can only be svg, png, jpg or jpeg")
      return
    }

    if (file.size > 5000000) {
      setFileMessage("File size is too big. File size limit is 5MB")
      return
    }
    handleUpdateTemplate("logo_url", URL.createObjectURL(file))
    setImage(file)
  }

  const handleSubmit = () => {
    setSaving(true)
    let docToBeSaved = template

    docToBeSaved["rightsRequestText"] = getHTML()

    // Check if there is a new file to upload
    if (image) {
      // Generate an id for filename
      const filename = uuidv4()
      let format = image.name.split(".").pop().toLowerCase()

      // Upload image to Storage
      setProgress(0)
      const uploadTask = storage
        .ref(
          `/${account.id}/right_request_templates/approval_pages/${
            filename + "." + format
          }`
        )
        .put(image)

      uploadTask.on(
        "state_changed",
        snapshot => {
          setProgress(snapshot.bytesTransferred / snapshot.totalBytes)
        },
        err => {
          setErrorMessage("Could not save template!")
          setSaving(false)
        },
        async () => {
          uploadTask.snapshot.ref.getDownloadURL().then(url => {
            docToBeSaved["logo_url"] = url
            // save to firestore and update redux
            // Update account

            saveAccount(docToBeSaved)
            setProgress(0)
          })
        }
      )
    } else {
      saveAccount(docToBeSaved)
    }
  }

  const saveAccount = docToBeSaved => {
    console.log(docToBeSaved)
    console.log("Index: " + index)

    let approvalPages
    if (index !== null) {
      approvalPages = account?.right_request_templates?.approvalPages.map(
        (approvalPage, i) =>
          i === parseInt(index) ? docToBeSaved : approvalPage
      )
    } else {
      if (account?.right_request_templates?.approvalPages) {
        approvalPages = [
          ...account?.right_request_templates?.approvalPages,
          docToBeSaved,
        ]
        setIndex(approvalPages.length - 1)
      } else {
        approvalPages = [docToBeSaved]
        setIndex(0)
      }
    }

    database.accounts
      .doc(account.id)
      .update({
        "right_request_templates.approvalPages": approvalPages,
      })
      .then(() => {
        //Update accounts in redux
        dispatch(
          updateAccounts({
            right_request_templates: {
              ...account.right_request_templates,
              approvalPages: approvalPages,
            },
          })
        )
        setSuccessMessage("Saved!")
        setSaving(false)
        // fileRef.current.value = null
      })
      .catch(err => {
        setErrorMessage("Could not save template!")
        console.log(err)
        setSaving(false)
      })
  }

  return (
    <>
      <div style={{ cursor: "pointer" }}>
        <div onClick={openModal}>{btn}</div>
      </div>

      <Modal show={open} onHide={closeModal} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Approval page template</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <strong>Preview</strong>
              <PreviewRequestLink approvalPage={template} />
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Template name</Form.Label>
                <Form.Control
                  type="text"
                  value={template?.link_name}
                  onChange={e =>
                    handleUpdateTemplate("link_name", e.target.value)
                  }
                />
                <Form.Text className="text-muted">
                  Give this template an internal name.
                </Form.Text>
              </Form.Group>
              {account?.right_request_templates?.domains?.length > 0 ? (
                <Form.Group className="mb-3">
                  <Form.Label>Select domain</Form.Label>
                  <Form.Control
                    as="select"
                    name="domain"
                    value={template?.domain}
                    onChange={e =>
                      e.target.value !== "showAll"
                        ? handleUpdateTemplate("domain", e.target.value)
                        : handleUpdateTemplate("domain", null)
                    }
                  >
                    <option value="showAll">--</option>
                    {account?.right_request_templates?.domains?.map(
                      (domain, index) => (
                        <option key={index} value={domain}>
                          {domain}
                        </option>
                      )
                    )}
                  </Form.Control>

                  <Form.Text className="text-muted">
                    Choose a branded domain name.
                  </Form.Text>
                </Form.Group>
              ) : null}

              <Form.Group className="mb-3">
                <Form.Label>Upload brand logo</Form.Label>

                {template?.logo_url ? (
                  <div className="d-flex my-3">
                    <img
                      alt="Brand logo"
                      style={{
                        objectFit: "contain",
                        maxWidth: "200px",
                        maxHeight: "80px",
                      }}
                      src={template?.logo_url}
                    />
                  </div>
                ) : null}
                <Form.Control
                  ref={fileRef}
                  type="file"
                  onChange={handleUploadImage}
                />

                <Form.Text className="text-muted">
                  Upload a brand logo to increase approval rates. Valid formats
                  are jpg, jpeg, png.
                </Form.Text>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Approval page introduction text</Form.Label>

                <ReelerEditor />

                <Form.Text className="text-muted">
                  Write a friendly introduction text, where you ask the content
                  creator (or 3rd party) for permission to use the content asset
                  in your communication. Give some praise for their great
                  photographic work! You may want to include a brief mention of
                  how you will use the content, but for the full terms &
                  conditions (T&C), you can include a URL below. At the end,
                  write that by clicking “approve” the end-user accepts your T&C
                  and your privacy policy.
                </Form.Text>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Modify button text (optional)</Form.Label>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>Approve button:</Form.Label>
                      <Form.Control
                        type="text"
                        value={
                          template?.approveButtonText
                            ? template?.approveButtonText
                            : "Approve"
                        }
                        onChange={e =>
                          handleUpdateTemplate(
                            "approveButtonText",
                            e.target.value
                          )
                        }
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>Reject button:</Form.Label>
                      <Form.Control
                        type="text"
                        value={
                          template?.rejectButtonText
                            ? template?.rejectButtonText
                            : "Reject"
                        }
                        onChange={e =>
                          handleUpdateTemplate(
                            "rejectButtonText",
                            e.target.value
                          )
                        }
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>
                  Add links to your T&C and privacy policy
                </Form.Label>
                <Form.Group>
                  <Form.Label>Terms & conditions URL</Form.Label>
                  <Form.Control
                    type="text"
                    value={template?.terms}
                    onChange={e =>
                      handleUpdateTemplate("terms", e.target.value)
                    }
                  />
                  <Form.Text className="text-muted">
                    Insert a URL link to your company’s terms & conditions (or
                    content licensing terms), where you specify what the content
                    creator or 3rd party should agree to. Typically, the T&C
                    would specify how you, as a brand, are allowed to use the
                    content and for what duration. Often, the T&C also spell out
                    whether there will be any compensation for your use of the
                    content or if you will receive a content license without any
                    monetary compensation.
                  </Form.Text>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Privacy policy URL</Form.Label>
                  <Form.Control
                    type="text"
                    value={template?.privacyPolicy}
                    onChange={e =>
                      handleUpdateTemplate("privacyPolicy", e.target.value)
                    }
                  />
                  <Form.Text className="text-muted">
                    Insert a URL link to your privacy policy, where you specify
                    how you will process personal data. The end-user should
                    approve both your T&C and your privacy policy by clicking
                    "approve".
                  </Form.Text>
                </Form.Group>
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <ReelerButton
            text="Close"
            styleClass="btn-secondary"
            dispatch={closeModal}
          />
          <ReelerButton
            text="Save"
            styleClass="btn-main"
            loading={saving}
            dispatch={() => handleSubmit()}
          />
        </Modal.Footer>
      </Modal>
    </>
  )
}
