import React from "react"
import { ImageContainer } from "../../styles"
import { ReactComponent as DummyImage } from "../../../../../assets/images/DummyAsset_optimized.svg"

export default function ImageWidget({ field }) {
  return (
    <ImageContainer $align={field?.align}>
      {field?.image_url ? (
        <img
          alt=""
          style={{
            width: `${field?.width}%`,
            margin: `${field?.margin}px`,
            padding: `${field?.padding}px`,
          }}
          src={field?.image_url}
        />
      ) : (
        <DummyImage
          id="dummy_image"
          style={{
            width: `250px`,
            height: `250px`,
          }}
        />
      )}
    </ImageContainer>
  )
}
