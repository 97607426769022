import React, { useState } from "react"
import { setFeed } from "../../../../../redux/feed/feed.actions"
import { selectFeed } from "../../../../../redux/feed/feed.selectors"
import { useDispatch, useSelector } from "react-redux"
import { Form } from "react-bootstrap"
import ReelerButton from "../../../../commons/ReelerButton"
import { FEED_TYPES } from "../../../../../constants"
import EmbedEmailFeed from "./email-feed/components/EmbedEmailFeed"
import useUserFeedbackMessage from "../../../../../hooks/useUserFeedbackMessage"

const EmbedTab = () => {
  const feed = useSelector(selectFeed)
  const [showIframeCode, setShowIframeCode] = useState(false)
  const { setSuccessMessage } = useUserFeedbackMessage()

  const divCode = `<div id="reeler-widget" data-feed="${feed?.id}"></div>`
  const scriptCode = `<script src="${process.env.REACT_APP_FIREBASE_WIDGET_URL}/ReelerWidgets.js"></script>`

  const iframeCode = `<iframe
  width="100%"
  height="700px"
  scrolling="yes"
  style="border: none"
  src="${process.env.REACT_APP_FIREBASE_WIDGET_URL}/iframe/feed/${feed?.id}"
></iframe>`

  const copyEmbedCode = divId => {
    var r = document.createRange()
    r.selectNode(document.getElementById(divId))
    window.getSelection().removeAllRanges()
    window.getSelection().addRange(r)
    document.execCommand("copy")
    window.getSelection().removeAllRanges()
    setSuccessMessage("Copied!")
  }

  return (
    <>
      {feed?.feedType === FEED_TYPES.email ? (
        <EmbedEmailFeed />
      ) : (
        <>
          <Form.Group className="mt-3">
            <Form.Text className="text-center mb-3">
              To publish this feed, copy and paste the code below into the HTML
              code on your website.
            </Form.Text>
            <Form.Control
              id="script"
              disabled={true}
              as="textarea"
              style={{ fontSize: "12px" }}
              rows={4}
              value={divCode + scriptCode}
              aria-label="Embed code"
            />
            <ReelerButton
              text="Copy"
              styleClass="btn-main mt-2"
              dispatch={() => copyEmbedCode("script")}
            />
          </Form.Group>
          <Form.Group className="w-100">
            <Form.Text className="text-center mb-3">
              or embed with{" "}
              <span
                className="notification-link icon-btn"
                onClick={() => setShowIframeCode(!showIframeCode)}
              >
                iframe code
              </span>
            </Form.Text>
            {showIframeCode ? (
              <>
                <Form.Control
                  id="iframe"
                  disabled={true}
                  as="textarea"
                  style={{ fontSize: "12px" }}
                  rows={7}
                  value={iframeCode}
                  aria-label="Embed code"
                />
                <ReelerButton
                  text="Copy iframe code"
                  styleClass="btn-main mt-2"
                  dispatch={() => copyEmbedCode("iframe")}
                />
              </>
            ) : null}
          </Form.Group>
        </>
      )}
    </>
  )
}

export default EmbedTab
