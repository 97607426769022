import React from "react"

// 3rd-party components
import { Form, Row, Col } from "react-bootstrap"
import InputGroup from "react-bootstrap/InputGroup"
import { SectionHeading } from "../../../../styles"

// Redux
import { useDispatch, useSelector } from "react-redux"
import { selectFeed } from "../../../../../../redux/feed/feed.selectors"
import {
  updateFeed,
  setIsFeedDirty,
} from "../../../../../../redux/feed/feed.actions"
import UnitInputGroup from "../../../../../commons/UnitInputGroup/UnitInputGroup"

const SocialWallSettings = () => {
  const dispatch = useDispatch()
  const feed = useSelector(selectFeed)

  const handleChange = ({ target: { name, value, type } }) => {
    if (type === "number") {
      dispatch(
        updateFeed({
          feedSettings: { ...feed.feedSettings, [name]: Number(value) },
        })
      )
    } else {
      dispatch(
        updateFeed({ feedSettings: { ...feed.feedSettings, [name]: value } })
      )
    }

    dispatch(setIsFeedDirty(true))
  }

  return (
    <>
      <SectionHeading>Social Wall</SectionHeading>
      <Row>
        <Col>
          <Form.Group className="mb-3" controlId="cols">
            <Form.Label>Columns</Form.Label>
            <br />
            <Form.Control
              type="number"
              min={0}
              max={10}
              value={
                feed.feedSettings.columns ? feed.feedSettings.columns : Number()
              }
              name="columns"
              onChange={handleChange}
            />
            <Form.Text muted>Number of assets in a row</Form.Text>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3" controlId="gutter">
            <UnitInputGroup
              label="Spacing between assets"
              name="gutter"
              min={0}
              max={20}
              value={feed.feedSettings.gutter ? feed.feedSettings.gutter : 0}
              dispatch={handleChange}
              unit="px"
            />

            <Form.Text muted>Spacing between assets</Form.Text>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group>
            <div className="custom-control custom-switch">
              <input
                type="checkbox"
                className="custom-control-input"
                id="show-caption"
                checked={feed.feedSettings?.showCaptionUnderImage}
                name="show-caption"
                onChange={() =>
                  dispatch(
                    updateFeed({
                      feedSettings: {
                        ...feed.feedSettings,
                        showCaptionUnderImage:
                          !feed.feedSettings?.showCaptionUnderImage,
                      },
                    })
                  )
                }
              />
              <label className="custom-control-label" htmlFor="show-caption">
                Show edited caption under card
              </label>
            </div>
          </Form.Group>
        </Col>
      </Row>
    </>
  )
}

export default SocialWallSettings
