import React from "react"
// 3rd-party libraries
import { Form } from "react-bootstrap"
import { useSelector } from "react-redux"
import { selectFormSchema } from "../../../../../redux/campaign/campaign.selectors"

import * as S from "../FormStyles"

export default function ThirdPartyApprovalWidget({
  fieldId,
  field,
  index,
  refArray = {},
  disabled = false,
  design,
}) {
  const formSchema = useSelector(selectFormSchema)

  return (
    <S.WidgetContainer>
      {field.title ? (
        <S.ColoredLabel for={fieldId} $design={design}>
          {field.title}
        </S.ColoredLabel>
      ) : null}
      {field.required ? <S.RequiredMark>*</S.RequiredMark> : null}

      <S.FormInput
        ref={el => (refArray.current = { ...refArray.current, [fieldId]: el })}
        style={{ color: "var(--reeler-grey-darker)" }}
        $focusColor={design?.fieldFocusColor}
        id={fieldId}
        type="email"
        placeholder={field.placeholder}
        disabled={disabled}
        required={field.required}
      />
      {field.description ? (
        <S.ColoredFormText $design={design}>
          {field.description}
        </S.ColoredFormText>
      ) : null}
    </S.WidgetContainer>
  )
}
