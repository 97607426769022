import axios from "axios"

const apiVersion = "v21.0"

export const getHashtagId = async (userId, searchTerm, access_token) => {
  // Get hashtag id
  const { data } = await axios({
    url: `https://graph.facebook.com/${apiVersion}/ig_hashtag_search?user_id=${userId}&q=${searchTerm}&access_token=${access_token}`,
    method: "get",
  })

  return data?.data[0]?.id
}

export const searchUsername = async (userId, username, access_token, after) => {
  let url = `https://graph.facebook.com/${apiVersion}/${userId}?fields=business_discovery.username(${username}){followers_count%2Cmedia_count%2Cmedia${
    after ? ".after(" + after + ")" : ""
  }{id%2Ccaption%2Cmedia_type%2Ccomments_count%2Clike_count%2Cchildren{media_url%2Cmedia_type}%2Cmedia_url%2Cpermalink%2Ctimestamp}}&access_token=${access_token}`

  // Get hashtag id
  const { data } = await axios({
    url: url,
    method: "get",
  })

  return data
}

export const getUsernameInsights = async (userId, username, access_token) => {
  let url = `https://graph.facebook.com/${apiVersion}/${userId}?fields=business_discovery.username(${username}){followers_count%2Cmedia_count}&access_token=${access_token}`

  // Get hashtag id
  try {
    const { data } = await axios({
      url: url,
      method: "get",
    })

    return data
  } catch (e) {
    return e.response.data
  }
}

export const getUserWithId = async (userId, access_token) => {
  // Get hashtag id
  const { data } = await axios({
    url: `https://graph.facebook.com/${apiVersion}/${userId}?fields=&access_token=${access_token}`,
    method: "get",
  })

  return data?.data[0]?.id
}

export const getHashtagName = async (hashtagId, access_token) => {
  // Get hashtag id
  const { data } = await axios({
    url: `https://graph.facebook.com/${apiVersion}/${hashtagId}?fields=name&access_token=${access_token}`,
    method: "get",
  })

  if (data?.name) {
    return data?.name
  }
}

export const getHashtagRecentMedia = async (
  hashtagId,
  userId,
  access_token
) => {
  // Get hashtag id
  const { data } = await axios({
    url: `https://graph.facebook.com/${apiVersion}/${hashtagId}/recent_media?user_id=${userId}&fields=id,caption,media_type,comments_count,like_count,children{media_url,media_type},media_url,permalink,timestamp&access_token=${access_token}`,
    method: "get",
  })

  return data
}

export const getHashtagTopMedia = async (hashtagId, userId, access_token) => {
  // Get hashtag id
  const { data } = await axios({
    url: `https://graph.facebook.com/${apiVersion}/${hashtagId}/top_media?user_id=${userId}&fields=id,caption,media_type,comments_count,like_count,children{media_url,media_type},media_url,permalink,timestamp&access_token=${access_token}`,
    method: "get",
  })

  return data
}

export const getRecentlySearchedHashtags = async (userId, access_token) => {
  // Get recently searched hashtags

  const { data } = await axios({
    url: `https://graph.facebook.com/${apiVersion}/${userId}/recently_searched_hashtags?limit=30&access_token=${access_token}`,
    method: "get",
  })

  return data
}
