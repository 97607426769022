// "use strict"
import React, { useContext, useState, useEffect } from "react"
import { auth, database } from "../firebase"
import { useDispatch } from "react-redux"
import { fetchAccountsSuccess } from "../redux/account/account.actions"
import { updateCurrentUser } from "../redux/user/user.actions"
const AuthContext = React.createContext()

export function useAuth() {
  return useContext(AuthContext)
}

export function AuthProvider({ children }) {
  const dispatch = useDispatch()
  const [currentUser, setCurrentUser] = useState()
  const [userDetails, setUserDetails] = useState()
  const [account, setAccount] = useState()
  const [loading, setLoading] = useState(true)

  async function createNewAccount(accountName) {
    if (currentUser) {
      let userId = currentUser.uid
      let email = currentUser.email
      // Create account
      const newAccount = {
        userId: userId,
        createdAt: database.getCurrentTimestamp(),
        name: accountName,
      }

      var accountDocRef = database.accounts.doc()
      await accountDocRef.set(newAccount)

      const newUser = {
        email: email,
        accounts: [accountDocRef.id],
        role: "owner",
        createdAt: database.getCurrentTimestamp(),
      }

      var userDetailsDocRef = database.users.doc(userId)
      await userDetailsDocRef.set(newUser)

      // Create private details for the account
      const newAccountPrivate = {
        roles: { [userId]: "owner" },
        createdAt: database.getCurrentTimestamp(),
      }

      var accountPrivateDocRef = accountDocRef
        .collection("private")
        .doc("private")
      await accountPrivateDocRef.set(newAccountPrivate)

      let accDoc = await accountDocRef.get()
      let formattedAccountDoc = database.formatDoc(accDoc)
      setAccount(formattedAccountDoc)
      dispatch(fetchAccountsSuccess(formattedAccountDoc))

      let userDoc = await userDetailsDocRef.get()
      let formattedUserDoc = database.formatDoc(userDoc)
      setUserDetails(formattedUserDoc)
      dispatch(updateCurrentUser(formattedUserDoc))
    }
  }

  async function signupInvite(invite) {
    if (currentUser) {
      const newUser = {
        email: currentUser.email,
        accounts: [invite.account],
        role: invite.role,
        createdAt: database.getCurrentTimestamp(),
      }

      database.users
        .doc(currentUser.uid)
        .set(newUser)
        .then(user => {
          // Uppdate account private with user role
          database.accounts
            .doc(invite.account)
            .collection("private")
            .doc("private")
            .update({
              [`roles.${invite.account}`]: invite.role,
            })
            .then(doc => {
              database.invites
                .doc(invite.id)
                .update({ valid: false })
                .then(doc => {
                  return doc
                })
            })
        })
        .catch(err => {
          setLoading(false)
        })
    }
  }

  function signup(email, password) {
    return auth.createUserWithEmailAndPassword(email, password)
  }

  function login(email, password) {
    return auth.signInWithEmailAndPassword(email, password)
  }

  function logout() {
    return auth.signOut()
  }

  function resetPassword(email) {
    return auth.sendPasswordResetEmail(email)
  }

  function updateEmail(email) {
    return currentUser.updateEmail(email)
  }

  function updatePassword(password) {
    return currentUser.updatePassword(password)
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setCurrentUser(user)
      setLoading(false)
    })

    return unsubscribe
  }, [])

  useEffect(() => {
    if (currentUser) {
      database.users
        .doc(currentUser.uid)
        .get()
        .then(user => {
          if (user.exists) {
            setUserDetails(database.formatDoc(user))
            dispatch(updateCurrentUser(database.formatDoc(user)))
            // load account
            database.accounts
              .doc(user.data().accounts[0])
              .get()
              .then(accountDoc => {
                if (accountDoc.exists) {
                  let formatedAccount = database.formatDoc(accountDoc)
                  setAccount(formatedAccount)
                  dispatch(fetchAccountsSuccess(formatedAccount))

                  return true
                } else {
                  // doc.data() will be undefined in this case
                }
              })
              .catch(err => {
                return false
              })
          } else {
            // doc.data() will be undefined in this case
          }
        })
        .then(() => {
          // Update user doc with latestActivity date
          if (currentUser?.uid) {
            database.users
              .doc(currentUser.uid)
              .update({ latestActivity: database.getCurrentTimestamp() })
              .catch(err => console.log(err))
          }
        })
        .catch(err => {
          return false
        })
    }
  }, [currentUser])

  // const fbLoginStatus = () => {
  //   if (window.FB) {
  //     window.FB.getLoginStatus(function (response) {
  //       if (response.status === "connected") {
  //         // Logged into Facebook.
  //         setFacebookUser(response)
  //       }
  //     })
  //   }
  // }

  // Check if user is logged in to facebook
  //useEffect(() => {
  //  fbLoginStatus();
  //}, [currentUser]);

  const value = {
    currentUser,
    userDetails,
    account,
    createNewAccount,
    setAccount,
    signupInvite,
    login,
    signup,
    logout,
    resetPassword,
    updateEmail,
    updatePassword,
  }

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  )
}
