import React, { useState } from "react"

import MediaType from "./MediaType"
import * as S from "../ConfirmationPage.styled"
import { Modal, Button } from "react-bootstrap"
import DownloadFile from "./DownloadFile"

export default function Media({ asset }) {
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  return (
    <>
      <S.AssetContainer>
        <S.MediaContainer onClick={() => handleShow()}>
          <MediaType asset={asset} />
        </S.MediaContainer>
      </S.AssetContainer>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <MediaType asset={asset} />
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <DownloadFile asset={asset} />
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
