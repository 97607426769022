import styled from "styled-components"

export const StatusBox = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: default;

  border-radius: 10px;
  background: ${({ status }) =>
    ["pending", "pending-third-party-approve"].includes(status)
      ? "var(--reeler-warning)"
      : ["approved", "approved-third-party-approve"].includes(status)
      ? "var(--reeler)"
      : ["not-approved", "not-approved-third-party"].includes(status)
      ? "var(--reeler-danger)"
      : "var(--reeler-grey-middle)"};

  width: 20px;
  height: 20px;
  margin-top: ${props => (props.inToolbar ? 0 : "0.5rem")};
  margin-right: ${props => (props.inToolbar ? 0 : "0.5rem")};
  padding: 4px;
`

export const ThirdPartyStatusBox = styled.div`
  position: absolute;
  top: 4px;
  right: 4px;
  display: flex;
  border-radius: 10px;
  background: ${({ status }) =>
    ["pending"].includes(status)
      ? "var(--reeler-warning)"
      : ["approved"].includes(status)
      ? "var(--reeler)"
      : ["not-approved"].includes(status)
      ? "var(--reeler-danger)"
      : "var(--reeler-grey)"};

  width: 10px;
  height: 10px;
`
