import React, { useState, useEffect } from "react"
import { Dropdown } from "react-bootstrap"
import { FaTags } from "react-icons/fa"
import AssetTags from "./asset-info/components/AssetTags"
import ReelerTooltip from "../../../commons/ReelerTooltip/ReelerTooltip"
import * as S from "../components/styles"

export const AddTagsToAsset = ({ asset }) => {
  const [tags, setTags] = useState(null)

  useEffect(() => {
    if (asset.tags) {
      setTags(Object.keys(asset.tags))
    }
  }, [asset])
  // The forwardRef is important!!
  // Dropdown needs access to the DOM node in order to position the Menu
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
      className="flex-row align-middle d-flex campaign-status"
      ref={ref}
      // onMouseOver={() => setShow(true)}
      // onMouseOut={() => setShow(false)}
      onClick={e => {
        e.preventDefault()
        onClick(e)
      }}
    >
      {children}
    </div>
  ))
  return (
    <Dropdown>
      <Dropdown.Toggle as={CustomToggle} id="campaign-status" drop="start">
        <ReelerTooltip text="Add labels">
          <div>
            <FaTags className="icon-color icon-btn" />

            {tags?.length > 0 ? (
              <S.Notification $top="0" $width="15" $height="15">
                {tags.length}
              </S.Notification>
            ) : null}
          </div>
        </ReelerTooltip>
      </Dropdown.Toggle>

      <Dropdown.Menu className="reeler-dropdown">
        <div className="p-2 mb-3" style={{ width: "300px" }}>
          <div className="mb-2">
            <strong>Manage content labels:</strong>
          </div>
          <div className="d-flex flex-column">
            <AssetTags asset={asset} />
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  )
}
