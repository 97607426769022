import styled from "styled-components"
import { Link } from "react-router-dom"
import "../../App.css"

export const LinkContainer = styled(Link)`
  color: var(--reeler-icon);
  border-bottom: 2px transparent solid;
  transition: all 0.2s ease;

  height: min-content;

  &:hover {
    border-bottom: 2px var(--reeler-icon) solid;
    text-decoration: none;
  }

  &:active {
    border-bottom: 2px var(--reeler) solid;
  }
`
export const Content = styled.span`
  color: var(--reeler-icon);
  display: flex;
  align-items: center;

  border-bottom: 2px transparent solid;
  transition: all 0.2s ease;

  &:active {
    color: var(--reeler);
  }
`
