import React from "react"
import * as S from "./SubHeader.styles"

const SubHeader = ({ children }) => {
  return <S.SubHeader>{children}</S.SubHeader>
}

const SubHeaderGroup = ({ children }) => {
  return <S.SubHeaderGroup>{children}</S.SubHeaderGroup>
}
const SubHeaderGroupLeft = ({ children }) => {
  return <S.SubHeaderGroupLeft>{children}</S.SubHeaderGroupLeft>
}

const SubHeaderGroupCenter = ({ children }) => {
  return <S.SubHeaderGroupCenter>{children}</S.SubHeaderGroupCenter>
}

const SubHeaderGroupRight = ({ children }) => {
  return <S.SubHeaderGroupRight>{children}</S.SubHeaderGroupRight>
}

export {
  SubHeader,
  SubHeaderGroup,
  SubHeaderGroupLeft,
  SubHeaderGroupCenter,
  SubHeaderGroupRight,
}
