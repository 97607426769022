import styled from "styled-components"

export const EyeContainer = styled.div`
  color: var(--reeler-icon);
  background-color: white;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  aspect-ratio: 1/1;
  border-bottom-right-radius: 5px;
  z-index: 1;
  cursor: pointer;
`
