import React, { useState } from "react"
import { Modal, ModalBody } from "react-bootstrap"
import { database } from "../../../firebase"
import { FaTrashAlt } from "react-icons/fa"
import ReelerButton from "../../commons/ReelerButton"
import Icon from "../../commons/Icon"

import { USER_STATUS } from "../../../constants"
import useUserFeedbackMessage from "../../../hooks/useUserFeedbackMessage"

export default function DeleteUser({ userId }) {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const { setUserFeedbackMessage } = useUserFeedbackMessage()

  function openModal() {
    setOpen(true)
  }

  function closeModal() {
    setOpen(false)
  }

  const handleDeleteUser = async () => {
    setLoading(true)

    const userData = { status: USER_STATUS.DEACTIVATED }

    database.users
      .doc(userId)
      .update(userData)
      .then(() => {
        setUserFeedbackMessage(`User deactivated successfully`, "success")
        setLoading(false)
      })
      .catch(err => {
        setUserFeedbackMessage(`Failed to deactivate the user`, "danger")
        setLoading(false)
      })
  }

  return (
    <>
      <Icon onClick={openModal} tooltip="Deactivate user">
        <FaTrashAlt />
      </Icon>

      <Modal centered show={open} onHide={closeModal}>
        <ModalBody>
          <Modal.Title>
            Are you sure you want to deactivate this user?
          </Modal.Title>
          <Modal.Body>
            If you deactivate a user, he/she cannot log in, but their user data
            still exists.
          </Modal.Body>
          <Modal.Footer>
            <div
              className="me-4"
              variant="secondary"
              onClick={closeModal}
              style={{ cursor: "pointer" }}
            >
              Close
            </div>
            <ReelerButton
              loading={loading}
              dispatch={() => handleDeleteUser()}
              text="Deactivate user"
              styleClass="btn-delete"
            />
          </Modal.Footer>
        </ModalBody>
      </Modal>
    </>
  )
}
