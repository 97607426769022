import styled, { keyframes } from "styled-components"

const fadeInOut = keyframes`
0%,100% { opacity: 0 }
10%, 75% { opacity: 1 }
`

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 1rem 0;
`

export const Alert = styled.div`
  width: 100%;
  justify-content: center;
  text-align: center;
  opacity: 1;
  animation: ${fadeInOut} ${({ duration }) => `${duration}ms`} linear forwards;
  padding: 1em;
  color: var(--reeler-title);
  background-color: ${({ variant }) => `${variant}-light)`};
  border: 1px solid ${({ variant }) => `${variant})`};
  border-radius: 0.25rem;
  z-index: 1001;
`
