import React, { useRef, useState } from "react"
import { Dropdown, Form, CloseButton } from "react-bootstrap"
import { FaEdit } from "react-icons/fa"
import ReelerTooltip from "../../commons/ReelerTooltip/ReelerTooltip"
import { useDispatch } from "react-redux"
import { database, firestore } from "../../../firebase"
import ReelerButton from "../../commons/ReelerButton"

import _ from "lodash"
import { BulkEditActionSuccess } from "../../../redux/bulk-edit/bulkEdit.actions"
import ReelerPopover from "../../commons/ReelerPopover"

export const BulkNotes = ({ assets }) => {
  const [show, setShow] = useState(false)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const inputRef = useRef(null)

  const handleUpdateNotes = async () => {
    setLoading(true)
    const note = inputRef.current.value
    console.log("no fo assets", assets.length)
    const batches = _.chunk(assets, 250).map(async assetDocs => {
      console.log("Creating batches")

      const batch = firestore.batch()

      for (let i = 0; i < assetDocs.length; i++) {
        const asset = assetDocs[i]
        console.log("Updating through asset", asset.id)

        let assetRef = database.assets.doc(asset.id)
        let assetDoc = await assetRef.get()
        let notes = ""
        if (assetDoc.data()?.notes) {
          notes = assetDoc.data().notes + "\r\n"
        }
        notes += note
        console.log(notes)
        batch.set(assetRef, { notes }, { merge: true })
      }

      return batch.commit()
    })

    await Promise.all(batches)
      .then(() => {
        dispatch(
          BulkEditActionSuccess({
            type: "success",
            duration: 3000,
            lead: "Notes",
            text: "Updated notes successfully",
            position: "bulkEdit",
          })
        )
        inputRef.current.value = ""
        setLoading(false)
        setShow(false)
      })
      .catch(err => {
        console.log(err)
        dispatch(
          BulkEditActionSuccess({
            type: "danger",
            duration: 3000,
            lead: "Error",
            text: "Could not update assets with notes",
            position: "bulkEdit",
          })
        )
      })
  }

  return (
    <ReelerPopover
      title="Add note"
      btn={
        <ReelerTooltip placement="bottom" text="Add note">
          <FaEdit size={14} className="icon-color icon-btn" />
        </ReelerTooltip>
      }
    >
      <div className="p-1" style={{ minWidth: "300px" }}>
        <Form.Group className="mb-1">
          <Form.Label>Add note</Form.Label>
          <Form.Control
            as="textarea"
            rows={5}
            disabled={loading}
            placeholder="Leave a note"
            ref={inputRef}
          />
          <Form.Text muted>
            The note will be added as an additional note to all selected assets.
          </Form.Text>
        </Form.Group>
        <div className="d-flex justify-content-end">
          <ReelerButton
            text="Save"
            styleClass="btn-main"
            size="sm"
            dispatch={() => handleUpdateNotes()}
            loading={loading}
            disabled={assets.length > 0 ? false : true}
          />
        </div>
      </div>
    </ReelerPopover>
  )
}
