import React from "react"
import * as S from "./Header.styles"

const Header = ({ children }) => {
  return <S.Header>{children}</S.Header>
}

const HeaderGroup = ({ children }) => {
  return <S.HeaderGroup>{children}</S.HeaderGroup>
}
const HeaderGroupLeft = ({ children }) => {
  return <S.HeaderGroupLeft>{children}</S.HeaderGroupLeft>
}

const HeaderGroupCenter = ({ children }) => {
  return <S.HeaderGroupCenter>{children}</S.HeaderGroupCenter>
}

const HeaderGroupRight = ({ children }) => {
  return <S.HeaderGroupRight>{children}</S.HeaderGroupRight>
}

export {
  Header,
  HeaderGroup,
  HeaderGroupLeft,
  HeaderGroupCenter,
  HeaderGroupRight,
}
