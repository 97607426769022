import NavbarActionTypes from "./navbar.types"

const INITIAL_STATE = {
  isCollapsed: false,
  subMenuStates: {},
}

const navbarReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case NavbarActionTypes.SET_IS_COLLAPSED:
      return {
        ...state,
        isCollapsed: action.payload,
      }
    case NavbarActionTypes.UPDATE_SUBMENU_STATE:
      return {
        ...state,
        subMenuStates: {
          ...state.subMenuStates,
          [action.payload.key]: action.payload.state,
        },
      }

    default:
      return state
  }
}

export default navbarReducer
