import React, { useEffect, useState } from "react"
import { Modal, Form, ModalBody, Alert } from "react-bootstrap"
import { database } from "../../../firebase"
import { useSelector } from "react-redux"
import { selectCurrentUser } from "../../../redux/user/user.selectors"
import {
  selectAccountId,
  selectAccounts,
} from "../../../redux/account/account.selectors"
import ReelerButton from "../../commons/ReelerButton"
import useUserFeedbackMessage from "../../../hooks/useUserFeedbackMessage"
import { ACCOUNT_PLANS, USER_ROLES } from "../../../constants/routes"
import { capitalize } from "lodash"
import SpinnerComponent from "../../commons/SpinnerComponent"

const UserInvite = () => {
  const { setUserFeedbackMessage, setErrorMessage } = useUserFeedbackMessage()
  const [open, setOpen] = useState(false)
  const [email, setEmail] = useState("")
  const [role, setRole] = useState("editor")
  const [userRoles, setUserRoles] = useState(null)
  const [msg, setMsg] = useState(null)
  const [sending, setSending] = useState(false)

  const currentUser = useSelector(selectCurrentUser)
  const account = useSelector(selectAccounts)

  function openModal() {
    setMsg(null)
    setOpen(true)
  }

  function closeModal() {
    setOpen(false)
  }

  useEffect(() => {
    if (account) {
      const roles = Object.keys(USER_ROLES).filter(role => {
        if (role === USER_ROLES.viewer) {
          if (account.plan === ACCOUNT_PLANS.premium) {
            return role
          }
        } else {
          return role
        }
      })
      setUserRoles(roles)
    }
  }, [account])

  const handleSubmit = async e => {
    e.preventDefault()
    setSending(true)

    if (!email) return

    // Verify email
    // check format
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      setErrorMessage("Email has wrong format")
      setSending(false)
      return
    }

    // Check if there are any users already with that email
    const usersRef = await database.users.where("email", "==", email).get()

    if (!usersRef.empty) {
      setErrorMessage("Email already exists, please try another")
      setSending(false)
      return
    }

    const newInvite = {
      email: email,
      account: account.id,
      userId: currentUser.id,
      invitedBy: {
        id: currentUser.id,
        firstName: currentUser.firstName,
        lastName: currentUser.lastName,
        email: currentUser.email,
      },
      role,
      valid: true,
      createdAt: database.getCurrentTimestamp(),
    }

    database.invites
      .add(newInvite)
      .then(doc => {
        setUserFeedbackMessage(
          `Invite sent successfully to ${email}`,
          "success"
        )

        setEmail("")
        setSending(false)
        closeModal()
      })
      .catch(err => {
        setUserFeedbackMessage(
          `Something went wrong and invite is not sent to ${email}`,
          "danger"
        )
        setSending(false)
      })
  }

  if (!account) return <SpinnerComponent />

  return (
    <>
      <ReelerButton
        text="Invite user"
        styleClass="btn-main"
        dispatch={openModal}
      />

      <Modal show={open} onHide={closeModal}>
        <ModalBody>
          <Form onSubmit={handleSubmit}>
            <Modal.Title>Invite new user</Modal.Title>
            <Modal.Body>
              {msg ? <Alert variant={msg.variant}>{msg.text}</Alert> : null}
              <Form.Group>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  required
                  onChange={e => setEmail(e.target.value)}
                  value={email}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Role</Form.Label>
                <Form.Control
                  as="select"
                  defaultValue={USER_ROLES.editor}
                  required
                  onChange={e => setRole(e.target.value)}
                >
                  {userRoles?.map((role, index) => (
                    <option key={index} value={role}>
                      {capitalize(role)}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <ReelerButton
                text="Close"
                styleClass="btn-secondary"
                dispatch={closeModal}
              />
              <ReelerButton
                text="Invite user"
                styleClass="btn-main"
                loading={sending}
                type="submit"
              />
            </Modal.Footer>
          </Form>
        </ModalBody>
      </Modal>
    </>
  )
}

export default UserInvite
