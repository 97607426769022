import React, { useRef, useState } from "react"
// Firebase
import firebase from "firebase/app"
import { database } from "../../../../firebase"
import {
  Form,
  Row,
  Col,
  InputGroup,
  FormControl,
  Button,
} from "react-bootstrap"
import CreatableSelect from "react-select/creatable"
import { IoClose } from "react-icons/io5"
import { useDispatch, useSelector } from "react-redux"
import { selectCampaign } from "../../../../redux/campaign/campaign.selectors"
import { updateTags } from "../../../../redux/campaign/campaign.actions"
import {
  selectAccountId,
  selectAccounts,
} from "../../../../redux/account/account.selectors"
import { updateAccounts } from "../../../../redux/account/account.actions"
import Tag from "../../../commons/Tag"
const customStyles = {
  container: () => ({
    // none of react-select's styles are passed to <Control />
    width: 300,
  }),
}
export default function CampaignTags() {
  const account = useSelector(selectAccounts)
  const accountId = useSelector(selectAccountId)
  const [tag, setTag] = useState("")
  const [loading, setLoading] = useState(false)

  const campaign = useSelector(selectCampaign)
  const dispatch = useDispatch()

  const handleNewTag = str => {
    setLoading(true)
    let tags
    if (campaign.tags) {
      tags = campaign.tags
    } else {
      tags = {}
    }
    let accountTags = []

    if (str.trim() !== null) {
      str
        .toLowerCase()
        .replace(/#+/g, "")
        .split(" ")
        .forEach(tag => {
          if (tag.trim() === "") {
            return
          }
          tags[tag] = true
          accountTags.push(tag)
        })
      dispatch(updateTags(tags))
      // update firebase and redux for account
      database.accounts
        .doc(accountId)
        .update({
          tags: firebase.firestore.FieldValue.arrayUnion(...accountTags),
        })
        .then(() => {
          // Update redux
          dispatch(
            updateAccounts({
              tags: account?.tags
                ? [...account?.tags, ...accountTags]
                : accountTags,
            })
          )
        })
        .catch(err => {
          setLoading(false)
        })
      setTag("")
      setLoading(false)
    }
  }

  const handleDeleteTag = tagToDelete => {
    setLoading(true)
    const { [tagToDelete]: value, ...tags } = campaign.tags
    dispatch(updateTags(tags))
    setLoading(false)
  }

  return (
    campaign && (
      <>
        <Form.Group controlId="add-tag">
          <Row>
            <Col sm={4}>
              <Form.Label className="mb-0">
                Add content source labels
              </Form.Label>
            </Col>
            <Col sm={8}>
              <InputGroup className="mb-3">
                <CreatableSelect
                  placeholder="Add a new label..."
                  styles={customStyles}
                  theme={theme => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: "var(--reeler-o-25)",
                      primary: "var(--reeler-light)",
                    },
                  })}
                  value={tag}
                  isClearable
                  onChange={(selectedOption, action) => {
                    if (action.action === "clear") {
                      setTag("")
                    } else if (action.action === "create-option") {
                      setTag(action.option.value?.trim().toLowerCase())
                      handleNewTag(action.option.value?.trim().toLowerCase())
                    } else {
                      handleNewTag(selectedOption?.value?.trim().toLowerCase())
                    }
                  }}
                  options={account?.tags
                    ?.sort((a, b) => {
                      if (a.toLowerCase() < b.toLowerCase()) {
                        return -1
                      }
                      if (a.toLowerCase() > b.toLowerCase()) {
                        return 1
                      }
                      return 0
                    })
                    ?.map(t => ({ value: t, label: t }))}
                />
              </InputGroup>
              <Form.Text muted>
                Labels are for finding content later, in your Reeler content
                library. Here you set labels you want automatically added to all
                content in the content source. Hit enter after typing each
                label. Later you can manually add additional labels to
                individual content assets, as you review them.
              </Form.Text>

              <div>
                {campaign.tags &&
                  Object.keys(campaign.tags).map((tag, index) => (
                    <Tag key={index} onClick={() => handleDeleteTag(tag)}>
                      {tag}
                    </Tag>
                  ))}
              </div>
            </Col>
          </Row>
        </Form.Group>
      </>
    )
  )
}
