import React, { useState } from "react"
import { Table } from "react-bootstrap"
import { BsSortDown } from "react-icons/bs"
import { useSelector } from "react-redux"
import { selectAccounts } from "../../../../redux/account/account.selectors"
import SpinnerComponent from "../../../../components/commons/SpinnerComponent"
import EditEmailTemplate from "./EditEmailTemplate"
import Icon from "../../../../components/commons/Icon"
import { FaEdit, FaTrash } from "react-icons/fa"
import DeleteEmailTemplate from "./DeleteEmailTemplate"

const EmailTemplatesTabel = () => {
  const account = useSelector(selectAccounts)

  if (!account) {
    return <SpinnerComponent />
  }

  return (
    <Table className="mt-3">
      <thead>
        <tr style={{ height: "50px", borderBottom: "2px solid #dee2e6" }}>
          <th style={{ width: "20px" }}>#</th>
          <th className="align-middle">Template name</th>

          <th className="text-center"></th>
        </tr>
      </thead>
      <tbody>
        {account?.right_request_templates?.emailTemplates?.map(
          (emailTemplate, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td className="align-middle">
                {emailTemplate.emailTemplateName}
              </td>
              <td className="d-flex flex-row justify-content-end">
                <EditEmailTemplate
                  existingTemplate={emailTemplate}
                  existingIndex={index}
                  btn={
                    <Icon tooltip="Edit template">
                      <FaEdit />
                    </Icon>
                  }
                />
                <DeleteEmailTemplate
                  existingTemplate={emailTemplate}
                  existingIndex={index}
                  btn={
                    <Icon tooltip="Delete template">
                      <FaTrash />
                    </Icon>
                  }
                />
              </td>
            </tr>
          )
        )}
      </tbody>
    </Table>
  )
}

export default EmailTemplatesTabel
