/**
 * Generate a user based on user information
 *
 * @param user.
 
 * @return Formatted user name as string.
 */

export function generateFullname(user) {
  var fullname = ""

  if (user?.firstName || user?.lastName) {
    if (user?.firstName) {
      fullname += user?.firstName
    }

    if (user?.lastName) {
      if (fullname.length > 0) {
        fullname += " " + user?.lastName
      } else {
        fullname += user?.lastName
      }
    }
    return fullname
  }

  return ""
}
