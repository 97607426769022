import React, { useState, useEffect } from "react"

// 3rd-party libraries
import { FaCheck } from "react-icons/fa"

// Redux
import { useDispatch, useSelector } from "react-redux"
import {
  BulkEditAddItem,
  BulkEditRemoveItem,
} from "../../../../redux/bulk-edit/bulkEdit.actions"

import {
  selectBulkEditArray,
  selectLastSelected,
} from "../../../../redux/bulk-edit/bulkEdit.selectors"
import { selectFiltredAssets } from "../../../../redux/filter/filter.selectors"

import ReelerTooltip from "../../../commons/ReelerTooltip/ReelerTooltip"

// Reeler components
import { SquareContainer, Square } from "./styles"

export default function AssetBulkEdit({ asset }) {
  const dispatch = useDispatch()
  const assetArray = useSelector(selectBulkEditArray)
  const filtredAssets = useSelector(selectFiltredAssets)
  const lastSelectedAsset = useSelector(selectLastSelected)
  const [selected, setSelected] = useState(false)

  const handleChecked = e => {
    // if shiftKey is hold, select multiple assets in between
    if (e.shiftKey) {
      const indexSelected = filtredAssets.findIndex(a => a.id === asset.id)
      const indexLastSelected = filtredAssets.findIndex(
        a => a.id === lastSelectedAsset?.id
      )

      const noOfSelectedAssets = Math.abs(indexSelected - indexLastSelected)
      console.log(noOfSelectedAssets)
      for (let i = 1; i <= noOfSelectedAssets; i++) {
        // If new assets are ahead of last selected
        let a
        if (indexSelected > indexLastSelected) {
          a = filtredAssets[indexLastSelected + i]
        } else {
          a = filtredAssets[indexLastSelected - i]
        }

        if (assetArray.find(item => item.id === a.id)) {
          dispatch(BulkEditRemoveItem(a))
        } else {
          dispatch(BulkEditAddItem(a))
        }
      }
    } else {
      if (assetArray.find(item => item.id === asset.id)) {
        dispatch(BulkEditRemoveItem(asset))
      } else {
        dispatch(BulkEditAddItem(asset))
      }
    }
  }

  useEffect(() => {
    setSelected(assetArray.find(item => item.id === asset.id))
  }, [assetArray])

  return (
    <SquareContainer onClick={e => handleChecked(e)}>
      <ReelerTooltip
        text={
          selected
            ? "Unselect"
            : lastSelectedAsset && lastSelectedAsset.id !== asset.id
            ? "Select (optional: hold shift to select all in between)"
            : "Select"
        }
      >
        <Square>{selected ? <FaCheck size={12} /> : null}</Square>
      </ReelerTooltip>
    </SquareContainer>
  )
}
