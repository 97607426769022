import AssetActionTypes from "./asset.types"

export const fetchAssetsStart = () => ({
  type: AssetActionTypes.FETCH_ASSETS_START,
})

export const fetchAssetsSuccess = payload => ({
  type: AssetActionTypes.FETCH_ASSETS_SUCCESS,
  payload: payload,
})

export const fetchAssetsFailure = error => ({
  type: AssetActionTypes.FETCH_ASSETS_FAILURE,
  payload: error,
})

export const fetchMoreAssetsStart = () => ({
  type: AssetActionTypes.FETCH_MORE_ASSETS_START,
})

export const fetchCampaignAssetsStart = campaignId => ({
  type: AssetActionTypes.FETCH_CAMPAIGN_ASSETS_START,
  payload: campaignId,
})

export const fetchCampaignAssetsSuccess = payload => ({
  type: AssetActionTypes.FETCH_CAMPAIGN_ASSETS_SUCCESS,
  payload: payload,
})

export const fetchCampaignAssetsFailure = error => ({
  type: AssetActionTypes.FETCH_CAMPAIGN_ASSETS_FAILURE,
  payload: error,
})

export const fetchMoreCampaignAssetsStart = campaignId => ({
  type: AssetActionTypes.FETCH_MORE_CAMPAIGN_ASSETS_START,
  payload: campaignId,
})

export const setActiveAsset = payload => ({
  type: AssetActionTypes.SET_ACTIVE_ASSET,
  payload,
})

export const updateAsset = payload => ({
  type: AssetActionTypes.UPDATE_ASSET,
  payload,
})

export const setShowAssetInfoModal = () => ({
  type: AssetActionTypes.SHOW_ASSET_INFO_MODAL,
})

export const setCloseAssetInfoModal = () => ({
  type: AssetActionTypes.CLOSE_ASSET_INFO_MODAL,
})

export const clearActiveAsset = () => ({
  type: AssetActionTypes.CLEAR_ACTIVE_ASSET,
})

export const setActiveSidebarTab = payload => ({
  type: AssetActionTypes.SET_ACTIVE_SIDEBAR_TAB,
  payload,
})

export const setSelectedVersion = payload => ({
  type: AssetActionTypes.SET_SELECTED_VERSION,
  payload,
})

export const clearSelectedVersion = () => ({
  type: AssetActionTypes.CLEAR_SELECTED_VERSION,
})

export const setVersionToView = payload => ({
  type: AssetActionTypes.SET_VERSION_TO_VIEW,
  payload,
})

export const setShowAssetRightsRequestModal = () => ({
  type: AssetActionTypes.SHOW_ASSET_RIGTHS_REQUEST_MODAL,
})

export const setCloseAssetRightsRequestModal = () => ({
  type: AssetActionTypes.CLOSE_ASSET_RIGTHS_REQUEST_MODAL,
})

export const setAssetRightsRequest = payload => ({
  type: AssetActionTypes.SET_ASSET_RIGTHS_REQUEST,
  payload,
})

export const clearAssetRightsRequest = payload => ({
  type: AssetActionTypes.CLEAR_ASSET_RIGTHS_REQUEST,
  payload,
})
export const resetToInitialAssetState = () => ({
  type: AssetActionTypes.RESET_TO_INITIAL_STATE,
})
