import React, { useEffect } from "react"

// 3rd-party components
import { Form, Row, Col } from "react-bootstrap"
import { SectionHeading } from "../../../../styles"

// Redux
import { useDispatch, useSelector } from "react-redux"
import {
  selectFeed,
  selectIsFeedDirty,
  selectGridSettings,
} from "../../../../../../redux/feed/feed.selectors"
import {
  setGridSettings,
  setIsFeedDirty,
} from "../../../../../../redux/feed/feed.actions"
import UnitInputGroup from "../../../../../commons/UnitInputGroup/UnitInputGroup"

const FeedLayoutSettings = () => {
  const dispatch = useDispatch()
  const feed = useSelector(selectFeed)
  const isDirty = useSelector(selectIsFeedDirty)
  const reduxSettings = useSelector(selectGridSettings)

  useEffect(() => {
    if (feed && feed.feedType === "grid" && feed.feedSettings && !isDirty) {
      dispatch(setGridSettings(feed.feedSettings))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feed, isDirty])

  const handleChange = ({ target: { name, value, type } }) => {
    if (type === "number") {
      dispatch(setGridSettings({ ...reduxSettings, [name]: Number(value) }))
    } else {
      dispatch(setGridSettings({ ...reduxSettings, [name]: value }))
    }

    dispatch(setIsFeedDirty(true))
  }
  if (reduxSettings) {
    return (
      <>
        <SectionHeading>Feed</SectionHeading>
        <Row>
          <Col>
            <Form.Group className="mb-3" controlId="cols">
              <Form.Label>Initial number of assets</Form.Label>
              <br />
              <Form.Control
                type="number"
                min={0}
                max={10}
                value={reduxSettings.initial ? reduxSettings.initial : Number()}
                name="initial"
                onChange={handleChange}
              />
              <Form.Text muted>
                Number of assets that are initially loaded in the feed.
              </Form.Text>
            </Form.Group>
          </Col>
        </Row>
      </>
    )
  } else {
    return null
  }
}

export default FeedLayoutSettings
