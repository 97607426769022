import React from "react"

// 3rd-party libraries
import { FaEye, FaEyeSlash } from "react-icons/fa"

// Redux
import { useDispatch, useSelector } from "react-redux"
import { updateFeed } from "../../../../../../../../../redux/feed/feed.actions"
import { selectFeed } from "../../../../../../../../../redux/feed/feed.selectors"

// Reeler components
import { EyeContainer } from "./styles"
import ReelerTooltip from "../../../../../../../../commons/ReelerTooltip/ReelerTooltip"
import { FEED_PUBLISH_OPTIONS } from "../../../../../../../../../constants"

const PublishFeedAsset = ({ asset }) => {
  const feed = useSelector(selectFeed)

  const dispatch = useDispatch()

  const includeAsset = () => {
    if (feed?.publish === FEED_PUBLISH_OPTIONS.manually) {
      if (feed?.manuallyAddedAssets?.length > 0) {
        let manuallyAddedAssets = feed.manuallyAddedAssets
        manuallyAddedAssets.push(asset.id)
        // add asset to feed.assets

        dispatch(updateFeed({ manuallyAddedAssets: manuallyAddedAssets }))
      } else {
        dispatch(updateFeed({ manuallyAddedAssets: [asset.id] }))
      }
    } else {
      // Remove asset from exclude asset array
      let excludedAssets = feed?.excludedAssets?.filter(
        assetId => assetId !== asset.id
      )
      dispatch(updateFeed({ excludedAssets: excludedAssets }))
    }
  }

  const exludeAsset = () => {
    if (feed?.publish === FEED_PUBLISH_OPTIONS.manually) {
      // Remove asset from exclude asset array
      let manuallyAddedAssets = feed?.manuallyAddedAssets?.filter(
        assetId => assetId !== asset.id
      )
      dispatch(updateFeed({ manuallyAddedAssets: manuallyAddedAssets }))
    } else {
      // Add asset to exclude asset array
      if (feed?.excludedAssets?.length > 0) {
        let excludedAssets = feed.excludedAssets
        excludedAssets.push(asset.id)
        // add asset to feed.assets

        dispatch(updateFeed({ excludedAssets: excludedAssets }))
      } else {
        dispatch(updateFeed({ excludedAssets: [asset.id] }))
      }
    }
  }

  return feed?.publish === FEED_PUBLISH_OPTIONS.manually ? (
    feed?.manuallyAddedAssets?.includes(asset.id) ? (
      <ReelerTooltip text={"Unpublish asset"}>
        <EyeContainer onClick={() => exludeAsset()}>
          <FaEye size={20} />
        </EyeContainer>
      </ReelerTooltip>
    ) : (
      <ReelerTooltip text={"Publish asset"}>
        <EyeContainer onClick={() => includeAsset()}>
          <FaEyeSlash size={20} />
        </EyeContainer>
      </ReelerTooltip>
    )
  ) : !feed?.excludedAssets?.includes(asset.id) ? (
    <ReelerTooltip text={"Unpublish asset"}>
      <EyeContainer onClick={() => exludeAsset()}>
        <FaEye size={20} />
      </EyeContainer>
    </ReelerTooltip>
  ) : (
    <ReelerTooltip text={"Publish asset"}>
      <EyeContainer onClick={() => includeAsset()}>
        <FaEyeSlash size={20} />
      </EyeContainer>
    </ReelerTooltip>
  )
}

export default PublishFeedAsset
