import React, { useState, useEffect } from "react"
import { database, functions } from "../../../firebase"
import moment from "moment"
import { useSelector, useDispatch } from "react-redux"
import { selectApprovedAssets } from "../../../redux/third-party-approval/third-party-approval.selectors"
import {
  setApprovedAssets,
  uncheckAll,
} from "../../../redux/third-party-approval/third-party-approval.actions"

// Third party
import { useParams } from "react-router-dom"
import parse from "html-react-parser"
import * as S from "./ThirdPartyApproval.styled"
import { Container, Row, Col, Button, Form } from "react-bootstrap"
import ApprovalAsset from "./components/ApprovalAsset"
import Loader from "../../commons/loader/Loader"
import PublicNotFound from "../publicNotFound/PublicNotFound"
import DownloadAssets from "./components/DownloadAssets"

moment.locale()

const ThirdPartyApprovalPage = () => {
  const { approvalId } = useParams()
  const dispatch = useDispatch()
  const [approvalDocument, setApprovalDocument] = useState(null)
  const [loading, setLoading] = useState(false)
  const [approved, setApproved] = useState(false)
  const approvedAssets = useSelector(selectApprovedAssets)

  useEffect(() => {
    database.thirdPartyApprovals
      .doc(approvalId)
      .get()
      .then(snapshot => {
        if (snapshot) {
          if (snapshot.data()) {
            setApprovalDocument(snapshot.data())
            if (snapshot.data()?.status === "approved") {
              setApproved(true)
              if (snapshot.data()?.approvedAssets) {
                dispatch(setApprovedAssets(snapshot.data().approvedAssets))
              }
            } else {
              if (snapshot.data()?.assets) {
                dispatch(setApprovedAssets(snapshot.data().assets))
              }
            }
          }
        } else {
          console.log("No data available")
        }
      })
  }, [approvalId])

  const handleCheckAll = () => {
    dispatch(setApprovedAssets(approvalDocument.assets))
  }

  const handleUnheckAll = () => {
    dispatch(uncheckAll())
  }

  if (!approvalDocument)
    return (
      <S.LoadingScreen>
        <Loader />
      </S.LoadingScreen>
    )

  const handleSubmit = e => {
    setLoading(true)
    e.preventDefault()
    /**
     * Loop through the approved Assets
     * Add approval information to asset
     * Who approved, when
     * Update assets with new status
     * Update third-party-approval-doc status to approved
     */

    approveThirdPartyAssets(approvedAssets, approvalId).then(res => {
      setApproved(true)
      setLoading(false)
    })
  }

  const approveThirdPartyAssets = async (assets, approvalId) => {
    var approveThirdPartyAssets = functions.httpsCallable(
      "approveThirdPartyAssets"
    )

    try {
      const { data } = await approveThirdPartyAssets({
        assets,
        approvalId,
      })
      return data
    } catch (err) {
      console.log(err)
    }
  }

  return approvalDocument ? (
    <S.Styles>
      <Container className="pt-5 pb-5">
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col>
              <S.ImageContainer $align={approvalDocument.align}>
                <img
                  alt=""
                  style={{
                    width: `${approvalDocument.width}%`,
                    margin: `${approvalDocument.margin}px`,
                    padding: `${approvalDocument.padding}px`,
                  }}
                  src={approvalDocument.image_url}
                />
              </S.ImageContainer>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col>{parse(approvalDocument.intro)}</Col>
          </Row>
          {approvalDocument?.form_fields_values ? (
            <>
              <Row>
                <Col>
                  <hr class="solid" />
                </Col>
              </Row>
              <Row>
                <Col
                  md={{ span: 10, offset: 1 }}
                  className="d-flex flex-column"
                >
                  {approvalDocument?.form_fields_values?.map(value => (
                    <S.MotivationSection>
                      <h2>
                        {value?.label
                          ? value.label
                          : value?.database_name
                          ? value?.database_name
                          : ""}
                      </h2>
                      <p>{value.value}</p>
                    </S.MotivationSection>
                  ))}
                </Col>
              </Row>
            </>
          ) : null}

          <Row>
            <Col>
              <hr class="solid" />
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Check
                  inline
                  label="Check all"
                  type="checkbox"
                  id="check_all"
                  name="check_all"
                  disabled={approved}
                  checked
                  onClick={handleCheckAll}
                />
                <Form.Check
                  inline
                  label="Uncheck all"
                  type="checkbox"
                  id="uncheck_all"
                  name="check_all"
                  checked={false}
                  disabled={approved}
                  onClick={handleUnheckAll}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            {approvalDocument.assets.map(asset => (
              <Col sm={6} md={6} lg={3} className="mb-4">
                <ApprovalAsset asset={asset} approved={approved} />
              </Col>
            ))}
          </Row>
          <Row>
            <Col>
              <DownloadAssets assets={approvalDocument.assets} />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              {approvalDocument.terms1 ? (
                <Form.Group className="mb-3">
                  <Form.Check type="checkbox" id="terms1">
                    {approved ? (
                      <Form.Check.Input type="checkbox" required checked />
                    ) : (
                      <Form.Check.Input type="checkbox" required />
                    )}

                    <Form.Check.Label>
                      <small>{parse(approvalDocument.terms1)}</small>
                    </Form.Check.Label>
                  </Form.Check>
                </Form.Group>
              ) : null}
              {approvalDocument.terms2 ? (
                <Form.Group className="mb-3">
                  <Form.Check type="checkbox" id="terms2">
                    {approved ? (
                      <Form.Check.Input type="checkbox" required checked />
                    ) : (
                      <Form.Check.Input type="checkbox" required />
                    )}

                    <Form.Check.Label>
                      <small>{parse(approvalDocument.terms2)}</small>
                    </Form.Check.Label>
                  </Form.Check>
                </Form.Group>
              ) : null}
            </Col>
          </Row>
          <Row>
            <Col className="d-flex flex-column">
              {loading ? (
                <div style={{ width: "150px" }}>
                  <Loader />
                </div>
              ) : approved ? (
                approvalDocument?.approvedAt ? (
                  <p style={{ fontFamily: "Roboto", color: "#252525" }}>
                    <small>
                      {"[Approved: " +
                        moment(approvalDocument.approvedAt).format(
                          "YYYY-MM-DD hh:ss"
                        ) +
                        "]"}
                    </small>
                  </p>
                ) : (
                  <Button
                    type="submit"
                    style={{
                      backgroundColor: "#00314D",
                      color: "#fff",
                      border: "none",
                      width: "150px",
                    }}
                    disabled
                  >
                    Approved!
                  </Button>
                )
              ) : (
                <Button
                  type="submit"
                  style={{
                    backgroundColor: "#00314D",
                    color: "#fff",
                    border: "none",
                    width: "150px",
                  }}
                >
                  Submit approval
                </Button>
              )}
            </Col>
          </Row>
        </Form>
      </Container>
    </S.Styles>
  ) : (
    <PublicNotFound />
  )
}

export default ThirdPartyApprovalPage
