import NavbarActionTypes from "./navbar.types"

export const setIsCollapsed = payload => ({
  type: NavbarActionTypes.SET_IS_COLLAPSED,
  payload,
})
export const updateSubmenuState = payload => ({
  type: NavbarActionTypes.UPDATE_SUBMENU_STATE,
  payload,
})
