import React, { useState, useEffect } from "react"
import Select from "react-select"
import { InputGroup } from "react-bootstrap"
import firebase from "firebase/app"
import { database } from "../../../../../../../../firebase"
import { useSelector, useDispatch } from "react-redux"
import { selectAccountId } from "../../../../../../../../redux/account/account.selectors"
import { selectActiveAsset } from "../../../../../../../../redux/asset/asset.selectors"
import { updateAsset } from "../../../../../../../../redux/asset/asset.actions"
import SpinnerComponent from "../../../../../../../commons/SpinnerComponent"

const customStyles = {
  container: () => ({
    // none of react-select's styles are passed to <Control />
    width: `100%`,
  }),
}

export default function AssetAddProduct() {
  const dispatch = useDispatch()
  const [products, setProducts] = useState([])
  const accountId = useSelector(selectAccountId)
  const asset = useSelector(selectActiveAsset)

  useEffect(() => {
    if (accountId) {
      const productsRef = database.accounts
        .doc(accountId)
        .collection("products")

      productsRef.get().then(querySnapshot => {
        let productArray = []
        querySnapshot.forEach(doc => {
          productArray.push(database.formatDoc(doc))
        })
        setProducts(productArray)
      })
    }
  }, [accountId])

  const handleChange = value => {
    let productIds
    if (asset?.productIds) {
      productIds = [...asset?.productIds, value.id]
    } else {
      productIds = [value.id]
    }

    let products
    if (asset?.products) {
      products = [...asset?.products, value]
    } else {
      products = [value]
    }

    const assetRef = database.assets.doc(asset.id)
    assetRef
      .update({
        productIds,
        products,
      })
      .then(() => {
        dispatch(
          updateAsset({
            ...asset,
            productIds,
            products,
          })
        )
      })
  }

  if (!products)
    return (
      <div className="p-5">
        <SpinnerComponent />
      </div>
    )

  return (
    <div>
      <InputGroup>
        <Select
          styles={customStyles}
          theme={theme => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: "var(--reeler-o-25)",
              primary: "var(--reeler-light)",
            },
          })}
          placeholder="Search products"
          onChange={(selectedOption, action) => {
            handleChange(selectedOption.value)
          }}
          options={products
            ?.filter(p => !asset?.products?.some(value => value.id === p.id))
            .map(p => ({ value: p, label: p.name }))}
        />
      </InputGroup>
    </div>
  )
}
