import React from "react"
import Approve from "./components/Approve"
import Reject from "./components/Reject"

import * as S from "./asset-approve-styles"

export default function AssetApprove({ asset }) {
  return (
    <S.ApproveContainer>
      <Approve asset={asset} />
      <Reject asset={asset} />
    </S.ApproveContainer>
  )
}
