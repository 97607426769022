import React from "react"
import { Form, Nav, Col } from "react-bootstrap"
import { useLocation, Link } from "react-router-dom"

import { MAIN_ROUTES } from "../../../constants/routes"

const Navbar = () => {
  const location = useLocation()

  const NavLink = ({ to, children }) => (
    <Nav.Link as={Link} to={to} active={location.pathname === to}>
      {children}
    </Nav.Link>
  )

  return (
    <Col md={3}>
      <section>
        <Form.Label>API guides</Form.Label>
        <Nav className="flex-column">
          <NavLink to={MAIN_ROUTES.manageApiView.path}>Overview</NavLink>
          <NavLink to={MAIN_ROUTES.apiFeedContent.path}>Feed Content</NavLink>
          <NavLink to={MAIN_ROUTES.apiMedia.path}>Media</NavLink>
          <NavLink to={MAIN_ROUTES.apiRecentLabeledContent.path}>
            Recent Labeled Content
          </NavLink>
        </Nav>
      </section>
    </Col>
  )
}

export default Navbar
