import React from "react"
import AssetTextField from "./AssetTextField"
import { FaLink } from "react-icons/fa"
import CopyToClipboard from "../../../../../commons/CopyToClipboard"

export default function AssetShare({ assetId }) {
  const link = process.env.REACT_APP_FIREBASE_APP_URL + "/content/" + assetId

  return (
    <AssetTextField
      icon={<FaLink className="icon-color" />}
      text={
        <div className="d-flex flex-row align-items-center">
          <span className="me-1">{assetId}</span>
          <CopyToClipboard
            link={link}
            tooltipText={`copy link
          ${link}`}
          />
        </div>
      }
    />
  )
}
