import React, { useState, useRef } from "react"
import CloseButton from "react-bootstrap/CloseButton"
import Overlay from "react-bootstrap/Overlay"
import Text from "../Text"

export default function ReelerPopover({
  btn,
  title,
  placement = "bottom",
  children,
}) {
  const [show, setShow] = useState(false)
  const target = useRef(null)

  return (
    <>
      <div ref={target} onClick={() => setShow(!show)}>
        {btn}
      </div>
      <Overlay
        target={target.current}
        show={show}
        placement={placement}
        rootClose={true}
        onHide={() => setShow(false)}
      >
        {({
          placement: _placement,
          arrowProps: _arrowProps,
          show: _show,
          popper: _popper,
          hasDoneInitialMeasure: _hasDoneInitialMeasure,
          ...props
        }) => (
          <div
            {...props}
            className="reeler-dropdown"
            style={{
              position: "absolute",

              ...props.style,
            }}
          >
            <div className="d-flex justify-content-between align-items-center mb-3">
              <Text weight="bold">{title}</Text>
              <CloseButton onClick={() => setShow(false)} />
            </div>
            {children}
          </div>
        )}
      </Overlay>
    </>
  )
}
