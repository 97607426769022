import React, { useEffect, useState } from "react"

// Redux
import { useSelector } from "react-redux"
import {
  selectLoading,
  selectFeed,
  selectPreviewAssets,
  selectIsSortingAssets,
} from "../../../../../../redux/feed/feed.selectors"

// Reeler components

import SpinnerComponent from "../../../../../commons/SpinnerComponent"
import * as S from "../../../../styles"
import EmailSingelWidget from "./email-preview/EmailSingelWidget"
import EmailRowWidget from "./email-preview/EmailRowWidget"
import {
  FEED_EMAIL_LAYOUTS,
  FEED_PUBLISH_OPTIONS,
} from "../../../../../../constants"

const dummyData = []
for (let i = 0; i < 20; i++) {
  dummyData.push({
    id: `${i}`,
    url: "../../assets/images/DummyAsset_optimized.svg",
    type: "image/jpeg",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse eu varius odio. Etiam efficitur, lectus ac ornare elementum, dolor elit facilisis dui, ac mollis nulla tellus ut neque. Proin dictum sapien eu dignissim lacinia.",
  })
}

const EmailPreview = () => {
  const feed = useSelector(selectFeed)
  const assets = useSelector(selectPreviewAssets)
  const [previewAssets, setPreviewAssets] = useState([])
  const loading = useSelector(selectLoading)
  const [loadingPreviewAssets, setLoadingPreviewAssets] = useState(true)
  const isSortingAssets = useSelector(selectIsSortingAssets)

  useEffect(() => {
    if (assets) {
      setLoadingPreviewAssets(true)
      if (feed?.publish === FEED_PUBLISH_OPTIONS.manually) {
        const manuallyAddedAssets = assets?.reduce((result, asset) => {
          if (feed?.manuallyAddedAssets?.includes(asset.id)) {
            result.push(asset)
          }

          return result
        }, [])
        setPreviewAssets(manuallyAddedAssets)
        setLoadingPreviewAssets(false)
      } else {
        const withoutExckludedAssets = assets?.reduce((result, asset) => {
          if (!feed?.excludedAssets?.includes(asset.id)) {
            result.push(asset)
          }
          return result
        }, [])
        setPreviewAssets(withoutExckludedAssets)
        setLoadingPreviewAssets(false)
      }
    }
  }, [assets])

  const renderFeed = assets => {
    switch (feed?.feedLayout) {
      case FEED_EMAIL_LAYOUTS.single:
        return <EmailSingelWidget assets={assets} />
      case FEED_EMAIL_LAYOUTS.row:
        return <EmailRowWidget assets={assets} />
      default:
        return (
          <S.LoadingContainer>Please choose feed layout</S.LoadingContainer>
        )
    }
  }

  return loadingPreviewAssets || loading || isSortingAssets ? (
    <S.LoadingContainer>
      <p className="mb-0 pb-0">Loading preview...</p>
      <br />
      <SpinnerComponent size="lg" />
    </S.LoadingContainer>
  ) : (
    <div className="d-flex justify-content-center">
      {previewAssets && previewAssets.length > 0
        ? renderFeed(previewAssets)
        : renderFeed(dummyData)}
    </div>
  )
}
export default EmailPreview
