const CampaignActionTypes = {
  FETCH_CAMPAIGNS_START: "FETCH_CAMPAIGNS_START",
  FETCH_CAMPAIGNS_SUCCESS: "FETCH_CAMPAIGNS_SUCCESS",
  FETCH_CAMPAIGNS_FAILURE: "FETCH_CAMPAIGNS_FAILURE",
  DELETE_CAMPAIGN: "DELETE_CAMPAIGN",
  CREATE_CAMPAIGN: "CREATE_CAMPAIGN",
  LOAD_CAMPAIGN: "LOAD_CAMPAIGN",
  SAVE_CAMPAIGN: "SAVE_CAMPAIGN",
  CLEAR_CAMPAIGN: "CLEAR_CAMPAIGN",
  SET_SELECTED_CAMPAIGN: "SET_SELECTED_CAMPAIGN",
  CLEAR_SELECTED_CAMPAIGN: "CLEAR_SELECTED_CAMPAIGN",
  FORM_BUILDER_START: "FORM_BUILDER_START",
  FETCH_FORM_SUCCESS: "FETCH_FORM_SUCCESS",
  FETCH_FORM_FAILURE: "FETCH_FORM_FAILURE",
  UPDATE_FORM: "UPDATE_FORM",
  ADD_FORM_FIELD: "ADD_FORM_FIELD",
  ADD_NEW_FORM_FIELD: "ADD_NEW_FORM_FIELD",
  IS_SAVING_FORM_FIELD: "IS_SAVING_FORM_FIELD",
  SAVE_FORM_FIELD: "SAVE_FORM_FIELD",
  REMOVE_FORM_FIELD: "REMOVE_FORM_FIELD",
  CHANGE_FIELD_POSITION: "CHANGE_FIELD_POSITION",
  SELECT_FIELD: "SELECT_FIELD",
  CLEAR_SELECTED_FIELD: "CLEAR_SELECTED_FIELD",
  SHOW_EDIT_MODAL: "SHOW_EDIT_MODAL",
  UPDATE_FORM_DESIGN: "UPDATE_FORM_DESIGN",
  UPDATE_TAGS: "UPDATE_TAGS",
  UPDATE_CAMPAIGNS_WITH_CAMPAIGN: "UPDATE_CAMPAIGNS_WITH_CAMPAIGN",
  RESET_TO_INITIAL_STATE: "RESET_TO_INITIAL_STATE",
}

export default CampaignActionTypes
