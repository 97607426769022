import styled, { css } from "styled-components"

const gridItem = css`
  aspect-ratio: 1/1;
  width: ${({ settings: { columns, gutter }, containerWidth }) =>
    `${containerWidth / columns - gutter * columns}px`};
  margin: ${({ settings: { gutter } }) => `${gutter}px`};
`

export const GridContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const GridItem = styled.div`
  ${gridItem}
`
export const GridVideoItem = styled.div`
  ${gridItem}
  object-fit: cover;
  overflow: hidden;
  border-radius: ${({ settings: { borderRadius } }) => `${borderRadius}px`};
`

export const SingleImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  border-radius: ${({ br }) => `${br}px`};
`

// export const LoadMoreButton = styled.div`
//   display: flex;
//   justify-content: center;
// `
