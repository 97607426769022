import React from "react"
import { FaRegEnvelope, FaHashtag } from "react-icons/fa"
import { MdAlternateEmail } from "react-icons/md"
import { FiUpload, FiLogIn } from "react-icons/fi"

export const IMG_SIZES = {
  thumb: 200,
  preView: 500,
}

export const CAMPAIGN_TYPE = {
  upload: {
    type: "upload_2",
    icon: (
      <FiUpload
        style={{
          strokeWidth: "3px",
        }}
      />
    ),
  },
  igHashtag: {
    type: "hashtag",
    icon: <FaHashtag />,
  },
  igMentions: {
    type: "mentions",
    icon: <MdAlternateEmail />,
  },
  email: {
    type: "email",
    icon: <FaRegEnvelope />,
  },
  landingpage: "landingpage",
  import: {
    type: "import",
    icon: (
      <FiLogIn
        style={{
          strokeWidth: "3px",
          transform: "scale(-1,1)",
        }}
      />
    ),
  },
}
