import { takeLatest, put, all, call } from "redux-saga/effects"
import UserActionTypes from "./user.types"
import {
  signInSuccess,
  signInFailure,
  signOutSuccess,
  signOutFailure,
  signUpSuccess,
  signUpFailure,
  setSignedOut,
} from "./user.actions"
import {
  auth,
  createUserProfileDoc,
  getCurrentUser,
  createInvitedUserProfileDoc,
} from "../../firebase"
import { resetToInitialAccountState } from "../account/account.actions"
import { resetToInitialAssetState } from "../asset/asset.actions"
import { resetToInitialCampaignState } from "../campaign/campaign.actions"
import { resetToInitialFeedState } from "../feed/feed.actions"
import { resetToInitialFilterState } from "../filter/filter.actions"

export function* getSnapshotFromUserAuth(userAuth, additionalData) {
  try {
    const userRef = yield call(createUserProfileDoc, userAuth, additionalData)
    const userSnapshot = yield userRef.get()
    yield put(
      signInSuccess({
        id: userSnapshot.id,
        ...userSnapshot.data(),
      })
    )
  } catch (error) {
    yield put(signInFailure(error))
  }
}

export function* isUserAuthenticated() {
  try {
    const userAuth = yield getCurrentUser()

    if (!userAuth) {
      yield put(setSignedOut())
      return
    }

    yield getSnapshotFromUserAuth(userAuth)
  } catch (error) {
    yield put(signInFailure(error))
  }
}

export function* signInWithEmail({ payload: { email, password } }) {
  try {
    const { user } = yield auth.signInWithEmailAndPassword(email, password)
    yield getSnapshotFromUserAuth(user)
  } catch (error) {
    yield put(signInFailure(error))
  }
}

export function* signOut() {
  try {
    yield auth.signOut()
    yield put(resetToInitialAccountState())
    yield put(resetToInitialAssetState())
    yield put(resetToInitialCampaignState())
    yield put(resetToInitialFeedState())
    yield put(resetToInitialFilterState())
    yield put(signOutSuccess())
  } catch (error) {
    yield put(signOutFailure())
  }
}

export function* signUp({
  payload: { email, password, name, firstName, lastName, phone },
}) {
  try {
    const { user } = yield auth.createUserWithEmailAndPassword(email, password)
    yield put(
      signUpSuccess({
        user,
        additionalData: { name, firstName, lastName, phone },
      })
    )
  } catch (error) {
    yield put(signUpFailure(error))
  }
}

export function* signUpWithInvite({
  payload: { email, password, role, accountId, inviteId, firstName, lastName },
}) {
  try {
    const { user } = yield auth.createUserWithEmailAndPassword(email, password)

    const userRef = yield call(createInvitedUserProfileDoc, user, {
      role,
      accountId,
      inviteId,
      firstName,
      lastName,
    })
    const userSnapshot = yield userRef.get()
    console.log({
      id: userSnapshot.id,
      ...userSnapshot.data(),
    })

    yield put(
      signInSuccess({
        id: userSnapshot.id,
        ...userSnapshot.data(),
      })
    )
  } catch (error) {}
}

export function* signInAfterSignUp({ payload: { user, additionalData } }) {
  yield getSnapshotFromUserAuth(user, additionalData)
}

export function* onEmailSignInStart() {
  yield takeLatest(UserActionTypes.EMAIL_SIGN_IN_START, signInWithEmail)
}

export function* onCheckUserSession() {
  yield takeLatest(UserActionTypes.CHECK_USER_SESSION, isUserAuthenticated)
}

export function* onSignOutStart() {
  yield takeLatest(UserActionTypes.SIGN_OUT_START, signOut)
}

export function* onSignUpStart() {
  yield takeLatest(UserActionTypes.SIGN_UP_START, signUp)
}

export function* onSignUpSuccess() {
  yield takeLatest(UserActionTypes.SIGN_UP_SUCCESS, signInAfterSignUp)
}

export function* onSignUpWithInvite() {
  yield takeLatest(UserActionTypes.SIGN_UP_WITH_INVITE_START, signUpWithInvite)
}

export function* userSagas() {
  yield all([
    call(onEmailSignInStart),
    call(onCheckUserSession),
    call(onSignOutStart),
    call(onSignUpStart),
    call(onSignUpSuccess),
    call(onSignUpWithInvite),
  ])
}
