import React, { useState, useEffect } from "react"
import * as S from "./MediaObjectCarousel.styles"
import { FaChevronLeft, FaChevronRight } from "react-icons/fa"

export default function MediaObjectCarousel({ mediaObjects }) {
  const [activeIndex, setActiveIndex] = useState(0)
  const nextSlide = () => {
    setActiveIndex(prevIndex => prevIndex + 1)
  }
  const prevSlide = () => {
    setActiveIndex(prevIndex => prevIndex - 1)
  }

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  return mediaObjects ? (
    <S.Carousel activeIndex={activeIndex}>
      {mediaObjects?.map((mediaObject, index) => {
        return (
          <S.Slide show={index === activeIndex}>
            {mediaObject.media_type === "VIDEO" ? (
              <S.Video key={index} src={mediaObject.media_url} controls />
            ) : (
              <S.Image key={index} src={mediaObject.media_url} />
            )}
          </S.Slide>
        )
      })}
      <S.PrevButton onClick={prevSlide} show={activeIndex !== 0}>
        <FaChevronLeft />{" "}
      </S.PrevButton>
      <S.NextButton
        onClick={nextSlide}
        show={activeIndex !== mediaObjects.length - 1}
      >
        <FaChevronRight />
      </S.NextButton>
      <S.Dots>
        {mediaObjects?.map((_, index) => {
          return (
            <S.Dot
              key={index}
              active={index === activeIndex}
              onClick={() => setActiveIndex(index)}
            />
          )
        })}
      </S.Dots>
    </S.Carousel>
  ) : null
}
