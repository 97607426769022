import React, { useState } from "react"
import { FaCloudDownloadAlt } from "react-icons/fa"
import { Button } from "react-bootstrap"

import SpinnerComponent from "../../../../../commons/SpinnerComponent"
import ReelerTooltip from "../../../../../commons/ReelerTooltip/ReelerTooltip"
import { generateFilename } from "../../../../../../utils/GenerateFilename"
export default function AssetDownload({ asset }) {
  const [loading, setLoading] = useState(false)
  let { id, url } = asset

  // if we find a main version, then set its values, otherwise use old values in asset.
  if (asset?.assetVersions?.length > 0) {
    let mainVersion = asset?.assetVersions.filter(
      version => version.main === true
    )
    if (mainVersion.length > 0) {
      id = mainVersion[0].id
      url = mainVersion[0].url
    }
  }

  const downloadAssetContent = async url => {
    setLoading(true)
    let filename = generateFilename(asset)
    fetch(url)
      .then(res => res.blob())
      .then(blob => {
        const previewUrl = window.URL.createObjectURL(blob)
        const anchor = document.createElement("a")
        anchor.download = filename
        anchor.crossOrigin = "Anonymous"
        anchor.href = URL.createObjectURL(blob)
        anchor.click()

        window.URL.revokeObjectURL(previewUrl)
        setLoading(false)
      })
  }

  return loading ? (
    <SpinnerComponent />
  ) : (
    <ReelerTooltip text="Download asset to computer">
      <Button
        variant="secondary"
        size="sm"
        onClick={() =>
          downloadAssetContent(
            asset.cloudinary ? asset.cloudinary.eager[0].secure_url : url
          )
        }
        className="d-flex flex-row align-items-center"
      >
        <FaCloudDownloadAlt className="me-1" />
        <span>Download</span>
      </Button>
    </ReelerTooltip>
  )
}
