import { openMediaObjectModal } from "./instagramSearch.actions"
import InstagramSearchActionsTypes from "./instagramSearch.types"

export const INITIAL_STATE = {
  selectedMedia: [],
  openMediaObjectModal: false,
  activeMediaObject: null,
}

const instagramSearchReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case InstagramSearchActionsTypes.ADD_SELECTED_MEDIA:
      return {
        ...state,
        selectedMedia: [...state.selectedMedia, ...action.payload],
      }
    case InstagramSearchActionsTypes.REMOVE_SELECTED_MEDIA:
      return {
        ...state,
        selectedMedia: state.selectedMedia.filter(
          media => media.id !== action.payload.id
        ),
      }

    case InstagramSearchActionsTypes.RESET_SELECTED_MEDIA:
      return {
        ...state,
        selectedMedia: [],
      }
    case InstagramSearchActionsTypes.OPEN_MEDIA_OBJECT_MODAL:
      return {
        ...state,
        activeMediaObject: action.payload,
        openMediaObjectModal: true,
      }

    case InstagramSearchActionsTypes.CLOSE_MEDIA_OBJECT_MODAL:
      return {
        ...state,
        activeMediaObject: null,
        openMediaObjectModal: false,
      }

    default:
      return state
  }
}

export default instagramSearchReducer
