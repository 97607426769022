import React, { useState } from "react"
import { ChromePicker } from "react-color"

export default function ColorPicker({ state, dispatch, disabled }) {
  const [displayColorPicker, setDisplayColorPicker] = useState(null)
  const popover = {
    position: "absolute",
    zIndex: "2",
  }
  const cover = {
    position: "fixed",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
  }
  const handleChangeColor = color => {
    dispatch(color.hex)
  }

  const handleClick = event => {
    setDisplayColorPicker(event.currentTarget)
  }

  const handleClose = () => {
    setDisplayColorPicker(null)
  }

  return (
    <>
      <div
        onClick={disabled ? null : handleClick}
        style={{
          cursor: disabled ? "not-allowed" : "pointer",
          width: "40px",
          height: "15px",
          background: state,
          borderRadius: "5px",
          border: "1px solid lightgrey",
        }}
      ></div>
      {displayColorPicker ? (
        <div style={popover}>
          <div style={cover} onClick={handleClose} />
          <ChromePicker
            color={state}
            disableAlpha
            onChange={handleChangeColor}
            input
          />
        </div>
      ) : null}
    </>
  )
}
