import React, { useState, useEffect } from "react"
import { Row, Col, Form, Button } from "react-bootstrap"
import { useHistory, Link } from "react-router-dom"
// Redux
import { useSelector, useDispatch } from "react-redux"
import { selectCampaign } from "../../../redux/campaign/campaign.selectors"
import {
  selectAccounts,
  selectAccountId,
} from "../../../redux/account/account.selectors"
import {
  saveCampaign,
  updateCampaignsWithCampaign,
} from "../../../redux/campaign/campaign.actions"

import { database } from "../../../firebase"
import SpinnerComponent from "../../commons/SpinnerComponent"
import { FaExclamationCircle } from "react-icons/fa"
import NoSuchDocument from "../../commons/NoSuchDocument"
import SearchHashtag from "./components/SearchHashtag"
import CampaignTagsRedux from "../edit-campaign/components/CampaignTagsRedux"
import ReelerButton from "../../commons/ReelerButton"
import FadeAlert from "../../commons/FadeAlert/FadeAlert"

import { CircleContainer, Circle, VerticalLine } from "./styles"
import DeleteCampaign from "../components/DeleteCampaign"
import { CAMPAIGN_TYPE } from "../../../constants"
import ShowCampaingAssets from "../components/ShowCampaingAssets"
import Section from "../../../layouts/core/Section"
import { Header } from "../../../layouts/core/Header"
import Title from "../../../layouts/core/Title"
import Body from "../../../layouts/core/Body"
import { MAIN_ROUTES } from "../../../constants/routes"
import ExcludeCreatorHashtags from "./components/ExcludeCreatorHashtags"
import ReelerTooltip from "../../commons/ReelerTooltip/ReelerTooltip"
import CampaignToggleStatus from "../components/CampaignToggleStatus"

// FIX: Verifying empty hashtag input

export default function EditHashtagCampaignView() {
  const dispatch = useDispatch()
  const campaign = useSelector(selectCampaign)
  const history = useHistory()
  const [saving, setSaving] = useState(false)
  const [users, setUsers] = useState(null)
  const [loading, setLoading] = useState(false)
  const [msg, setMsg] = useState()
  const [showAdvancedSettings, setShowAdvancedSettings] = useState(false)

  const account = useSelector(selectAccounts)
  const accountId = useSelector(selectAccountId)
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(false)

  useEffect(() => {
    if (account) {
      if (!campaign?.instagram_business_account) {
        if (
          account?.integrations?.instagram?.accounts &&
          account?.integrations?.instagram?.accounts[0]
        ) {
          dispatch(
            saveCampaign({
              ...campaign,
              instagram_business_account:
                account?.integrations?.instagram?.accounts[0]
                  ?.ig_business_account_profile?.id,
              access_token:
                account?.integrations?.instagram?.accounts[0]
                  .long_lived_access_token,
            })
          )
        }
      }
    }
  }, [account])

  useEffect(() => {
    if (accountId) {
      database.users
        .where("accounts", "array-contains", accountId)
        .get()
        .then(querySnapshot => {
          let userArray = []
          querySnapshot.forEach(doc => {
            // doc.data() is never undefined for query doc snapshots
            userArray.push(database.formatDoc(doc))
          })
          setUsers(userArray)
        })
    }
  }, [accountId])

  const handleChangeIGBusinessAccount = id => {
    const igBusinessAccount =
      account?.integrations?.instagram?.accounts?.filter(
        account => account.ig_business_account_profile.id === id
      )
    dispatch(
      saveCampaign({
        ...campaign,
        instagram_business_account: id,
        access_token: igBusinessAccount[0].long_lived_access_token,
      })
    )
  }

  const createInstagramMediaTask = async () => {
    // Create a new instagram media task to start fetching assets
    if (
      campaign?.hashtag?.id &&
      campaign?.instagram_business_account &&
      account?.integrations?.instagram?.long_lived_user_token
    ) {
      let url_request = `https://graph.facebook.com/v16.0/${campaign.hashtag.id}/recent_media?user_id=${campaign.instagram_business_account}&fields=id%2Ccaption%2children%2Cmedia_type%2Cmedia_url%2Cpermalink%2Ctimestamp%2Ccomments_count%2Clike_count%2Cchildren%7Bmedia_url%2C%20media_type%7D&access_token=${account?.integrations?.instagram?.long_lived_user_token}`

      // Get all connected instagram business accounts ids
      const igBusinessAccountIds =
        account?.integrations?.instagram?.accounts.map(
          igAccount => igAccount?.ig_business_account_profile?.id
        )

      let IGRecentMediaTask = {
        url: url_request,
        campaignId: campaign.id,
        accountId: campaign.accountId,
        igBusinessAccountIds,
        accessToken: account?.integrations?.instagram?.long_lived_user_token,
        hashtag: campaign.hashtag,
        campaignType: CAMPAIGN_TYPE.igHashtag.type,
        status: "saved_source",
        tags: campaign.tags ? campaign.tags : null,
        exclude_hashtags: campaign.exclude_hashtags
          ? campaign.exclude_hashtags
          : [],
        createdAt: database.getCurrentTimestamp(),
      }

      return await database.instagramMediaTask.add(IGRecentMediaTask)
    }
  }

  const handleSubmit = () => {
    setMsg(null)
    setSaving(true)
    if (campaign.campaignName) {
      // Update campaign
      let { id: value, ...campaignWithoutId } = campaign
      database.campaigns
        .doc(campaign.id)
        .set(campaignWithoutId, { merge: true })
        .then(async () => {
          dispatch(updateCampaignsWithCampaign(campaign))

          // If campaign has a verified hashtag id then start a new fetch
          if (campaign?.hashtag?.id && campaign.status === "active") {
            await createInstagramMediaTask()

            setMsg({
              success: true,
              lead: "Success!",
              text: "Content source updated successfully and started to load hashtag content.",
            })
            setSaving(false)
          } else {
            setMsg({
              success: true,
              lead: "Success!",
              text: "Content source updated successfully!",
            })
            setSaving(false)
          }
        })
    } else {
      setMsg({
        success: false,
        lead: "",
        text: "Please fill in a content source name!",
      })
      setSaving(false)
    }
  }

  if (loading) {
    return <SpinnerComponent size="lg" />
  }

  return (
    <Section>
      <Header>
        <Title>
          Edit {campaign?.campaignName ? campaign.campaignName : null}
        </Title>
        <div className="d-flex align-items-center ms-auto">
          <div className="me-2">
            {campaign?.campaignType === CAMPAIGN_TYPE.igHashtag.type &&
            (campaign?.hashtag === undefined || !campaign?.access_token) ? (
              <ReelerTooltip text="Action needed! Edit the source and verify the hashtag with Instagram.">
                <div className="d-flex mx-2">
                  <FaExclamationCircle
                    style={{ color: "var(--reeler-danger)" }}
                  />
                </div>
              </ReelerTooltip>
            ) : (
              campaign && (
                <CampaignToggleStatus
                  campaignId={campaign.id}
                  status={campaign.status}
                />
              )
            )}
          </div>
          <div className="me-2">
            <ReelerButton
              text="All sources"
              styleClass="btn-secondary"
              dispatch={() => history.push(MAIN_ROUTES.campaigns.path)}
            />
          </div>
          <div className="me-2">
            <ShowCampaingAssets />
          </div>
          <ReelerButton
            text="Save"
            styleClass="btn-main"
            loading={saving}
            dispatch={() => handleSubmit()}
          />
        </div>
      </Header>
      <Body>
        {!error ? (
          <>
            {account &&
            account.integrations &&
            account.integrations.instagram &&
            account.integrations.instagram.accounts &&
            account?.integrations?.instagram?.long_lived_user_token ? (
              <>
                <Row>
                  <Col sm={3} md={2} className="d-flex flex-column">
                    <CircleContainer>
                      <Circle active={campaign?.campaignName ? true : false}>
                        1
                      </Circle>
                    </CircleContainer>
                    <VerticalLine />
                  </Col>
                  <Col sm={9} md={10}>
                    <Form.Group controlId="campaign-name">
                      <Row className="my-3">
                        <Col sm={4} className="d-flex">
                          <Form.Label className="mb-0">
                            Content source name
                          </Form.Label>
                        </Col>
                        <Col sm={8} className="d-flex">
                          <Form.Group>
                            <Form.Control
                              type="text"
                              value={
                                campaign?.campaignName
                                  ? campaign.campaignName
                                  : ""
                              }
                              onChange={e => {
                                dispatch(
                                  saveCampaign({
                                    ...campaign,
                                    campaignName: e.target.value,
                                  })
                                )
                              }}
                              required
                            />
                            <Form.Text muted>For internal use only</Form.Text>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={3} md={2} className="d-flex flex-column">
                    <CircleContainer>
                      <Circle
                        active={
                          campaign?.instagram_business_account ? true : false
                        }
                      >
                        2
                      </Circle>
                    </CircleContainer>
                    <VerticalLine />
                  </Col>
                  <Col sm={9} md={10}>
                    <Row className="my-3">
                      <Col sm={4}>
                        <Form.Label className="mb-0">
                          Choose Instagram account
                        </Form.Label>
                      </Col>
                      <Col sm={8}>
                        <Form.Group controlId="ig_business_account">
                          <Form.Control
                            as="select"
                            name="ig_business_account"
                            value={campaign?.instagram_business_account}
                            onChange={e =>
                              handleChangeIGBusinessAccount(e.target.value)
                            }
                          >
                            {account?.integrations?.instagram?.accounts.map(
                              (account, key) => (
                                <option
                                  key={key}
                                  value={account.ig_business_account_profile.id}
                                >
                                  {account.ig_business_account_profile.username}
                                </option>
                              )
                            )}
                          </Form.Control>
                          <Form.Text muted>
                            Choose which Instagram business account that this
                            hashtag source should be accociated with.
                          </Form.Text>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col sm={3} md={2} className="d-flex flex-column">
                    <CircleContainer>
                      <Circle active={campaign?.hashtag?.id ? true : false}>
                        3
                      </Circle>
                    </CircleContainer>
                    <VerticalLine />
                  </Col>
                  <Col sm={9} md={10}>
                    <Form.Group controlId="search-hashtag">
                      <Row className="my-3">
                        <Col sm={4}>
                          <Form.Label className="mb-0">
                            Verify hashtag
                          </Form.Label>
                        </Col>
                        <Col sm={8}>
                          {campaign && (
                            <SearchHashtag
                              ig_business_id={
                                campaign?.instagram_business_account
                              }
                              long_lived_token={
                                account?.integrations?.instagram
                                  ?.long_lived_user_token
                              }
                              campaignId={campaign?.id}
                            />
                          )}
                        </Col>
                      </Row>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col sm={3} md={2} className="d-flex flex-column">
                    <CircleContainer>
                      <Circle
                        active={
                          campaign?.tags &&
                          Object.keys(campaign?.tags).length > 0
                            ? true
                            : false
                        }
                      >
                        4
                      </Circle>
                    </CircleContainer>
                    <VerticalLine />
                  </Col>
                  <Col sm={9} md={10}>
                    <div className="my-3">
                      <CampaignTagsRedux campaign={campaign} />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm={3} md={2} className="d-flex flex-column">
                    <CircleContainer>
                      <Circle
                        active={
                          campaign?.exclude_tags?.length > 0 ? true : false
                        }
                      >
                        5
                      </Circle>
                    </CircleContainer>
                    <VerticalLine />
                  </Col>
                  <Col sm={9} md={10}>
                    <div className="my-3">
                      <ExcludeCreatorHashtags />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm={3} md={2} className="d-flex flex-column">
                    <CircleContainer>
                      <Circle active={campaign?.userId ? true : false}>
                        6
                      </Circle>
                    </CircleContainer>
                  </Col>
                  <Col sm={9} md={10}>
                    <Row className="my-3">
                      <Col sm={4}>
                        <Form.Label className="mb-0">
                          Set source owner
                        </Form.Label>
                      </Col>
                      <Col sm={8}>
                        <Form.Group controlId="userId">
                          <Form.Control
                            as="select"
                            name="userId"
                            value={campaign?.userId}
                            onChange={e =>
                              dispatch(
                                saveCampaign({
                                  ...campaign,
                                  userId: e.target.value,
                                })
                              )
                            }
                          >
                            {users?.map((user, key) => (
                              <option key={key} value={user.id}>
                                {user.email}
                              </option>
                            ))}
                          </Form.Control>
                          <Form.Text muted>
                            Set “Source Owner” to be able to easily find your
                            content, if you are many people or many teams
                            working in the same Reeler account.
                          </Form.Text>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <div>
                  {msg && <FadeAlert msg={msg} delay={5000} />}
                  <div className="d-flex flex-row justify-content-end py-3 mb-5">
                    <div className="me-2">
                      <DeleteCampaign />
                    </div>
                    <ReelerButton
                      text="Save"
                      styleClass="btn-main"
                      loading={saving}
                      dispatch={() => handleSubmit()}
                    />
                  </div>
                </div>
              </>
            ) : (
              <div className="d-flex flex-column justify-content-center align-items-center pt-5">
                <img
                  className="mb-3"
                  height="150px"
                  src="../../women_working_out.svg"
                  alt=""
                />
                <p className="p-3">
                  You have not connected any Instagram Business Account.
                </p>

                <Button
                  as={Link}
                  className="btn-main"
                  to={MAIN_ROUTES.settingsIntegration.path}
                >
                  Connect your account here
                </Button>
                <p className="p-3">Or delete the campaign </p>

                <DeleteCampaign />
              </div>
            )}
          </>
        ) : (
          <NoSuchDocument
            message="Could not find the campaign."
            img="../../no_content_women_dog.svg"
            alt=""
          />
        )}
      </Body>
    </Section>
  )
}
