import React, { useEffect, useRef, useState } from "react"
import { Form, Card, Row, Col } from "react-bootstrap"
import { Link, useHistory } from "react-router-dom"
import CenteredContainer from "../commons/CenteredContainer"
import { signUpStart } from "../../redux/user/user.actions"
import { useDispatch, useSelector } from "react-redux"
import {
  selectUserIsLoading,
  selectCurrentUser,
} from "../../redux/user/user.selectors"
import ReelerButton from "../commons/ReelerButton"
import StaticAlert from "../commons/StaticAlert/StaticAlert"
import { MAIN_ROUTES, PUBLIC_ROUTES } from "../../constants/routes"

const Signup = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const currentUser = useSelector(selectCurrentUser)
  const isLoading = useSelector(selectUserIsLoading)
  const accountNameRef = useRef()
  const firstNameRef = useRef()
  const lastNameRef = useRef()
  const phoneRef = useRef()
  const emailRef = useRef()
  const passwordRef = useRef()
  const passwordConfirmRef = useRef()
  const [error, setError] = useState("")

  useEffect(() => {
    if (currentUser) {
      history.push(MAIN_ROUTES.content.path)
    }
  }, [currentUser])

  function handleSubmit(e) {
    e.preventDefault()

    if (
      accountNameRef.current.value &&
      accountNameRef.current.value.length < 3
    ) {
      return setError({
        success: false,
        text: "Business name is too short. It must be at least 3 characters.",
      })
    }

    if (
      passwordRef.current.value &&
      !/^(?=(.*[0-9]))(?=(.*)).{6,}$/i.test(passwordRef.current.value)
    ) {
      return setError({
        success: false,
        text: "Your password must be at least 6 characters long and contain a number.",
      })
    }

    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError({ success: false, text: "Passwords do not match" })
    }

    if (passwordRef.current.value.length > 127) {
      return setError({ success: false, text: "Password to long" })
    }

    if (emailRef.current.value.length > 256) {
      return setError({ success: false, text: "Invalid email address" })
    }

    if (
      emailRef.current.value &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(emailRef.current.value)
    ) {
      return setError({ success: false, text: "Invalid email address" })
    }

    dispatch(
      signUpStart({
        name: accountNameRef.current.value,
        email: emailRef.current.value,
        password: passwordRef.current.value,
        firstName: firstNameRef.current.value,
        lastName: lastNameRef.current.value,
        phone: phoneRef.current.value,
        plan: "basic",
      })
    )
  }

  return (
    <CenteredContainer>
      <Link to={"/"} style={{ textDecoration: "none" }}>
        <h1 className="text-center mb-5 logo">Reeler</h1>
      </Link>
      <Card>
        <Card.Body>
          <h2 className="mb-4 text-center">Sign Up</h2>
          {error && <StaticAlert msg={error} />}
          <Form onSubmit={handleSubmit}>
            <Form.Group id="name">
              <Form.Label>Name</Form.Label>
              <Row>
                <Col>
                  <Form.Control
                    type="text"
                    ref={firstNameRef}
                    placeholder="First name"
                  />
                </Col>
                <Col>
                  <Form.Control
                    type="text"
                    ref={lastNameRef}
                    placeholder="Last name"
                  />
                </Col>
              </Row>
              <Form.Text muted>Optional</Form.Text>
            </Form.Group>
            <Form.Group id="account-name">
              <Form.Label>Phone number</Form.Label>
              <Form.Control type="tel" ref={phoneRef} />
              <Form.Text muted>Optional</Form.Text>
            </Form.Group>
            <Form.Group id="account-name">
              <Form.Label>Business name</Form.Label>
              <Form.Control type="text" ref={accountNameRef} required />
            </Form.Group>
            <Form.Group id="email">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" ref={emailRef} required />
            </Form.Group>
            <Form.Group id="password">
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" ref={passwordRef} required />
              <Form.Text muted>
                6 characters minimum and at least one number.
              </Form.Text>
            </Form.Group>
            <Form.Group id="password-confirm">
              <Form.Label>Password Confirmation</Form.Label>
              <Form.Control type="password" ref={passwordConfirmRef} required />
            </Form.Group>
            {error && <StaticAlert msg={error} />}
            <ReelerButton
              text="Sign up"
              styleClass="btn-main w-100"
              type="submit"
              loading={isLoading}
            />
          </Form>
        </Card.Body>
      </Card>
      <div className="mt-2 text-center w-100">
        Already have an account?{" "}
        <Link to={PUBLIC_ROUTES.login.path}>Log In</Link>
      </div>
    </CenteredContainer>
  )
}

export default Signup
