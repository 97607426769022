import React from "react"
import { Container, Alert } from "./styles"

const StaticAlert = ({ msg }) => {
  const { success, lead, text } = msg
  return (
    msg && (
      <Container>
        <Alert variant={success ? "var(--reeler" : "var(--reeler-danger"}>
          <strong>{lead ? lead : ""} </strong> {text}
        </Alert>
      </Container>
    )
  )
}

export default StaticAlert
