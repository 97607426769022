import React, { useState } from "react"

// 3rd-party libraries
import { Modal } from "react-bootstrap"
import { FaClone } from "react-icons/fa"
import { v4 as uuidV4 } from "uuid"

// Redux
import { useDispatch, useSelector } from "react-redux"
import { addFormField } from "../../../../redux/campaign/campaign.actions"
import { selectFormSchema } from "../../../../redux/campaign/campaign.selectors"
import ReelerButton from "../../../commons/ReelerButton"
import ReelerIconButton from "../../../commons/reelerIconButton/ReelerIconButton"

// Reeler
import ReelerTooltip from "../../../commons/ReelerTooltip/ReelerTooltip"

export default function CopyWidget({ field, previousFieldId }) {
  const dispatch = useDispatch()
  const formSchema = useSelector(selectFormSchema)
  const [showModal, setShowModal] = useState(false)

  const handleCopyField = () => {
    //Generate new unique key identifier for field
    const fieldId = uuidV4()

    let order = formSchema.order
    const index = order.indexOf(previousFieldId)
    order.splice(index + 1, 0, fieldId)
    dispatch(addFormField(fieldId, field, order))
    setShowModal(false)
  }

  return (
    <div>
      <ReelerTooltip text="Duplicate field">
        <FaClone onClick={() => setShowModal(true)} />
      </ReelerTooltip>
      <Modal centered show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header>
          <Modal.Title>Duplicate field</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to duplicate this field?</p>
        </Modal.Body>
        <Modal.Footer>
          <ReelerButton
            text="Close"
            dispatch={() => setShowModal(false)}
            styleClass="btn-secondary"
          />
          <ReelerIconButton
            text="Duplicate field"
            icon={<FaClone className="me-1" />}
            styleClass="btn-edit"
            dispatch={() => handleCopyField()}
          />
        </Modal.Footer>
      </Modal>
    </div>
  )
}
