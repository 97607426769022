import React, { useState } from "react"
import { Modal, Row, Col } from "react-bootstrap"

import { useSelector, useDispatch } from "react-redux"
import { selectAccounts } from "../../../../redux/account/account.selectors"
import { database } from "../../../../firebase"
import ReelerButton from "../../../../components/commons/ReelerButton"
import useUserFeedbackMessage from "../../../../hooks/useUserFeedbackMessage"
import { updateAccounts } from "../../../../redux/account/account.actions"

export default function DeleteEmailTemplate({
  existingTemplate,
  existingIndex,
  btn,
}) {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)

  const [isDeleting, setIsDeleting] = useState(false)
  const { setSuccessMessage, setErrorMessage } = useUserFeedbackMessage()

  const account = useSelector(selectAccounts)

  function openModal() {
    setOpen(true)
  }

  function closeModal() {
    setOpen(false)
  }

  const handleDeleteTemplate = () => {
    setIsDeleting(true)
    let emailTemplates = account?.right_request_templates?.emailTemplates
    emailTemplates.splice(existingIndex, 1)

    database.accounts
      .doc(account.id)
      .update({
        "right_request_templates.emailTemplates": emailTemplates,
      })
      .then(() => {
        //Update accounts in redux
        dispatch(
          updateAccounts({
            right_request_templates: {
              ...account?.right_request_templates,
              emailTemplates: emailTemplates,
            },
          })
        )
        setSuccessMessage("Template deleted!")
        setIsDeleting(false)
        closeModal()
      })
      .catch(err => {
        console.log(err)
        setErrorMessage("Could not delete template!")
        setIsDeleting(false)
      })
  }

  return (
    <>
      <div style={{ cursor: "pointer" }}>
        <div onClick={openModal}>{btn}</div>
      </div>

      <Modal show={open} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            Delete {existingTemplate?.emailTemplateName} email template
          </Modal.Title>
        </Modal.Header>
        <Modal.Body></Modal.Body>
        <Modal.Footer>
          <ReelerButton
            text="Close"
            styleClass="btn-secondary"
            dispatch={closeModal}
          />
          <ReelerButton
            text="Delete"
            styleClass="btn-danger"
            loading={isDeleting}
            dispatch={() => handleDeleteTemplate()}
          />
        </Modal.Footer>
      </Modal>
    </>
  )
}
