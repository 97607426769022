import React from "react"
import Loader from "../commons/loader/Loader"
import { useSelector } from "react-redux"
import { Route, Redirect } from "react-router-dom"
import { selectUserIsLoading } from "../../redux/user/user.selectors"
import { selectAccountsIsLoading } from "../../redux/account/account.selectors"
import { selectCurrentUser } from "../../redux/user/user.selectors"
import { LoadingScreen } from "./styles"
import NotFound from "../not-found/NotFound"
import { useAuth } from "../../contexts/AuthContext"
import { PUBLIC_ROUTES } from "../../constants/routes"

const PrivateRoute = ({
  component: Component,
  userRoles = ["owner", "editor"],
  children,
  ...rest
}) => {
  const { currentUser } = useAuth()
  const isLoading = useSelector(selectUserIsLoading)
  const isLoadingAccunt = useSelector(selectAccountsIsLoading)

  if (isLoadingAccunt && isLoading) {
    return (
      <LoadingScreen>
        <Loader />
      </LoadingScreen>
    )
  }

  if (!currentUser) {
    return (
      <Route
        {...rest}
        render={_ => {
          return <Redirect to={PUBLIC_ROUTES.login.path} />
        }}
      />
    )
  }

  return (
    <Route
      {...rest}
      render={_ => {
        return children
      }}
    />
  )
}

export default PrivateRoute
