import React, { useState, useEffect } from "react"
// Firebase
import { database } from "../../../../../../../firebase"
import moment from "moment"

// Redux
import { useSelector, useDispatch } from "react-redux"
import { selectActiveAsset } from "../../../../../../../redux/asset/asset.selectors"
import {
  setAssetRightsRequest,
  setShowAssetRightsRequestModal,
} from "../../../../../../../redux/asset/asset.actions"

import * as S from "./styles"
import { FaEye, FaPlus, FaRegPaperPlane } from "react-icons/fa"
import ReelerTooltip from "../../../../../../commons/ReelerTooltip/ReelerTooltip"
import Loader from "../../../../../../commons/loader/Loader"
import DeleteRightRequest from "./DeleteRightRequest"
import {
  APPROVAL_CHANNEL,
  RIGHTS_REQUEST_TYPE,
  VERIFICATION_METHOD,
} from "../../../../../../../constants"
import Icon from "../../../../../../commons/Icon"
import { RightsRequesStatusLog } from "./RightsRequesStatusLog"
import { Form } from "react-bootstrap"

export default function RightRequests() {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [rightRequests, setRightRequest] = useState(null)
  const asset = useSelector(selectActiveAsset)

  useEffect(() => {
    if (asset) {
      setLoading(true)
      // Get all right request for the asset

      database.rightRequests
        .where("accountId", "==", asset.accountId)
        .where("assetId", "==", asset.id)
        .onSnapshot(querySnapshot => {
          let formatedDocs = []
          querySnapshot.forEach(doc => {
            formatedDocs.push(database.formatDoc(doc))
          })

          setRightRequest(formatedDocs)
          setLoading(false)
        })
    }
  }, [asset])

  const handleSetRightsRequest = rightsRequest => {
    dispatch(setAssetRightsRequest(rightsRequest))
    dispatch(setShowAssetRightsRequestModal())
  }
  const handleNewCreatorRightRequest = () => {
    dispatch(
      setAssetRightsRequest({ requestType: RIGHTS_REQUEST_TYPE.creator })
    )

    dispatch(setShowAssetRightsRequestModal())
  }

  const handleOpenPreview = link => {
    window.open(link, "_blank", "noreferrer")
  }

  return (
    <S.RightRequestContainer>
      <div className="d-flex flex-row justify-content-between align-items-center">
        <Form.Label className="mb-0">
          Creator requests{" "}
          {rightRequests
            ? "(" +
              rightRequests.filter(
                request => request.requestType === RIGHTS_REQUEST_TYPE.creator
              )?.length +
              ")"
            : null}
        </Form.Label>
        {rightRequests?.filter(
          request => request.requestType === RIGHTS_REQUEST_TYPE.creator
        )?.length === 0 ? (
          <ReelerTooltip text="Create new creator rights request">
            <FaPlus
              className="icon-color icon-btn"
              onClick={() => handleNewCreatorRightRequest()}
            />
          </ReelerTooltip>
        ) : null}
      </div>

      {loading ? (
        <Loader />
      ) : (
        rightRequests
          ?.filter(
            request => request.requestType === RIGHTS_REQUEST_TYPE.creator
          )
          ?.map((rightRequest, index) => (
            <div
              key={index}
              className="d-flex flex-row justify-content-between align-items-center p-2 my-2"
              style={{
                background: "var(--reeler-grey-middle)",
                borderRadius: "5px",
              }}
            >
              <div className="d-flex flex-row">
                <div className="d-flex justify-content-center align-items-center me-2">
                  <ReelerTooltip
                    text={
                      rightRequest?.completedAt
                        ? `${rightRequest?.approvalStatus} (${moment(
                            rightRequest?.completedAt?.toDate()
                          ).format("YYYY-MM-DD hh:ss")})`
                        : rightRequest?.approvalStatus
                    }
                  >
                    <S.ApprovalStatus status={rightRequest?.approvalStatus} />
                  </ReelerTooltip>
                </div>
                <div className="me-2">
                  <ReelerTooltip text={rightRequest?.contactDetails?.note}>
                    <div className="capitalize">
                      {rightRequest?.approvalStatus}
                    </div>
                  </ReelerTooltip>
                </div>
              </div>

              <div className="d-flex justify-content-center align-items-center">
                <div className="mx-1">
                  <Icon
                    tooltip="Preview approval page"
                    onClick={() => handleOpenPreview(rightRequest?.link)}
                  >
                    <FaEye />
                  </Icon>
                </div>
                <div className="mx-1">
                  <Icon
                    tooltip="Send request"
                    onClick={() => handleSetRightsRequest(rightRequest)}
                  >
                    <FaRegPaperPlane />
                  </Icon>
                </div>
                <div className="mx-1">
                  <RightsRequesStatusLog rightsRequest={rightRequest} />
                </div>
                <div className="mx-1">
                  <DeleteRightRequest rightsRequest={rightRequest} />
                </div>
              </div>
            </div>
          ))
      )}
      <div className="d-flex flex-row justify-content-between align-items-center mt-2">
        <Form.Label className="mb-0">
          Third party requests{" "}
          {rightRequests
            ? "(" +
              rightRequests.filter(
                request =>
                  request.requestType === RIGHTS_REQUEST_TYPE.thirdParty
              )?.length +
              ")"
            : null}
        </Form.Label>
        <ReelerTooltip text={"Create new third party rights request"}>
          <FaPlus
            className="icon-color icon-btn"
            onClick={() =>
              handleSetRightsRequest({
                requestType: RIGHTS_REQUEST_TYPE.thirdParty,
                approvalChannel: APPROVAL_CHANNEL.approvalPage,
                verificationMethod: VERIFICATION_METHOD.none,
                recipients: [
                  {
                    name: "",
                    lastName: "",
                    email: "",
                  },
                ],
              })
            }
          />
        </ReelerTooltip>
      </div>

      {loading ? (
        <Loader />
      ) : (
        rightRequests
          ?.filter(
            request => request.requestType === RIGHTS_REQUEST_TYPE.thirdParty
          )
          ?.map((rightRequest, index) => (
            <div
              key={index}
              className="d-flex flex-row justify-content-between align-items-center p-2 my-2"
              style={{
                background: "var(--reeler-grey-middle)",
                borderRadius: "5px",
              }}
            >
              <div className="d-flex flex-row">
                <div className="d-flex justify-content-center align-items-center me-2">
                  <ReelerTooltip
                    text={
                      rightRequest?.completedAt
                        ? `${rightRequest?.approvalStatus} (${moment(
                            rightRequest?.completedAt?.toDate()
                          ).format("YYYY-MM-DD hh:ss")})`
                        : rightRequest?.approvalStatus
                    }
                  >
                    <S.ApprovalStatus status={rightRequest?.approvalStatus} />
                  </ReelerTooltip>
                </div>
                <div className="me-2">
                  <ReelerTooltip text={rightRequest?.contactDetails?.note}>
                    <div>
                      {rightRequest?.contactDetails?.email
                        ? rightRequest?.contactDetails?.email
                        : rightRequest?.contactDetails?.name
                        ? rightRequest?.contactDetails?.name
                        : rightRequest?.contactDetails?.note
                        ? rightRequest?.contactDetails?.note
                        : "No contact information"}
                    </div>
                  </ReelerTooltip>
                </div>
              </div>
              <div className="d-flex justify-content-center align-items-center">
                <div className="mx-1">
                  <Icon
                    tooltip="Preview approval page"
                    onClick={() => handleOpenPreview(rightRequest?.link)}
                  >
                    <FaEye />
                  </Icon>
                </div>
                <div className="mx-1">
                  <Icon
                    tooltip="Send request"
                    onClick={() => handleSetRightsRequest(rightRequest)}
                  >
                    <FaRegPaperPlane />
                  </Icon>
                </div>
                <div className="mx-1">
                  <RightsRequesStatusLog rightsRequest={rightRequest} />
                </div>
                <div className="mx-1">
                  <DeleteRightRequest rightsRequest={rightRequest} />
                </div>
              </div>
            </div>
          ))
      )}
    </S.RightRequestContainer>
  )
}
