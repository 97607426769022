import React from "react"
import Grid from "./components/grid/Grid"
import Carousel from "./components/carousel/Carousel"
import SocialWall from "./components/socialwall/SocialWall"
import Feed from "./components/feed/Feed"

export default function RenderFeed({ feed, assets }) {
  const renderFeed = () => {
    switch (feed?.feedLayout) {
      case "grid":
        return <Grid feed={feed} assets={assets} />
      case "carousel":
        return <Carousel assets={assets} feed={feed} />
      case "socialWall":
        return <SocialWall assets={assets} feed={feed} />
      case "feed":
        return <Feed assets={assets} feed={feed} />
      default:
        return <div>Please choose feed layout</div>
    }
  }
  return renderFeed()
}
