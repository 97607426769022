import React from "react"

// 3rd-party libraries
import { BiCameraOff } from "react-icons/bi"
// import { useSelector } from "react-redux"
// import { selectActiveAsset } from "../../redux/asset/asset.selectors"

export default function MediaType({ asset }) {
  const { id, type, url } = asset
  let post

  switch (type) {
    case "video/mp4":
      post = (
        <video
          width="100%"
          height="100%"
          src={url ? url : url}
          type="video/mp4"
          controls
          style={{
            background: "#000",
          }}
        ></video>
      )
      break
    case "video/quicktime":
      post = (
        <video
          width="100%"
          height="100%"
          src={asset.url}
          type="video/mp4"
          controls
          style={{
            background: "#000",
          }}
        ></video>
      )
      break
    case "image/jpeg":
    case "image/jpg":
    case "image/png":
      post = (
        <img
          width="100%"
          height="100%"
          id={id}
          src={url}
          style={{
            objectFit: "contain",
            background: "#000",

            display: "block",
          }}
          alt=""
        />
      )

      break
    case "audio/mpeg":
    case "audio/ogg":
    case "audio/wav":
    case "audio/x-m4a":
      post = (
        <div className="d-flex justify-content-center align-items-center h-100 w-100">
          <audio controls>
            <source src={url} type={type} />
            <p>Your browser doesn't support HTML5 audio. </p>
          </audio>
        </div>
      )

      break
    default:
      post = (
        <div
          style={{
            width: "100%",
            height: "100%",
            color: "#fff",
            background: "#000",
          }}
          className="d-flex flex-column justify-content-center align-items-center"
        >
          <BiCameraOff size={25} />
          <p>Unsupported Format</p>
        </div>
      )
  }

  return <>{post}</>
}
