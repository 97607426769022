import React, { useState, useEffect } from "react"

// 3rd-party
import { Modal, Form, ProgressBar } from "react-bootstrap"
import { MdError } from "react-icons/md"
import { v4 as uuidV4 } from "uuid"

// Firebase
import { database, storage } from "../../../../../../firebase"

// Redux
import { useDispatch, useSelector } from "react-redux"
import {
  selectActiveAsset,
  selectSelectedVersion,
} from "../../../../../../redux/asset/asset.selectors"

// Reeler components
import ReelerButton from "../../../../../commons/ReelerButton"
import ReelerSwitch from "../../../../../commons/Switch/ReelerSwitch"
// Styled
import { ErrMsg, FileInfo } from "../../styles"

// Utils
import { humanFileSize } from "../../../../../../utils/humanFileSize"
import { renderDate } from "../../../../../../utils/RenderDate"
import {
  clearSelectedVersion,
  updateAsset,
} from "../../../../../../redux/asset/asset.actions"
import { selectCurrentUser } from "../../../../../../redux/user/user.selectors"
import { getMediaMetadata } from "../../../../../../utils/GetMediaMetadata"

const VersionsUploadModal = ({ show, handleClose }) => {
  const dispatch = useDispatch()
  const selectedVersion = useSelector(selectSelectedVersion)
  const asset = useSelector(selectActiveAsset)
  const user = useSelector(selectCurrentUser)

  const [fileToUpload, setFileToUpload] = useState()
  const [errMsg, setErrMsg] = useState(null)
  const [settings, setSettings] = useState(null)

  const [disabled, setDisabled] = useState(false)
  const [uploadSuccess] = useState(false)
  const [uploadError, setUploadError] = useState(false)
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    if (selectedVersion) {
      setSettings(selectedVersion)
    } else {
      setSettings({
        id: uuidV4(),
        url: null,
        title: "",
        size: "",
        type: "",
        file_name: "",
        modifiedAt: Date.now(),
        createdAt: Date.now(),
        uploadedBy: "",
        main: false,
      })
    }
  }, [selectedVersion])

  const handleChange = e => {
    const file = e.target.files[0]

    const allowedFormats = [
      "mp4",
      "mov",
      "png",
      "jpg",
      "jpeg",
      "tiff",
      "tif",
      "mp3",
      "wav",
      "ogg",
      "m4a",
      "aif",
      "aiff",
    ]
    let format = file.name.split(".").pop().toLowerCase()

    if (!allowedFormats.includes(format)) {
      setErrMsg(
        "File format can only be mp4, mov, png, jpg, jpeg, mp3, wav or ogg."
      )
      return
    }
    // Check if account has set any uploading file size restrictions in bytes

    if (file.size > 1000000000) {
      setErrMsg("File size is too big. File size limit is 1GB")
      setFileToUpload(null)
      return
    }

    setFileToUpload(file)
    setErrMsg(null)
    // setMakeCurrent(false)
  }

  const handleSubmit = e => {
    setDisabled(true)
    e.preventDefault()

    let uploadedBy =
      user.firstName || user.lastName
        ? user.firstName + " " + user.lastName
        : user.email

    if (fileToUpload) {
      let accessToken = settings.id
      let format = fileToUpload.name.split(".").pop()
      let newFileName = accessToken + "." + format
      let filePath = asset.accountId + "/" + asset.campaignId + "/"

      const uploadTask = storage.ref(filePath + newFileName).put(fileToUpload)

      uploadTask.on(
        "state_changed",
        snapshot => {
          setProgress(snapshot.bytesTransferred / snapshot.totalBytes)
        },
        err => {
          setUploadError(true)
          setDisabled(true)
        },
        async () => {
          uploadTask.snapshot.ref.getDownloadURL().then(async url => {
            // Updating asset

            const metadata = await getMediaMetadata(
              fileToUpload,
              asset.media_type
            )

            updateAssetinFirestorandRedux({
              ...settings,
              url: url,
              size: fileToUpload.size,
              type: fileToUpload.type,
              file_name: newFileName,
              file_path: filePath,
              access_token: accessToken,
              original_file_name: fileToUpload.name,
              modifiedAt: Date.now(),
              metadata: metadata,
              uploadedBy: uploadedBy,
            })
          })
        }
      )
    } else {
      updateAssetinFirestorandRedux(settings)
    }
  }

  const updateAssetinFirestorandRedux = updatedVersion => {
    let updatedArr = asset?.assetVersions ? asset.assetVersions : []
    let updateAssetInfo = {}
    // If main is true, then filter out the current main and set its main to false, unless its the same version as beeing updated
    if (updatedVersion.main) {
      updateAssetInfo["metadata"] = updatedVersion.metadata
      updatedArr = updatedArr?.map(version => {
        if (version.main === true) {
          // Do not change if the updated version already is main
          if (version.id !== updatedVersion.id) {
            return { ...version, main: false }
          }
        }
        return version
      })
    }

    // update object in assetVersions
    if (selectedVersion) {
      updatedArr = updatedArr?.map(version => {
        if (version.id === updatedVersion.id) {
          return { ...version, ...updatedVersion }
        }
        return version
      })
    } else {
      // add new version to versions array
      updatedArr = [...updatedArr, updatedVersion]
    }

    updateAssetInfo["assetVersions"] = updatedArr

    var docRef = database.assets.doc(asset.id)
    docRef.update(updateAssetInfo)

    // update redux state
    dispatch(
      updateAsset({
        ...asset,
        ...updateAssetInfo,
      })
    )

    setDisabled(false)
    setErrMsg(null)
    setFileToUpload(null)
    setProgress(0)
    dispatch(clearSelectedVersion())
    handleClose()
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          {selectedVersion ? `Edit ${selectedVersion.title}` : "Upload"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group className="mb-3" controlId="title">
          <Form.Label>Title</Form.Label>
          <Form.Control
            type="text"
            id="assetVersionTitle"
            defaultValue={settings?.title}
            onChange={e => setSettings({ ...settings, title: e.target.value })}
          />
        </Form.Group>
        <Form.Group controlId="formFile" className="mb-3">
          <Form.Label>Select a file</Form.Label>
          <Form.Control type="file" onChange={handleChange} />
        </Form.Group>
        {!uploadSuccess && progress > 0 ? (
          <ProgressBar
            className="mt-3 mb-3"
            animated={!uploadError}
            variant={uploadError ? "danger" : "primary"}
            now={uploadError ? 100 : progress * 100}
            label={uploadError ? "Error" : `${Math.round(progress * 100)}%`}
          />
        ) : null}
        {errMsg ? (
          <ErrMsg>
            <MdError />
            {errMsg}
          </ErrMsg>
        ) : null}
        {fileToUpload ? (
          <FileInfo>
            <ul>
              <li>
                <span>Filename:</span> {fileToUpload.name}
              </li>
              <li>
                <span>Filetype:</span> {fileToUpload.type}
              </li>
              <li>
                <span>Size:</span> {humanFileSize(fileToUpload.size)}
              </li>
              <li>
                <span>Modified at:</span>
                {renderDate(fileToUpload.lastModifiedDate)}
              </li>
            </ul>
          </FileInfo>
        ) : (
          <FileInfo>
            <ul>
              <li>
                <span>Filetype:</span> {settings?.type}
              </li>
              <li>
                <span>Size in bytes:</span> {settings?.size}
              </li>
              <li>
                <span>Created at:</span> {renderDate(settings?.createdAt)}
              </li>
            </ul>
          </FileInfo>
        )}
        <Form.Group>
          <ReelerSwitch
            label="Set as main version?"
            name="main-version"
            action={e =>
              setSettings({
                ...settings,
                main: !settings?.main,
              })
            }
            checkedBoolean={settings?.main}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <ReelerButton
          text="Close"
          styleClass="btn-secondary"
          dispatch={handleClose}
        />

        <ReelerButton
          disabled={errMsg || disabled}
          text={selectedVersion ? "Save" : "Upload"}
          dispatch={e => handleSubmit(e)}
        />
      </Modal.Footer>
    </Modal>
  )
}

export default VersionsUploadModal
