import { createSelector } from "reselect"

const creators = state => state.creators

export const selectCreators = createSelector(
  [creators],
  creators => creators?.creators
)
export const selectCreator = createSelector(
  [creators],
  creators => creators?.creator
)
export const selectCreatorFilterParameters = createSelector(
  [creators],
  creators => creators?.filterParameters
)
export const selectCreatorTextSearch = createSelector(
  [creators],
  creators => creators?.creatorTextSearch
)
export const selectLoading = createSelector(
  [creators],
  creators => creators?.loading
)
export const selectIsSearching = createSelector(
  [creators],
  creators => creators?.isSearching
)
