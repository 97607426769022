import React from "react"
import { Row, Col } from "react-bootstrap"
import AssetAddProduct from "./components/AssetAddProduct"
import AssetProductList from "./components/AssetProductList"
import AssetAddCaption from "../AssetAddCaption"
import * as S from "../../../styles"

export default function AssetProducts() {
  return (
    <S.ModalTabContainer>
      <AssetAddProduct />
      <AssetProductList />
      <Row className="mt-3">
        <Col>
          <div
            className="p-2"
            style={{ borderRadius: "3px", background: "var(--reeler-grey)" }}
          >
            <AssetAddCaption />
          </div>
        </Col>
      </Row>
    </S.ModalTabContainer>
  )
}
