import styled from "styled-components"
export const InputUnit = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #495057;
  background-color: #e9ecef;
  padding: 0 0.75em;
  border: 1px solid #ced4da;
  border-left: none;
  border-radius: 0 0.25em 0.25em 0;
`
