import { Dropdown } from "react-bootstrap"
import styled from "styled-components"

export const Styles = styled.section`
  background-color: ${props =>
    props.formBackground ? props.formBackground : "#fff"};
  color: ${props => (props.fontColor ? props.fontColor : "#000")};

  h1 {
    font-size: 32px;
    font-weight: 800;
    line-height: 56px;
    color: ${props => (props.h1Color ? props.h1Color : "#000")};
  }

  h2 {
    font-size: 20px;
    font-weight: bold;
    color: #00314d;
    color: ${props => (props.h2Color ? props.h2Color : "#000")};
  }

  p {
    font-size: 12px;
    color: ${props => (props.pColor ? props.pColor : "#000")};
    span {
      font-style: italic;
    }
  }
  /*
  label {
    font-size: 12px;
  }

  li {
    font-size: 12px;
  }

  small {
    color: ${props => (props.fontColor ? props.fontColor : "#000")};
  }

  .text {
    padding: 10px;
  } */
  /*
  .form-control::placeholder {
    color: #a7a7a7;
  }
  Most modern browsers support this now. */
`

export const FormBuilderContainer = styled.div`
  padding: 0;
  min-height: 100vh;
  margin: 0 auto;
`
export const FormBuilderHeader = styled.div`
  margin-bottom: 0.5rem;
`
export const FormBuilderFooter = styled.div`
  display: flex;
  margin-bottom: 0.5rem;
  width: 70%;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  background-color: white;
  color: ${props => (props.fontColor ? props.fontColor : "#000")};
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  padding: 1rem;
  margin: 0 auto 0.75rem auto;
  max-width: ${props => (props.mobileView ? "375px" : null)};
`

export const FormHeaderMenu = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const FormContainer = styled.div`
  background-color: ${props =>
    props.formBackground ? props.formBackground : "#fff"};
  color: ${props => (props.fontColor ? props.fontColor : "#000")};

  padding: 1rem;
  margin: 0 auto 0.75rem auto;
  max-width: ${props => (props.mobileView ? "375px" : null)};
`

export const FormFieldContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  padding: 0.25rem;
  border: 1px solid transparent;
  width: 100%;
  ${({ $disableHover }) =>
    $disableHover
      ? null
      : `&:hover {
    border-radius: 0.25rem;
    border: 1px solid var(--reeler-grey-dark);
    
  }`}
  ${({ selected }) =>
    selected
      ? `border-radius: 0.25rem;
    border: 1px solid var(--reeler-grey-dark);
    background-color: var(--reeler-light-grey);`
      : null}
`

export const DropIndicator = styled.div`
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: var(--reeler);
  ${({ edge }) => (edge === "top" ? `top: 0;` : `bottom: 0;`)}
`

export const ChildrenContainer = styled.div`
  width: 100%;
`
export const FormFieldDragNDrop = styled.div`
  color: var(--reeler-grey-darker);
  width: 1.5rem;
`

export const FormFieldSectionContainer = styled.div`
  background: var(--reeler-grey);
  padding: 10px;
  margin-bottom: 1rem;
  border-radius: 5px;
`

export const FormFieldControlsContainer = styled.div`
  display: ${({ $active }) => (!$active ? "none" : "flex")};
  flex-direction: row;
  position: absolute;
  top: 0;
  right: 0;
  margin-top: -15px;
  border-radius: 0.25rem;
  border: 1px solid rgba(0, 0, 0, 0.125);
  padding: 0.25rem;
  background: #fff;
`

export const FormFieldControl = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--reeler-grey-darker);
  width: 30px;
  height: 30px;
  margin: 2px;
  &:hover {
    cursor: pointer;
    border-radius: 50%;
    background-color: var(--reeler-grey);
    color: var(--reeler-icon);
  }
`

// pop-up modal
export const ModalBodyContainer = styled.div`
  position: absolute !important;
  z-index: 10000 !important;
  left: 28%;
  background-color: #fff;
  @media screen and (max-width: 575px) {
    left: 23%;
  }
`

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 10px;
  border-radius: 5px;
  @media screen and (max-width: 575px) {
    padding: 7px;
  }
`
export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: center;
  margin-left: 6px;
  padding-right: 8px;
  align-items: center;
  @media screen and (max-width: 575px) {
    padding-bottom: 4px;
    padding-right: 5px;
  }
`
export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`
export const ModalButton = styled.button`
  border: none;
  width: 50px;
  padding: 2px 3px;
  border-radius: 4px;
  background-color: var(--reeler-edit);
  color: #fff;
  margin-top: 15px;
  align-self: flex-end;
  margin-right: 1rem;
  outline: none;

  &:hover {
    background-color: var(--reeler-edit-hover);
  }
  &:active {
    outline: none;
  }

  @media screen and (max-width: 575px) {
    margin-top: 8px;
  }
`
export const CloseModal = styled.span`
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  font-weight: bold;
  margin-bottom: 10px;
`

export const OptionsRow = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  /* margin-left: -24px; */
  margin-top: 5px;
  margin-bottom: 5px;
  border: ${({ $selected }) =>
    $selected ? "1px solid rgba(0, 0, 0, 0.125)" : "none"};
  border-radius: 0.25rem;
  position: relative;
`

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  /* margin-left: -24px; */
  margin-top: 5px;
  margin-bottom: 5px;
  border: ${({ $selected }) =>
    $selected ? "1px solid rgba(0, 0, 0, 0.125)" : "none"};
  border-radius: 0.25rem;
`
export const LabelRow = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: row;
`

export const TagsRow = styled.div`
  display: flex;
  padding: 5px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
`

export const RowIconItem = styled.div`
  height: 24px;
  width: 24px;
  color: var(--reeler-icon);
  display: flex;
  justify-content: start;
  align-items: center;
`
export const RowInputItem = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
`

export const InputOption = styled.input`
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &:hover {
    border-bottom: 1px solid var(--reeler-input);
  }
  :focus {
    outline: none;
    border-bottom: 1px solid var(--reeler);
  }
`
export const AddOption = styled.span`
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &:hover {
    cursor: pointer;
  }
`

export const AddFieldContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 0.25rem;
  margin: 1rem 0;
  /* background-color: cyan; */
  border: 2px dashed var(--reeler-grey);
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  &:hover {
    border: 2px dashed var(--reeler-grey-dark);
  }
`
export const FullWidthCenter = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

export const AddFieldControl = styled.div`
  display: flex;
  align-items: center;
  /* background-color: red; */
  width: 100%;
  justify-content: center;
  width: 30px;
  height: 30px;
  color: var(--reeler-grey-darker);
  &:hover {
    color: var(--reeler-icon);
  }
`
export const StyledItem = styled(Dropdown.Item)`
  display: flex;
  align-items: center;
  color: var(--reeler-icon);
  padding: 0.25rem 1rem;
  &:active {
    background-color: var(--reeler);
  }
`

export const StyledItemIcon = styled.span`
  margin-right: 1rem;
`

export const ImageContainer = styled.div`
  display: flex;
  justify-content: ${({ $align }) => ($align ? $align : "center")};
  width: 100%;
`

export const RotateIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(${({ open }) => (open ? "180deg" : "0deg")});
  transition: all ease-in-out 0.2s;
`
export const AdvancedContainer = styled.div`
  border: 1px solid var(--reeler-grey-dark);
  background-color: var(--reeler-grey);
  padding: 0.5em !important;
  border-radius: 5px;
  margin-bottom: 0.75rem;
`

export const DeviceSwitchContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0.5rem;
`

export const CheckboxGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 0.25rem;
`
export const EditorContainer = styled.div`
  background-color: rgb(255, 255, 255);
  padding: 10px;
  border-radius: 3px;
`

export const ColoredLabel = styled.label`
  font-weight: 500;
  color: ${({ $color }) => ($color ? $color : "var(--reeler-title)")};
  margin-right: 4px;
`

export const ColoredFormText = styled.small`
  margin-top: 0.25rem;
  color: ${({ $color }) =>
    $color ? `${$color} !important` : "var(--reeler-grey-darker) !important"};
`

export const RequiredMark = styled.span`
  color: var(--reeler-danger);
  font-size: ${({ $size }) => ($size ? `${$size}rem` : "none")};
  font-weight: bolder;
`

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--reeler-icon);
  width: ${({ $size }) => ($size ? $size : "30px")};
  height: ${({ $size }) => ($size ? $size : "30px")};
  margin: 2px;
  &:hover {
    cursor: pointer;
    border-radius: 50%;
    background-color: var(--reeler-grey);
  }

  position: relative;
`

export const Notification = styled.div`
  width: 12px;
  height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  font-weight: bold;
  color: #fff;
  background-color: var(--reeler);
  border-radius: 50%;
  font-size: 8px;
  position: absolute;
  top: -1px;
  right: -1px;
`

export const AddNewFormFieldContainer = styled.div`
  display: grid;
  grid-gap: 0.3rem;
  grid-template-columns: repeat(auto-fill, minmax(min(5rem, 100%), 1fr));
  padding: 0 1rem;
  margin-bottom: 1rem;
`

export const NewFormFieldItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0.4rem;
  border: 1px solid var(--reeler-grey-dark);
  background: ${({ selected }) => (selected ? "var(--reeler-grey)" : null)};
  aspect-ratio: 1/1;

  &:hover {
    border: 1px solid var(--reeler);
  }
`
export const NewFormFieldItemText = styled.small`
  text-align: center;
`

export const FormFieldGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  margin-bottom: 1rem;
`
export const FormFieldItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.2rem;
  margin: 0.1rem 0;
  cursor: ${({ selected }) => (selected ? "no-drop" : "grab")};
  border: 1px solid var(--reeler-grey-dark);
  background: ${({ selected }) => (selected ? "var(--reeler-grey)" : null)};

  &:hover {
    border: 1px solid var(--reeler);
  }
`
export const FormFieldItemText = styled.small``

export const FormFieldTitle = styled.small`
  font-weight: 600;
  padding-left: 1rem;
  font-size: 1rem;
`
