import React, { useState, useEffect } from "react"

import ApprovalMedia from "./ApprovalMedia"
import * as S from "../ThirdPartyApproval.styled"
import { Modal, Button } from "react-bootstrap"
import DownloadFile from "./DownloadFile"
import { useSelector, useDispatch } from "react-redux"
import {
  addAsset,
  removeAsset,
} from "../../../../redux/third-party-approval/third-party-approval.actions"
import { selectApprovedAssets } from "../../../../redux/third-party-approval/third-party-approval.selectors"

export default function ApprovalAsset({ asset, approved }) {
  const [show, setShow] = useState(false)
  const [checked, setChecked] = useState(true)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const dispatch = useDispatch()
  const approvedAssets = useSelector(selectApprovedAssets)

  useEffect(() => {
    if (approvedAssets) {
      let assetIsApproved = approvedAssets.filter(a => a.id === asset.id)
      if (assetIsApproved.length > 0) {
        setChecked(true)
      } else {
        setChecked(false)
      }
    }
  }, [approvedAssets])

  const handleCheck = () => {
    if (checked) {
      dispatch(removeAsset(asset))
    } else {
      dispatch(addAsset(asset))
    }
  }

  return (
    <>
      <S.AssetContainer>
        <S.TopLeft>
          <div class="form-check" style={{ marginLeft: "0.25rem" }}>
            <input
              class="form-check-input"
              type="checkbox"
              checked={checked}
              onClick={handleCheck}
              disabled={approved}
            />
          </div>
        </S.TopLeft>

        <S.MediaContainer onClick={() => handleShow()}>
          <ApprovalMedia asset={asset} />
        </S.MediaContainer>
      </S.AssetContainer>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <ApprovalMedia asset={asset} />
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <DownloadFile asset={asset} />
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
