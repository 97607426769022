import { createSelector } from "reselect"

const selectUser = state => state.user

export const selectCurrentUser = createSelector(
  [selectUser],
  user => user.currentUser
)

export const selectUserIsLoading = createSelector(
  [selectUser],
  user => user.isLoading
)

export const selectUserId = createSelector(
  [selectCurrentUser],
  user => user?.id
)

export const selectUserMediaFilter = createSelector(
  [selectCurrentUser],
  user => user.mediaFilter
)

export const selectUserError = createSelector([selectUser], user => user.error)
