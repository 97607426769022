import styled from "styled-components"

export const Header = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 999;
  height: 60px;
  padding: 16px 1.25rem;
  border-bottom: 1px solid var(--reeler-grey-dark);

  background: white;
`

export const HeaderGroup = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
`

export const HeaderGroupLeft = styled.div`
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  flex-basis: 0%;
  -webkit-box-flex: 1;
  flex-grow: 1;
  max-width: 100%;
  white-space: nowrap;
`
export const HeaderGroupCenter = styled.div`
  display: flex;
  flex-shrink: 1;
  flex-grow: 1;
  justify-content: center;
  flex-basis: auto;
`
export const HeaderGroupRight = styled.div`
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  flex-basis: 0%;
  -webkit-box-flex: 1;
  flex-grow: 1;
  max-width: 100%;
  white-space: nowrap;
  justify-content: flex-end;
`
