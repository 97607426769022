import React, { useRef, useState } from "react"
import { Form } from "react-bootstrap"
import { useSelector } from "react-redux"
import { selectCurrentUser } from "../../redux/user/user.selectors"
import { updateEmail, updatePassword, updateEmailSuccess } from "../../firebase"
import "../App.css"
import ReelerButton from "../commons/ReelerButton"
import StaticAlert from "../commons/StaticAlert/StaticAlert"
import Section from "../../layouts/core/Section"
import { Header } from "../../layouts/core/Header"
import Title from "../../layouts/core/Title"
import Body from "../../layouts/core/Body"
import BackButton from "../commons/BackButton"

export default function ProfileSecurity() {
  const currentUser = useSelector(selectCurrentUser)
  const emailRef = useRef()
  const passwordRef = useRef()
  const passwordConfirmRef = useRef()
  const [msg, setMsg] = useState("")
  const [loading, setLoading] = useState(false)

  function handleSubmit(e) {
    e.preventDefault()
    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setMsg({
        success: false,
        text: "Passwords do not match",
      })
    }

    const promises = []
    setLoading(true)
    setMsg("")

    if (emailRef.current.value !== currentUser.email) {
      promises.push(updateEmail(emailRef.current.value))
    }
    if (passwordRef.current.value) {
      promises.push(updatePassword(passwordRef.current.value))
    }

    Promise.all(promises)
      .then(() => {
        if (currentUser.email !== emailRef.current.value) {
          updateEmailSuccess(currentUser.id, emailRef.current.value)
        }
        setMsg({ success: true, text: "Updated successfully!" })
      })
      .catch(err => {
        setMsg({ success: false, text: err?.message })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <Section>
      <Header>
        <Title>Security</Title>
        <div className="ms-auto">
          <BackButton />
        </div>
      </Header>

      <Body>
        <p>These preferences will only be applied to you.</p>

        {msg && <StaticAlert msg={msg} />}
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="email">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              ref={emailRef}
              required
              defaultValue={currentUser.email}
            />
          </Form.Group>
          <Form.Group controlId="password">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              ref={passwordRef}
              placeholder="Leave blank to keep the same"
            />
          </Form.Group>
          <Form.Group controlId="password-confirm">
            <Form.Label>Password Confirmation</Form.Label>
            <Form.Control
              type="password"
              ref={passwordConfirmRef}
              placeholder="Leave blank to keep the same"
            />
          </Form.Group>
          <div className="d-flex flex-row justify-content-end">
            <ReelerButton
              text="Update"
              styleClass="btn-main"
              type="submit"
              loading={loading}
              spinnerClass="ms-1"
            />
          </div>
        </Form>
      </Body>
    </Section>
  )
}
