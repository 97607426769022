import React, { useState, useEffect } from "react"

// Redux
import { useSelector, useDispatch } from "react-redux"
import {
  selectedField,
  selectFormSchema,
  selectShowEditModal,
} from "../../../../redux/campaign/campaign.selectors"
import { setShowEditModal } from "../../../../redux/campaign/campaign.actions"

// 3rd-party libraries
import { Modal, ModalBody } from "react-bootstrap"

import InputField from "../components/InputField"
import TextField from "./TextField"
import MultipleChoiceField from "./MultipleChoiceField"
import ImageField from "./ImageField"
import FileField from "./FileField"
import TermsField from "./TermsField"
import ThirdPartyApprovalField from "./ThirdPartyApprovalField"
import SubmitButtonField from "./SubmitButtonField"
import DividerField from "./DividerField"
import ReelerButton from "../../../commons/ReelerButton"

export default function EditWidget() {
  const dispatch = useDispatch()
  const [widgetType, setWidgetType] = useState()
  const fieldId = useSelector(selectedField)
  const formSchema = useSelector(selectFormSchema)
  const showEditModal = useSelector(selectShowEditModal)

  useEffect(() => {
    if (fieldId && formSchema) {
      if (formSchema.properties[fieldId]?.widget) {
        setWidgetType(formSchema.properties[fieldId].widget)
      }
    }
  }, [fieldId])

  const closeEditModal = () => {
    dispatch(setShowEditModal(false))
  }

  const fieldTypes = {
    input: "Input",
    text: "Text",
    multiple_choice: "Multiple choice",
    image: "Image",
    file: "Upload file button",
    third_party_approval: "Thid party approval",
    submitButton: "Submit button",
    divider: "Spacing or divider",
    terms: "Terms",
  }

  return (
    <Modal size="lg" show={showEditModal} onHide={closeEditModal}>
      <Modal.Header closeButton>
        <Modal.Title>Edit field ({fieldTypes[widgetType]})</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {(() => {
          switch (widgetType) {
            case "input":
              return <InputField />
            case "text":
              return <TextField />
            case "multiple_choice":
              return <MultipleChoiceField />
            case "image":
              return <ImageField />
            case "file":
              return <FileField />
            case "third_party_approval":
              return <ThirdPartyApprovalField />
            case "submitButton":
              return <SubmitButtonField />
            case "divider":
              return <DividerField />
            case "terms":
              return <TermsField />

            default:
              return null
          }
        })()}
      </Modal.Body>
    </Modal>
  )
}
