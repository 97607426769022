export const loadVideoMetadata = path =>
  new Promise(resolve => {
    const v = document.createElement("video")
    v.onloadedmetadata = () =>
      resolve({ width: v.videoWidth, height: v.videoHeight })

    v.onerror = () => resolve({ error: "error" })

    v.src = path
  })
