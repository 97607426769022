import React, { useEffect, useState, useRef } from "react"
import { Modal, Form, Row, Col } from "react-bootstrap"

import { useSelector, useDispatch } from "react-redux"
import {
  selectAccountId,
  selectAccounts,
} from "../../../../redux/account/account.selectors"
import withoutProperty from "../../../../utils/withoutProperty"
import { storage, database } from "../../../../firebase"
import ReelerButton from "../../../../components/commons/ReelerButton"
import { useEditor } from "../../../../hooks/useEditor"
import useUserFeedbackMessage from "../../../../hooks/useUserFeedbackMessage"
import { v4 as uuidv4 } from "uuid"
import { updateAccounts } from "../../../../redux/account/account.actions"

export default function EditMessageTemplate({
  existingTemplate,
  existingIndex,
  btn,
}) {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [template, setTemplate] = useState({})
  const [index, setIndex] = useState(null)
  const [saving, setSaving] = useState(false)
  const { setSuccessMessage, setErrorMessage } = useUserFeedbackMessage()

  const account = useSelector(selectAccounts)

  const newTemplate = {
    templateName: "",
    textMessage: "",
    createdAt: new Date(),
  }

  function openModal() {
    setOpen(true)
  }

  function closeModal() {
    setOpen(false)
  }

  useEffect(() => {
    if (existingTemplate) {
      setIndex(existingIndex)
      setTemplate(existingTemplate)
    } else {
      setTemplate(newTemplate)
    }
  }, [existingTemplate])

  const handleUpdateTemplate = (property, value) => {
    setTemplate({ ...template, [property]: value })
  }

  const handleSubmit = () => {
    setSaving(true)

    if (existingTemplate) {
      // Update existing template
      let updatedTextMessageArr =
        account?.right_request_templates.textMessages.map((message, i) => {
          if (i === index) {
            return {
              ...message,
              ...template,
            }
          }
          return message
        })
      database.accounts
        .doc(account.id)
        .update({
          "right_request_templates.textMessages": updatedTextMessageArr,
        })
        .then(() => {
          dispatch(
            updateAccounts({
              right_request_templates: {
                ...account.right_request_templates,
                textMessages: updatedTextMessageArr,
              },
            })
          )

          setSaving(false)
          setSuccessMessage("Saved!")
        })
    } else {
      // New template
      let textMessages
      if (account?.right_request_templates?.textMessages) {
        textMessages = [
          ...account?.right_request_templates?.textMessages,
          template,
        ]
      } else {
        textMessages = [template]
      }
      database.accounts
        .doc(account.id)
        .update({
          "right_request_templates.textMessages": textMessages,
        })
        .then(() => {
          dispatch(
            updateAccounts({
              right_request_templates: {
                ...account.right_request_templates,
                textMessages: textMessages,
              },
            })
          )

          setSuccessMessage("Saved!")

          setSaving(false)
        })
    }
  }

  return (
    <>
      <div style={{ cursor: "pointer" }}>
        <div onClick={openModal}>{btn}</div>
      </div>

      <Modal show={open} onHide={closeModal} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Instagram message template</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Template name</Form.Label>
                <Form.Control
                  type="text"
                  value={template?.templateName}
                  onChange={e =>
                    handleUpdateTemplate("templateName", e.target.value)
                  }
                />
                <Form.Text className="text-muted">
                  Give this template an internal name.
                </Form.Text>
              </Form.Group>
              <Form.Group>
                <Form.Label>Text message template</Form.Label>
                <Form.Control
                  as="textarea"
                  value={template?.textMessage}
                  placeholder=""
                  onChange={e =>
                    handleUpdateTemplate("textMessage", e.target.value)
                  }
                  rows={3}
                  style={{ fontSize: "12px" }}
                />
                <Form.Text muted>
                  E.g., ‘Hello, we love your photo. Would you approve of us
                  featuring it on our company’s marketing channels?’ The link to
                  the approval page will automatically be added at the end of
                  your message.
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <ReelerButton
            text="Close"
            styleClass="btn-secondary"
            dispatch={closeModal}
          />
          <ReelerButton
            text="Save"
            styleClass="btn-main"
            loading={saving}
            dispatch={() => handleSubmit()}
          />
        </Modal.Footer>
      </Modal>
    </>
  )
}
