import FilterActionTypes from "./filter.types"

export const setActiveFilter = payload => ({
  type: FilterActionTypes.SET_ACTIVE_FILTER,
  payload,
})
export const setFilterParameters = payload => ({
  type: FilterActionTypes.SET_FILTER_PARAMETERS,
  payload,
})
export const clearFilter = () => ({
  type: FilterActionTypes.CLEAR_FILTER,
})

export const clearFilterParameters = () => ({
  type: FilterActionTypes.CLEAR_FILTER_PARAMETERS,
})
export const setFilterLoading = payload => ({
  type: FilterActionTypes.SET_LOADING,
  payload,
})

export const setFiltredAssets = payload => ({
  type: FilterActionTypes.SET_FILTRED_ASSETS,
  payload,
})

export const setSubFiltredAssets = payload => ({
  type: FilterActionTypes.SET_SUB_FILTRED_ASSETS,
  payload,
})

export const setMediaSortBy = payload => ({
  type: FilterActionTypes.SET_MEDIA_SORT_BY,
  payload,
})

export const setMediaFilterIsOpen = () => ({
  type: FilterActionTypes.SET_MEDIA_FILTER_OPEN,
})

export const setMediaFilterIsClose = () => ({
  type: FilterActionTypes.SET_MEDIA_FILTER_CLOSE,
})

export const forceClearFilter = payload => ({
  type: FilterActionTypes.FORCE_CLEAR_FILTER,
  payload,
})

export const deleteTagStart = payload => ({
  type: FilterActionTypes.DELETE_TAG_START,
  payload,
})

export const deleteTagSuccess = payload => ({
  type: FilterActionTypes.DELETE_TAG_SUCCESS,
  payload,
})

export const deleteTagFailure = error => ({
  type: FilterActionTypes.DELETE_TAG_FAILURE,
  payload: error,
})

export const createTagStart = payload => ({
  type: FilterActionTypes.CREATE_TAG_START,
  payload,
})

export const createTagSuccess = payload => ({
  type: FilterActionTypes.CREATE_TAG_SUCCESS,
  payload,
})

export const createTagFailure = error => ({
  type: FilterActionTypes.CREATE_TAG_FAILURE,
  payload: error,
})

export const clearAllTags = () => ({
  type: FilterActionTypes.CLEAR_ALL_TAGS,
})

export const createAccountFilterStart = payload => ({
  type: FilterActionTypes.CREATE_ACCOUNT_FILTER_START,
  payload,
})

export const createAccountFilterSuccess = payload => ({
  type: FilterActionTypes.CREATE_ACCOUNT_FILTER_SUCCESS,
  payload,
})

export const createAccountFilterFailure = error => ({
  type: FilterActionTypes.CREATE_ACCOUNT_FILTER_FAILURE,
  payload: error,
})

export const createUserFilterStart = payload => ({
  type: FilterActionTypes.CREATE_USER_FILTER_START,
  payload,
})

export const createUserFilterSuccess = payload => ({
  type: FilterActionTypes.CREATE_USER_FILTER_SUCCESS,
  payload,
})

export const createUserFilterFailure = error => ({
  type: FilterActionTypes.CREATE_USER_FILTER_FAILURE,
  payload: error,
})

export const deleteAccountFilterStart = payload => ({
  type: FilterActionTypes.DELETE_ACCOUNT_FILTER_START,
  payload,
})

export const deleteAccountFilterSuccess = payload => ({
  type: FilterActionTypes.DELETE_ACCOUNT_FILTER_SUCCESS,
  payload,
})
export const deleteAccountFilterFailure = payload => ({
  type: FilterActionTypes.DELETE_ACCOUNT_FILTER_FAILURE,
  payload,
})
export const updateNumberOfFiltredAssets = payload => ({
  type: FilterActionTypes.UPDATE_NUMBER_OF_FILTRED_ASSETS,
  payload,
})

export const setAssetView = payload => ({
  type: FilterActionTypes.SET_ASSET_VIEW,
  payload,
})

export const setActiveAccountFilter = payload => ({
  type: FilterActionTypes.SET_ACTIVE_ACCOUNT_FILTER,
  payload,
})
export const clearActiveAccountFilter = () => ({
  type: FilterActionTypes.CLEAR_ACTIVE_ACCOUNT_FILTER,
})
export const toggleShowFilter = () => ({
  type: FilterActionTypes.SHOW_FILTER,
})
export const setShowFilter = payload => ({
  type: FilterActionTypes.SET_SHOW_FILTER,
  payload,
})
export const setShowSaveFilter = payload => ({
  type: FilterActionTypes.SET_SHOW_SAVE_FILTER,
  payload,
})
export const setShowViewers = payload => ({
  type: FilterActionTypes.SET_SHOW_VIEWERS,
  payload,
})
export const setViewerFilterParameters = payload => ({
  type: FilterActionTypes.SET_VIEWER_FILTER_PARAMETERS,
  payload,
})
export const clearViewerFilterParameters = payload => ({
  type: FilterActionTypes.CLEAR_VIEWER_FILTER_PARAMETERS,
  payload,
})
export const resetToInitialFilterState = () => ({
  type: FilterActionTypes.RESET_TO_INITIAL_STATE,
})
