import React, { useRef, useEffect, useState } from "react"
import axios from "axios"
import { useAuth } from "../../../../../../contexts/AuthContext"
import { storage } from "../../../../../../firebase"
import { Modal, Row, Col, Form, Alert, Button } from "react-bootstrap"
import ReelerButton from "../../../../../commons/ReelerButton"
import { FaFacebookSquare } from "react-icons/fa"
import ReelerTooltip from "../../../../../commons/ReelerTooltip/ReelerTooltip"
import MediaType from "../../../../../commons/MediaType"
import ReelerIconButton from "../../../../../commons/reelerIconButton/ReelerIconButton"
import { IMG_SIZES, MEDIA_TYPE } from "../../../../../../constants"
import { useSelector } from "react-redux"
import { selectAccounts } from "../../../../../../redux/account/account.selectors"
import { Link } from "react-router-dom"
import { MAIN_ROUTES } from "../../../../../../constants/routes"

// https://developers.facebook.com/docs/graph-api/reference/page/photos/#Creating

export default function AssetPublishPostToFacebookPage({ asset }) {
  const { media_type } = asset
  const [open, setOpen] = useState(false)
  const account = useSelector(selectAccounts)
  const [page, setPage] = useState(null)
  const [url, setUrl] = useState(null)

  const [openPublish, setOpenPublish] = useState(false)
  const [publishing, setPublishing] = useState(false)
  const [msg, setMsg] = useState()
  const [message, setMessage] = useState()

  const modalRef = useRef()

  useEffect(() => {
    if (account && !page) {
      setPage(account?.integrations?.instagram?.accounts[0])
    }
  }, [account])

  useEffect(() => {
    // if we find a main version, then set its values, otherwise use old values in asset.
    if (asset) {
      if (asset?.assetVersions?.length > 0) {
        let mainVersion = asset?.assetVersions.filter(
          version => version.main === true
        )

        setUrl(mainVersion[0].url)
      } else {
        setUrl(asset.url)
      }
    }
  }, [asset])

  function openModal() {
    setOpen(true)
  }

  function closeModal() {
    setOpen(false)
  }

  function openModalPublish() {
    setOpenPublish(true)
  }

  function closeModalPublish() {
    setOpenPublish(false)
  }

  const handleChangePage = page_id => {
    const filtredPage = account?.integrations?.instagram?.accounts?.filter(
      account => account.page_id === page_id
    )
    console.log(page_id)
    console.log(filtredPage)
    setPage(filtredPage[0])
  }

  /**
   * https://developers.facebook.com/docs/pages/publishing/
   *
   */

  const updatePost = async (pagePostId, pageAccessToken, message) => {
    // Need to check if the content is IMAGE or VIDEO, different API calls

    let apiUrl = `https://graph.facebook.com/${pagePostId}`

    if (message) {
      let encodedCMessage = encodeURIComponent(message)
      apiUrl += `?message=${encodedCMessage}`
    }

    apiUrl += `&access_token=${pageAccessToken}`

    try {
      const { data } = await axios({
        url: apiUrl,
        method: "post",
      })
      console.log(data)
      return data
    } catch (err) {}
  }

  /**
   * https://developers.facebook.com/docs/pages/publishing/
   * U
   */

  const uploadContentToFacebook = async (
    pageId,
    accessToken,
    mediaType,
    mediaUrl
  ) => {
    // Need to check if the content is IMAGE or VIDEO, different API calls

    let apiUrl

    let downloadableURL = await storage.refFromURL(mediaUrl).getDownloadURL()
    let downloadableURI = encodeURIComponent(downloadableURL)

    switch (mediaType) {
      case MEDIA_TYPE.IMAGE:
        apiUrl = `https://graph.facebook.com/${pageId}/photos?url=${downloadableURI}&access_token=${accessToken}`
        break

      default:
        return false
    }

    console.log(apiUrl)
    const { data } = await axios({
      url: apiUrl,
      method: "post",
    })
    console.log(data)
    return data
  }

  const handlePublishPostToFacebookPage = () => {
    setMsg(null)
    setPublishing(true)

    if (page.long_lived_page_access_token) {
      uploadContentToFacebook(
        page.page_id,
        page.long_lived_page_access_token,
        media_type,
        url
      )
        .then(response => {
          if (response?.post_id) {
            // Update post with message

            updatePost(
              response?.post_id,
              page.long_lived_page_access_token,
              message
            )
              .then(response => {
                if (response.success) {
                  setMsg({
                    text: "Successfully published post to Facebook.",
                    variant: "success",
                  })
                  setPublishing(false)
                  closeModalPublish()
                } else {
                  setMsg({
                    text: "We could not publish the message, only the asset where uploaded.",
                    variant: "success",
                  })
                  setPublishing(false)
                  closeModalPublish()
                }
              })
              .catch(err => {
                setMsg({
                  text: "We could not publish the message.",
                  variant: "danger",
                })
                setPublishing(false)
                closeModalPublish()
              })
          }
        })
        .catch(err => {
          setMsg({
            text: "We could not publish the content to Facebook. Please contact Reeler support.",
            variant: "danger",
          })
          setPublishing(false)
          console.log(err)
          closeModalPublish()
        })
    } else {
      setMsg({
        text: "We could not publish the content to Facebook. Please contact Reeler support.",
        variant: "danger",
      })
      setPublishing(false)
      closeModalPublish()
    }
  }

  return (
    <>
      <ReelerTooltip text="Publish post to Facebook page">
        <ReelerIconButton
          text=""
          styleClass="btn-secondary"
          dispatch={openModal}
          icon={<FaFacebookSquare size={16} className="icon-color icon-btn" />}
        />
      </ReelerTooltip>

      <Modal
        dialogClassName="modal-90w"
        ref={modalRef}
        show={open}
        onHide={closeModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Post to Facebook page -{" "}
            {account?.integrations?.instagram?.page_name}
          </Modal.Title>
        </Modal.Header>

        <>
          {account &&
          account.integrations &&
          account.integrations.instagram &&
          account.integrations.instagram.accounts &&
          account.integrations.instagram?.scope?.includes(
            "pages_manage_posts"
          ) ? (
            <Modal.Body>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Select Facebook page</Form.Label>
                    <Form.Control
                      as="select"
                      name="page"
                      value={page?.page_id}
                      onChange={e => handleChangePage(e.target.value)}
                    >
                      {account?.integrations?.instagram?.accounts.map(
                        (account, key) => (
                          <option key={key} value={account.page_id}>
                            {account.page_name}
                          </option>
                        )
                      )}
                    </Form.Control>
                    <Form.Text muted>
                      Choose which Facebook page to publish to.
                    </Form.Text>
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>Message</Form.Label>

                    <Form.Control
                      as="textarea"
                      rows={6}
                      defaultValue={
                        asset?.edited_caption ? asset?.edited_caption : null
                      }
                      onChange={e => {
                        setMessage(e.target.value)
                      }}
                    />

                    <Form.Text muted>Write your message to the post.</Form.Text>
                  </Form.Group>
                </Col>
                <Col>
                  {page ? (
                    <div
                      className="d-flex justify-content-center"
                      style={{ background: "var(--reeler-grey)" }}
                    >
                      <div
                        className="m-3"
                        style={{
                          borderRadius: "6px",
                          background: "#ffffff",
                          boxShadow:
                            "rgb(0 0 0 / 13%) 0px 3.2px 7.2px 0px, rgb(0 0 0 / 11%) 0px 0.6px 1.8px 0px",
                        }}
                      >
                        <div
                          className="d-flex flex-row align-items-center"
                          style={{ margin: "0 0 12px", padding: "12px 16px 0" }}
                        >
                          <div className="me-2">
                            <img
                              style={{
                                borderRadius: "50%",
                                border: "2px solid #0000001a",
                              }}
                              src={page.page_profile_picture}
                            />
                          </div>
                          <div className="d-flex flex-column">
                            <strong>
                              <span style={{ fontSize: "15px" }}>
                                {page.page_name}
                              </span>
                            </strong>
                            <span
                              style={{
                                fontSize: "12px",
                                fontWeigh: "300",
                                color: "#65686b",
                              }}
                            >
                              23 maj 2023
                            </span>
                          </div>
                        </div>
                        {message ? (
                          <div
                            style={{
                              padding: "0 16px 16px",
                              whiteSpace: "pre-line",
                              fontSize: "15px",
                              wordWrap: "break-word",
                            }}
                          >
                            {message}
                          </div>
                        ) : null}
                        <div>
                          <MediaType
                            asset={asset}
                            imgSize={IMG_SIZES.preView}
                          />
                        </div>
                      </div>
                    </div>
                  ) : null}
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="mt-3">
                    {msg && (
                      <Alert
                        style={{ textAlign: "center" }}
                        variant={msg.variant}
                      >
                        {msg.text}
                      </Alert>
                    )}
                  </div>
                </Col>
              </Row>
            </Modal.Body>
          ) : (
            <Modal.Body>
              <p>
                We do not have all permissions needed to publish to a Facebook
                page. Please reconnect your Reeler connect with Instagram and
                Facebook again to gain the right permissions. This feature might
                be newer than when you last did your connection and therefore we
                might miss the right permission for this feature.
              </p>
              <p>
                To login with Facebook, go to settings and integrations and
                press the button Login with Facebook.
              </p>

              <Button
                as={Link}
                className="btn-main"
                to={MAIN_ROUTES.settingsIntegration.path}
              >
                Go to integrations
              </Button>
            </Modal.Body>
          )}
          <Modal.Footer>
            <div className="d-flex flex-row justify-content-end align-items-center mt-2">
              <ReelerButton
                text="Close"
                styleClass="btn-secondary"
                dispatch={closeModal}
              />
              <ReelerButton
                text="Publish post"
                styleClass="btn-main ms-2"
                dispatch={openModalPublish}
              />
            </div>
          </Modal.Footer>
        </>
      </Modal>
      <Modal centered show={openPublish} onHide={closeModalPublish}>
        <Modal.Header closeButton>
          <Modal.Title>
            Are you sure you want to publish this post to your Facebook page?
          </Modal.Title>
        </Modal.Header>

        <Modal.Body></Modal.Body>
        <Modal.Footer>
          <div
            className="me-4"
            variant="secondary"
            onClick={closeModalPublish}
            style={{ cursor: "pointer" }}
          >
            Close
          </div>
          <ReelerButton
            loading={publishing}
            dispatch={() => handlePublishPostToFacebookPage()}
            text="Publish now"
            styleClass="btn-main"
          />
        </Modal.Footer>
      </Modal>
    </>
  )
}
