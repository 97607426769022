import React, { useState } from "react"
import {
  AdvancedMarker,
  APIProvider,
  Map,
  Pin,
} from "@vis.gl/react-google-maps"
import Icon from "../../../../../../commons/Icon"
import { FaMap } from "react-icons/fa"

import ReelerButton from "../../../../../../commons/ReelerButton"
import { Modal } from "react-bootstrap"

export default function AssetMap({ location }) {
  const [open, setOpen] = useState(false)

  function openModal() {
    setOpen(true)
  }

  function closeModal() {
    setOpen(false)
  }
  return (
    location && (
      <>
        <Icon onClick={openModal} size="md" tooltip="Show map">
          <FaMap size={14} />
        </Icon>
        <Modal centered show={open} onHide={closeModal} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Asset location: {location?.description}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex justify-content-center">
              <APIProvider apiKey={process.env.REACT_APP_FIREBASE_MAPS_API_KEY}>
                <Map
                  mapId={"6c3dc0448c8c687c"}
                  style={{ width: "500px", height: "500px" }}
                  defaultCenter={{
                    lat: location?.lat,
                    lng: location?.lng,
                  }}
                  defaultZoom={6}
                  gestureHandling={"greedy"}
                >
                  <AdvancedMarker
                    position={{
                      lat: location?.lat,
                      lng: location?.lng,
                    }}
                    title={location?.description}
                  >
                    <Pin
                      background={"#1ee9be"}
                      borderColor={"#0c755e"}
                      glyphColor={"#0c755e"}
                    ></Pin>
                  </AdvancedMarker>
                </Map>
              </APIProvider>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <ReelerButton
              text="Close"
              dispatch={closeModal}
              styleClass="btn-secondary"
            />
          </Modal.Footer>
        </Modal>
      </>
    )
  )
}
