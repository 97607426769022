import React, { useState } from "react"
import { FaCheck } from "react-icons/fa"
// Firebase
import { database } from "../../../../../../../../firebase"

// Reeler
import * as S from "../asset-approve-styles"
import ReelerTooltip from "../../../../../../../commons/ReelerTooltip/ReelerTooltip"
import { ASSET_STATE } from "../../../../../../../../constants"
import SpinnerComponent from "../../../../../../../commons/SpinnerComponent"
// Redux
import { useSelector, useDispatch } from "react-redux"
import { selectActiveAsset } from "../../../../../../../../redux/asset/asset.selectors"
import { updateAsset } from "../../../../../../../../redux/asset/asset.actions"
import { selectCurrentUser } from "../../../../../../../../redux/user/user.selectors"
import { USER_ROLES } from "../../../../../../../../constants/routes"
import { Modal } from "react-bootstrap"
import ReelerButton from "../../../../../../../commons/ReelerButton"

export default function Approve({ asset }) {
  const [loading, setLoading] = useState(false)
  const [show, setShow] = useState(false)
  const dispatch = useDispatch()
  const assetRedux = useSelector(selectActiveAsset)
  const currentUser = useSelector(selectCurrentUser)

  const handleApprove = () => {
    setLoading(true)
    if (asset?.state === ASSET_STATE.approved) {
      // Remove state from asset
      database.assets
        .doc(asset.id)
        .update({
          state: ASSET_STATE.unreviewed,
          stateChangedAt: database.getCurrentTimestamp(),
        })
        .then(() => {
          if (assetRedux) {
            dispatch(
              updateAsset({
                ...assetRedux,
                state: ASSET_STATE.unreviewed,
                stateChangedAt: database.getCurrentTimestamp(),
              })
            )
          }
          setLoading(false)
          handleClose()
        })
        .catch(err => {
          console.log(err)
        })
    } else {
      // Add state to asset
      database.assets
        .doc(asset.id)
        .update({
          state: ASSET_STATE.approved,
          stateChangedAt: database.getCurrentTimestamp(),
        })
        .then(() => {
          if (assetRedux) {
            dispatch(
              updateAsset({
                ...assetRedux,
                state: ASSET_STATE.approved,
                stateChangedAt: database.getCurrentTimestamp(),
              })
            )
          }
          setLoading(false)
          handleClose()
        })
        .catch(err => {
          console.log(err)
        })
    }
  }

  const handleClose = () => {
    setShow(false)
  }

  const handleShow = () => {
    setShow(true)
  }

  return (
    <>
      <S.ApproveIcon
        disabled={currentUser?.role === USER_ROLES.viewer}
        onClick={() =>
          asset.state === ASSET_STATE.approved ? handleShow() : handleApprove()
        }
        borderRadius={"3px 0 0 3px"}
        bgHover={
          asset.state === ASSET_STATE.approved
            ? "var(--reeler-light)"
            : "var(--reeler-grey-darker)"
        }
        bgColor={
          asset.state === ASSET_STATE.approved
            ? "var(--reeler-super-light)"
            : "var(--reeler-grey-middle)"
        }
      >
        {loading ? (
          <SpinnerComponent />
        ) : (
          <ReelerTooltip
            className="w-100"
            text={
              currentUser?.role !== USER_ROLES.viewer ? "Approve asset" : null
            }
          >
            <FaCheck
              style={{
                width: "100%",
                color:
                  asset?.state === ASSET_STATE.approved
                    ? "var(--reeler-dark-green)"
                    : "var(--reeler-icon)",
              }}
            />
          </ReelerTooltip>
        )}
      </S.ApproveIcon>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Unapprove asset?</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to unapprove this asset?</Modal.Body>
        <Modal.Footer>
          <ReelerButton
            text="Close"
            styleClass="btn-secondary"
            dispatch={handleClose}
          />

          <ReelerButton
            text="Unapprove"
            loading={loading}
            styleClass="btn-main"
            dispatch={() => handleApprove()}
          />
        </Modal.Footer>
      </Modal>
    </>
  )
}
