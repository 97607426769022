import React, { useState } from "react"

// 3rd-party libraries
import { Dropdown, Modal, ModalBody } from "react-bootstrap"
import { FaEllipsisV, FaPlus } from "react-icons/fa"
import InputField from "./InputField"
import ContentFields from "./ContentFields"
import ReelerTooltip from "../../../commons/ReelerTooltip/ReelerTooltip"
import CreatorFields from "./CreatorFields"
import SocialMediaFields from "./SocialMediaFields"
import TextField from "./TextField"
import MultipleChoiceField from "./MultipleChoiceField"
import TermsField from "./TermsField"
import { FormFieldControl } from "../styles"

//Redux
import { clearSelectedField } from "../../../../redux/campaign/campaign.actions"
import { useSelector } from "react-redux"
import ImageField from "./ImageField"
import ThirdPartyApprovalField from "./ThirdPartyApprovalField"
import DividerField from "./DividerField"
import Icon from "../../../commons/Icon"
import CopyCampaign from "../../../campaigns/components/CopyCampaign"
import { selectCampaign } from "../../../../redux/campaign/campaign.selectors"
import DeleteCampaign from "../../../campaigns/components/DeleteCampaign"

export default function FormActions() {
  const campaign = useSelector(selectCampaign)
  // The forwardRef is important!!
  // Dropdown needs access to the DOM node in order to position the Menu
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
      ref={ref}
      onClick={e => {
        e.preventDefault()
        onClick(e)
      }}
    >
      {children}
    </div>
  ))

  return (
    <Dropdown>
      <Dropdown.Toggle as={CustomToggle} id="form-actions">
        <Icon tooltip="More functions">
          <FaEllipsisV />
        </Icon>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item>
          <CopyCampaign campaign={campaign} btn="Clone" />
        </Dropdown.Item>
        <Dropdown.Divider />

        <div className="d-flex py-1 px-4">
          <DeleteCampaign />
        </div>
      </Dropdown.Menu>
    </Dropdown>
  )
}
