import React from "react"
import { selectFeed } from "../../../../../redux/feed/feed.selectors"
import { useSelector } from "react-redux"
import { Form, Row, Col, InputGroup } from "react-bootstrap"
import { Link } from "react-router-dom"
import { MAIN_ROUTES } from "../../../../../constants/routes"
import CopyToClipboard from "../../../../commons/CopyToClipboard"

const FeedAPITab = () => {
  const feed = useSelector(selectFeed)

  return (
    <Row>
      <Col md={6}>
        <Form.Group>
          <Form.Label>Feed ID</Form.Label>
          <InputGroup className="d-flex align-items-center">
            <Form.Control disabled value={feed.id} aria-label="Feed id" />
            <div className="ms-3">
              <CopyToClipboard link={feed.id} tooltipText="Copy Feed ID" />
            </div>
          </InputGroup>
          <Form.Text>
            The unique ID for the feed to be used in{" "}
            <Link to={MAIN_ROUTES.apiFeedContent.path}>
              Feed Content API call.
            </Link>
          </Form.Text>
        </Form.Group>
      </Col>
    </Row>
  )
}

export default FeedAPITab
