import { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import {
  selectAccounts,
  selectAccountsIsLoading,
} from "../redux/account/account.selectors"

export function useAccount() {
  const accountInRedux = useSelector(selectAccounts)
  const accountIsLoading = useSelector(selectAccountsIsLoading)
  const [account, setAccount] = useState()
  const [plan, setPlan] = useState(null)
  const [loadingAccount, setLoadingAccount] = useState()

  useEffect(() => {
    if (accountInRedux) {
      setPlan(accountInRedux?.plan)
      setAccount(accountInRedux)
    }
  }, [accountInRedux])

  useEffect(() => {
    setLoadingAccount(accountIsLoading)
  }, [accountIsLoading])

  return { loadingAccount, plan, account }
}
