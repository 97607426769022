import React, { useState } from "react"

// Redux
import { useSelector } from "react-redux"
import { database } from "../../../../../firebase"
import {
  selectFeed,
  selectIsValidToSave,
} from "../../../../../redux/feed/feed.selectors"
import withoutProperty from "../../../../../utils/withoutProperty"

// 3rd-party components
import { Row } from "react-bootstrap"

// Reeler components
import ReelerButton from "../../../../commons/ReelerButton"

import useUserFeedbackMessage from "../../../../../hooks/useUserFeedbackMessage"

const SaveButton = () => {
  // states
  const [msg, setMsg] = useState()
  const [saving, setSaving] = useState(false)
  const isValidToSave = useSelector(selectIsValidToSave)

  const { setUserFeedbackMessage } = useUserFeedbackMessage()

  const feed = useSelector(selectFeed)

  const handleSubmit = () => {
    setMsg(null)
    setSaving(true)

    console.log("feed before", feed)
    let feedWithoutId = withoutProperty(feed, "id")
    console.log("feed after", feed)

    database.feeds
      .doc(feed.id)
      .update(feedWithoutId)
      .then(doc => {
        // if position on any asset has changed then update all assets with new index
        setUserFeedbackMessage("Saved!", "success")

        setSaving(false)
      })
      .catch(err => {
        setUserFeedbackMessage(
          "Something went wrong! Your changes could not be saved",
          "danger"
        )
        console.log(err)
        setSaving(false)
      })
  }

  return (
    <ReelerButton
      loading={saving}
      disabled={!isValidToSave || saving}
      dispatch={() => handleSubmit()}
      text="Save"
      className="me-3"
      styleClass="btn-main"
      spinnerClass="ms-1"
    />
  )
}

export default SaveButton
