import React from "react"
import { useDispatch } from "react-redux"
import {
  BulkEditActionSuccess,
  BulkEditMessageReset,
} from "../redux/bulk-edit/bulkEdit.actions"

export default function useUserFeedbackMessage() {
  const dispatch = useDispatch()

  const setUserFeedbackMessage = (
    text,
    type = "success",
    duration = 3000,
    position = "bulkEdit"
  ) => {
    dispatch(
      BulkEditActionSuccess({
        type,
        duration,
        text,
        position,
      })
    )
  }
  const setSuccessMessage = (text, duration = 3000) => {
    dispatch(
      BulkEditActionSuccess({
        type: "success",
        duration,
        text,
        position: "bulkEdit",
      })
    )
  }
  const setErrorMessage = (text, duration = 3000) => {
    dispatch(
      BulkEditActionSuccess({
        type: "danger",
        duration,
        text,
        position: "bulkEdit",
      })
    )
  }

  const clearUserFeedbackMessage = () => {
    dispatch(BulkEditMessageReset)
  }

  return {
    setSuccessMessage,
    setErrorMessage,
    setUserFeedbackMessage,
    clearUserFeedbackMessage,
  }
}
