import React from "react"
import Signup from "../components/authentication/Signup"
import Login from "../components/authentication/Login"
import ForgotPassword from "../components/authentication/ForgotPassword"
import InviteSignup from "../components/authentication/InviteSignup"
import Campaigns from "../components/campaigns/Campaigns"
import MediaLibraryView from "../components/media-library/MediaLibraryView"
import FeedWidget from "../components/public-components/feed/FeedWidget"
import FeedsListView from "../components/feeds/components/feeds-list/FeedsListView"
import EditFeed from "../components/feeds/components/EditFeed/EditFeed"
import LandingPageForm from "../components/public-components/LandingPageForm"
import ThirdPartyApprovalPage from "../components/public-components/ThirdPartyApprovalPage"
import ConfirmationPage from "../components/public-components/ConfirmationPage"
import RightRequest from "../components/public-components/rightRequest"
import DownloadView from "../components/download/DownloadView"
import HelpView from "../components/help"
import IframeForm from "../components/public-components/iframe/IframeForm"
import GuideTopic from "../components/help/components/GuideTopic/GuideTopic"
import ProductsView from "../components/products/ProductsView"
import EditCampaign from "../components/campaigns/edit-campaign/EditCampaignView"
import FormBuilderView from "../components/campaign/form-builder/FormBuilderView"
import EditHashtagCampaignView from "../components/campaigns/edit-hashtag-campaign/EditHashtagCampaignView"
import EditEmailCampaignView from "../components/campaigns/edit-email-campaign/EditEmailCampaignView"
import EditMentionsCampaignView from "../components/campaigns/mentions/EditMentionsCampaignView"
import UploadMediaFiles from "../components/import-assets/components/UploadMediaFiles"
import ReviewMediaFiles from "../components/import-assets/components/ReviewMediaFiles"
import RightRequestTemplatesView from "../components/rights/ApprovalPages_OLD/RightRequestTemplatesView"
import AppovalPageTemplatesPage from "../pages/Rights/AppovalPageTemplates"
import CollectConsentHashtagView from "../components/rights/ConsentHashtags/CollectConsentHashtagView"
import Profile from "../components/settings/Profile"
import ProfileSecurity from "../components/settings/ProfileSecurity"
import Account from "../components/settings/Account"
import AccountUsers from "../components/settings/users/AccountUsers"
import AccountTags from "../components/settings/AccountTags"
import Integrations from "../components/settings/integrations/Integrations"
import EmailNotification from "../components/settings/EmailNotification"
import { Redirect } from "react-router-dom"
import ManageAPIView from "../components/api/ManageAPIView"
import RecentLabeledContentAPIView from "../components/api/RecentLabeledContentAPIView"
import FiltersView from "../components/filter/FiltersView"
import FeedContentAPIView from "../components/api/FeedContentAPIView"
import MediaAPIView from "../components/api/MediaAPIView"
import MessageTemplatesPage from "../pages/Rights/MessageTemplates/MessageTemplatesPage"
import EmailTemplatesPage from "../pages/Rights/EmailTemplates"
import EditWhatsAppCampaignView from "../components/campaigns/whatsapp/EditWhatsAppCampaignView"
import CreatorsPage from "../pages/Creators/Creators"
import CreatorPage from "../pages/Creators/Creator/CreatorPage"
import InstagramSearchPage from "../pages/InstagramSearch"

export const USER_ROLES = {
  owner: "owner",
  editor: "editor",
  viewer: "viewer",
}

export const ACCOUNT_PLANS = {
  disabled: "disabled",
  basic: "basic",
  pro: "pro",
  premium: "premium",
}
export const MAIN_ROUTES = {
  // HELP
  helpGuideTopicVideo: {
    path: "/help",
    component: GuideTopic,
    params: ["guideTopicId", "guideTopicVideoId"],
    plans: [ACCOUNT_PLANS.basic, ACCOUNT_PLANS.pro, ACCOUNT_PLANS.premium],
  },
  helpGuideTopic: {
    path: "/help",
    component: GuideTopic,
    params: ["guideTopicId"],
    plans: [ACCOUNT_PLANS.basic, ACCOUNT_PLANS.pro, ACCOUNT_PLANS.premium],
  },
  help: {
    path: "/help",
    component: HelpView,
    plans: [ACCOUNT_PLANS.basic, ACCOUNT_PLANS.pro, ACCOUNT_PLANS.premium],
  },

  // CAMPAIGNS
  sourcesInstagramSearch: {
    path: "/instagram-search",
    component: InstagramSearchPage,
    plans: [ACCOUNT_PLANS.basic, ACCOUNT_PLANS.pro, ACCOUNT_PLANS.premium],
  },
  campaignsFormBuilder: {
    path: "/sources/form-builder",
    component: FormBuilderView,
    plans: [ACCOUNT_PLANS.basic, ACCOUNT_PLANS.pro, ACCOUNT_PLANS.premium],
  },
  campaignsEditCampaign: {
    path: "/sources/edit-campaign",
    component: EditCampaign,
    plans: [ACCOUNT_PLANS.basic, ACCOUNT_PLANS.pro, ACCOUNT_PLANS.premium],
  },
  campaignsEditHashtag: {
    path: "/sources/edit-hashtag-campaign",
    component: EditHashtagCampaignView,
    plans: [ACCOUNT_PLANS.basic, ACCOUNT_PLANS.pro, ACCOUNT_PLANS.premium],
  },
  campaignsEditMention: {
    path: "/sources/edit-mentions-campaign",
    component: EditMentionsCampaignView,
    plans: [ACCOUNT_PLANS.basic, ACCOUNT_PLANS.pro, ACCOUNT_PLANS.premium],
  },
  campaignsEditWhatsApp: {
    path: "/sources/edit-whatsapp-campaign",
    component: EditWhatsAppCampaignView,
    plans: [ACCOUNT_PLANS.basic, ACCOUNT_PLANS.pro, ACCOUNT_PLANS.premium],
  },
  campaignsEditEmail: {
    path: "/sources/edit-email-campaign",
    component: EditEmailCampaignView,
    plans: [ACCOUNT_PLANS.basic, ACCOUNT_PLANS.pro, ACCOUNT_PLANS.premium],
  },
  campaigns: {
    path: "/sources",
    component: Campaigns,
    plans: [ACCOUNT_PLANS.basic, ACCOUNT_PLANS.pro, ACCOUNT_PLANS.premium],
  },
  importAssetsReview: {
    path: "/import/review",
    component: ReviewMediaFiles,
    plans: [ACCOUNT_PLANS.basic, ACCOUNT_PLANS.pro, ACCOUNT_PLANS.premium],
  },
  importAssets: {
    path: "/import",
    component: UploadMediaFiles,
    plans: [ACCOUNT_PLANS.basic, ACCOUNT_PLANS.pro, ACCOUNT_PLANS.premium],
  },

  // RIGHTS
  rightsApprovalPages: {
    path: "/rights/approval-pages",
    component: RightRequestTemplatesView,
    plans: [ACCOUNT_PLANS.basic, ACCOUNT_PLANS.pro, ACCOUNT_PLANS.premium],
  },
  rightsApprovalPageTemplates: {
    path: "/rights/approval-page-templates",
    component: AppovalPageTemplatesPage,
    plans: [ACCOUNT_PLANS.basic, ACCOUNT_PLANS.pro, ACCOUNT_PLANS.premium],
  },
  rightsMessageTemplates: {
    path: "/rights/message-templates",
    component: MessageTemplatesPage,
    plans: [ACCOUNT_PLANS.basic, ACCOUNT_PLANS.pro, ACCOUNT_PLANS.premium],
  },
  rightsEmailTemplates: {
    path: "/rights/email-templates",
    component: EmailTemplatesPage,
    plans: [ACCOUNT_PLANS.basic, ACCOUNT_PLANS.pro, ACCOUNT_PLANS.premium],
  },
  rightsConsentHashtags: {
    path: "/rights/consent-hashtags",
    component: CollectConsentHashtagView,
    plans: [ACCOUNT_PLANS.basic, ACCOUNT_PLANS.pro, ACCOUNT_PLANS.premium],
  },

  // CONTENT
  asset: {
    path: "/content",
    component: MediaLibraryView,
    params: ["assetId"],
    plans: [ACCOUNT_PLANS.basic, ACCOUNT_PLANS.pro, ACCOUNT_PLANS.premium],
  },
  content: {
    path: "/content",
    component: MediaLibraryView,
    plans: [ACCOUNT_PLANS.basic, ACCOUNT_PLANS.pro, ACCOUNT_PLANS.premium],
  },
  download: {
    path: "/download",
    component: DownloadView,
    plans: [ACCOUNT_PLANS.basic, ACCOUNT_PLANS.pro, ACCOUNT_PLANS.premium],
  },

  // Products
  products: {
    path: "/products",
    component: ProductsView,
    userRoles: [USER_ROLES.owner, USER_ROLES.editor],
    plans: [ACCOUNT_PLANS.pro, ACCOUNT_PLANS.premium],
  },
  // Creators
  creator: {
    path: "/creators",
    params: ["creatorId"],
    component: CreatorPage,
    userRoles: [USER_ROLES.owner, USER_ROLES.editor],
    plans: [ACCOUNT_PLANS.pro, ACCOUNT_PLANS.premium],
  },
  creators: {
    path: "/creators",
    component: CreatorsPage,
    userRoles: [USER_ROLES.owner, USER_ROLES.editor],
    plans: [ACCOUNT_PLANS.pro, ACCOUNT_PLANS.premium],
  },

  // FILTERS
  filters: {
    path: "/filters",
    component: FiltersView,
    userRoles: [USER_ROLES.owner, USER_ROLES.editor, USER_ROLES.viewer],
    plans: [ACCOUNT_PLANS.pro, ACCOUNT_PLANS.premium],
  },

  // FEEDS
  feedsEditFeed: {
    path: "/feeds/feed",
    component: EditFeed,
    exact: true,
    userRoles: [USER_ROLES.owner, USER_ROLES.editor],
    plans: [ACCOUNT_PLANS.pro, ACCOUNT_PLANS.premium],
  },
  feeds: {
    path: "/feeds",
    component: FeedsListView,
    userRoles: [USER_ROLES.owner, USER_ROLES.editor],
    plans: [ACCOUNT_PLANS.pro, ACCOUNT_PLANS.premium],
  },

  // SETTINGS ACCOUNT
  settingsAccount: {
    path: "/settings/account",
    component: Account,
    userRoles: [USER_ROLES.owner, USER_ROLES.editor],
    plans: [ACCOUNT_PLANS.basic, ACCOUNT_PLANS.pro, ACCOUNT_PLANS.premium],
  },
  settingsAccountUsers: {
    path: "/settings/users",
    component: AccountUsers,
    userRoles: [USER_ROLES.owner, USER_ROLES.editor],
    plans: [ACCOUNT_PLANS.basic, ACCOUNT_PLANS.pro, ACCOUNT_PLANS.premium],
  },
  settingsAccountTags: {
    path: "/settings/labels",
    component: AccountTags,
    userRoles: [USER_ROLES.owner, USER_ROLES.editor],
    plans: [ACCOUNT_PLANS.basic, ACCOUNT_PLANS.pro, ACCOUNT_PLANS.premium],
  },
  settingsIntegration: {
    path: "/settings/integrations",
    component: Integrations,
    userRoles: [USER_ROLES.owner, USER_ROLES.editor],
    plans: [ACCOUNT_PLANS.basic, ACCOUNT_PLANS.pro, ACCOUNT_PLANS.premium],
  },

  // SETTING USER
  settingsProfileSecurity: {
    path: "/user/security",
    component: ProfileSecurity,
    userRoles: [USER_ROLES.owner, USER_ROLES.editor, USER_ROLES.viewer],
    plans: [ACCOUNT_PLANS.basic, ACCOUNT_PLANS.pro, ACCOUNT_PLANS.premium],
  },

  settingsEmailNotification: {
    path: "/user/email-notification",
    component: EmailNotification,
    userRoles: [USER_ROLES.owner, USER_ROLES.editor],
    plans: [ACCOUNT_PLANS.basic, ACCOUNT_PLANS.pro, ACCOUNT_PLANS.premium],
  },
  settingsProfile: {
    path: "/user/profile",
    component: Profile,
    userRoles: [USER_ROLES.owner, USER_ROLES.editor, USER_ROLES.viewer],
    plans: [ACCOUNT_PLANS.basic, ACCOUNT_PLANS.pro, ACCOUNT_PLANS.premium],
  },

  apiFeedContent: {
    path: "/manage-api/feed-content",
    component: FeedContentAPIView,
    userRoles: [USER_ROLES.owner, USER_ROLES.editor],
    plans: [ACCOUNT_PLANS.premium],
  },
  apiRecentLabeledContent: {
    path: "/manage-api/recent-labeled-content",
    component: RecentLabeledContentAPIView,
    userRoles: [USER_ROLES.owner, USER_ROLES.editor],
    plans: [ACCOUNT_PLANS.premium],
  },
  apiMedia: {
    path: "/manage-api/media",
    component: MediaAPIView,
    userRoles: [USER_ROLES.owner, USER_ROLES.editor],
    plans: [ACCOUNT_PLANS.premium],
  },
  manageApiView: {
    path: "/manage-api",
    component: ManageAPIView,
    userRoles: [USER_ROLES.owner, USER_ROLES.editor],
    plans: [ACCOUNT_PLANS.premium],
  },
}

export const PUBLIC_ROUTES = {
  // PUBLIC ROUTES
  thirdPartyApproval: {
    path: "/approval",
    params: ["approvalId"],
    component: ThirdPartyApprovalPage,
  },
  rightsRequest: {
    path: "/request",
    params: ["linkId"],
    component: RightRequest,
  },
  landingPageForm: {
    path: "/c/v2",
    params: ["campaignId"],
    component: LandingPageForm,
  },
  iframeForm: {
    path: "/iframe/v3",
    params: ["campaignId"],
    component: IframeForm,
  },
  confirmation: {
    path: "/confirmation",
    params: ["confirmationId"],
    component: ConfirmationPage,
  },
  iframeWidget: { path: "iframe/widget" },
  iframeFeedWidget: {
    path: "/iframe/feed",
    params: ["feedId"],
    component: FeedWidget,
  },

  // PUBLIC AUTH ROUTES
  signup: { path: "/signup", component: Signup },
  login: { path: "/login", component: Login },
  forgotPassword: { path: "/forgot-password", component: ForgotPassword },
  invite: { path: "/invite", params: ["inviteId"], component: InviteSignup },
}
