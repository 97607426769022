import React from "react"
import { Form, Row, Table } from "react-bootstrap"

import "../App.css"

import Section from "../../layouts/core/Section"
import { Header } from "../../layouts/core/Header"
import Body from "../../layouts/core/Body"
import Title from "../../layouts/core/Title"
import BackButton from "../commons/BackButton"

import Navbar from "./components/Navbar"
import Page from "./components/Page"
import PageSection from "./components/PageSection"
import PageSectionTitle from "./components/PageSectionTitle"

const errorExample = {
  error: {
    message: "Media does not exists",
  },
}

const MediaAPIView = () => {
  return (
    <Section>
      <Header>
        <Title>Reeler Content API</Title>
        <div className="ms-auto">
          <BackButton />
        </div>
      </Header>
      <Body>
        <Row>
          <Navbar />
          <Page>
            <h1 className="title">Media</h1>
            <p>
              This API endpoint serves media (IMAGE and VIDEO) related to an
              asset.
            </p>

            <PageSection>
              <PageSectionTitle>Endpoint</PageSectionTitle>

              <Form.Control
                disabled={true}
                value="GET /media"
                aria-label="Enpoint"
              />
            </PageSection>

            <PageSection>
              <PageSectionTitle>Limitations</PageSectionTitle>
              <ul>
                <li>Returns an image or video (no audio clips).</li>
              </ul>
            </PageSection>

            <PageSection>
              <PageSectionTitle>Requirements</PageSectionTitle>

              <ul>
                <li>
                  Media ID, provided from other endpoints such as Recent Labeled
                  Content or Feed Content.
                </li>
              </ul>
            </PageSection>
            <PageSection>
              <PageSectionTitle>Request syntax</PageSectionTitle>
              <Form.Control
                as="textarea"
                rows={3}
                disabled={true}
                value="GET https://app.reelertech.com/api/v1.0/media/{media-id}?size={size}&cache_age={cache-age}"
                aria-label="Media endpoint"
              />
              <Form.Text muted>Returns.</Form.Text>
            </PageSection>
            <PageSection>
              <PageSectionTitle>Query String Parameters</PageSectionTitle>
              <p>
                Include any of the following query string parameters to refine
                your request:
              </p>
              <Table bordered>
                <thead>
                  <tr>
                    <th>Key</th>
                    <th>Value</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>size</td>
                    <td>
                      This parameter is only applicable to images. If omitted,
                      the original image size will be returned. For images,
                      choose either size 200 or 500, which returns images with a
                      height limit of 200px or 500px, respectively
                    </td>
                  </tr>
                  <tr>
                    <td>cache_age</td>
                    <td>
                      <span>
                        Use the cache_age parameter to define the maximum
                        duration for caching API responses, expressed in
                        seconds. If you omit this parameter, the default maximum
                        duration of “3600” seconds (1 hour) will be used. The
                        cache_age parameter allows you to adjust the maximum
                        duration from “0" (no caching) to a maximum of “2592000”
                        seconds (one month).
                      </span>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </PageSection>

            <PageSection>
              <PageSectionTitle>Example request:</PageSectionTitle>
              <Form.Control
                as="textarea"
                rows={3}
                disabled={true}
                value="GET https://app.reelertech.com/api/v1.0/media/dsfs123d23ds4?size=200&cache_age=86400"
                aria-label="Example request"
              />
            </PageSection>

            <PageSection>
              <PageSectionTitle>Error response:</PageSectionTitle>
              <Form.Control
                as="textarea"
                rows={5}
                disabled={true}
                value={JSON.stringify(errorExample, undefined, 2)}
                aria-label="Error response"
              />
            </PageSection>
          </Page>
        </Row>
      </Body>
    </Section>
  )
}

export default MediaAPIView
