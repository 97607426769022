import { MEDIA_ORIENTATION, MEDIA_TYPE } from "../constants"
import { loadImageMetaData } from "./LoadImageMetadata"
import { loadVideoMetadata } from "./LoadVideoMetadata"
import { aspectRatio } from "./CalculateAspectRatio"
export const getMediaMetadata = async (file, mediaType) => {
  let metadata = {}
  if (!file) return metadata
  let url = URL.createObjectURL(file)
  console.log(url)
  if (mediaType === MEDIA_TYPE.IMAGE) {
    // Get image width and height
    const dimensions = await loadImageMetaData(url)
    if (!dimensions.error) {
      const { width, height } = dimensions
      metadata["dimensions"] = dimensions
      metadata["aspect_ratio"] = aspectRatio(width, height)
      metadata["orientation"] =
        width === height
          ? MEDIA_ORIENTATION.SQUARE
          : width > height
          ? MEDIA_ORIENTATION.LANDSCAPE
          : MEDIA_ORIENTATION.PORTRAIT
    }
  } else if (mediaType === MEDIA_TYPE.VIDEO) {
    const dimensions = await loadVideoMetadata(url)
    console.log(dimensions)
    if (!dimensions.error) {
      const { width, height } = dimensions
      metadata["dimensions"] = dimensions
      metadata["aspect_ratio"] = aspectRatio(width, height)
      metadata["orientation"] =
        width === height
          ? MEDIA_ORIENTATION.SQUARE
          : width > height
          ? MEDIA_ORIENTATION.LANDSCAPE
          : MEDIA_ORIENTATION.PORTRAIT
    }
  }

  metadata["type"] = file.type
  metadata["size"] = file.size
  return metadata
}
