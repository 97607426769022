import React, { useState, useEffect } from "react"
import { database } from "../../firebase"
import GuideTopicVideoList from "./components/GuideTopic/components/GuideTopicVideoList/GuideTopicVideoList"
import Section from "../../layouts/core/Section"
import { Header } from "../../layouts/core/Header"
import Title from "../../layouts/core/Title"
import Body from "../../layouts/core/Body"
import BackButton from "../commons/BackButton"

export default function HelpView() {
  const [guideTopics, setGuideTopics] = useState(null)

  useEffect(() => {
    database.guides
      .where("published", "==", true)
      .orderBy("order")
      .get()
      .then(querySnapshot => {
        let topics = []
        querySnapshot.forEach(doc => {
          // doc.data() is never undefined for query doc snapshots
          topics.push(database.formatDoc(doc))
        })
        setGuideTopics(topics)
      })
      .catch(error => {
        console.log("Error getting documents: ", error)
      })
  }, [])

  return (
    <Section>
      <Header>
        <Title>Reeler Guides & Support</Title>
        <div className="ms-auto">
          <BackButton />
        </div>
      </Header>
      <Body>
        <div className="px-3 mb-5 w-75">
          <p>
            <small>
              To maximize your experience with the Reeler platform, explore our
              Reeler Guides – tutorial videos covering different platform
              functions, including tips and tricks for efficiently managing
              content from your users. If there’s a specific tutorial you’re
              missing, please let us know!
            </small>
          </p>
          <p>
            <small>
              For more hands-on assistance or technical support, feel free to:
            </small>
          </p>
          <ul>
            <li>
              <small>
                <bold>
                  Email us at{" "}
                  <a href="mailto:support@reelertech.com">
                    support@reelertech.com
                  </a>
                </bold>
                . We typically respond the same day or within 24 hours (on
                working days).
              </small>
            </li>
            <li>
              <small>
                <bold>Live chat with our support team. </bold>Chat availability
                may vary; when active, you’ll find a chat icon at the bottom
                right of this page.
              </small>
            </li>
          </ul>
        </div>
        {guideTopics?.map(guideTopic => (
          <div className="mb-3">
            <GuideTopicVideoList
              key={guideTopic.id}
              guideTopicId={guideTopic.id}
            />
          </div>
        ))}
      </Body>
    </Section>
  )
}
