import React, { useEffect, useState } from "react"
import { Row, Col, Modal, Form } from "react-bootstrap"
import { FaEye } from "react-icons/fa"
import moment from "moment"

// Redux
import { useSelector, useDispatch } from "react-redux"
import {
  selectActiveAsset,
  selectShowAssetRightsRequestModal,
  selectAssetRightsRequest,
} from "../../../../../../../redux/asset/asset.selectors"
import {
  setCloseAssetRightsRequestModal,
  clearAssetRightsRequest,
} from "../../../../../../../redux/asset/asset.actions"

// Reeler components
import ReelerButton from "../../../../../../commons/ReelerButton"
import PreviewRequestLink from "../../../../../../../pages/Rights/AppovalPageTemplates/components/PreviewRequestLink"
import {
  APPROVAL_CHANNEL,
  CAMPAIGN_TYPE,
  RIGHTS_REQUEST_TYPE,
} from "../../../../../../../constants"

import RightRequestInstagram from "./RightRequestInstagram"
import RightRequestEmail from "./RightRequestEmail"
import RightsRequestLink from "./RightsRequestLink"
import Icon from "../../../../../../commons/Icon"
import { RightsRequesStatusLog } from "./RightsRequesStatusLog"
import SelectApprovalPageTemplate from "./SelectApprovalPageTemplate"
import * as S from "./styles"
import NewThridPartyRightsRequest from "./NewThridPartyRightsRequest"
import ContactInformation from "./ContactInformation"
import ReelerTooltip from "../../../../../../commons/ReelerTooltip/ReelerTooltip"
import SaveRightRequest from "./SaveRightRequest"

export default function CreateRightRequest() {
  const dispatch = useDispatch()
  const [channel, setChannel] = useState(null)
  const asset = useSelector(selectActiveAsset)
  const showModal = useSelector(selectShowAssetRightsRequestModal)
  const rightsRequest = useSelector(selectAssetRightsRequest)

  useEffect(() => {
    if (asset) {
      if (
        [CAMPAIGN_TYPE.igHashtag.type, CAMPAIGN_TYPE.igMentions.type].includes(
          asset.campaign_type
        )
      ) {
        setChannel("instagram")
      }
    }
  }, [asset])

  const closeModal = () => {
    dispatch(setCloseAssetRightsRequestModal())
    dispatch(clearAssetRightsRequest())
  }

  const handleOpenPreview = () => {
    if (rightsRequest?.link) {
      window.open(rightsRequest?.link, "_blank", "noreferrer")
    }
  }

  return (
    <>
      <Modal id="right-requests" show={showModal} onHide={closeModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            {rightsRequest?.requestType === RIGHTS_REQUEST_TYPE.creator
              ? "Creator rights request"
              : "Third party rights request"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ minHeight: "500px", padding: "0 1rem" }}>
          <Row>
            <Col>
              <S.RightRequestContainer>
                <h6>Preview</h6>
                {rightsRequest?.approvalPage ? (
                  <PreviewRequestLink
                    approvalPage={rightsRequest.approvalPage}
                    asset={asset}
                  />
                ) : (
                  <div className="d-flex flex-column justify-content-center align-items-center pt-5">
                    <img
                      width="200px"
                      src="../../no_content_women_dog.svg"
                      alt="No template selected"
                    />
                    <p>No template selected</p>
                  </div>
                )}
              </S.RightRequestContainer>
            </Col>
            <Col style={{ borderLeft: "1px solid var(--reeler-grey-dark)" }}>
              <S.RightRequestContainer>
                {rightsRequest?.requestType === RIGHTS_REQUEST_TYPE.creator ? (
                  <>
                    <div className="d-flex flex-row justify-content-end align-items-center">
                      <div className="d-flex flex-row align-items-center">
                        <div className="mx-1">
                          <ReelerTooltip
                            text={
                              rightsRequest?.completedAt
                                ? `${rightsRequest?.approvalStatus} (${moment(
                                    rightsRequest?.completedAt?.toDate()
                                  ).format("YYYY-MM-DD hh:ss")})`
                                : rightsRequest?.approvalStatus
                            }
                          >
                            <S.ApprovalStatus
                              status={rightsRequest?.approvalStatus}
                            />
                          </ReelerTooltip>
                        </div>
                        <div className="mx-1">
                          <RightsRequesStatusLog
                            rightsRequest={rightsRequest}
                          />
                        </div>
                        <div className="mx-1">
                          <Icon
                            tooltip="Preview approval page"
                            onClick={() => handleOpenPreview()}
                          >
                            <FaEye />
                          </Icon>
                        </div>
                      </div>
                    </div>
                    <Row className="mb-3">
                      <Col>
                        <SelectApprovalPageTemplate />
                        <ContactInformation />

                        <SaveRightRequest />
                      </Col>
                    </Row>
                    {rightsRequest?.approvalPage && rightsRequest?.id ? (
                      <Row className="mt-3">
                        <Col className="pb-3">
                          <Form.Group controlId="userId">
                            <Form.Label className="form-label-larger">
                              Select how you like to send the request:
                            </Form.Label>
                            <Form.Control
                              as="select"
                              name="channel"
                              value={channel}
                              onChange={e => setChannel(e.target.value)}
                            >
                              <option value="">-</option>
                              {[
                                CAMPAIGN_TYPE.igHashtag.type,
                                CAMPAIGN_TYPE.igMentions.type,
                              ].includes(asset.campaign_type) &&
                              rightsRequest?.requestType ===
                                RIGHTS_REQUEST_TYPE.creator ? (
                                <option value="instagram">
                                  Message on Instagram
                                </option>
                              ) : null}
                              <option value="email">Send as email</option>
                              <option value="link">Send link manually</option>
                            </Form.Control>
                            <Form.Text muted>
                              Select how to send the rights request.
                            </Form.Text>
                          </Form.Group>

                          <div className="ms-3">
                            {channel === "instagram" &&
                            [
                              CAMPAIGN_TYPE.igHashtag.type,
                              CAMPAIGN_TYPE.igMentions.type,
                            ].includes(asset.campaign_type) &&
                            rightsRequest?.requestType ===
                              RIGHTS_REQUEST_TYPE.creator ? (
                              <RightRequestInstagram />
                            ) : channel === "email" ? (
                              <RightRequestEmail />
                            ) : channel === "link" ? (
                              <RightsRequestLink />
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                    ) : null}
                  </>
                ) : (
                  <>
                    <SelectApprovalPageTemplate />
                    <NewThridPartyRightsRequest />
                  </>
                )}
              </S.RightRequestContainer>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <ReelerButton
            text="Close"
            dispatch={closeModal}
            styleClass="btn-secondary"
          />
        </Modal.Footer>
      </Modal>
    </>
  )
}
