import React from "react"
import { ButtonContainer, IconContainer } from "./styles"

const ReelerIconButton = ({
  small = false,
  text = "",
  icon,
  loading,
  dispatch,
  styleClass = "btn-main",
}) => {
  return (
    <ButtonContainer
      $small={small}
      disabled={loading}
      onClick={dispatch}
      className={styleClass}
    >
      <IconContainer>{icon}</IconContainer>
      <span>{text}</span>
    </ButtonContainer>
  )
}

export default ReelerIconButton
