import React, { useState } from "react"

import * as S from "../styles"
import FeedItemSoMeIcon from "../../FeedItemSoMeIcon"
import MediaType from "../../MediaType"
import FeedModal from "../../FeedModal/FeedModal"
import { IMG_SIZES } from "../../../constants"

export default function GridItem({ feed, asset }) {
  const [show, setShow] = useState(false)

  const handleOnClick = () => {
    if (asset?.products[0]?.url) {
      window.open(asset.products[0].url, "_blank", "noreferrer")
    }
  }
  return (
    <>
      <S.GridItem
        settings={feed?.feedSettings}
        feedLayout={feed.feedLayout}
        {...(feed.feedSettings?.showFeedModal
          ? {
              onClick: () => setShow(true),
            }
          : feed.feedSettings?.linkToProductUrl
          ? {
              onClick: handleOnClick,
            }
          : null)}
      >
        {feed.feedSettings?.socialMediaIcon ? (
          <FeedItemSoMeIcon asset={asset} />
        ) : null}

        <MediaType
          asset={asset}
          imgSize={IMG_SIZES.preView}
          objectFit="cover"
          aspectRatio="1/1"
          videoSettings={{
            autoplay: "autoplay",
            muted: true,
            loop: true,
            controls: false,
            playsinline: true,
          }}
        />

        <S.GridItemOverlay settings={feed?.feedSettings}></S.GridItemOverlay>
      </S.GridItem>
      {feed.feedSettings?.showFeedModal ? (
        <FeedModal
          isOpen={show}
          feed={feed}
          asset={asset}
          onClose={() => setShow(false)}
        />
      ) : null}
    </>
  )
}
