import React, { useState } from "react"
import { Form, Col, Row } from "react-bootstrap"
import ConfirmationTab from "./ConfirmationTab"
import { useDispatch, useSelector } from "react-redux"
import { saveCampaign } from "../../../../../../redux/campaign/campaign.actions"
import {
  selectCampaign,
  selectFormSchema,
} from "../../../../../../redux/campaign/campaign.selectors"
import ReelerButton from "../../../../../commons/ReelerButton"
import useUserFeedbackMessage from "../../../../../../hooks/useUserFeedbackMessage"
import { database } from "../../../../../../firebase"

export default function FormAutomation() {
  const [settings, setSettings] = useState()
  const [submitType, setSubmitType] = useState("submitMessage")
  const [isSaving, setIsSaving] = useState(false)
  const formSchema = useSelector(selectFormSchema)
  const campaign = useSelector(selectCampaign)
  const dispatch = useDispatch()
  const { setSuccessMessage } = useUserFeedbackMessage()

  useState(() => {
    if (campaign) {
      setSettings({
        submitMessage: campaign?.formSchema?.submitMessage
          ? campaign?.formSchema?.submitMessage
          : campaign?.formSchema?.properties["submitButton"]?.submitMessage
          ? campaign?.formSchema?.properties["submitButton"]?.submitMessage
          : "",
        redirectUrl: campaign?.formSchema?.redirectUrl
          ? campaign?.formSchema?.redirectUrl
          : campaign?.formSchema?.properties["submitButton"]?.redirectUrl
          ? campaign?.formSchema?.properties["submitButton"]?.redirectUrl
          : "",
        submitType: campaign?.formSchema?.submitType
          ? campaign?.formSchema?.submitType
          : "submitMessage",
      })
    }
  }, [campaign])

  const handleChange = (target, value) => {
    setSettings({ ...settings, [target]: value })
  }

  const handleOnBlur = (target, value) => {
    // let { id, ...updateDoc } = campaign
    // updateDoc = {
    //   ...updateDoc,
    //   formSchema: {
    //     ...updateDoc?.formSchema,
    //     ...settings,
    //     properties: {
    //       ...updateDoc?.formSchema.properties,
    //       submitButton: {
    //         ...updateDoc?.formSchema.properties.submitButton,
    //         redirectUrl: "",
    //         submitMessage: "",
    //       },
    //     },
    //   },
    // }

    dispatch(
      saveCampaign({
        ...campaign,
        formSchema: {
          ...campaign?.formSchema,
          [target]: value,
          properties: {
            ...campaign?.formSchema.properties,
            submitButton: {
              ...campaign?.formSchema.properties.submitButton,
              redirectUrl: "",
              submitMessage: "",
            },
          },
        },
      })
    )
  }

  return (
    <div>
      <Form.Group controlId="success-message">
        <Row className="p-3">
          <Col sm={4}>
            <Form.Label className="mb-0">
              What should happen after a user submits this form?
            </Form.Label>
          </Col>
          <Col sm={8}>
            <Form.Group>
              <Form.Label className="mb-3">
                Choose between displaying a thank you message or redirecting the
                submitter to another page.
              </Form.Label>

              <Form.Check
                type="radio"
                id="submit-message"
                label="Thank you message"
                onChange={() => handleChange("submitType", "submitMessage")}
                checked={
                  settings?.submitType === "submitMessage" ? true : false
                }
              />
              <Form.Check
                type="radio"
                id="redirect"
                label="Redirect to external page"
                onChange={() => handleChange("submitType", "redirect")}
                checked={settings?.submitType === "redirect" ? true : false}
              />
            </Form.Group>

            {settings?.submitType === "submitMessage" ? (
              <Form.Group controlId="submitMessage">
                <Form.Label className="mb-0">
                  Add a thank you message
                </Form.Label>
                <Form.Control
                  as="textarea"
                  type="text"
                  value={settings?.submitMessage}
                  onChange={e => handleChange("submitMessage", e.target.value)}
                  onBlur={e => handleOnBlur("submitMessage", e.target.value)}
                />
                <Form.Text muted>
                  This will be displayed when the form is submitted
                </Form.Text>
              </Form.Group>
            ) : (
              <Form.Group controlId="redirectUrl">
                <Form.Label className="mb-0">Redirect URL</Form.Label>
                <Form.Control
                  type="text"
                  value={settings?.redirectUrl}
                  onChange={e => handleChange("redirectUrl", e.target.value)}
                  onBlur={e => handleOnBlur("redirectUrl", e.target.value)}
                />
                <Form.Text muted>
                  The user will be redirected to this URL after submitting the
                  form (not recommended if you use iframe embedding).
                </Form.Text>
              </Form.Group>
            )}
          </Col>
        </Row>
      </Form.Group>
      <Form.Group controlId="confirmation-mail">
        <Row className="p-3">
          <Col sm={4}>
            <Form.Label className="mb-0">
              Automate the post-submission process of the form.
            </Form.Label>
          </Col>
          <Col sm={8}>
            <ConfirmationTab />
          </Col>
        </Row>
      </Form.Group>
    </div>
  )
}
