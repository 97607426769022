import styled, { css } from "styled-components"

const truncatedCaption = css`
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
`

export const SocialWallContainer = styled.div`
  -webkit-column-count: ${({ settings: { columns } }) => columns};
  column-count: ${({ settings: { columns } }) => columns};
  -webkit-column-gap: ${({ settings: { gutter } }) => `${gutter}px`};
  -moz-column-gap: ${({ settings: { gutter } }) => `${gutter}px`};
  grid-column-gap: ${({ settings: { gutter } }) => `${gutter}px`};
  column-gap: ${({ settings: { gutter } }) => `${gutter}px`};

  orphans: 1;
  widows: 1;
`
export const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
  transition: all 100ms ease-in-out;
  color: #fff;
  /* center overlay text */
  display: none;
  align-items: center;
  justify-content: center;
  border-radius: ${({ settings: { borderRadius } }) => `${borderRadius}px`};
`

export const SocialWallCard = styled.div`
  display: inline-block;
  overflow: hidden;
  position: relative;
  flex-direction: column;
  cursor: pointer;
  width: 100%;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: initial;
  border-radius: ${({ settings: { borderRadius } }) => `${borderRadius}px`};
  /* box-shadow: ${({ settings: { showShadow } }) =>
    showShadow ? "0px 4px 4px 0px #00000040;" : null}; */
  margin-bottom: ${({ settings: { gutter } }) => `${gutter - 6}px`};
  &:hover ${Overlay} {
    display: flex;
  }
`

export const SocialWallImage = styled.img`
  width: 100%;
  cursor: ${({ btn }) => (btn ? "pointer" : "inherit")};
  vertical-align: middle;
  border-top-left-radius: ${({ settings: { borderRadius } }) =>
    `${borderRadius}px`};
  border-top-right-radius: ${({ settings: { borderRadius } }) =>
    `${borderRadius}px`};
`

export const SocialWallCardBody = styled.div`
  flex: 1 1 auto;
  padding: 0.75rem 0.25rem !important;
`

export const Caption = styled.div`
  margin-bottom: 5px;
  font-size: 0.6rem;
  text-overflow: ellipsis; // this line adds the ellipsis
  overflow: hidden; // this hides the overflowing text
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`

export const ShowMoreButton = styled.div`
  font-size: 0.5rem;
  font-weight: 600;
  cursor: pointer;
  :hover {
    color: var(--reeler-link);
  }
`
export const TopRight = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`

export const CampaignType = styled.div`
  display: flex;
  align-self: center;
  justify-content: center;
  background: #fff;
  padding: 0.2rem;
  margin: 0.3rem;
  border-radius: 5px;
`

export const LoadMoreContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 5px;
`
export const LoadMoreButton = styled.div`
  display: flex;
  justify-content: center;
  border-radius: 3px;
  padding: 5px;
  color: var(--reeler-link);
  box-shadow: var(--reeler-shadow);
  width: 200px;

  :hover {
    cursor: pointer;
    background: var(--reeler-grey);
  }
`
