import React from "react"
import { Container } from "react-bootstrap"
import { Link } from "react-router-dom"

const PublicNotFound = ({ url = "/" }) => {
  return (
    <Container fluid className="container_background">
      <div className="pt-5 d-flex flex-column justify-content-center align-items-center">
        <img width="200px" src="no_content_women_dog.svg" alt="" />

        <h1>Page not found</h1>
        <p style={{ width: "400px" }}>
          Whooops! We don't have the page you're looking for...
        </p>
        <Link to={url}>Take me home</Link>
      </div>
    </Container>
  )
}

export default PublicNotFound
