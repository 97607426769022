import { put, call, all, takeLatest } from "redux-saga/effects"
import InviteActionTypes from "./invite.types"
import {
  fetchInviteCodeDataSuccess,
  fetchInviteCodeDataFailure,
} from "./invite.actions"
import { getInviteCodeData } from "../../firebase"

export function* fetchInviteCodeDataAsync({ payload: { inviteId } }) {
  try {
    const inviteRef = yield call(getInviteCodeData, inviteId)

    if (!inviteRef) {
      yield put(fetchInviteCodeDataFailure("Invalid Invite Code"))
      return
    }
    yield put(fetchInviteCodeDataSuccess(inviteRef))
  } catch (error) {
    yield put(fetchInviteCodeDataFailure(error))
  }
}

export function* onfetchInviteCodeDataStart() {
  yield takeLatest(
    InviteActionTypes.FETCH_INVITE_CODE_DATA_START,
    fetchInviteCodeDataAsync
  )
}

export function* inviteSagas() {
  yield all([call(onfetchInviteCodeDataStart)])
}
