import styled from "styled-components"

export const ContainerOverlay = styled.div`
  background-color: var(--reeler-grey);
  width: 100%;
  height: 201px;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.5;
`

export const AssetPreviewContainer = styled.div`
  user-select: none;
  position: relative;
  cursor: pointer;
  border: ${({ selected }) =>
    selected
      ? "3px solid var(--reeler) !important"
      : "1px solid #dee2e6 !important"};
  /* box-shadow: ${({ selected }) =>
    selected ? "0 0 0 0.2rem var(--reeler-o-25) !important;" : "none"}; */

  background: var(--reeler-white);
`

export const Iconbar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5em;
`
export const ThumbnailContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1/1;
  max-height: 200px;
  padding: 0.5rem;
  margin-bottom: -0.5rem;
`
export const AssetMediaTypeVideoPlayIcon = styled.div`
  position: absolute;
  color: white;
  z-index: 1;
  &:hover {
    color: var(--reeler-grey-darker);
  }
`

export const Notification = styled.div`
  aspect-ratio: 1/1;

  width: ${props => (props.$width ? `${props.$width}px` : "20px")};
  height: ${props => (props.$height ? `${props.$height}px` : "20px")};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  background-color: var(--reeler-light);
  color: var(--reeler-icon);
  border-radius: 50%;
  font-size: 10px;

  position: absolute;
  top: ${props => (props.$top ? `${props.$top}px` : "-6px")};
  right: ${props => (props.$right ? `${props.$right}px` : "-6px")};
`
