import React, { useState, useEffect } from "react"

// 3 party
import { Editor } from "react-draft-wysiwyg"
import { EditorState, convertFromHTML, ContentState } from "draft-js"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { stateToHTML } from "draft-js-export-html"

// Styles
import * as S from "../components/styles/styles"

export const toolbarOptions = {
  options: ["blockType", "inline", "list", "link"],
  blockType: {
    inDropdown: true,
    options: ["Normal", "H1", "H2"],
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
  },
  inline: {
    options: ["bold", "italic", "underline"],
  },
  link: {
    showOpenOptionOnHover: true,
    defaultTargetOption: "_blank",
  },
  list: {
    options: ["unordered", "ordered"],
  },
}

export function useEditor() {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  )
  const [defaultState, setDefaultState] = useState()
  const [htmlTextOnBlur, setHtmlTextOnBlur] = useState()

  const clearEditor = () => {
    setEditorState(EditorState.createEmpty())
  }
  useEffect(() => {
    if (defaultState) {
      const blocksFromHTML = convertFromHTML(defaultState)
      const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      )

      setEditorState(EditorState.createWithContent(state))
    }
  }, [defaultState])

  const onEditorBlur = () => {
    setHtmlTextOnBlur(getHTML())
  }

  const ReelerEditor = () => {
    return (
      <S.EditorContainer>
        <Editor
          editorState={editorState}
          onEditorStateChange={setEditorState}
          wrapperClassName="editor-wrapper"
          editorClassName="text-editor"
          toolbar={toolbarOptions}
          onBlur={onEditorBlur}
        />
      </S.EditorContainer>
    )
  }

  const getHTML = () => {
    return stateToHTML(editorState.getCurrentContent())
  }

  return {
    ReelerEditor,
    editorState,
    getHTML,
    setDefaultState,
    clearEditor,
    htmlTextOnBlur,
  }
}
