import {
  all,
  call,
  put,
  takeLatest,
  select,
  takeEvery,
} from "redux-saga/effects"
import CampaignActionTypes from "./campaign.types"
import { getAccountCampaignsRef } from "../../firebase"
import {
  fetchCampaignsSuccess,
  fetchCampaignsFailure,
  selectField,
  addFormField,
  setShowEditModal,
  setIsSavingFormField,
} from "./campaign.actions"
import { selectAccountId } from "../account/account.selectors"
import { selectCampaign, selectFormSchema } from "./campaign.selectors"

import { v4 as uuidV4 } from "uuid"
import { saveCampaign } from "../../services/CampaignsService"
import { BulkEditActionSuccess } from "../bulk-edit/bulkEdit.actions"

export function* fetchCampaignsAsync() {
  try {
    const accountId = yield select(selectAccountId)
    // const ids = yield Object.keys(accountId)
    const campaignRef = yield call(getAccountCampaignsRef, accountId)

    const { campaigns } = campaignRef
    yield put(fetchCampaignsSuccess({ campaigns }))
  } catch (error) {
    yield fetchCampaignsFailure(error.message)
  }
}

export function* addNewFormFieldAsync({ fieldId, field, newOrder }) {
  try {
    yield put(addFormField(fieldId, field, newOrder))

    const campaign = yield select(selectCampaign)

    yield call(saveCampaign, campaign)
  } catch (error) {
    yield put(
      BulkEditActionSuccess({
        type: "danger",
        duration: 3000,
        text: "Could not add new form field",
        position: "bulkEdit",
      })
    )
  }
}
export function* saveFormFieldAsync({ fieldId, field }) {
  try {
    yield put(setIsSavingFormField(true))
    const formSchema = yield select(selectFormSchema)
    let order = formSchema.order

    yield put(addFormField(fieldId, field, order))

    const campaign = yield select(selectCampaign)

    yield call(saveCampaign, campaign)

    yield put(
      BulkEditActionSuccess({
        type: "success",
        duration: 3000,
        text: "Saved!",
        position: "bulkEdit",
      })
    )
    yield put(setIsSavingFormField(false))
  } catch (error) {
    console.log("error", error)
    yield put(setIsSavingFormField(false))

    yield put(
      BulkEditActionSuccess({
        type: "danger",
        duration: 3000,
        text: "Could not save field",
        position: "bulkEdit",
      })
    )
  }
}

export function* fetchCampaignsStart() {
  yield takeLatest(
    CampaignActionTypes.FETCH_CAMPAIGNS_START,
    fetchCampaignsAsync
  )
}
export function* addNewFormField() {
  yield takeEvery(CampaignActionTypes.ADD_NEW_FORM_FIELD, addNewFormFieldAsync)
}
export function* saveFormField() {
  yield takeEvery(CampaignActionTypes.SAVE_FORM_FIELD, saveFormFieldAsync)
}

export function* campaignSagas() {
  yield all([call(fetchCampaignsStart), addNewFormField(), saveFormField()])
}
