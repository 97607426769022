import React, { useState } from "react"
// 3rd-party libraries
import { Form, Row, Col } from "react-bootstrap"

// Redux
import { useSelector } from "react-redux"
import { selectFormSchema } from "../../../../../redux/campaign/campaign.selectors"

import { countryListAllIsoData } from "../../../../commons/CountryData"
import { usStates } from "../../../../commons/StateData"

// Reeler components
import InputCounter from "../../../../commons/InputCounter"

import * as S from "../FormStyles"

export default function InputWidget({
  fieldId,
  field,
  index,
  refArray,
  disabled = false,
  design,
}) {
  const [textAreaInput, setTextAreaInput] = useState("")
  const [textInput, setTextInput] = useState("")

  return (
    <S.WidgetContainer>
      {field?.title ? (
        <>
          <S.ColoredLabel htmlFor={fieldId} $design={design}>
            {field.title}
          </S.ColoredLabel>
          {field.required ? <S.RequiredMark>*</S.RequiredMark> : null}
        </>
      ) : null}
      {field.format === "textarea" ? (
        <S.FormTextArea
          $focusColor={design?.fieldFocusColor}
          ref={el =>
            (refArray.current = { ...refArray.current, [fieldId]: el })
          }
          style={{ color: "var(--reeler-grey-darker) !important" }}
          rows={field?.rows ? field?.rows : 3}
          placeholder={
            !field?.title && field.required
              ? "*" + field.placeholder
              : field.placeholder
          }
          id={fieldId}
          value={textAreaInput}
          onChange={e => setTextAreaInput(e.target.value)}
          maxLength={field.maxChars}
          disabled={disabled}
          required={field.required}
        />
      ) : field.format === "country" ? (
        <S.FormSelect
          ref={el =>
            (refArray.current = { ...refArray.current, [fieldId]: el })
          }
          $focusColor={design?.fieldFocusColor}
          style={{ color: "var(--reeler-grey-darker) !important" }}
          disabled={disabled}
          required={field.required}
          id={fieldId}
        >
          <option>{field.placeholder}</option>
          {countryListAllIsoData.map((country, index) => (
            <option key={index} value={country.name}>
              {country.name}
            </option>
          ))}
        </S.FormSelect>
      ) : field.format === "state" ? (
        <S.FormSelect
          ref={el =>
            (refArray.current = { ...refArray.current, [fieldId]: el })
          }
          style={{ color: "var(--reeler-grey-darker) !important" }}
          size="sm"
          as="select"
          $focusColor={design?.fieldFocusColor}
          className="form-select"
          disabled={disabled}
          required={field.required}
        >
          <option>{field.placeholder}</option>
          {usStates.map((state, index) => (
            <option key={index} value={state.abbreviation}>
              {state.name}
            </option>
          ))}
        </S.FormSelect>
      ) : (
        <S.FormInput
          ref={el =>
            (refArray.current = { ...refArray.current, [fieldId]: el })
          }
          style={{ color: "var(--reeler-grey-darker) !important" }}
          $focusColor={design?.fieldFocusColor}
          type={field.format}
          placeholder={
            !field?.title && field.required
              ? `${field.placeholder} *`
              : field.placeholder
          }
          id={fieldId}
          value={textInput}
          maxLength={field.maxChars}
          onChange={e => setTextInput(e.target.value)}
          disabled={disabled}
          required={field.required}
        />
      )}
      {field.description ? (
        <S.FormDescriptionContainer>
          <S.ColoredFormText $design={design}>
            {field.description}
          </S.ColoredFormText>
          {field?.maxChars ? (
            <S.InputCounterContainer>
              <InputCounter
                currentChars={textInput.length || textAreaInput.length}
                maxChars={field.maxChars}
              />
            </S.InputCounterContainer>
          ) : null}
        </S.FormDescriptionContainer>
      ) : null}
    </S.WidgetContainer>
  )
}
