import React, { useEffect, useState } from "react"
import { database } from "../../firebase"
import { useSelector } from "react-redux"
// 3rd-party libraries
import parse from "html-react-parser"
import {
  NotificationBarContainer,
  Notification,
  NotificationMessage,
  NotificationDismissButton,
  NotificationTitle,
} from "./notification-bar-styles"
import { selectAccountId } from "../../redux/account/account.selectors"
import CloseButton from "../commons/CloseButton"
export default function NotificationBar() {
  const accountId = useSelector(selectAccountId)
  const [notifications, setNotifications] = useState(null)

  useEffect(() => {
    if (accountId) {
      var unsubscribe = database.notifications
        .where("accountId", "==", accountId)
        .where("show", "==", true)
        .orderBy("createdAt", "desc")
        .limit(1)
        .onSnapshot(querySnapshot => {
          let notificationsArray = []
          querySnapshot.forEach(doc => {
            notificationsArray.push(database.formatDoc(doc))
          })

          setNotifications(notificationsArray)
        })
      return () => unsubscribe()
    }
  }, [accountId])

  const handleDismiss = id => {
    database.notifications.doc(id).update({
      show: false,
    })
  }

  return notifications
    ? notifications.map(notification => (
        <NotificationBarContainer key={notification.id}>
          <Notification message_type={notification?.variant}>
            <NotificationDismissButton
              onClick={() => handleDismiss(notification.id)}
            >
              <CloseButton />
            </NotificationDismissButton>
            {notification?.title ? (
              <NotificationTitle>{notification?.title}</NotificationTitle>
            ) : null}
            <NotificationMessage>
              {notification?.formatted_message
                ? parse(notification?.formatted_message)
                : null}
            </NotificationMessage>
          </Notification>
        </NotificationBarContainer>
      ))
    : null
}
