import React from "react"
import NotificationBar from "../components/notification-bar/NotificationBar"
import BulkEditAlert from "../components/commons/BulkEditAlert/BulkEditAlert"
import MainSidebar from "../components/navbar/MainSidebar"
import CreateFilterCollection from "../components/media-library/components/CreateFilterCollection/CreateFilterCollection"
import AddViewers from "../components/media-library/components/CollectionInformation/components/AddViewers"
import AuthBasedComponent from "../components/authentication/AuthBasedComponent"
import { ACCOUNT_PLANS } from "../constants/routes"
import ForceBrowserReload from "../components/authentication/ForceBrowserReload"

export default function MainLayout({ children }) {
  return (
    <div className="d-flex flex-column position-relative w-100">
      <AuthBasedComponent
        plans={[ACCOUNT_PLANS.basic, ACCOUNT_PLANS.pro, ACCOUNT_PLANS.premium]}
      >
        <NotificationBar />
      </AuthBasedComponent>
      <BulkEditAlert />
      <CreateFilterCollection />
      <AddViewers />
      <ForceBrowserReload />
      <section className="d-flex">
        <div className="vh-100 sticky-top">
          <MainSidebar />
        </div>

        <main
          className="d-flex flex-column w-100"
          style={{ background: "var(--reeler-container)" }}
        >
          <div>{children}</div>
        </main>
      </section>
    </div>
  )
}
