import styled, { css } from "styled-components"

const fontWeight = {
  regular: 400,
  semibold: 500,
  bold: 600,
}

const lineHeight = {
  small: 1,
  regular: 1.2,
  large: 1.5,
}

const fontSize = {
  xm: 10,
  sm: 12,
  md: 16,
  lg: 20,
  xl: 30,
}

export const oneRemInPx = 16

const getFontSize = sizeInPx => {
  const sizeInRem = sizeInPx / oneRemInPx

  return `${sizeInRem}rem`
}

export const Text = styled.div`
  margin: 0;
  padding: 0;
  overflow-wrap: break-word;

  ${({ color }) =>
    color &&
    css`
      color: ${color ? color : "inherit"};
    `}
  ${({ weight }) =>
    weight &&
    css`
      font-weight: ${fontWeight[weight]};
    `}
  ${({ height }) =>
    height &&
    css`
      line-height: ${lineHeight[height]};
    `}
  ${({ size }) =>
    size &&
    css`
      font-size: ${getFontSize(fontSize[size])};
    `}
  ${({ centered }) =>
    centered &&
    css`
      text-align: center;
    `}

  ${({ nowrap }) =>
    nowrap &&
    css`
      white-space: nowrap;
    `}

  ${({ cropped }) =>
    cropped &&
    css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}
`
