import React, { useEffect, useState } from "react"
import { FaBan } from "react-icons/fa"
import ReelerTooltip from "../../../../../commons/ReelerTooltip/ReelerTooltip"
import { useSelector, useDispatch } from "react-redux"
import { updateFormDesign } from "../../../../../../redux/campaign/campaign.actions"
import { selectFormSchema } from "../../../../../../redux/campaign/campaign.selectors"

import ColorPicker from "../../../../../commons/ColorPicker"
import { Col, Row, Form } from "react-bootstrap"
import ReelerAccordation from "../../../../../commons/ReelerAccordation"

import * as S from "../../../styles"
import { FORM_DEFAULT_SETTINGS } from "../../../../../../constants/formDefaultSettings"
import withoutProperty from "../../../../../../utils/withoutProperty"

export const FormDesign = () => {
  const dispatch = useDispatch()

  const formSchema = useSelector(selectFormSchema)

  const [designSettings, setDesignSettings] = useState()

  useEffect(() => {
    if (formSchema) {
      setDesignSettings(formSchema.design)
    }
  }, [formSchema])

  const ClearColor = ({ onClick }) => (
    <ReelerTooltip text="Clear color">
      <FaBan className="me-1 icon-btn icon-color" onClick={onClick} />
    </ReelerTooltip>
  )

  const handleUpdateFormDesign = (target, value) => {
    dispatch(
      updateFormDesign({
        ...formSchema.design,
        [target]: value,
      })
    )
  }

  const handleClearFormDesign = (target, clearColor) => {
    if (clearColor) {
      dispatch(
        updateFormDesign({
          ...formSchema.design,
          [target]: clearColor,
        })
      )
    } else {
      dispatch(
        updateFormDesign({
          ...formSchema.design,
          [target]: null,
        })
      )
    }
  }

  const ColorInput = ({ label, target, defaultColor, clearColor }) => (
    <Form.Group className="mb-2">
      <small>
        <strong>{label}</strong>
      </small>
      <div className="d-flex flex-row align-items-center">
        <Form.Control
          size="sm"
          style={{ width: "100px" }}
          defaultValue={defaultColor}
          onBlur={e => handleUpdateFormDesign(target, e.target.value)}
          onKeyUp={e =>
            e.key === "Enter"
              ? handleUpdateFormDesign(target, e.target.value)
              : null
          }
        />
        <div className="mx-3">
          <Form.Control
            type="color"
            id={"id" + target}
            defaultValue={
              defaultColor !== "transparent" ? defaultColor : "#ffffff"
            }
            title="Choose your color"
            style={{ width: "30px", height: "30px", padding: "0px" }}
            onBlur={e => handleUpdateFormDesign(target, e.target.value)}
            onKeyUp={e =>
              e.key === "Enter"
                ? handleUpdateFormDesign(target, e.target.value)
                : null
            }
          />
        </div>
        <ClearColor onClick={() => handleClearFormDesign(target, clearColor)} />
      </div>
    </Form.Group>
  )
  const SizeInput = ({ label, target, defaultValue }) => (
    <Form.Group className="mb-2">
      <small>
        <strong>{label}</strong>
      </small>
      <Form.Control
        type="number"
        size="sm"
        style={{ width: "100px" }}
        name={target}
        steps={1}
        defaultValue={defaultValue}
        onKeyUp={e =>
          e.key === "Enter"
            ? handleUpdateFormDesign(target, e.target.value)
            : null
        }
        onBlur={e => handleUpdateFormDesign(target, e.target.value)}
      />
    </Form.Group>
  )

  const DesignDropdown = ({ label, target, defaultValue, children }) => (
    <Form.Group className="mb-2">
      <small>
        <strong>{label}</strong>
      </small>
      <Form.Control
        as="select"
        name={target}
        size="sm"
        style={{ width: "200px" }}
        defaultValue={defaultValue}
        onChange={e => handleUpdateFormDesign(target, e.target.value)}
      >
        {children}
      </Form.Control>
    </Form.Group>
  )

  return (
    <>
      <ReelerAccordation
        title={<S.FormFieldTitle>Customize form design</S.FormFieldTitle>}
        visible={true}
      >
        <S.FormFieldGroupContainer>
          <ColorInput
            label="Form background:"
            target="formBackground"
            defaultColor={
              designSettings?.formBackground
                ? designSettings?.formBackground
                : FORM_DEFAULT_SETTINGS.formBackground
            }
            clearColor={FORM_DEFAULT_SETTINGS.formBackground}
          />
          <DesignDropdown
            label="Font"
            target="fontFamily"
            defaultValue={
              designSettings?.fontFamily
                ? designSettings?.fontFamily
                : FORM_DEFAULT_SETTINGS.fontFamily
            }
          >
            <option value="inherit">Default (Inherit)</option>
            <option value="Arial, sans-serif">Arial (sans-serif)</option>
            <option value="Verdana, sans-serif">Verdana (sans-serif)</option>
            <option value="Tahoma, sans-serif">Tahoma (sans-serif)</option>
            <option value="Trebuchet MS, sans-serif">
              Trebuchet MS (sans-serif)
            </option>
            <option value="Times New Roman, serif">
              Times New Roman (serif)
            </option>
            <option value="Georgia, serif">Georgia (serif)</option>
            <option value="Garamond, serif">Garamond (serif)</option>
            <option value="Courier New, serif">Courier New (monospace)</option>
            <option value="Brush Script MT, serif">
              Brush Script MT (cursive)
            </option>
            <option value="sans-serif, serif">Sans-serif</option>
            <option value="serif">Serif</option>
          </DesignDropdown>
        </S.FormFieldGroupContainer>
      </ReelerAccordation>
      <ReelerAccordation
        title={<S.FormFieldTitle>Input field design</S.FormFieldTitle>}
        visible={true}
      >
        <S.FormFieldGroupContainer>
          <ColorInput
            label="Field title color:"
            target="titleColor"
            defaultColor={designSettings?.titleColor}
          />
          <SizeInput
            label="Field title size:"
            target="fieldTitleSize"
            defaultValue={designSettings?.fieldTitleSize}
          />
          <ColorInput
            label="Field help description color:"
            target="descriptionColor"
            defaultColor={designSettings?.descriptionColor}
          />
          <SizeInput
            label="Field help description size:"
            target="fieldDescriptionSize"
            defaultValue={designSettings?.fieldDescriptionSize}
          />
          <ColorInput
            label="Active field focus color:"
            target="fieldFocusColor"
            defaultColor={designSettings?.fieldFocusColor}
          />
        </S.FormFieldGroupContainer>
      </ReelerAccordation>
      <ReelerAccordation
        title={<S.FormFieldTitle>Headings and Paragraph</S.FormFieldTitle>}
        visible={true}
      >
        <S.FormFieldGroupContainer>
          <ColorInput
            label="Heading 1 color:"
            target="h1Color"
            defaultColor={designSettings?.h1Color}
          />
          <SizeInput
            label="Heading 1 size:"
            target="h1Size"
            defaultValue={
              designSettings?.h1Size ? designSettings?.h1Size : null
            }
          />
          <ColorInput
            label="Heading 2:"
            target="h2Color"
            defaultColor={designSettings?.h2Color}
          />
          <SizeInput
            label="Heading 2 size:"
            target="h2Size"
            defaultValue={
              designSettings?.h2Size ? designSettings?.h2Size : null
            }
          />
          <ColorInput
            label="Paragraph color:"
            target="pColor"
            defaultColor={designSettings?.pColor}
          />
          <SizeInput
            label="Paragaph size:"
            target="pSize"
            defaultValue={designSettings?.pSize}
          />
        </S.FormFieldGroupContainer>
      </ReelerAccordation>
      {/* <ReelerAccordation
        title={<S.FormFieldTitle>File upload button</S.FormFieldTitle>}
        visible={true}
      >
        <S.FormFieldGroupContainer>
          <ColorInput
            label="Button font:"
            target="fileBtnFontColor"
            defaultColor={
              designSettings?.fileBtnFontColor
                ? designSettings?.fileBtnFontColor
                : FORM_DEFAULT_SETTINGS.fileBtnFontColor
            }
            clearColor={FORM_DEFAULT_SETTINGS.fileBtnFontColor}
          />
          <ColorInput
            label="Button background:"
            target="fileBtnColor"
            defaultColor={
              designSettings?.fileBtnColor
                ? designSettings?.fileBtnColor
                : FORM_DEFAULT_SETTINGS.fileBtnColor
            }
            clearColor={FORM_DEFAULT_SETTINGS.fileBtnColor}
          />
          <ColorInput
            label="Button border:"
            target="fileBtnBorderColor"
            defaultColor={
              designSettings?.fileBtnBorderColor
                ? designSettings?.fileBtnBorderColor
                : FORM_DEFAULT_SETTINGS.fileBtnBorderColor
            }
            clearColor={FORM_DEFAULT_SETTINGS.fileBtnBorderColor}
          />
          <DesignDropdown
            label="Button size"
            target="fileBtnSize"
            defaultValue={
              designSettings?.fileBtnSize
                ? designSettings?.fileBtnSize
                : FORM_DEFAULT_SETTINGS.fileBtnSize
            }
          >
            <option value="sm">Small</option>
            <option value="md">Medium</option>
            <option value="lg">Large</option>
          </DesignDropdown>
        </S.FormFieldGroupContainer>
      </ReelerAccordation> */}
      <ReelerAccordation
        title={<S.FormFieldTitle>Submit button</S.FormFieldTitle>}
        visible={true}
      >
        <S.FormFieldGroupContainer>
          <ColorInput
            label="Button font:"
            target="submitBtnFontColor"
            defaultColor={
              designSettings?.submitBtnFontColor
                ? designSettings?.submitBtnFontColor
                : formSchema?.properties?.submitButton?.btnFontColor
                ? formSchema?.properties?.submitButton?.btnFontColor
                : FORM_DEFAULT_SETTINGS.submitBtnFontColor
            }
            clearColor={FORM_DEFAULT_SETTINGS.submitBtnFontColor}
          />
          <ColorInput
            label="Button background:"
            target="submitBtnColor"
            defaultColor={
              designSettings?.submitBtnColor
                ? designSettings?.submitBtnColor
                : formSchema?.properties?.submitButton?.btnColor
                ? formSchema?.properties?.submitButton?.btnColor
                : FORM_DEFAULT_SETTINGS.submitBtnColor
            }
            clearColor={FORM_DEFAULT_SETTINGS.submitBtnColor}
          />
          <ColorInput
            label="Button border:"
            target="submitBtnBorderColor"
            defaultColor={
              designSettings?.submitBtnBorderColor
                ? designSettings?.submitBtnBorderColor
                : FORM_DEFAULT_SETTINGS.submitBtnBorderColor
            }
            clearColor={FORM_DEFAULT_SETTINGS.submitBtnBorderColor}
          />

          <DesignDropdown
            label="Button size"
            target="submitBtnSize"
            defaultValue={
              designSettings?.submitBtnSize
                ? designSettings?.submitBtnSize
                : FORM_DEFAULT_SETTINGS.submitBtnSize
            }
          >
            <option value="sm">Small</option>
            <option value="md">Medium</option>
            <option value="lg">Large</option>
          </DesignDropdown>
        </S.FormFieldGroupContainer>
      </ReelerAccordation>
      <ReelerAccordation
        title={<S.FormFieldTitle>Terms & conditions</S.FormFieldTitle>}
        visible={true}
      >
        <S.FormFieldGroupContainer>
          <ColorInput
            label="Terms color:"
            target="termsColor"
            defaultColor={designSettings?.termsColor}
            clearColor={null}
          />
        </S.FormFieldGroupContainer>
      </ReelerAccordation>
    </>
  )
}
